import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { NSRole } from "@dryad-web-app/shared/state"
import { map } from "rxjs/operators"
import { plainToInstance } from "class-transformer"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"

@Injectable({
  providedIn: "root",
})
export class NsRoleService extends ApiHttpService {

  NS_ROLES_API_PATH = "items/ns_role"

  constructor() {
    super()
    this.apiBaseUrl = ApiBase.DATA_API_BASE_URL
  }

  list(): Observable<NSRole[]> {
    return this.get<NSRole[]>(this.NS_ROLES_API_PATH + '?fields=*.*').pipe(
      // @ts-ignore
      map((response) => {
        // @ts-ignore
        const roles = response['data'].map(role=> {
           role.name = role.name +' (' + role.network_server.name + ')'
          return role
        })
        // @ts-ignore
        return plainToInstance(NSRole, roles as Array<NSRole>)
      }),
    )
  }
}
