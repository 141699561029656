<div
  class="layout-wrapper"
  [ngClass]="{
    'layout-wrapper-menu-active': sidebarActive,
    'layout-overlay-menu': overlay
  }"
>
  <dryad-web-app-header></dryad-web-app-header>
  <dryad-web-app-side-bar-menu
    [menuItems]="menuItems"
  ></dryad-web-app-side-bar-menu>
  <app-view-sidebar></app-view-sidebar>
  <div class="layout-main" [ngClass]="{'pre-planning-layout': isPrePlanningRoute}">
    <div class="layout-main-content">
      <dryad-breadcrumb></dryad-breadcrumb>
      <router-outlet></router-outlet>
      <dryad-web-app-footer></dryad-web-app-footer>
    </div>
  </div>
</div>

<app-alert-modal></app-alert-modal>
<app-alert-notification></app-alert-notification>
