import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core"
import {
  DirectusApiService,
  GatewayService,
  SensorNodeService,
} from "@dryad-web-app/shared/data-access"
import {
  DeviceMetadata,
  DeviceTypes, FormEvent, FORMLY_WRAPPER_EVENT, FormlyFieldStatus, ObservableState,
} from "@dryad-web-app/shared/state"
import { FormFactoryService } from "@dryad-web-app/ui/dryad-form"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { ConfirmationService, MessageService } from "primeng/api"
import { Subscription } from "rxjs"

@Component({
  selector: "app-device-metadata",
  templateUrl: "./device-metadata.component.html",
  styleUrls: [ "./device-metadata.component.scss" ],
})
export class DeviceMetadataComponent implements OnInit, OnDestroy, OnChanges {
  @Input() metaData: DeviceMetadata
  @Input() deviceType: DeviceTypes
  @Input() isActive: boolean
  fields: FormlyFieldConfig[]
  subscription = new Subscription()

  constructor(
    private formFactory: FormFactoryService,
    private sensorNodeService: SensorNodeService,
    private gatewayService: GatewayService,
    private messageService: MessageService,
    private oss: ObservableState,
    private directusService: DirectusApiService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit(): void {
    this.fields = this.formFactory.sensorNodeMetaFields()
    this.initData()
    this.subscription.add(
      this.oss.formlyEditWrapperEvent$().subscribe((data: FormlyFieldStatus) => {
        if (this.isActive) {
          if (data && data.field && data.type === FormEvent.UPDATE) {
            if (this.deviceType === DeviceTypes.SENSOR_NODE) {
              this.sensorNodeService
                .updateSensorNode(this.metaData)
                .subscribe(() => {
                  this.messageService.add({
                    severity: "success",
                    summary: "Device Update",
                  })
                  this.initData()
                })
            } else {
              this.gatewayService
                .updateGateway(this.metaData)
                .subscribe(() => {
                  this.messageService.add({
                    severity: "success",
                    summary: "Device Update",
                  })
                  this.initData()
                })
            }
          }
        }
      })
    )
  }

  initData(): void {
    if (this.deviceType === DeviceTypes.SENSOR_NODE) {
      this.sensorNodeService.sensorNode(this.metaData.id).subscribe(data => {
        this.metaData.notes = data.notes
        this.metaData = { ...this.metaData }
      })
    } else {
      this.gatewayService.gateway(this.metaData.id).subscribe(data => {
        this.metaData.notes = data.notes
        this.metaData = { ...this.metaData }
      })
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.oss.dispatch({
      type: FORMLY_WRAPPER_EVENT,
      payload: new FormlyFieldStatus(null, FormEvent.DESTROY),
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isActive = changes.isActive.currentValue
  }

  handleReset(): void {
    this.confirmationService.confirm({
      header: "Reset confirmation ",
      message: "This will remove the location data and reset the deployment status to pending. " +
        "This action cannot be undone. Do you want to proceed?",
      icon: "pi pi-info-circle",
      acceptLabel: "Confirm",
      rejectButtonStyleClass: " cancel-btn button-only-boarder",
      acceptButtonStyleClass: "p-button ",
      accept: () => this.sendResetRequest(),
    })
  }

  sendResetRequest(): void {
    this.directusService.resetDeviceStatus([ this.metaData.id ], this.deviceType).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Device Update",
      })
    })
  }
}
