import { DateUtils } from "@dryad-web-app/shared/state"
import { Map as MGLMap } from "maplibre-gl"

export type RemoveListenerCallback = () => void

export const urlToImage = (name: string, url: string, size=64): Promise<[string, HTMLImageElement]> => new Promise((resolve) => {
  const img = new Image(size, size)
  img.src = url
  img.onload = (): void => resolve([name, img])
})

class Utils {
  static ZIndex = {
    Marker: {
      SITE_AREA: 0,
      DEVICE: 1,
      DEVICE_CLUSTER: 2,
      SITE: 3,
      SITE_CLUSTER: 4,
    },
    InfoWindow: {
      SITE_CLUSTER: 10,
      SITE: 11,
      DEVICE_CLUSTER: 12,
    },
  }

  static makeControlContainer = (map: MGLMap, name: string): void => {
    const container = map._controlPositions[name] || document.createElement("div")
    container.className = `maplibregl-ctrl-${name}`
    map._controlPositions[name] = container
    map._controlContainer.appendChild(container)
  }

  static getFireLayerUrl = (): string => "https://ies-ows.jrc.ec.europa.eu/gwis?" +
    "LAYERS=ecmwf.fwi" +
    "&FORMAT=image/png" +
    "&TRANSPARENT=true" +
    "&SINGLETILE=false" +
    "&SERVICE=wms&VERSION=1.1.1" +
    "&REQUEST=GetMap" +
    "&SRS=EPSG:3857&WIDTH=2000&HEIGHT=1000&TIME=" +
    DateUtils.currentYYYYMMDD() +
    "&BBOX={bbox-epsg-3857}"
}

export default Utils
