import { Injectable, NgZone } from "@angular/core"
import { Store } from "@ngrx/store"
import { Observable } from "rxjs"
import { skip, take } from "rxjs/operators"
import {
  FirmwareVersion,
  FormlyFieldStatus,
  NetworkStatus,
  SiteDataAggregate,
  UpMessage,
  UserDetails,
} from "./model"
import { AppAction, } from "./reducers.state"
import { FloatingPanel, ViewSidebarState } from "./ui-state"

export interface AppState {
  userLoggedIn: UserDetails
  currentSiteList: SiteDataAggregate[]
  viewSidebarState: ViewSidebarState
  fireAlertEventState: any
  fireAlertsMinimise: any
  fireAlerts: Map<string, UpMessage[]>,
  acknowledgedAlertIds: string[],
  floatingPanelEvents: FloatingPanel,
  networkConnectionState: NetworkStatus,
  formlyEditWrapperEvent: FormlyFieldStatus,
  allSiteSafe: boolean,
  firmwareVersion: FirmwareVersion[],
}


export const initialAppState: AppState = {
  // @ts-ignore
  userLoggedIn: undefined,
  currentSiteList: [],
  // @ts-ignore
  viewSidebarState: undefined,
  fireAlertsMinimise: undefined,
  fireAlertEventState: undefined,
  fireAlerts: new Map<string, UpMessage[]>(),
   // @ts-ignore
  acknowledgedAlertIds: undefined,
  // @ts-ignore
  floatingPanelEvents: undefined,
  // @ts-ignore
  networkConnectionState: undefined,
  // @ts-ignore
  formlyEditWrapperEvent: undefined,
  // @ts-ignore
  allSiteSafe: undefined,
  // @ts-ignore
  firmwareVersion: undefined,
}

@Injectable()
export class ObservableState {
  constructor(private store: Store<AppState>, private ngZone: NgZone) {
    this.store.pipe(skip(1))
  }

  appState(): AppState {
    let state: AppState = initialAppState
    this.store.pipe(take(1)).subscribe((s: AppState) => (state = s)) // You can always rely on subscribe()
    // running synchronously if you have
    // to get the state value
    return state
  }

  appStore(): Store<AppState> {
    return this.store
  }

  dispatch(action: AppAction) {
    return this.ngZone.run(() => this.store.dispatch(action))
  }

  userLoggedIn(): UserDetails {
    return this.appState().userLoggedIn
  }

  userLoggedIn$(): Observable<UserDetails> {
    return this.appStore().select((state: AppState) => state.userLoggedIn)
  }

  currentSiteList(): SiteDataAggregate[] {
    return this.appState().currentSiteList
  }

  currentSiteList$(): Observable<SiteDataAggregate[]> {
    return this.appStore().select((state: AppState) => state.currentSiteList)
  }

  viewSidebarState(): ViewSidebarState {
    return this.appState().viewSidebarState
  }

  viewSidebarState$(): Observable<ViewSidebarState> {
    return this.appStore().select((state: AppState) => state.viewSidebarState)
  }

  fireAlertEventState(): any {
    return this.appState().fireAlertEventState
  }

  fireAlertEventState$(): Observable<any> {
    return this.appStore().select(
      (state: AppState) => state.fireAlertEventState
    )
  }

  fireAlerts(): Map<string, UpMessage[]> {
    return this.appState().fireAlerts
  }

  fireAlerts$(): Observable<Map<string, UpMessage[]>> {
    return this.appStore().select((state: AppState) => state.fireAlerts)
  }

  fireAlertsMinimise(): any {
    return this.appState().fireAlertsMinimise
  }

  fireAlertsMinimise$(): Observable<any> {
    return this.appStore().select((state: AppState) => state.fireAlertsMinimise)
  }

  acknowledgedFireAlertIds(): string[] {
    return this.appState().acknowledgedAlertIds
  }

  acknowledgedFireAlertIds$(): Observable<string[]> {
    return this.appStore().select((state: AppState) => state.acknowledgedAlertIds)
  }

  floatingPanelEvents(): any {
    return this.appState().floatingPanelEvents
  }

  floatingPanelEvents$(): Observable<any> {
    return this.appStore().select((state: AppState) => state.floatingPanelEvents)
  }

  networkConnectionState(): NetworkStatus {
    return this.appState().networkConnectionState
  }

  networkConnectionState$(): Observable<NetworkStatus> {
    return this.appStore().select((state: AppState) => state.networkConnectionState)
  }

  formlyEditWrapperEvent(): FormlyFieldStatus {
    return this.appState().formlyEditWrapperEvent
  }

  formlyEditWrapperEvent$(): Observable<FormlyFieldStatus> {
    return this.appStore().select((state: AppState) => state.formlyEditWrapperEvent)
  }
  allSiteSafe$(): Observable<boolean> {
    return this.appStore().select((state: AppState) => state.allSiteSafe)
  }

  firmwareVersion(): FirmwareVersion[] {
    return this.appState().firmwareVersion
  }

  firmwareVersion$(): Observable<FirmwareVersion[]> {
    return this.appStore().select((state: AppState) => state.firmwareVersion)
  }
}
