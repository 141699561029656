<div class="training-controls">
  <button
    pButton
    type="button"
    label="New Experiment"
    (click)="handleNewRecord()"
  ></button>
  <button
    pButton
    type="button"
    icon="ph ph-arrows-clockwise"
    (click)="init(10,0)"
  ></button>
</div>
<p-table
  [value]="experiments"
  [rows]="limit"
  [paginator]="true"
  [lazy]="true"
  [loading]="loading"
  (onLazyLoad)="loadExperiment($event)"
  [totalRecords]="totalRecords"
  dataKey="id"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [showCurrentPageReport]="true"
>

  <ng-template pTemplate="header">
    <tr>
      <th>ID</th>
      <th>Start Time</th>
      <th>End Time</th>
      <th>Venue</th>
      <th [pTooltip]="'Burn Material'">Material</th>
      <th [pTooltip]="'Burn Material Amount'"> Amount</th>
      <th [pTooltip]="'Sensor Hotplate Distance'">Distance</th>
      <th>Hotplate Start</th>
      <th>Hotplate Temp</th>
      <th>Notes</th>
      <th></th>
    </tr>
    <tr>
      <th>
        <columnFilter type="text" field="id" [showMenu]="false"></columnFilter>
      </th>
      <th>
        <columnFilter type="text" field="start_time" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-calendar
              #calendar
              [ngModel]="value"
              (onClose)="filter(calendar.value)"
              [showTime]='true'
              dataType="string"
              appendTo="body"
              dateFormat="dd.mm.yy"
            ></p-calendar>
          </ng-template>
        </columnFilter>
      </th>
      <th>
        <columnFilter type="text" field="end_time" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback" >
            <p-calendar
              #calendar
              [ngModel]="value"
              (onClose)="filter(calendar.value)"
              [showTime]='true'
              dataType="string"
              appendTo="body"
              dateFormat="dd.mm.yy"
            ></p-calendar>
          </ng-template>
        </columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-record>

    <tr>
      <td>{{ record.id }}</td>
      <td>{{ record.start_time | date : 'dd.MM.yyyy HH:mm'}}</td>
      <td>{{ record.end_time | date : 'dd.MM.yyyy HH:mm'}}</td>
      <td>{{ record.venue }}</td>
      <td>{{ record.burn_material }}</td>
      <td>{{ record.burn_material_amount }}</td>
      <td>{{ record.sensor_hotplate_distance }}</td>
      <td>{{ record.hotplate_start | date : 'dd.MM.yyyy HH:mm'}}</td>
      <td>{{ record.hotplate_temp }}</td>
      <td>{{ record.notes }}</td>
      <td>
        <button
          pButton
          pRipple
          icon="ph ph-trash"
          class="p-button-rounded p-button-warning p-mr-2"
          (click)="deleteRecord(record)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
