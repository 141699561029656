import { HttpContextToken, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiBase, ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { MessageService } from "primeng/api"
import { from, Observable, of, throwError } from "rxjs"
import { catchError, mergeMap, tap } from "rxjs/operators"
import { Router } from "@angular/router"

interface TokenData {
  legacyToken: string
  forestfloorToken: string
}

const TOKEN_AUTHORITY = new HttpContextToken<"forestfloor" | "legacy">(() => "legacy")

const isValidJwt = (token: string, allowPermanentToken = false): boolean => {
  if (!token.startsWith("ey")) return allowPermanentToken
  const parts = token.split(".")
  if (parts.length !== 3) return allowPermanentToken
  try {
    const decoded = window.atob(parts[1])
    const payload = JSON.parse(decoded)
    return (payload.exp || 0) * 1000 > Date.now()
  } catch {
    return false
  }
}

const addAuthHeader = <T>(request: HttpRequest<T>, { legacyToken, forestfloorToken }: TokenData): HttpRequest<T> => {
  let headers = request.headers
  if (request.url?.includes("forestfloor")) {
    request.context.set(TOKEN_AUTHORITY, "forestfloor")
    headers = headers.append(
      "Authorization",
      `Bearer ${forestfloorToken}`,
    ).append("Accept",  "application/json; version=1.0")
  } else {
    request.context.set(TOKEN_AUTHORITY, "legacy")
    headers = headers.append(
      "Authorization",
      `Bearer ${legacyToken}`,
    )
  }
  return request.clone({ headers })
}

@Injectable({ providedIn: "root" })
export class RestApiInterceptor implements HttpInterceptor {
  protected environment: any
  private showLogoutWarning = false
  private legacyTokenPromise?: Promise<string>
  private ffTokenPromise?: Promise<string>

  constructor(
    private messageService: MessageService, 
    private router: Router,) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
  }

  async refreshLegacyToken(): Promise<string> {
    if (this.legacyTokenPromise) return this.legacyTokenPromise
    
    const doRefresh = async () => {
      const response = await fetch(`${ApiBase.DATA_API_BASE_URL}auth/refresh`, {
        method: "POST",
        credentials: "include",
      })
      if (!response.ok) throw new Error("Unable to refresh legacy token")
      const payload = await response.json()
      const token = payload.data.access_token
      if (!token) throw new Error("Received invalid legacy token")
      this.environment.dataApiKey = token
      return token
    }
    this.legacyTokenPromise = doRefresh()
    setTimeout(() => this.legacyTokenPromise = undefined, 30_000)
    return this.legacyTokenPromise
  }

  async refreshFFToken(): Promise<string> {
    if (this.ffTokenPromise) return this.ffTokenPromise

    const doRefresh = async () => {
      const response = await fetch(`${ApiBase.FORESTFLOOR_API_BASE_URL}token/refresh/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Accept": "application/json; version=1.0"
        }
      })
      if (!response.ok) {
        console.log("ff refresh unable", response)
        throw new Error("Unable to refresh FF token")
      }
      const payload = await response.json()
      const token = payload.access
      if (!token) {
        console.log("invalid ff token", payload)
        throw new Error("Received invalid FF token")
      }
      localStorage.setItem("forestfloor_token", token)
      return token
    }

    this.ffTokenPromise = doRefresh()
    setTimeout(() => this.ffTokenPromise = undefined, 30_000)
    return this.ffTokenPromise
  }

  async ensureTokens(): Promise<TokenData> {
    try {
      let legacyToken = this.environment.dataApiKey
      if (!legacyToken || !isValidJwt(legacyToken, true)) legacyToken = await this.refreshLegacyToken()
      let forestfloorToken = localStorage.getItem("forestfloor_token")
      if (!forestfloorToken || !isValidJwt(forestfloorToken)) forestfloorToken = await this.refreshFFToken()
      return { legacyToken, forestfloorToken }
    } catch (error) {
      console.error("Error while refreshing tokens:", error)
      return {
        legacyToken: "",
        forestfloorToken: ""
      }
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Don't handle auth if we already have a header
    if (request.headers.has("Authorization")) return next.handle(request).pipe(catchError((err) =>throwError(err)))
    
    return from(this.ensureTokens()).pipe(
      catchError((error): Observable<TokenData> => {
        console.log("error during ensureToken", error)
        return of({ forestfloorToken: "", legacyToken: "" })
      }),
      // Ensure non-expired tokens and inject headers
      mergeMap((tokenData) => next.handle(addAuthHeader(request, tokenData)))
    ).pipe(catchError((error, caught) => {
      console.log("unhappy path", { error, caught, request })
      // Forward auth-unrelated errors
      if (error.status !== 401) return
      // Reset tokens and retry
      if (request.context.get(TOKEN_AUTHORITY) === "forestfloor") {
        localStorage.removeItem("forestfloor_token")
      } else {
        this.environment.dataApiKey = undefined
      }
      return from(this.ensureTokens()).pipe(
        mergeMap(tokenData => next.handle(addAuthHeader(request, tokenData)))
      ).pipe(
        catchError((_, caught) => {
          console.log("fallthrough: back to login", _ , caught)
          // Fallthrough: Navigate to login screen
          this.router.navigate(["/login"], { queryParams: { reason: "token_expired", service: request.context.get(TOKEN_AUTHORITY) }})
          if (!this.showLogoutWarning) {
            this.showLogoutWarning = true
            this.messageService.add({
              severity: "info",
              summary: "Info",
              detail:
                "You have been logged out due to inactivity. Please sign in again.",
              sticky: true,
              closable: true,
            })
          }
          return of(new HttpResponse({
            status: 401,
            statusText: "Token expired"
          }))
        })
      )
    })).pipe(tap(result => {
      // Reset warning after successful response
      if (result.type === HttpEventType.Response && result.ok) this.showLogoutWarning = false
    }))
  }
}
