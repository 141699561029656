<div *ngFor="let field of field.fieldGroup; let i = index;" class="grid flex-center">
  <div class="col-12 md:col-11 ">
    <formly-group
      [model]="model[i]"
      [field]="field"
      [options]="options"
      [form]="formControl">
    </formly-group>
  </div>

  <div class="col-12 md:col-1">
    <p-button
      pRipple
      label="Remove"
      styleClass="p-button-danger"
      (click)="remove(i)"></p-button>
  </div>
</div>
<div  class="grid flex-center">
  <div class="col-12 md:col-12 ">
  <p-button
    *ngIf="(!to.maxItems || to.maxItems > field.fieldGroup.length)"
    pRipple
    label="{{ to.addBtn.label }}"
    (click)="addFn()"></p-button>
  </div>
</div>
