<div
  class="p-field-radiobutton"
  *ngFor="let option of to.options | formlySelectOptions: field | async"
>
  <p-radioButton
    [name]="field.name || id"
    [formControl]="formControl"
    [label]="option.label"
    [value]="option.value"
  >
  </p-radioButton>
</div>
