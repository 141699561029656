import { Injectable } from "@angular/core"
import {
  ACCESS_EXPERIMENT_VIEW,
  ACCESS_SENSOR_DATA_LOGS_VIEW, ACCESS_USER_MGT_VIEW,
  DB_SETTINGS_UPDATE, DEFAULT_PERMIT, GATEWAY_DELETE,
  ObservableState, SENSOR_NODE_ADD,
  SENSOR_NODE_DELETE,
  SITE_DELETE, SITE_UPDATE,
  User,
} from "@dryad-web-app/shared/state"
import { RoleNames, ResellerFlag } from "./constants"

@Injectable({
  providedIn: "root",
})
export class RbacService {
  constructor(private oss: ObservableState) { }

  permit(action: string): boolean {
    const currentUser = this.oss.userLoggedIn()
    switch (action) {
      case DB_SETTINGS_UPDATE:
      case ACCESS_SENSOR_DATA_LOGS_VIEW:
      case ACCESS_EXPERIMENT_VIEW:
      case ACCESS_USER_MGT_VIEW:
      case SITE_UPDATE:
      case SITE_DELETE:
      case SENSOR_NODE_ADD:
      case SENSOR_NODE_DELETE:
      case GATEWAY_DELETE:
        return this.isSuperAdmin(currentUser?.user)
      case DEFAULT_PERMIT:
        return true
      default:
        return false
    }
  }

  private isSuperAdmin(user: User): boolean {
    return (user && user.roleName?.toLowerCase() === RoleNames.DryadAdmin.toLowerCase())
      || (user && user.roleName?.toLowerCase() === RoleNames.SuperAdmin.toLowerCase())
  }

  public isSuperAdminUser():boolean {
    const currentUser = this.oss.userLoggedIn()
    return (currentUser?.user && currentUser?.user.roleName?.toLowerCase() === RoleNames.SuperAdmin.toLowerCase())
  }

  public isDryadAdmin(): boolean {
    const currentUser = this.oss.userLoggedIn()
    return (currentUser?.user && currentUser?.user.roleName?.toLowerCase() === RoleNames.DryadAdmin.toLowerCase())
  }

  public isClientAdmin(): boolean | undefined{
    const currentUser = this.oss.userLoggedIn()
    if (currentUser) return currentUser.user && currentUser.user.roleName?.toLowerCase() === RoleNames.ClientAdmin.toLowerCase()
  }

  public isStandardUser(): boolean | undefined {
    const currentUser = this.oss.userLoggedIn()
    if (currentUser)  return currentUser.user && currentUser.user.roleName?.toLowerCase() === RoleNames.StandardUser.toLowerCase()
  }

  public isUserReseller(): boolean {
    const currentUser = this.oss.userLoggedIn()
    return currentUser.user.reseller_flag === ResellerFlag.Reseller
  }

  public isUserDirectCustomer(): boolean {
    const currentUser = this.oss.userLoggedIn()
    return currentUser.user.reseller_flag === ResellerFlag.DirectCustomer
  }
  public isUserInternalCustomer(): boolean {
    const currentUser = this.oss.userLoggedIn()
    return currentUser.user.reseller_flag === ResellerFlag.Internal
  }
}
