import { Injectable } from '@angular/core';
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiHttpService {
  protected environment: any
  constructor() {
    super(ApiHttpService.DATA_API_BASE_URL)
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
  }

  redirectDirectusAuth() {
      window.location.replace(
        `${this.apiBaseUrl}auth/login/keycloak/?mode=cookie&redirect=${window.location.origin}/oauth/callback`
      )
      return true
  }

  logout(): Observable<boolean> {
    return this.post("auth/logout", {}, undefined, true).pipe(map(() => true))
  }
}
