import { Component, OnInit, ViewEncapsulation } from "@angular/core"
import { FloatingPanel, ObservableState } from "@dryad-web-app/shared/state"
import { NavigationStart, Router } from "@angular/router"

@Component({
  selector: "dryad-web-app-floating-panel",
  templateUrl: "./floating-panel.component.html",
  styleUrls: ["./floating-panel.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FloatingPanelComponent implements OnInit {
  floatingPanel: FloatingPanel | undefined

  constructor(private oss: ObservableState, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.floatingPanel = undefined
      }
    })
    this.oss.floatingPanelEvents$().subscribe((data: FloatingPanel) => {
      if (data) {
        this.floatingPanel = data
      } else {
        this.floatingPanel = undefined
      }
    })
  }

  ngOnInit(): void {
  }

}
