import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { ObservableState, DryadRoutes } from "@dryad-web-app/shared/state"

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  constructor(
    private oss: ObservableState,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.oss.userLoggedIn())
      this.router.navigate([DryadRoutes.DASHBOARD])
    else
      this.router.navigate([DryadRoutes.LOGIN])
  }
}
