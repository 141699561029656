import { Pipe, PipeTransform } from '@angular/core'
import { JobStage, JobStatus, JobStatusResponse } from "@dryad-web-app/shared/state"

@Pipe({
  name: 'jobStatus'
})
export class JobStatusPipe implements PipeTransform {

  transform(jobStatusResponse:JobStatusResponse ): string {
    const status:JobStage[] = jobStatusResponse.jobStageTracker
    if(jobStatusResponse.jobStatus === JobStatus.COMPLETED) {
      if (!status) return "N/A"
      const isSuccess = status.find(job => !job.status)
      if (!isSuccess) return "Successful"
      if (isSuccess) return "Failed"
    }else {
      return jobStatusResponse.jobStatus
    }
  }

}
