<div id="welcomePage">
  <header>
    <a href="https://dryad.net" target="_blank">
      <img
        src="/assets/images/logo/dryad-logo-with-name.svg"
        class="margin-t-m margin-l-m"
        alt="dryad's logo"
      />
    </a>

    <div id="presentation" class="padding-l-l padding-r-l">
      <h1 class="text-uppercase bold">Connecting the natural world</h1>

      <p>
        Dryad provides ultra-early fire detection as well as health & growth
        monitoring solutions for public and private forests.
      </p>
    </div>
  </header>

  <section class="flex justify-content-center padding-m">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1310 208" fill="none">
      <g clip-path="url(#clip0_3046_4663)">
        <path
          id="waveBack"
          d="M695 98.475L734.452 91.1773C773.903 84.1523 852.806 69.1477 931.71 58.4398C1010.61 47.3227 1089.52 40.5023 1168.42 51.2102C1247.32 62.3273 1326.23 90.9727 1405.13 109.388C1484.03 127.802 1562.94 134.623 1641.84 120.3C1720.74 105.977 1799.65 69.1477 1839.1 51.2102L1878.55 33V207.6H1839.1C1799.65 207.6 1720.74 207.6 1641.84 207.6C1562.94 207.6 1484.03 207.6 1405.13 207.6C1326.23 207.6 1247.32 207.6 1168.42 207.6C1089.52 207.6 1010.61 207.6 931.71 207.6C852.806 207.6 773.903 207.6 734.452 207.6H695V98.475Z"
          fill-opacity="0.37"
        />
        <path
          id="waveFront"
          d="M0 78.0926L43.6667 69.3885C87.3333 61.0098 174.667 43.1136 262 30.3422C349.333 17.0828 436.667 8.94811 524 21.7195C611.333 34.979 698.667 69.1445 786 91.108C873.333 113.072 960.667 121.206 1048 104.123C1135.33 87.0407 1222.67 43.1136 1266.33 21.7195L1310 0V208.247H1266.33C1222.67 208.247 1135.33 208.247 1048 208.247C960.667 208.247 873.333 208.247 786 208.247C698.667 208.247 611.333 208.247 524 208.247C436.667 208.247 349.333 208.247 262 208.247C174.667 208.247 87.3333 208.247 43.6667 208.247H0V78.0926Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3046_4663">
          <rect width="1310" height="208" fill="white" />
        </clipPath>
      </defs>
    </svg>

    <p>
      Login with your Dryad account to manage and access data from your sites
      protected by
      <a href="https://www.dryad.net/silvanet" target="_blank" class="bold">
        Silvanet
      </a>
      .
    </p>

    <dryad-button
      label="Login"
      (click)="login()"
      styleClass="button-primary margin-t-l"
    ></dryad-button>
    <form #ffForm ngNoForm method="post" [action]="redirectUrl">
      <input type="hidden" name="provider" value="keycloak" />
      <input type="hidden" name="process" value="login" />
      <input type="hidden" name="callback_url" [value]="callbackUrl" />
      <input type="hidden" name="csrfmiddlewaretoken" [value]="csrfToken" />
    </form>
  </section>
</div>
