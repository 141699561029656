import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { GatewayService, SensorNodeService } from "@dryad-web-app/shared/data-access"
import { DeviceTypes, GatewayDataDetails, SensorNodeDataDetails } from "@dryad-web-app/shared/state"

@Component({
  selector: "app-device-tech-info",
  templateUrl: "./device-tech-info.component.html",
  styleUrls: ["./device-tech-info.component.scss"],
})
export class DeviceTechInfoComponent implements OnInit, OnChanges {
  @Input() deviceId: number
  @Input() deviceType: DeviceTypes
  @Input() isActive: boolean
  gatewayTechInfo: GatewayDataDetails
  sensorTechInfo: SensorNodeDataDetails
  isLoading = false

  constructor(
    private sensorNodeService: SensorNodeService,
    private gatewayService: GatewayService,
  ) {
  }

  ngOnInit(): void {
    if (this.isActive) this.initDeviceData()
  }

  initDeviceData(): void {
    this.isLoading = true
    if (this.deviceType === DeviceTypes.SENSOR_NODE) {
      this.sensorNodeService.sensorNodeWithDetails(this.deviceId).subscribe(data => {
        this.sensorTechInfo = data
        this.isLoading = false
      })
    } else {
      this.gatewayService.gatewayWithDetails(this.deviceId).subscribe(data => {
        this.gatewayTechInfo = data
        this.isLoading = false
      })
    }
  }

  ngOnChanges(): void {
    if (this.isActive) this.initDeviceData()
  }
}
