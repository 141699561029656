import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { plainToClass } from "class-transformer"
import { Observable, of } from "rxjs"
import { map } from "rxjs/operators"
import {
  EntityType,
  Gateway,
  GatewayDataDetails,
  GatewaySensorTopology,
  GatewayTypes
} from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class GatewayService extends ApiHttpService {
  private readonly GATEWAY_API_PATH = "gateways"

  constructor() {
    super()
  }

  gatewaysForSite(
    siteId: number,
    params = new HttpParams(),
    deploymentStatus?: string
  ): Observable<Gateway[]> {
    const gatewayFilter = {
      site: {
        id: {
          _eq: siteId,
        },
      }
    }
    if (deploymentStatus) {
      // @ts-ignore
      gatewayFilter["deployment_status"] = {
        label: { _eq: deploymentStatus },
      }
    }
    return this.get<Gateway[]>(
      this.GATEWAY_API_PATH,
      params.append("filter", JSON.stringify(gatewayFilter))
    ).pipe(map((response) => plainToClass(Gateway, response)))
    }
 
  
  gateways(limit: number = -1, offset: number = 0): Observable<Gateway[]> {
    let params = new HttpParams().set("sort", "site,id")
    params = params.set("limit", limit)
    if (offset > 0) params = params.set("offset", offset)
    return this.get<Gateway[]>(this.GATEWAY_API_PATH, params).pipe(
      map((response) => plainToClass(Gateway, response)),
    )
  }

  gateway(gatewayId: number, params = new HttpParams()): Observable<Gateway> {
    return this.get<Gateway>(
      this.GATEWAY_API_PATH + "/" + gatewayId,
      params,
    ).pipe(map((response) => plainToClass(Gateway, response)))
  }

  gatewayWithDetails(
    gatewayId: number,
    params = new HttpParams(),
  ): Observable<GatewayDataDetails> {
    return this.get<GatewayDataDetails>(
      this.GATEWAY_API_PATH + "/" + gatewayId + "/details",
      params,
    ).pipe(map((response) => plainToClass(GatewayDataDetails, response)))
  }

  createGateway(gatewayDataDetails: GatewayDataDetails): Observable<Gateway> {
    return this.post<Gateway>(this.GATEWAY_API_PATH, gatewayDataDetails).pipe(
      map((response) => plainToClass(Gateway, response)),
    )
  }

  updateGateway(gateway: any): Observable<Gateway> {
    return this.put<Gateway>(
      this.GATEWAY_API_PATH + "/" + gateway.id,
      gateway,
    ).pipe(map((response) => plainToClass(Gateway, response)))
  }

  deleteGateway(id: number): Observable<boolean> {
    return this.delete(this.GATEWAY_API_PATH + "/" + id)
  }

  types(): Observable<EntityType[]> {
    return this.get<EntityType[]>(this.GATEWAY_API_PATH + "/types").pipe(
      map((response) => plainToClass(EntityType, response)),
    )
  }

  getBorderGatewaysForSite(
    siteId: number,
    params = new HttpParams(),
  ): Observable<Gateway[]> {
    const gatewayFilter = {
      site: {
        id: {
          _eq: siteId,
        },
      },
      gateway_type: {
        _eq: GatewayTypes.BORDER_GATEWAY
      }
    }
    return this.get<Gateway[]>(
      this.GATEWAY_API_PATH,
      params.append("filter", JSON.stringify(gatewayFilter))
    ).pipe(map((response) => plainToClass(Gateway, response)))
  }

  getSensorTopologyForGateway(siteId:number,bgEUI:string,mgEUI: string):Observable<GatewaySensorTopology> {
    return this.get<GatewaySensorTopology>(
      this.GATEWAY_API_PATH + "/snt/" + siteId + "/" + bgEUI + "/" + mgEUI,
    ).pipe(map((response:GatewaySensorTopology) => plainToClass(GatewaySensorTopology, response)))
  }
}
