import { Component, OnInit } from "@angular/core"
import { SiteService } from "@dryad-web-app/shared/data-access"
import { SiteDataAggregate, DryadRoutes } from "@dryad-web-app/shared/state"

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  DryadRoutes = DryadRoutes
  sites: SiteDataAggregate[] = []
  totalGateways = 0
  totalSensors = 0
  totalSites = 0
  isLoading = true

  constructor(
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.siteService
      .sitesWithAggregate()
      .subscribe((sites: SiteDataAggregate[]) => {
        this.sites = sites
        this.totalSites = sites.length
        sites.map((s) => {
          if (s.aggregate?.gatewayDetails)
            this.totalGateways += s.aggregate?.gatewayDetails?.nbOfDevices
          if (s.aggregate?.sensorNodeDetails)
            this.totalSensors += s.aggregate?.sensorNodeDetails?.nbOfDevices
        })
        this.isLoading = false
      })
  }
}
