<span class="p-float-label input-wrapper">
  <input
    [ngClass]="{ 'ng-invalid': !formControl.valid }"
    pInputText
    [type]="to.type || 'text'"
    [formControl]="formControl"
    [formlyAttributes]="field"
  />
  <label [ngClass]="{ 'has-error': showError }"
  >{{ to.label }}<span *ngIf="to.required">*</span></label
  >
  <span class="p-invalid error-message" *ngIf="showError">{{
    FormUtils.fieldErrorMessage(to.label, formControl)
    }}</span>
</span>
