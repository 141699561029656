<form [formGroup]="form">
  <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form" (change)="changeForm()" (modelChange)="changeForm()">
  </formly-form>
  <span *ngIf="showErrorSummery">
    <div class="error-summery" *ngIf="form.invalid && form.dirty && form.errors !== null">
      <i class="ph ph-warning-circle"></i>
      <span>Required field not provided</span>
    </div>
  </span>

  <div class="form-buttons p-3">
    <dryad-button *ngIf="showSubmitButton" type="button" [disabled]="this.form.invalid || this.form.pristine || isDisabled" label="Submit"
      [loading]="isSubmitting" (click)="!(this.form.invalid || this.form.pristine) && handleSubmit()"></dryad-button>
    <dryad-button class="ml-3" [showCancelButton]="showCancelButton" *ngIf="showCancelButton" label="Cancel" (click)="handleCancel()"></dryad-button>
  </div>
</form>
