export const DEFAULT_MIN_DENSITY = 500
export const DEFAULT_MID_DENSITY = 200
export const DEFAULT_MAX_DENSITY = 100

export const GATEWAY_ALTITUDE_LOW_METERS = 3
export const GATEWAY_ALTITUDE_MEDIUM_METERS = 5
export const GATEWAY_ALTITUDE_HIGH_METERS = 10

export const GATEWAY_RADIUS_SHORT_METERS = 700
export const GATEWAY_RADIUS_MEDIUM_METERS = 1500
export const GATEWAY_RADIUS_LONG_METERS = 3000

export const GATEWAY_DEFAULT_ALTITUDE = GATEWAY_ALTITUDE_LOW_METERS
export const GATEWAY_DEFAULT_MAX_DISTANCE = GATEWAY_RADIUS_SHORT_METERS
export const MAX_BLOCKED_DISTANCE = 2 * GATEWAY_DEFAULT_MAX_DISTANCE

export const DEFAULT_SUFFICIENT_COVERAGE = 95

export const ALTITUDE_RADIUS_OPTIONS = [
  {
    name: "3m Standard",
    value: { altitude: GATEWAY_ALTITUDE_LOW_METERS, radius: GATEWAY_RADIUS_SHORT_METERS },
  },
  {
    name: "5m Pole",
    value: { altitude: GATEWAY_ALTITUDE_MEDIUM_METERS, radius: GATEWAY_RADIUS_MEDIUM_METERS },
  },
  {
    name: "10m Tower",
    value: { altitude: GATEWAY_ALTITUDE_HIGH_METERS, radius: GATEWAY_RADIUS_LONG_METERS },
  },
]


export const DOCUMENTATION_URL =
  "https://docs.google.com/document/d/10Hh59vi25svBgAvgB-xVLRdMK5tnGy_9Zx3K-qtfuqc/edit"

export const MIN_SENSORS_ZOOM = 13.21 // less then 500m
export const MEDIUM_SENSOR_ZOOM = 15 // less then 200m
export const ZOOM_300M = 14.22
export const ZOOM_500M = 13.21
export const ZOOM_1000M = 12.22

export const BASE_LAYER_ID = "base-layer"

export const enum PlanningMode {
  Select,
  Path,
  Area,
  ConnectivitySelect,
  ConnectivityAddGateways,
  Comment,
  Sensor,
  SensorAddSensor,
}

export const SELECT_MODE_CONFIG = {
  mode: PlanningMode.Select,
  drawMode: "simple_select",
}

export const PATH_MODE_CONFIG = {
  mode: PlanningMode.Path,
  drawMode: "draw_line_string",
}

export const AREA_MODE_CONFIG = {
  mode: PlanningMode.Area,
  drawMode: "draw_polygon",
}

export const CONNECTIVITY_MODE_SELECT = {
  mode: PlanningMode.ConnectivitySelect,
  drawMode: "static_gataways",
}

export const CONNECTIVITY_MODE_ADD_GATAWAYS = {
  mode: PlanningMode.ConnectivityAddGateways,
  drawMode: "static_add_gataways",
}

export const COMMENT_MODE_CONFIG = {
  mode: PlanningMode.Comment,
  drawMode: "draw_point",
}

export const SENSOR_MODE_CONFIG = {
  mode: PlanningMode.Sensor,
  drawMode: "static_sensor",
}

export const SENSOR_ADD_SENSOR_MODE_CONFIG = {
  mode: PlanningMode.SensorAddSensor,
  drawMode: "static_add_sensor",
}

export const MODE_CONFIG = [
  {
    ...SELECT_MODE_CONFIG,
    title: "Select",
    icon: "ph ph-cursor",
  },
  {
    ...PATH_MODE_CONFIG,
    title: "Draw path",
    icon: "ph ph-line-segments",
  },
  {
    ...AREA_MODE_CONFIG,
    title: "Draw area",
    icon: "ph ph-polygon",
  },
  {
    ...CONNECTIVITY_MODE_SELECT,
    title: "Plan connectivity",
    icon: "pi pi-wifi",
  },
  {
    ...COMMENT_MODE_CONFIG,
    title: "Add comment",
    icon: "ph ph-chat-text",
  },
]


export const GATAWAY_TYPES = [
  {
    value: "border",
    name: "Border Gateway",
  },
  {
    value: "mesh",
    name: "Mesh Gateway",
  },
]
