import { Component, Input, OnInit } from "@angular/core"
import { DryadRoutes, MenuItem } from "@dryad-web-app/shared/state"
import { RbacService } from '@dryad-web-app/shared/data-access'
import { AlertsService } from "apps/silvanet-web/src/app/service/http/alerts.service"
import { CssVariableService } from "@dryad-web-app/ui/dryad-ui"
import { Observable } from "rxjs"


@Component({
  selector: "dryad-web-app-side-bar-menu",
  templateUrl: "./side-bar-menu.component.html",
  styleUrls: ["./side-bar-menu.component.scss"],
})
export class SideBarMenuComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  showLabels = true
  activeSlug: DryadRoutes | null | undefined;
  dismissedAlertsCount: Observable<number>
  showDismissedAlertsCount: boolean
  activeAlertsCounter: number

  constructor(
    public rbacService: RbacService,
    private cssVariableService: CssVariableService,
    private alertsService: AlertsService,
    ) {
  }

  ngOnInit(): void {
    this.updateShowLabelsCssVar()
    this.alertsService.activeAlerts$.subscribe(alerts => this.activeAlertsCounter = alerts.length)
  }

  public toggleShowLabels = (): void => {
    this.showLabels = !this.showLabels
    localStorage.setItem("ngx_sidebar-with-labels", JSON.stringify(this.showLabels))
    this.updateShowLabelsCssVar()
  }

  private updateShowLabelsCssVar = (): void => {
    this.cssVariableService.set(
      '--sidebar-width',
      this.showLabels ? 'var(--sidebar-width-big)' : 'var(--sidebar-width-small)'
    )
  }
}
