import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { Experiment } from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { HttpParams } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class ExperimentService extends ApiHttpService {
  private readonly EXPERIMENT_API_PATH = "exp"

  constructor() {
    super()
  }

  create(experiment: Experiment): Observable<Experiment> {
    return this.post<Experiment>(this.EXPERIMENT_API_PATH, experiment).pipe(
      map((response) => plainToClass(Experiment, response))
    )
  }

  list(limit: number, offset: number, filter?: any): Observable<Experiment[]> {
    let params = new HttpParams()
    if (filter) params = params.append("filter", JSON.stringify(filter))
    return this.get<Experiment[]>(
      this.EXPERIMENT_API_PATH +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        "&meta=*&sort=sort,-id",
      params
    ).pipe(map((response) => plainToClass(Experiment, response)))
  }

  destroy(id: number): Observable<boolean> {
    return this.delete(this.EXPERIMENT_API_PATH + "/" + id)
  }
}
