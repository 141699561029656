import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core"

@Component({
  selector: 'dryad-web-app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FooterComponent implements OnInit {
  @Input() activeTabIndex: number | undefined
  constructor() { }

  ngOnInit(): void {
  }

}
