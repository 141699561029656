<p-steps
  *ngIf="fields"
  [model]="items"
  [readonly]="false"
  [(activeIndex)]="activeIndex"

></p-steps>

<p-card class="stepper-body">
  <dryad-web-app-dryad-formly
    *ngIf="activeIndex < fields.length"
    class="formly-form"
    [model]="model[fields[activeIndex].key.toString()]"
    [fields]="fields[activeIndex].fieldGroup"
    [form]="form"
    [showSubmitButton]="false"
    [showCancelButton]="false"
  >
  </dryad-web-app-dryad-formly>
  <dryad-web-app-dryad-formly-review
    *ngIf="activeIndex == fields.length"
    [model]="model"
    [fields]="fields"
    [form]="form"
  ></dryad-web-app-dryad-formly-review>
  <ng-template pTemplate="footer">
    <div class="grid p-nogutter p-justify-end card-buttons">
      <p-button
        *ngIf="activeIndex > 0"
        pRipple
        label="Back"
        (onClick)="prev()"
        iconPos="left"
        data-cy="btn-prev"
        class="button-only-boarder"
      ></p-button>
      <p-button
        *ngIf="activeIndex < fields.length"
        [disabled]="!isValid(fields[activeIndex])"
        pRipple
        label="Next"
        (onClick)="next()"
        iconPos="right"
        data-cy="btn-next"
        class="button-small-filled "
      ></p-button>
      <p-button
        *ngIf="activeIndex === fields.length"
        pRipple
        label="Submit"
        (onClick)="submit()"
        [disabled]="!form.valid"
        iconPos="right"
        data-cy="btn-submit"
        class="button-small-filled "
      ></p-button>
    </div>
  </ng-template>
</p-card>
