import { Injectable } from "@angular/core"
import { Event, NavigationEnd, Router } from "@angular/router"
import { Title } from "@angular/platform-browser"
import { filter } from "rxjs/operators"
import { DryadRoutes } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class PageTitleService {
  private DEFAULT_TITLE = "Dryad"
  private TITLE_APPEND = " • Dryad"

  public pageTitle: string

  constructor(
    private titleService: Title,
    private router: Router
  ) {
    this.initRouterObserver()
  }

  private initRouterObserver(): void {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setTitleForSlug(event.url)
      })
  }

  private setTitleForSlug(slug: string): void {
    if (slug.startsWith("/")) slug = slug.substring(1)

    this.pageTitle = undefined
    let appTitle

    if (slug.startsWith(DryadRoutes.DASHBOARD)) {
      appTitle = "Dashboard"
      this.pageTitle = "Dashboard"
    } else if (slug.startsWith(DryadRoutes.SITES)) {
      appTitle = "Sites"
      this.pageTitle = "Site Management"
    } else if (slug.startsWith(DryadRoutes.MAP)) {
      appTitle = "Map"
      this.pageTitle = "Map View"
    } else if (slug.startsWith(DryadRoutes.USER_MANAGEMENT)) {
      appTitle = "User Management"
      this.pageTitle = "User Management"
    } else if (slug.startsWith(DryadRoutes.USER_SETTINGS)) {
      appTitle = "User Settings"
      this.pageTitle = "User Settings"
    } else if (slug.startsWith(DryadRoutes.EXPERIMENTS)) {
      appTitle = "Experiments"
      this.pageTitle = "Experiments"
    } else if (slug.startsWith(DryadRoutes.ALERT_CENTER)) {
      appTitle = "Alert Center"
      this.pageTitle = "Alert Center"
    } else if (slug.startsWith(DryadRoutes.NOT_FOUND)) {
      appTitle = "Page Not Found"
      this.pageTitle = "Error Page"
    } else if (slug.startsWith(DryadRoutes.DEVICES)) {
      appTitle = "Devices"
      this.pageTitle = "Devices"
    } else if (slug.startsWith(DryadRoutes.SENSOR_DATA)) {
      appTitle = "Sensor Data"
      this.pageTitle = "Sensor Data"
    } else if (slug.startsWith(DryadRoutes.DEVICE_MANAGEMENT_HOME)) {
      appTitle = "Device Management"
      this.pageTitle = "Device Management"
    }

    if (appTitle)
      this.set(appTitle)
    else
      this.setDefault()
  }

  public setDefault(): void {
    this.titleService.setTitle(this.DEFAULT_TITLE)
  }

  public set(title: string): void {
    this.titleService.setTitle(title + this.TITLE_APPEND)
  }
}
