import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from "@ngx-formly/core"
import { FormUtils } from "@dryad-web-app/shared/state"

@Component({
  selector: 'dryad-web-app-dryad-formly-field-checkbox',
  templateUrl: './dryad-formly-field-checkbox.component.html',
  styleUrls: ['./dryad-formly-field-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DryadFormlyFieldCheckboxComponent extends FieldType  implements OnInit {

   FormUtils = FormUtils

  constructor() {
    super()
  }

  ngOnInit(): void {}

}
