<div
  class="map-container"
  inViewport
  [inViewportOptions]="{ threshold: [0] }"
  (inViewportAction)="mapInViewport = $event.visible"
>
  <div *ngIf="!mapLoaded || !ready || !mapInViewport" class="loader" (click)="onClick($event)">
    <dryad-logo-spinner class="color-primary"></dryad-logo-spinner>
  </div>

  <img
    class="underlay"
    [src]="underlayImage"
    [style.background-color]="availableBasemapStyles[basemapStyle].backgroundColor"
  />
  <mgl-map
      #mapComponent
      *ngIf="mapInViewport"
      [style]="availableBasemapStyles[basemapStyle].styleUrl"
      [zoom]="2"
      [minZoom]="2"
      [maxZoom]="20"
      [center]="[13.76198, 52.85651]"
      [preserveDrawingBuffer]="true"
      [interactive]="interactive"
      [attributionControl]="true"
      [dragRotate]="showTerrain || isfake2d3dViewEnabled"
      [touchZoomRotate]="showTerrain || isfake2d3dViewEnabled"
      (mapClick)="onClick($event)"
      [terrain]="(showTerrain || fake2dViewMode) ? {source: 'terrainSource', 'exaggeration': MAP_EXAGGERATION} : null"
      (mapLoad)="initializeMap($event)"
      [class.terrain]="showTerrain || isfake2d3dViewEnabled"
    >
    <!-- Sources -- terrain from MapTiler, fire risk from ESRI, sites and site areas from component -->
    <mgl-raster-dem-source
      id="terrainSource"
      *ngIf="environment.mapTilerApiKey"
      [url]="'https://api.maptiler.com/tiles/terrain-rgb-v2/tiles.json?key=' + environment.mapTilerApiKey"
      tileSize="256"
      [minzoom]="7"
    ></mgl-raster-dem-source>
    <mgl-raster-source
      id="fireRiskSource"
      [tiles]="[getFireLayerUrl()]"
      [tileSize]="1024"
    ></mgl-raster-source>

    <!-- Draw fire risk layer if active -->
    <mgl-layer
      id="fireRisk"
      type="raster"
      source="fireRiskSource"
      [paint]="{'raster-opacity': 0.5}"
      [layout]="{'visibility': fireLayer ? 'visible' : 'none'}"
    ></mgl-layer>

    <!-- Add additional components passed via a child template -->
    <ng-container *ngIf="mapServiceInjector">
      <ng-container *ngTemplateOutlet="overlayComponents; context: { interactive }; injector: mapServiceInjector"></ng-container>
    </ng-container>

    <!-- Set up controls for interactive maps -->
    <ng-container *ngIf="interactive">
      <mgl-control
        *ngIf="allowSearchbar"
        mglSearchBar
        position="top-left"
      ></mgl-control>
      <mgl-control
        mglNavigation
        showZoom="true"
        visualizePitch="true"
      ></mgl-control>
      <mgl-control
        mglScale
        [unit]="unitSystem"
        maxWidth="200"
        position="bottom-right"
      ></mgl-control>
      <mgl-control position="bottom-left">
        <div class="basemap-selector">
          <button *ngFor="let style of availableBasemapStyles; index as i"
            (click)="changeBasemapStyle(i)"
            [title]="'Switch to ' + style.name + ' view'"
            [class.active]="basemapStyle === i"
          >
            <img [src]="style.previewUrl" />
          </button>
        </div>
      </mgl-control>
      <mgl-control>
        <div class="maplibregl-ctrl-group">
          <button *ngIf="environment.mapTilerApiKey && fake2dViewMode"
          [class.maplibregl-ctrl-terrain-enabled]="isfake2d3dViewEnabled"
          [class.maplibregl-ctrl-terrain]="!isfake2d3dViewEnabled"
          (click)="toggleFake2d3dView()"
          [title]="showTerrain ? 'Disable terrain' : 'Enable terrain'">
          <span class="maplibregl-ctrl-icon" aria-hidden="true"></span>
          </button>
          <button
            *ngIf="environment.mapTilerApiKey && !fake2dViewMode"
            [class.maplibregl-ctrl-terrain-enabled]="showTerrain"
            [class.maplibregl-ctrl-terrain]="!showTerrain"
            (click)="toggleTerrain()"
            [title]="showTerrain ? 'Disable terrain' : 'Enable terrain'"
          >
            <span class="maplibregl-ctrl-icon" aria-hidden="true"></span>
          </button>
          <button
            [class.active]="fireLayer"
            (click)="toggleFire()"
            [title]="fireLayer ? 'Hide fire risk layer' : 'Show fire risk layer'"
          >
            <i class="ph ph-fire"></i>
          </button>
        </div>
      </mgl-control>
    </ng-container>
  </mgl-map>
</div>
