export enum Favicons {
  DEFAULT = "faviconDefault",
  DANGER = "faviconDanger",
}

export type AvailableCustomFavicons = { [key in Favicons]: string }

export const favicons: AvailableCustomFavicons = {
  faviconDefault: "/favicons/default.ico",
  faviconDanger: "/favicons/danger.ico",
}
