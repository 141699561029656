<p-table [value]="items" dataKey="name" class="row-expander" responsive="false" [id]="'dry-expand'">
  <ng-template pTemplate="body" let-item let-expanded="expanded">
    <tr class="expander-tr">
      <td class="expander-icons" *ngIf="showIndicators">
        <ng-container *ngTemplateOutlet="headerActions"></ng-container>
      </td>
      <td class="expander-label clickable" [pRowToggler]="item" (click)="clickExpand($event)">
        {{ label }}
      </td>
      <td class="expander">
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="item"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'"
          (click)="clickExpand($event)"
        ></button>
      </td>
      <td class="expander-label" *ngIf="headerLabelList?.length > 0">
        <div class="p-d-flex">
          <div *ngFor="let headerLabel of headerLabelList" class="p-mr-2">
            {{ headerLabel }}
          </div>
        </div>
      </td>
      <td class="text-align-end expander-button-end action-buttons">
        <ng-container *ngTemplateOutlet="headerButtons"></ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-item>
    <tr>
      <td colspan="4">
        <div class="gateway-name">
          <ng-container *ngTemplateOutlet="tmpl"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
