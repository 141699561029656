import {
  AfterContentInit,
  Directive,
  Host,
  Input,
} from "@angular/core"
import maplibregl from "maplibre-gl"
import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder"
import { ControlComponent, MapService } from "@maplibre/ngx-maplibre-gl"
import { flatten } from "lodash"

@Directive({
  selector: "[mglSearchBar]",
  standalone: true,
})
export class SearchBarControlDirective implements AfterContentInit {
  @Input() container?: HTMLElement

  geocoderApi = {
    forwardGeocode: async (config): Promise<GeoJSON.FeatureCollection> => {
      try {
        const params = new URLSearchParams({
          q: config.query,
          format: "geojson",
          limit: config.limit,
          "accept-language": config.language.join(","),
        })
        if (config.bbox)
          params.append("viewbox", config.bbox.join(","))
        const request = `https://nominatim.openstreetmap.org/search?${params}`
        const response = await fetch(request)
        const geojson = await response.json()
        geojson.features = geojson.features.map(feature => ({
          ...feature,
          place_name: feature.properties.display_name,
          place_type: ["place"],
          text: feature.properties.display_name,
          center: feature.geometry.coordinates,
        }))
        return geojson
      } catch (e) {
        console.error(`Failed to forwardGeocode with error: ${e}`)
      }
    },
  }

  constructor(
    private mapService: MapService,
    @Host() private controlComponent: ControlComponent<any>
  ) { }

  ngAfterContentInit(): void {
    this.mapService.mapCreated$.subscribe(() => {
      if (this.controlComponent.control)
        throw new Error("Another control is already set for this control")

      this.controlComponent.control = new MaplibreGeocoder(
        this.geocoderApi,
        {
          flyTo: {
            speed: 2,
            curve: 2,
            essential: false,
          },
          clearOnBlur: true,
          clearAndBlurOnEsc: true,
          container: this.container,
          maplibregl,
          enableEventLogging: false,
          bbox: flatten(this.mapService.mapInstance.getBounds().toArray()),
        }
      )
      this.mapService.addControl(
        this.controlComponent.control,
        this.controlComponent.position
      )
      this.mapService.mapInstance.on("moveend", () => {
        this.controlComponent.control.setBbox(flatten(this.mapService.mapInstance.getBounds().toArray()))
      })
    })
  }
}
