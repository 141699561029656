import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { LatLong } from "@dryad-web-app/shared/state";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";
import { ApiHttpService } from "@dryad-web-app/shared/data-access";

@Injectable({
  providedIn: 'root'
})
export class CacheService extends ApiHttpService {

  constructor() {
    super()
  }
  clearSensorNodeCache(sensorNodeId: number): Observable<any> {
    return this.get<LatLong>("cache/refresh/site-sensor-node-status/sensorNode/" + sensorNodeId
    ).pipe(map((response) => plainToClass(LatLong, response)))
  }

  clearSiteCache(siteId: number): Observable<any> {
    return this.get<LatLong>("cache/refresh/site-sensor-node-status/site/" + siteId
    ).pipe(map((response) => plainToClass(LatLong, response)))
  }
}
