import { environment } from "../../environments/environment"

export const serviceRootUrl = (serviceName: string): string => {
  if (!environment.production && !environment.serviceRoot) return `/${serviceName}`
  return `${window.location.protocol}//${serviceName}.${environment.serviceRoot || window.location.host}`
}

const getDirectusUrl = (): string => {
  if (environment.directusBaseUrl) return environment.directusBaseUrl
  return `${window.location.protocol}//db.${environment.serviceRoot || window.location.host}`
}

export const directusUrl = getDirectusUrl()

export const COLLECTION_FORMATS = {
  "csv": ",",
  "tsv": "   ",
  "ssv": " ",
  "pipes": "|",
}
