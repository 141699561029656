import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from "@ngx-formly/core"

@Component({
  selector: 'dryad-web-app-dryad-formly-field-file',
  templateUrl: './dryad-formly-field-file.component.html',
  styleUrls: ['./dryad-formly-field-file.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DryadFormlyFieldFileComponent extends FieldType {
  constructor() {
    super()
  }

  ngOnInit(): void {}
}
