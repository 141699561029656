<ng-template #container>
  <div class="grid">
    <div class="col-12 md:col-4">
      <div class="widget border-round-s widget-bg dry-padding-m">
        <div class="flex-header">
          <h3 class="dry-font-m">Total Sensors</h3>
        </div>
        <div class="white-bg dry-padding-m border-round-m">
          <div class="flex-center-content padding-0">
            <h1 class="dry-font-xl color-primary">{{ totalSensors }}</h1>
          </div>
        </div>
        <div class="white-bg dry-padding-m border-round-m margin-t-m stretch">
          <div class="flex-header">
            <h3 class="dry-font-m">Sensors of each site</h3>
          </div>
          <div class="white-bg dry-padding-m">
            <div class="flex-header flex-space item" *ngFor="let site of sites">
              <h4 class="margin-0">
                <a [routerLink]="['/', DryadRoutes.SITES, site.data?.id]">
                  {{ site.data?.name }}
                </a>
              </h4>
              <h4 class="margin-0">
                {{ site.aggregate?.sensorNodeDetails?.nbOfDevices ?? 0 }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <div class="widget border-round-s widget-bg dry-padding-m">
        <div class="flex-header">
          <h3 class="dry-font-m">Total Gateways</h3>
        </div>
        <div class="white-bg dry-padding-m border-round-m">
          <div class="flex-center-content padding-0">
            <h1 class="dry-font-xl color-green">{{ totalGateways }}</h1>
          </div>
        </div>
        <div class="white-bg dry-padding-m border-round-m margin-t-m stretch">
          <div class="flex-header">
            <h3 class="dry-font-m">Gateways of each site</h3>
          </div>
          <div class="white-bg dry-padding-m">
            <div class="flex-header flex-space item" *ngFor="let site of sites">
              <h4 class="margin-0">
                <a [routerLink]="['/', DryadRoutes.SITES, site.data?.id]">
                  {{ site.data?.name }}
                </a>
              </h4>
              <h4 class="margin-0">
                {{ site.aggregate?.gatewayDetails?.nbOfDevices ?? 0 }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <div class="widget border-round-s widget-bg dry-padding-m">
        <div class="flex-header">
          <h3 class="dry-font-m">Total Number Of Sites</h3>
        </div>
        <div class="white-bg dry-padding-m border-round-m">
          <div class="flex-center-content padding-0">
            <h1 class="dry-font-xl color-blue">{{ totalSites }}</h1>
          </div>
        </div>
        <div class="white-bg dry-padding-m border-round-m margin-t-m stretch">
          <div class="flex-header">
            <h3 class="dry-font-m">Covered area of each site</h3>
          </div>
          <div class="white-bg dry-padding-m">
            <div class="flex-header flex-space item" *ngFor="let site of sites">
              <h4 class="margin-0">
                <a [routerLink]="['/', DryadRoutes.SITES, site.data?.id]">
                  {{ site.data?.name }}
                </a>
              </h4>
              <h4 class="margin-0">
                {{ site.aggregate?.sensorNodeDetails?.area ?? 0 | area }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="isLoading;else container" class="loading-container">
  <dryad-logo-spinner class="flex-center-content justify-content-center color-primary margin-auto"
  style="width: 40%"></dryad-logo-spinner>
</div>