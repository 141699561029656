import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { GatewayService, UplinkMessageService } from "@dryad-web-app/shared/data-access"
import {
  Assets,
  BaseButton,
  FormFactory,
  Gateway,
  GatewayTypes, MapUtils,
  ObservableState,
  TTNGateway,
} from "@dryad-web-app/shared/state"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { MenuItem } from "primeng/api/menuitem"
import * as _ from "lodash"
import { GatewayTtnService } from "../service/http/gateway-ttn.service"
import { DeviceListService } from "../shared/device-list.service"

@Component({
  selector: "app-gateway",
  templateUrl: "./gateway.component.html",
  styleUrls: [ "./gateway.component.scss" ],
})
export class GatewayComponent implements OnInit {
  @Input() siteId: number
  gateways: Gateway[] = []
  selectedGateway: Gateway
  totalRecords: number
  viewGatewaySettings = false
  gatewayModel: any = {}
  gatewayFields: FormlyFieldConfig[] = []

  breadcrumbItems: MenuItem[]
  home = { icon: "ph ph-home" }

  buttonList: BaseButton[] = []
  loadingGateways = true
  loadingGatewaysMessage = "Loading gateways ..."

  private readonly GATEWAYS_ID = "gateways"
  private readonly GATEWAY_SETTINGS_ID = "settings"
  private bcGateway = { label: "gateways", id: this.GATEWAYS_ID }

  constructor(
    private gatewayService: GatewayService,
    private deviceListService: DeviceListService,
    private gatewayTtnService: GatewayTtnService,
    private oss: ObservableState,
    private uplinkMessageService: UplinkMessageService,
    private changeDetectRef: ChangeDetectorRef,
  ) {
    this.gatewayFields = FormFactory.gatewayFormFields()
  }

  ngOnInit(): void {
    this.breadcrumbItems = [ this.bcGateway ]
    this.loadGateways()
    this.initButtonList()
    this.loadGateways()
  }

  loadGateways(): void {
    this.gatewayService
      .gatewaysForSite(this.siteId)
      .subscribe((gateways: Gateway[]) => {
        if (gateways) {
          this.gateways = gateways
          this.totalRecords = this.gateways.length
          if (gateways.length === 0)
            this.loadingGatewaysMessage = "No gateways found."
          else this.getLastMessage(gateways)
        }
        this.loadingGateways = false
      })
  }

  latitude(gateway: Gateway): number {
    return gateway.latitude
  }

  longitude(gateway: Gateway): number {
    return gateway.longitude
  }

  initButtonList(): void {
    this.buttonList.push(
      new BaseButton(
        "Edit",
        (selectedEntity: Gateway) => {
          this.deviceListService.editGateway(selectedEntity)
        },
        true,
        false,
        "ph-pencil-simple ph-2x",
        "p-button p-button-rounded p-button-text button-blue"
      )
    )
  }

  getLastMessage(ids: Gateway[]): void {
    this.uplinkMessageService
      .updateGatewaysWithStatus(ids)
      .subscribe((gateways) => {
        gateways.map((gateway) => {
          const selectedSensor = _.find(
            this.gateways,
            (e) => e.id === gateway.id
          )
          selectedSensor.state = gateway.state
          const gatewayIcon = selectedSensor.gateway_type === GatewayTypes.MESH_GATEWAY ? MapUtils.meshGatewayMarkerState(gateway.state)
            : MapUtils.borderGatewayMarkerState(gateway.state)
          selectedSensor["icon"] =
            Assets.MARKER_BASE_URL + gatewayIcon +
            ".svg"
        })
        this.changeDetectRef.detectChanges()
      })
  }

  selectPage(id: string): void {
    switch (id) {
      case this.GATEWAYS_ID:
        this.viewGatewaySettings = false
        this.selectedGateway = undefined
        this.breadcrumbItems = [ this.bcGateway ]
        break
      case this.GATEWAY_SETTINGS_ID:
        break
      default:
        this.viewGatewaySettings = false
        this.selectGatewayById(id)
    }
  }

  selectGatewayById(gatewayId: string): void {
    this.selectedGateway = this.gateways.find(
      (gateway: Gateway) => gateway.ns_gateway_id === gatewayId
    )
    this.breadcrumbItems = [ this.bcGateway, { label: gatewayId, id: gatewayId } ]
  }

  onRowEditClick(gateway: Gateway): void {
    this.gatewayTtnService
      .gateway(gateway.ns_gateway_id)
      .subscribe((ttnGateway: TTNGateway) => {
        this.gatewayModel = ttnGateway
      })
    this.viewGatewaySettings = true
    this.selectGatewayById(gateway.ns_gateway_id)
    this.breadcrumbItems.push({
      label: this.GATEWAY_SETTINGS_ID,
      id: this.GATEWAY_SETTINGS_ID,
    })
  }

}
