<div class="filter filter-messages">
  <div class="flex-center">
    <div class="filter-message-item">
      <p-dropdown
        class="time-period"
        [options]="timePeriods"
        [(ngModel)]="selectedTimePeriod"
        (onChange)="updateTimePeriod($event)"
      ></p-dropdown>
    </div>
    <div class="filter-message-item">
      <p-multiSelect [options]="filterOptions" [(ngModel)]="selectedFilter" optionLabel="label" [filter]="false"
                     (onChange)="updateMessages()"
                     class="up-filter-setting"
                     placeholder="Show .."
                     [showHeader]="false"
                     display="chip"></p-multiSelect>
    </div>
    <div class="filter-message-item">
      <button
        pButton
        type="button"
        icon="ph-arrow-clockwise ph ph-2"
        (click)="updateMessages()"
      ></button>
    </div>
  </div>
  <div class="flex-center">
    <dryad-web-app-search-bar
      [isAutoComplete]="true"
      (inputChange)="dv.filter($event)"
      [field]="'ns_end_device_id'" [minLength]="1" [suggestions]="filteredSensorNodes" (completeMethod)="filterUplinkMessages($event)" (onSelect)="dv.filter($event.ns_end_device_id)" (onClear)="dv.filter($event.ns_end_device_id)"

    ></dryad-web-app-search-bar>
  </div>

</div>
<div class="card p-p-0">
  <p-dataView #dv [value]="messages" [paginator]="true" [rows]="10" filterBy="endDevice.id" layout="grid">
    <ng-template let-message pTemplate="gridItem">
      <div class="col-12 md:col-12">
        <div class="message-grid-item card">
          <div class="grid">
            <div class="sensor-data col-12 md:col-6">
              <p-divider layout="horizontal">
                <b>Sensor {{message.endDevice.id }} </b>
              </p-divider>
              <div class="grid p-p-1">
                <div class="col-4">Time :</div>
                <div class="col-8">  {{ dateParse(message.time) }}</div>
              </div>
              <!--            <div class="grid">-->
              <!--              <div class="grid-col-4">Smoke</div>-->
              <!--              <div class="grid-col-6"> {{ message.sensorValues.temperature }}</div>-->
              <!--            </div>-->
              <div class="grid p-p-1">
                <div class="col-4">Temp :</div>
                <div class="col-8"> {{ message.sensorValues.temperature }}</div>
              </div>
              <div class="grid p-p-1">
                <div class="col-4">Humidity :</div>
                <div class="col-8"> {{ message.sensorValues.humidity }}</div>
              </div>
              <div class="grid p-p-1">
                <div class="col-4">Pressure :</div>
                <div class="col-8"> {{ message.sensorValues["air-pressure"] }}</div>
              </div>
            </div>
            <div class="gateway-data col-12 md:col-6" *ngIf="displayGatewayInfo">
              <p-divider layout="horizontal">
                <b>Gateways </b>
              </p-divider>
              <div *ngFor="let gateway of message.gateways">
                <div class="grid p-p-1">
                  <div class="col-4">EUI :</div>
                  <div class="col-8">  {{ gateway.eui}}</div>
                </div>
                <div class="grid p-p-1">
                  <div class="col-4">Rssi :</div>
                  <div class="col-8"> {{ gateway.rssi }}</div>
                </div>
                <div class="grid p-p-1">
                  <div class="col-4">SNR :</div>
                  <div class="col-8"> {{ gateway.snr }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
