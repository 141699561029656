import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { UntypedFormGroup } from "@angular/forms"

@Component({
  selector: 'dryad-web-app-dryad-formly-review',
  templateUrl: './dryad-formly-review.component.html',
  styleUrls: ['./dryad-formly-review.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DryadFormlyReviewComponent implements OnInit {
  @Input()
  model: any = {}

  @Input()
  fields: FormlyFieldConfig[] = []

  @Input()
  form = new UntypedFormGroup({})

  constructor() {}

  ngOnInit(): void {}
}

