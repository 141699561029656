import { Injectable } from '@angular/core'
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"
import { Observable } from "rxjs"
import { DirectusDeviceResponse, SensorDetails} from "@dryad-web-app/shared/state"
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { plainToInstance } from "class-transformer";

@Injectable({
  providedIn: 'root'
})
export class DeviceDirectusApiService extends ApiHttpService {

  constructor() {
    super(ApiBase.DATA_API_BASE_URL)
  }

  sensorList(limit: number = -1, offset: number = 0, sensorNodeFilter: any = {}): Observable<DirectusDeviceResponse> {
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    cloneParams = cloneParams.append("filter", JSON.stringify(sensorNodeFilter))
    // @ts-ignore
    return this.get<DirectusDeviceResponse>(
      "items/sensor_node?fields=*,site.id,site.name&meta=*&sort=id",
      cloneParams,
    ).pipe(map((response) =>  {
      return plainToInstance(DirectusDeviceResponse, response)
    }))
  }

  gatewayList(limit: number = -1, offset: number = 0, gatewayFilter: any = {}): Observable<DirectusDeviceResponse> {
    //
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    cloneParams = cloneParams.append("filter", JSON.stringify(gatewayFilter))
    // @ts-ignore
    return this.get<DirectusDeviceResponse>(
      "items/gateway?fields=*,site.id,site.name&meta=*&sort=id",
      cloneParams,
    ).pipe(map((response) =>  {
      return plainToInstance(DirectusDeviceResponse, response)
    }))
  }

  getSensorListByIds(sensorIds: number[]):Observable<DirectusDeviceResponse> {
    let cloneParams = new HttpParams()
    const sensorNodeFilter = {
      id: {
        _in: sensorIds
      }
    }
    cloneParams = cloneParams.append("filter", JSON.stringify(sensorNodeFilter))
    // @ts-ignore
    return this.get<DirectusDeviceResponse>(
      "items/sensor_node",
      cloneParams,
    ).pipe(map((response) =>  {
      return plainToInstance(DirectusDeviceResponse, response)
    }))
  }

  getSensorByNsEndDeviceId(ns_end_device_id: string): Observable<SensorDetails> {
    let cloneParams = new HttpParams()
    const sensorNodeFilter = {
      "ns_end_device_id": {
        "_eq": ns_end_device_id
      }
    }
    cloneParams = cloneParams.append("filter", JSON.stringify(sensorNodeFilter))
    return this.get<SensorDetails>("items/sensor_node?fields=name,latitude,longitude", cloneParams)
  }
}
