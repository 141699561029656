import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CardModule } from "primeng/card"
import { DropdownModule } from "primeng/dropdown"

import { ButtonModule } from "primeng/button"
import { UiDryadFormModule } from "@dryad-web-app/ui/dryad-form"
import { CheckboxModule } from "primeng/checkbox"
import { SharedModule } from "../shared/shared.module"

import { SharedHelpersModule } from "../../../../../libs/shared/helpers/src/lib/shared-helpers.module"
import { UserCreateComponent } from "./user-create/user-create.component"
import { UserManagementComponent } from "./user-management table/user-management.component"

@NgModule({
  declarations: [
    UserCreateComponent,
    UserManagementComponent],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    DropdownModule,
    SharedHelpersModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    SharedModule,
    CommonModule,
    SharedModule,
    UiDryadFormModule,
  ],
  exports: [
    UserCreateComponent,
    UserManagementComponent,
  ],
})
export class UserManagementModule { }
