import { Injectable } from "@angular/core"
import {
  GatewayService,
  SensorNodeService,
} from "@dryad-web-app/shared/data-access"
import {
  DeviceTypes,
  DialogMode,
  Gateway,
  GATEWAY_DELETE,
  ObservableState, SensorNode,
} from "@dryad-web-app/shared/state"
import { ConfirmationService, MessageService } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { DeviceEditComponent } from "../device/device-edit/device-edit.component"

@Injectable({
  providedIn: "root",
})
export class DeviceListService {
  constructor(
    private sensorNodeService: SensorNodeService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private oss: ObservableState,
    private gatewayService: GatewayService,
    private confirmationService: ConfirmationService
  ) {
  }

  editSensorNode(sensorNode: SensorNode): void {
    this.dialogService.open(DeviceEditComponent, {
      header: "Edit sensor : " + sensorNode.name,
      data: {
        data: sensorNode,
        dialogMode: DialogMode.UPDATE,
        deviceType: DeviceTypes.SENSOR_NODE,
      },
    })
  }

  deleteSensor(sensorNode: SensorNode): void {
    this.confirmationService.confirm({
      message: "Do you want to delete this Sensor?",
      header: "Delete Confirmation",
      icon: "ph ph-info",
      accept: () => {
        this.sensorNodeService.deleteSensorNode(sensorNode.id).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: "Sensor node deleted",
          })
        })
      },
    })
  }

  editGateway(selectedGateway: Gateway): void {
    const ref = this.dialogService.open(DeviceEditComponent, {
      header: "Edit Gateway : " + selectedGateway.name,
      data: {
        data: selectedGateway,
        dialogMode: DialogMode.UPDATE,
        deviceType: DeviceTypes.GATEWAY,
      },
    })
    ref.onClose.subscribe((gateway: Gateway) => {
      if (!gateway) return
      this.messageService.add({
        severity: "success",
        summary: "Gate Update",
        detail: "Name : " + gateway.name,
      })
    })
  }

  deleteGateway(selectedGateway: Gateway): void {
    this.confirmationService.confirm({
      message: "Do you want to delete this gateway?",
      header: "Delete Confirmation",
      icon: "ph ph-info",
      accept: () => {
        this.gatewayService
          .deleteGateway(selectedGateway.id)
          .subscribe(() => {
            this.oss.dispatch({
              type: GATEWAY_DELETE,
              payload: selectedGateway,
            })
            this.messageService.add({
              severity: "success",
              summary: "Gateway deleted",
            })
          })
      },
    })
  }
}
