import { Injectable } from "@angular/core"
import {
  ClientService,
  GatewayService,
  NetworkServerService,
  NsRoleService,
  RoleService,
  SensorNodeService,
  TypeService,
  UserService,
} from "@dryad-web-app/shared/data-access"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { ActivationMode, ADMIN_USER_CREATE, ObservableState, SELF_USER_UPDATE, } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class FormFactoryService {

  constructor(
    private networkServerService: NetworkServerService,
    private gatewayService: GatewayService,
    private sensorNodeService: SensorNodeService,
    private typeService: TypeService,
    private roleService: RoleService,
    private nsRoleService: NsRoleService,
    private clientService: ClientService,
    private oss: ObservableState,
    private userService: UserService,
  ) {
  }

  gatewayFormFields(
    onChange?: (field: FormlyFieldConfig, event?: any) => void,
  ): FormlyFieldConfig[] {
    return [
      {
        key: "data",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Data" },
        fieldGroup: [
          {
            key: "name",
            type: "primeng-input",
            templateOptions: {
              label: "Name",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "gateway_type",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Type",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.gatewayService.types(),
              valueProp: "id",
              labelProp: "label",
            },
          },
          {
            key: "latitude",
            type: "primeng-input",
            templateOptions: {
              label: "Latitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "longitude",
            type: "primeng-input",
            templateOptions: {
              label: "Longitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "notes",
            type: "primeng-text-area",
            templateOptions: {
              label: "Notes",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
        ],
      },
      {
        key: "details",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Details" },
        fieldGroup: [
          {
            key: "gatewayEUI",
            type: "primeng-input",
            templateOptions: {
              label: "Gateway EUI",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "frequencyPlanId",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Frequency Plan",
              placeholder: "",
              description: "Description",
              required: true,
              options: [],
              filter: true,
              valueProp: "id",
              labelProp: "name",
            },
          },
          {
            key: "scheduleAnytimeDelayMillis",
            type: "primeng-input",
            templateOptions: {
              label: "Schedule Anytime Delay Millis",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "autoUpdate",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Auto Update",
              description: "",
              required: false,
            },
          },
          {
            key: "updateLocationFromStatus",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Update Location From Status",
              description: "",
              required: false,
            },
          },
          {
            key: "scheduleDownlinkLate",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Schedule Downlink Late",
              description: "",
              required: false,
            },
          },
          {
            key: "statusPublic",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Status Public",
              description: "",
              required: false,
            },
          },
          {
            key: "locationPublic",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Location Public",
              description: "",
              required: false,
            },
          },
          {
            key: "enforceDutyCycle",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Enforce Duty Cycle",
              description: "",
              required: false,
            },
          },
        ],
      },
    ]
  }

  sensorNodeFormFields(
    onChange: (field: FormlyFieldConfig, event?: any) => void,
  ): FormlyFieldConfig[] {
    return [
      {
        key: "data",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Data" },
        fieldGroup: [
          {
            key: "name",
            type: "primeng-input",
            templateOptions: {
              label: "Name",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "sensor_node_type",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Type",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.sensorNodeService.types(),
              valueProp: "id",
              labelProp: "label",
            },
          },
          {
            key: "active",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Active",
              description: "",
              required: false,
            },
          },
          {
            key: "latitude",
            type: "primeng-input",
            templateOptions: {
              label: "Latitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "longitude",
            type: "primeng-input",
            templateOptions: {
              label: "Longitude",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "notes",
            type: "primeng-text-area",
            templateOptions: {
              label: "Notes",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
        ],
      },
      {
        key: "details",
        wrappers: ["primeng-panel"],
        templateOptions: { label: "Details" },
        fieldGroup: [
          {
            key: "activationMode",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Activation Mode",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.sensorNodeService.activationModes(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "idPrefix",
            type: "primeng-input",
            templateOptions: {
              label: "Device ID Prefix",
              placeholder: "",
              description: "Description",
              required: false,
            },
          },
          {
            key: "devAddr",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "Device Address",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "frameNetworkSIntKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "Network Session Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "appSKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              !(
                model.activationMode === ActivationMode.ABP ||
                model.activationMode === ActivationMode.MULTICAST
              ),
            templateOptions: {
              label: "App Session Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "appKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "App Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "nwkKey",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Network Key",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "joinEUI",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Join EUI",
              placeholder: "",
              description: "Description",
              required: true,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "networkServerKekLabel",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Network Server Kek Label",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "applicationServerKekLabel",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Application Network Server Kek Label",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "applicationServerId",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.OTAA,
            templateOptions: {
              label: "Application Server Id",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "pingSlotPeriodicity",
            type: "primeng-input",
            hideExpression: (model: any) =>
              model.activationMode !== ActivationMode.MULTICAST,
            templateOptions: {
              label: "Ping Slot Periodicity",
              placeholder: "",
              description: "Description",
              required: false,
              attributes: {
                style: "border-color: blue",
              },
            },
          },
          {
            key: "devEUI",
            type: "primeng-input",
            templateOptions: {
              label: "Device EUI",
              placeholder: "",
              description: "Description",
              required: true,
            },
          },
          {
            key: "lorawanVersion",
            type: "primeng-dropdown",
            templateOptions: {
              label: "LoRaWAN MAC Version",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.networkServerService.macVersions(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "lorawanPhyVersion",
            type: "primeng-dropdown",
            templateOptions: {
              label: "LoRaWAN PHY Version",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.networkServerService.phyVersions(),
              valueProp: "label",
              labelProp: "label",
            },
          },
          {
            key: "frequencyPlanId",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Frequency Plan",
              placeholder: "",
              description: "Description",
              required: true,
              options: [],
              filter: true,
              valueProp: "id",
              labelProp: "name",
            },
          },
          {
            key: "macSupports32BitFCnt",
            type: "primeng-checkbox",
            templateOptions: {
              label: "MAC Support 32 Bit Frame Count",
              description: "",
              required: false,
            },
          },
          {
            key: "supportsClassC",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Supports Class C",
              description: "",
              required: false,
            },
          },
          {
            key: "supportsClassB",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Supports Class B",
              description: "",
              required: false,
            },
          },
          {
            key: "resetsFrameCnt",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Resets Frame Count",
              description: "",
              required: false,
            },
          },
          {
            key: "skipPayloadCrypto",
            type: "primeng-checkbox",
            templateOptions: {
              label: "Skip Payload Crypto",
              description: "",
              required: false,
            },
          },
        ],
      },
    ]
  }

  resolveAlertFields(): FormlyFieldConfig[] {
    return [
      {
        key: "resolved_alert_type",
        type: "primeng-radio",
        templateOptions: {
          required: true,
          options: this.typeService.resolvedAlertType(),
          valueProp: "id",
          labelProp: "label",
        },
      },
    ]
  }

  userFormFields(actionType: string, isDryadAdmin?: boolean, isStandardUser?:boolean): FormlyFieldConfig[] {
    const loggedinUserId = this.oss.userLoggedIn().user.id
    return [
      {
        fieldGroup: [
          {
            key: "first_name",
            type: "primeng-input",
            templateOptions: {
              label: "First Name",
              placeholder: "",
              description: "Description",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "last_name",
            type: "primeng-input",
            templateOptions: {
              label: "Last Name",
              placeholder: "",
              description: "Description",
              required: true,
              maxLength: 50,
            },
          },
          {
            key: "email",
            type: "primeng-input",
            templateOptions: {
              label: "Email",
              placeholder: "",
              description: "Description",
              required: actionType === ADMIN_USER_CREATE,
              disabled: actionType !== ADMIN_USER_CREATE,
              pattern: /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,10})+$/,
            },
          },
        ],
      },
      {
        key: "userDetails",
        wrappers: ["primeng-group"],
        templateOptions: { label: "User Permission" },
        fieldGroup: [
          {
            key: "phone_number",
            type: "primeng-input",
            templateOptions: {
              label: "Phone Number",
              type: "text",
              pattern: /^[\+\(\s.\-\/\d\)]{5,30}$/,
            }
          },
          {
            key: "country",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Country",
              placeholder: "Select the user's country",
              description: "Description",
              required: true,
              options: this.userService.getCountriesList(),
              valueProp: "id",
              labelProp: "country",
              filter: true
            }
          },
          {
            key: "client",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Client",
              placeholder: "",
              description: "Description",
              required: true,
              disabled: isStandardUser,
              //@ts-ignore
              options: isDryadAdmin ? this.clientService.list() : this.userService.listClientsByUserId(loggedinUserId),
              valueProp: "id",
              labelProp: "name",
              filter: true
            },
          },
          {
            key: "ns_role",
            type: "primeng-multiselect-dropdown",
            hide: !isDryadAdmin || isStandardUser,
            templateOptions: {
              label: "Ns Role",
              placeholder: "",
              description: "Description",
              required: true,
              options: this.nsRoleService.list(),
              valueProp: "id",
              labelProp: "name",
              filter: true,
            },
          },
        ],
      },
      {
        fieldGroup: [
          {
            key: "role",
            type: "primeng-dropdown",
            templateOptions: {
              label: "Role",
              description: "Description",
              required: true,
              disabled: actionType === SELF_USER_UPDATE && !isDryadAdmin,
              options: isDryadAdmin ? this.roleService.list() : this.roleService.getclientAdminUserRoles(),
              valueProp: "id",
              labelProp: "name",
              filter: true,
            },
          },
        ]
      }
    ]
  }

  addNoteFields(): FormlyFieldConfig[] {
    return [
      {
        key: "note",
        type: "primeng-text-area",
        templateOptions: {
          required: true,
          label: "Note ",
        },
      },
    ]
  }

  sensorNodeMetaFields(): FormlyFieldConfig[] {
    return [
      {
        key: "name",
        type: "primeng-input",
        templateOptions: {
          label: "Name ",
          disabled: true
        },
        wrappers: ["primeng-table"],
      },
      {
        key: "notes",
        type: "primeng-text-area",
        templateOptions: {
          label: "Notes ",
        },
        wrappers: ["primeng-table"],
      },
    ]
  }

  sensorNodeLocationFields(): FormlyFieldConfig[] {
    return [
      {
        key: "latitude",
        type: "primeng-input",
        templateOptions: {
          label: "Latitude ",
           pattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,10})?))$/

        },
        wrappers: ["primeng-group"],
      },
      {
        key: "longitude",
        type: "primeng-input",
        templateOptions: {
          label: "Longitude ",
           pattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,10})?))$/

        },
        wrappers: ["primeng-group"],
      },
    ]
  }

  getInstallField(qrCode: boolean): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "serialId",
            type: "primeng-input",
            templateOptions: {
              label: "Serial number",
              placeholder: "",
              description: "Serial number",
              required: true,
              disabled: qrCode
            },
          },
          {
            key: "latitude",
            type: "primeng-input",
            templateOptions: {
              label: "Latitude",
              placeholder: "",
              description: "Latitude",
              required: true,
            },
          },
          {
            key: "longitude",
            type: "primeng-input",
            templateOptions: {
              label: "Longitude",
              placeholder: "",
              description: "Longitude",
              required: true,
            },
          },
          {
            key: "notes",
            type: "primeng-text-area",
            templateOptions: {
              label: "Notes",
              placeholder: "",
              description: "Notes",
              required: false,
            },
          },
        ],
      },
    ]
  }

  getGatewaySettingsField(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "frequency_min",
            type: "primeng-input",
            templateOptions: {
              label: "Min frequency",
              placeholder: "",
              description: "Min frequency",
              required: true,
            },
          },
          {
            key: "frequency_max",
            type: "primeng-input",
            templateOptions: {
              label: "Max frequency",
              placeholder: "",
              description: "Max frequency",
              required: true,
            },
          },
          {
            key: "hw_version",
            type: "primeng-input",
            templateOptions: {
              label: "Hardware version",
              placeholder: "",
              description: "Hardware version",
              required: true,
            },
          },
        ],
      },
    ]
  }

}
