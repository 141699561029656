import { Injectable } from "@angular/core"
import { SourceSpecification } from "maplibre-gl"
import { BASE_LAYER_ID } from "../pre-planning.constants"
import { LayerFactory, LayerSpecification } from "./../models/layer.factory"

const HIGHLIGHT_LINE_WIDTH = 5
const HIGHLIGHT_FILL_OPACITY = 0.9

const invertColor = (hex: string): string =>  {
  hex = hex.replace(/^#/, "")

  let r: string | number = parseInt(hex.substring(0, 2), 16)
  let g: string | number = parseInt(hex.substring(2, 4), 16)
  let b: string | number = parseInt(hex.substring(4, 6), 16)

  // Invert each color component
  r = (255 - r).toString(16).padStart(2, "0")
  g = (255 - g).toString(16).padStart(2, "0")
  b = (255 - b).toString(16).padStart(2, "0")

  return `#${r}${g}${b}`
}


@Injectable()
export class LayerManagementService {
  private mapInstance: any
  private customLayers: { [key: string]: { feature: GeoJSON.Feature; id: string } } = {}

  constructor() { }

  private isMapInitialized(): boolean {
    if (!this.mapInstance) {
      console.error("Map is not initialized")
      return false
    }
    return true
  }

  setMapInstance(mapInstance: any): void {
    this.mapInstance = mapInstance
  }

  addLayerToMap(feature: GeoJSON.Feature, id: string): void {
    if (!this.isMapInitialized()) return

    this.customLayers[id] = { feature, id }
    this.addLayerToMapInternal(feature, id)
  }

  private addLayerToMapInternal(feature: GeoJSON.Feature, id: string): void {
    const source: SourceSpecification = {
      type: "geojson",
      data: feature,
    }

    this.mapInstance.addSource(id, source)

    try {
      const layers = LayerFactory.createLayer(feature, id)
      layers.forEach(layer => this.addLayerBelowBase(layer))
    } catch (error) {
      console.warn(error.message)
    }
  }

  setLayerVisibility(layerId: string, visible: boolean): void {
    if (!this.isMapInitialized()) return

    const visibility = visible ? "visible" : "none"
    if (this.mapInstance.getLayer(layerId))
      this.mapInstance.setLayoutProperty(layerId, "visibility", visibility)

    if (this.mapInstance.getLayer(`${layerId}-text`))
      this.mapInstance.setLayoutProperty(`${layerId}-text`, "visibility", visibility)
  }

  removeLayer(layerId: string): void {
    if (!this.isMapInitialized()) return

    if (this.mapInstance.getLayer(layerId))
      this.mapInstance.removeLayer(layerId)

    if (this.mapInstance.getLayer(`${layerId}-text`))
      this.mapInstance.removeLayer(`${layerId}-text`)

    if (this.mapInstance.getSource(layerId))
      this.mapInstance.removeSource(layerId)

    if (this.customLayers[layerId])
      delete this.customLayers[layerId]
  }

  updateLayerStyle(layerId: string, color: string, opacity: number): void {
    if (!this.isMapInitialized()) return

    const layer = this.mapInstance.getLayer(layerId)
    if (!layer) {
      console.error(`Layer ${layerId} not found`)
      return
    }

    switch (layer.type) {
      case "circle":
        this.mapInstance.setPaintProperty(layerId, "circle-color", color)
        this.mapInstance.setPaintProperty(layerId, "circle-opacity", Number(opacity))
        break
      case "line":
        this.mapInstance.setPaintProperty(layerId, "line-color", color)
        this.mapInstance.setPaintProperty(layerId, "line-opacity", Number(opacity))
        break
      case "fill":
        this.mapInstance.setPaintProperty(layerId, "fill-color", color)
        this.mapInstance.setPaintProperty(layerId, "fill-opacity", Number(opacity))
        break
    }
  }

  updateTextLayer(layerId: string, name: string): void {
    if (!this.isMapInitialized()) return

    if (this.mapInstance.getLayer(`${layerId}-text`))
      this.mapInstance.setLayoutProperty(`${layerId}-text`, "text-field", name)
  }

  private addLayerBelowBase(layer: LayerSpecification): void {
    if (this.mapInstance.getLayer(BASE_LAYER_ID))
      this.mapInstance.addLayer(layer, BASE_LAYER_ID)
    else {
      console.warn(`Base layer ${BASE_LAYER_ID} not found. Adding layer on top.`)
      this.mapInstance.addLayer(layer)
    }
  }

  reAddCustomLayers(): void {
    Object.values(this.customLayers).forEach(({ feature, id }) => {
      this.addLayerToMapInternal(feature, id)
    })
  }

  highlightLayer(layerId: string): void {
    if (!this.isMapInitialized()) return

    const layer = this.mapInstance.getLayer(layerId)
    if (!layer) {
      console.error(`Layer ${layerId} not found`)
      return
    }


    switch (layer.type) {
      case "circle":
        this.mapInstance.setPaintProperty(layerId, "circle-opacity", 1)
        const originalColor = this.mapInstance.getPaintProperty(layerId, "circle-color")
        const invertedColor = invertColor(originalColor)
        this.mapInstance.setPaintProperty(layerId, "circle-color", invertedColor)
        break
      case "line":
        this.mapInstance.setPaintProperty(layerId, "line-opacity", 1)
        this.mapInstance.setPaintProperty(layerId, "line-width", HIGHLIGHT_LINE_WIDTH)
        break
      case "fill":
        this.mapInstance.setPaintProperty(layerId, "fill-opacity", HIGHLIGHT_FILL_OPACITY)
        break
    }
  }

  unhighlightLayer(layerId: string, feature: GeoJSON.Feature): void {
    if (!this.isMapInitialized()) return

    const layer = this.mapInstance.getLayer(layerId)
    if (!layer) {
      console.error(`Layer ${layerId} not found`)
      return
    }

    const originalOpacity = feature.properties?.opacity

    switch (layer.type) {
      case "circle":
        const originalRadius = feature.properties?.radius
        const originalColor =  feature.properties?.color
        this.mapInstance.setPaintProperty(layerId, "circle-opacity", originalOpacity)
        this.mapInstance.setPaintProperty(layerId, "circle-radius", originalRadius)
        this.mapInstance.setPaintProperty(layerId, "circle-color", originalColor)
        break
      case "line":
        const originalLineWidth = feature.properties?.width
        this.mapInstance.setPaintProperty(layerId, "line-opacity", originalOpacity)
        this.mapInstance.setPaintProperty(layerId, "line-width", originalLineWidth)
        break
      case "fill":
        this.mapInstance.setPaintProperty(layerId, "fill-opacity", originalOpacity)
        break
    }
  }
}


