import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { DeviceTypes, Gateway, UpGateway } from "@dryad-web-app/shared/state"
import { UplinkMessageService } from "@dryad-web-app/shared/data-access"
import * as _ from "lodash"
import { EChartsOption } from "echarts"
import { DryadDatePipe } from "libs/ui/dryad-ui/src/lib/pipes/date.pipe"
import { ChartService } from "../../service/chart.service"
import { utility } from "../../common/utility"

interface Times {
  name: string
  code: string
}

@Component({
  selector: "app-gateway-data",
  templateUrl: "./gateway-data.component.html",
  styleUrls: ["./gateway-data.component.scss"],
})
export class GatewayDataComponent implements OnInit, OnChanges {
  @Input() gateway: Gateway
  public chartOptBattery: any
  selectedTime = {
    name: "24H",
    code: "now-1d",
  }
  times: Times[]
  nodataMsg = "Loading data ..."

  constructor(private chartService: ChartService,
    private uplinkMessageService: UplinkMessageService,
    private dryadDatePipe: DryadDatePipe
  ) {
  }

  ngOnInit(): void {
    this.chartOptBattery = this.chartService.gatewayPowerChatConfig()
    this.populateTimes()
    this.getUplinkMessages()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gateway?.previousValue) {
      this.chartOptBattery.series[0].data = []
      this.getUplinkMessages()
    }
  }

  populateTimes(): void {
    this.times = [
      {
        name: "6H",
        code: "now-6h",
      },
      {
        name: "12H",
        code: "now-12h",
      },
      {
        name: "24H",
        code: "now-1d",
      },
      {
        name: "3D",
        code: "now-3d",
      },
      {
        name: "10D",
        code: "now-10d",
      },
    ]
  }

  changeTime(): void {
    this.getUplinkMessages()
  }

  getUplinkMessages(): void {
    const time = this.selectedTime ? this.selectedTime.code : "now-1d"
    if (!this.gateway) {
      this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
      return
    }
    this.uplinkMessageService
      .gatewayUplinkMessages(this.gateway.ns_gateway_id, time)
      .subscribe(
        (upMessages: UpGateway[]) => {
          if (upMessages.length === 0) this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
          this.chartOptBattery.series[0].data = []
          upMessages.forEach((upMessage) => {
            this.updateChart(upMessage)
          })
        },
        () => {
          this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
        }
      )
  }

  updateChart(upMessage: UpGateway): void {
    if (upMessage?.voltage !== undefined) {
      this.chartOptBattery = this.computeData(_.cloneDeep(this.chartOptBattery),
        upMessage.time, utility.calculateBatteryPercentage(upMessage?.voltage, DeviceTypes.GATEWAY, this.gateway.hw_version))
    }
  }

  computeData(options: EChartsOption, time: string, value: number): EChartsOption {
    if (options.series[0].data) {
      options.series[0].data.push(
        [utility.toTimeStamp(time), value]
      )
    } else {
      options.series[0]["data"] = []
      options.series[0].data.push(
        [utility.toTimeStamp(time), value]
      )
    }
    return options
  }
}
