import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from "@ngx-formly/core"

@Component({
  selector: 'dryad-web-app-dryad-formly-panel-wrapper',
  templateUrl: './dryad-formly-panel-wrapper.component.html',
  styleUrls: ['./dryad-formly-panel-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DryadFormlyPanelWrapperComponent extends FieldWrapper implements OnInit {
  constructor() {
    super()
  }
  ngOnInit(): void {}
}

