import {
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core"
import { UserService } from "@dryad-web-app/shared/data-access"
import { Map as MGLMap, MapMouseEvent } from "maplibre-gl"
import { MapComponent as MGLComponent, MapService } from "@maplibre/ngx-maplibre-gl"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { LayerManagementService } from "../../pre-planning/services/layer-management.service"
import Utils from "./utils"

/** Target number of pixels for telemetry underlay map */
let UNDERLAY_PIXEL_COUNT = 600_000
/** Image formats to use for telemetry underlay map, ordered by preference */
const UNDERLAY_IMAGE_FORMATS = ["image/webp", "image/jpeg", "image/png"]
/** Image quality to use for telemetry underlay map */
const UNDERLAY_IMAGE_QUALITY = 0.05

interface BasemapStyle {
  name: string
  styleUrl: string
  previewUrl: string
  backgroundColor: string
}

@Component({
  selector: "app-dryad-map",
  templateUrl: "./map.component.html",
  styleUrls: [ "./map.component.scss" ],
})
export class MapComponent {
  @Input("searchbar") allowSearchbar = true
  @Input() interactive = false
  @Input() fireLayer = false
  @Input() showTerrain = false
  @Input() fake2dViewMode = false
  @Input() layerImportMode = false
  @Output() mapOnClick = new EventEmitter<any>()
  @Output() mapReady = new EventEmitter<any>()
  @ViewChild("mapComponent") mapComponent: MGLComponent
  @ContentChild(TemplateRef) overlayComponents

  mglMap: MGLMap
  basemapStyle = 0
  availableBasemapStyles: BasemapStyle[]
  ready = true
  environment: any
  mapServiceInjector: Injector
  unitSystem: "metric" | "imperial"
  DEFAULT_MAP_EXAGGERATION = 1.5
  FLAT_MAP_EXAGGERATION_FAKE_2D = 0
  mapLoaded = false
  underlayImage: string
  underlayCanvas: HTMLCanvasElement
  private _mapInViewport = false
  isfake2d3dViewEnabled = false

  get MAP_EXAGGERATION(): number {
    if(this.fake2dViewMode && !this.isfake2d3dViewEnabled)
      return this.FLAT_MAP_EXAGGERATION_FAKE_2D

    return this.DEFAULT_MAP_EXAGGERATION
  }

  constructor(
    private userService: UserService,
    private layerManagementService: LayerManagementService,
  ) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    if (this.environment.mapTilerApiKey) {
      this.availableBasemapStyles = [
        {
          name: "Satellite",
          styleUrl: `https://api.maptiler.com/maps/hybrid/style.json?key=${this.environment.mapTilerApiKey}`,
          previewUrl: `https://api.maptiler.com/maps/hybrid/256/0/0/0.jpg?key=${this.environment.mapTilerApiKey}`,
          backgroundColor: "rgba(51, 67, 42, .8)",
        },
        {
          name: "Outdoor",
          styleUrl: `https://api.maptiler.com/maps/outdoor/style.json?key=${this.environment.mapTilerApiKey}`,
          previewUrl: `https://api.maptiler.com/maps/outdoor-v2/256/0/0/0.png?key=${this.environment.mapTilerApiKey}`,
          backgroundColor: "rgba(224, 241, 221, .8)",
        },
      ]
    } else {
      this.availableBasemapStyles = [
        {
          name: "Demo",
          styleUrl: "https://demotiles.maplibre.org/style.json",
          previewUrl: "/assets/images/demo-map.png",
          backgroundColor: "rgba(221, 241, 254, .8)",
        },
      ]
    }
    this.underlayCanvas = document.createElement("canvas")
  }

  initializeMap(map: MGLMap): void {
    this.unitSystem = this.userService.getLocalSettings().unitSystem
    this.mglMap = map
    Utils.makeControlContainer(map, "top")
    Utils.makeControlContainer(map, "left")
    Utils.makeControlContainer(map, "right")
    Utils.makeControlContainer(map, "bottom")
    map._controlPositions["outer-left"] = document.querySelector(".maplibregl-ctrl-outer-left")
    map._controlPositions["outer-top-left"] = document.querySelector(".maplibregl-ctrl-outer-top-left")
    map._controlPositions["outer-top-right"] = document.querySelector(".maplibregl-ctrl-outer-top-right")
    map.on("layerReady", (evt) => this.onLayerReady(evt.ready))
    map.on("idle", () => {
      const source = map.getCanvas()
      const dest = this.underlayCanvas
      const factor = Math.max(Math.sqrt((source.width * source.height) / UNDERLAY_PIXEL_COUNT), 1)
      dest.width = source.width / factor
      dest.height = source.height / factor
      const ctx = dest.getContext("2d")
      ctx.drawImage(source, 0, 0, dest.width, dest.height)
      const reader = new FileReader()
      reader.addEventListener("load", () => this.underlayImage = reader.result as string, false)
      dest.toBlob(blob => {
        if (blob.type !== UNDERLAY_IMAGE_FORMATS[0] && UNDERLAY_IMAGE_FORMATS.length > 1)
          return UNDERLAY_IMAGE_FORMATS.shift()
        if (blob.size > 75_000)
          return UNDERLAY_PIXEL_COUNT *= 0.9
        else if (blob.size < 50_000 && UNDERLAY_PIXEL_COUNT < (source.width * source.height))
          UNDERLAY_PIXEL_COUNT = Math.max(UNDERLAY_PIXEL_COUNT / 0.9, source.width * source.height)
        reader.readAsDataURL(blob)
      }, UNDERLAY_IMAGE_FORMATS[0], UNDERLAY_IMAGE_QUALITY)
    })
    this.mapServiceInjector = Injector.create({
      providers: [
        {
          provide: MapService,
          useValue: this.mapComponent["mapService"],
        },
      ],
    })
    setTimeout(() => this.mapLoaded = true, 50)
  }

  get mapInViewport(): boolean {
    return this._mapInViewport
  }

  set mapInViewport(value: boolean) {
    if (!value) {
      this.mapLoaded = false
      this.mapServiceInjector = undefined
      this.mglMap = undefined
    }
    this._mapInViewport = value
  }

  @HostListener("layerReady", ["$event.detail"])
    onLayerReady = (value: boolean): void => {
      this.ready = value
    }

  onClick(event: MapMouseEvent): void {
    if (!this.interactive) {
      event.preventDefault()
      event.originalEvent.stopPropagation()
      this.mapOnClick.emit()
    }
  }

  toggleFire(): void {
    this.fireLayer = !this.fireLayer
  }

  toggleTerrain(): void {
    this.showTerrain = !this.showTerrain
    if (!this.showTerrain)
      this.mglMap.easeTo({ pitch: 0, bearing: 0 })
  }

  toggleFake2d3dView(): void {
    this.isfake2d3dViewEnabled = !this.isfake2d3dViewEnabled
    if (!this.isfake2d3dViewEnabled)
      this.mglMap.easeTo({ pitch: 0, bearing: 0 })
    else
      this.mglMap.easeTo({ pitch: 30, bearing: 0 })
  }


  changeBasemapStyle(index: number): void {
    if(this.layerImportMode){
      this.mglMap.once("styledata", () => {
        this.layerManagementService.reAddCustomLayers()
      })
    }
    this.basemapStyle = index
  }

  getFireLayerUrl = Utils.getFireLayerUrl
}
