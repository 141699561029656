import { Component, OnInit } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { MessageService } from "primeng/api"
import { UserService, UserSettings } from "@dryad-web-app/shared/data-access"

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: [ "./user-settings.component.css" ],
})
export class UserSettingsComponent implements OnInit {

  constructor(
    public userService: UserService,
    private messageService: MessageService,
  ) {
  }

  settingsForm = new FormGroup({
    unitSystem: new FormControl<UserSettings["unitSystem"]>("metric"),
    dateFormat: new FormControl<UserSettings["dateFormat"]>("dd/MM/yyyy"),
    timeFormat: new FormControl<UserSettings["timeFormat"]>("HH:mm:ss"),
  })

  unitSystems = [
    { label: "Metric system (m, km, ha, °C, Pa)", value: "metric" },
    { label: "Imperial system (ft, mile, acre, °F, inHg)", value: "imperial" },
  ]

  dateFormats = [
    { label: "dd/mm/yyyy", value: "dd/MM/yyyy" },
    { label: "yyyy-mm-dd", value: "yyyy-MM-dd" },
  ]

  timeFormats = [
    { label: "24 hour format (22:00)", value: "HH:mm:ss" },
    { label: "12 hour format (10:00PM)", value: "h:mm:ss a" },
  ]

  ngOnInit(): void {
    this.setUserSettings()
    this.initFormChangeHandler()
  }

  private setUserSettings(): void {
    this.settingsForm.setValue(this.userService.getLocalSettings())
  }

  private initFormChangeHandler(): void {
    this.settingsForm.valueChanges.subscribe((change) => {
      this.userService.setLocalSettings(change)
      this.messageService.add({
        severity: "success",
        summary: "Settings updated successfully!",
        life: 600,
      })
    })
  }
}
