import { Component, OnInit, ViewEncapsulation } from "@angular/core"
import { MenuItem } from "@dryad-web-app/shared/state"
import { MenuItemsService } from "@dryad-web-app/shared/data-access"

@Component({
  selector: "dryad-web-app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PublicLayoutComponent implements OnInit {
  menuItems: MenuItem[] | undefined

  constructor(private menuItemsService: MenuItemsService) {
  }

  ngOnInit(): void {
    this.initMenuItems()
  }

  initMenuItems() {
    this.menuItems = this.menuItemsService.getMenuItems()
  }
}
