<div class="location-container">
  <div>
    <h4>
      Installed device location
    </h4>
    <dryad-web-app-dryad-formly
      [showCancelButton]="false"
      [showSubmitButton]="true"
      [fields]="fields"
      [model]="locationData"
      [isDisabled]="isFormSubmitDisabled"
      (submitEvent)="handleSubmit()">
    </dryad-web-app-dryad-formly>
  </div>
 <div>
  <p-gmap
  *ngIf="isGMapScriptLoaded"
  class="gmap"
  [options]="options"
  [overlays]="overlays"
  (onMapReady)="setMap($event)"
  (onOverlayDragEnd)="dragEnd($event)"
  styleClass="site-map-dashboard map"></p-gmap>
 </div>
</div>
