<div
  class="grid sensor-list"
  *ngFor="let sensor of sensorNodes"
  (mouseover)="sensor.hover = true"
  (mouseleave)="sensor.hover = false"
>
  <div class="col-12">
    <ng-template #headerActions>
      <img src="{{sensor.icon}}"  width="30px" height="30px">
    </ng-template>
    <dryad-web-app-row-expansion
      [label]="sensor.name"
      [headerButtons]="headerButtonSensorList"
      [headerActions]="headerActions"
      [showIndicators]="true"
      [showAddButton]="true"
    >
      <ng-template let-item>
        <app-sensor-data [sensor]="sensor"></app-sensor-data>
      </ng-template>
    </dryad-web-app-row-expansion>
  </div>
  <ng-template #headerButtonSensorList>
    <app-button-panel
      [buttonList]="buttonList"
      *ngIf="sensor.hover"
      [selectedEntity]="sensor"
    ></app-button-panel>
  </ng-template>
</div>
<p-paginator
  [rows]="numberOfRows"
  [totalRecords]="totalRecords"
  (onPageChange)="pageChange($event)"
  pageLinkSize="3"
></p-paginator>
