import { AbstractControl } from "@angular/forms"
import { FormlyFieldConfig } from "@ngx-formly/core"

export class FormUtils {
  public static fieldErrorMessage(
    label: string,
    formControl: AbstractControl
  ): string {
    if (formControl.touched && formControl.errors) {
      if (formControl.errors.required) return label + " is required"
      if (formControl.errors.pattern) return label + " pattern is not valid"
    }
    return ""
  }
}

export class FormFactory {
  static gatewayFormFields(): FormlyFieldConfig[] {
    return [
      {
        key: "name",
        type: "primeng-input",
        templateOptions: {
          label: "Name",
          placeholder: "",
          description: "Description",
          required: true,
        },
      },
      {
        key: "ids",
        // wrappers: ["panel"],
        templateOptions: { label: "IDs" },
        fieldGroup: [
          {
            key: "gateway_id",
            type: "primeng-input",
            templateOptions: {
              required: true,
              type: "text",
              label: "Gateway Id",
            },
          },
          {
            key: "eui",
            type: "primeng-input",
            templateOptions: {
              required: true,
              type: "text",
              label: "EUI",
            },
          },
        ],
      },
    ]
  }
}
