<div class="exception-body error-body">
  <div class="exception-panel">
    <p-button label="GO TO DASHBOARD" [routerLink]="['/']"></p-button>
  </div>

  <div class="exception-band">
    <div class="exception-content">
      <h1>ERROR OCCURED</h1>
      <p>Please try again later</p>
    </div>
  </div>
</div>
