import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { UiDryadUiModule } from "@dryad-web-app/ui/dryad-ui"
import { UiDryadFormModule } from "@dryad-web-app/ui/dryad-form"
import { SharedHelpersModule } from "@dryad-web-app/shared/helpers"
import { RecentJobsComponent } from "./job-tracker/recent-jobs/recent-jobs.component"
import { JobTrackerComponent } from "./job-tracker/job-tracker.component"
import { JobStatusComponent } from "./job-tracker/job-status/job-status.component"


@NgModule({
  declarations: [
    RecentJobsComponent,
    JobTrackerComponent,
    JobStatusComponent,
  ],
  imports: [
    CommonModule,
    UiDryadUiModule,
    UiDryadFormModule,
    SharedHelpersModule,
  ],
  exports: [
    RecentJobsComponent,
    JobTrackerComponent,
    JobStatusComponent,
  ],
})
export class JobsModule {
}
