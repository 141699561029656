import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from "@angular/core"
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core"
import { UntypedFormGroup } from "@angular/forms"

@Component({
  selector: 'dryad-web-app-dryad-formly',
  templateUrl: './dryad-formly.component.html',
})
export class DryadFormlyComponent implements OnChanges {
  @Input()
  model: any = {}

  @Input()
  fields: FormlyFieldConfig[] = []

  @Input()
  showSubmitButton = true

  @Input()
  showCancelButton = true

  @Input()
  showErrorSummery = true

  @Input()
  isSubmitting = false

  @Input()
  isDisabled = true

  @Input()
  form = new UntypedFormGroup({})

  options: FormlyFormOptions = {}

  @Output() submitEvent = new EventEmitter<any>()

  @Output() cancelEvent = new EventEmitter<boolean>()

  @Output() changeEvent = new EventEmitter<any>()

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes['isDisabled']  && changes['isDisabled'].currentValue === false) this.form.markAsDirty()
   }

  handleSubmit() {
    this.submitEvent.emit(this.model)
    this.isDisabled = true
  }

  handleCancel() {
    this.cancelEvent.emit(true)
  }

  changeForm() {
    this.isDisabled = false
    this.changeEvent.emit(this.model)
  }
}
