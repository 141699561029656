import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FooterComponent } from "./layout/footer/footer.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { InputTextModule } from "primeng/inputtext";
import { AutoCompleteModule } from "primeng/autocomplete";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { SliderModule } from "primeng/slider";
import { RowExpansionComponent } from "./row-expansion/row-expansion.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SideBarMenuComponent } from "./layout/side-bar-menu/side-bar-menu.component";
import { MainLayoutComponent } from "./layout/main-layout/main-layout.component";
import { PublicLayoutComponent } from "./layout/public-layout/public-layout.component";
import { ViewSidebarComponent } from "./layout/panel/view-sidebar/view-sidebar.component";
import { DryadToastComponent } from "./dryad-toast/dryad-toast.component";
import { DryadChartsDirective } from "./directive/dryad-charts.directive";
import { ButtonPanelComponent } from "./button-panel/button-panel.component";
import { SharedHelpersModule } from "@dryad-web-app/shared/helpers";
import { FloatingPanelComponent } from "./floating-panel/floating-panel.component";
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { AnimatedIllustration404Component } from "./animated-illustration/404/404.component";
import { DryadLogoSpinner } from "./animated-illustration/dryad-logo-spinner/dryad-logo-spinner.component";
import { ButtonComponent } from "./button/button.component";
import { PeacefullForestComponent } from "./animated-illustration/peacefull-forest/peacefull-forest.component";
import { AccessDeniedComponent } from "./animated-illustration/access-denied/access-denied.component";
export { CssVariableService } from "./services/css-variable.service";
import { DistancePipe } from "./pipes/distance.pipe";
import { AreaPipe } from "./pipes/area.pipe";
import { DryadDatePipe } from "./pipes/date.pipe";
import { SiteNotFoundComponent } from "./animated-illustration/site-not-found/site-not-found.component";
import { DryadBreadcrumbComponent } from "./dryad-breadcrumb/dryad-breadcrumb.component";
import { TemperaturePipe } from "./pipes/temperature.pipe";
import { AirPressurePipe } from "./pipes/air-pressure.pipe";
import { DryadPickListComponent } from "./dryad-pick-list/dryad-pick-list.component";
import { PickListModule } from "primeng/picklist";
import { CheckboxModule } from "primeng/checkbox";
import { ShortenStringPipe } from "./pipes/shorten-string.pipe";
import { ExtractPathPipe } from "./pipes/extract-path.pipe";
import { DynamicPipePipe } from "./pipes/dynamic-pipe.pipe";
import { JobStatusPipe } from "./pipes/job-status.pipe";
import { AlertModalComponent } from "./alert-modal/alert-modal.component";
import { ColorPickerModule } from "primeng/colorpicker";
import { AlertNotificationComponent } from "../../../../../apps/silvanet-web/src/app/standalone-components/alerts/alert-notification/alert-notification.component"

@NgModule({
  imports: [ AlertNotificationComponent,
    CommonModule,
    InputTextModule,
    AutoCompleteModule,
    SharedHelpersModule,
    BreadcrumbModule,
    PickListModule,
    CheckboxModule,
    ColorPickerModule,
  ],
  declarations: [
    FooterComponent,
    SearchBarComponent,
    RowExpansionComponent,
    HeaderComponent,
    SideBarMenuComponent,
    MainLayoutComponent,
    PublicLayoutComponent,
    ViewSidebarComponent,
    DryadToastComponent,
    DryadChartsDirective,
    ButtonPanelComponent,
    FloatingPanelComponent,
    AlertModalComponent,
    RelativeTimePipe,
    AnimatedIllustration404Component,
    DryadLogoSpinner,
    ButtonComponent,
    PeacefullForestComponent,
    AccessDeniedComponent,
    DistancePipe,
    AreaPipe,
    DryadDatePipe,
    SiteNotFoundComponent,
    DryadBreadcrumbComponent,
    DryadPickListComponent,
    TemperaturePipe,
    AirPressurePipe,
    ShortenStringPipe,
    ExtractPathPipe,
    DynamicPipePipe,
    JobStatusPipe,
  ],
  providers: [
    DistancePipe,
    DryadDatePipe,
    DatePipe,
    TemperaturePipe,
    AirPressurePipe,
  ],
  exports: [
    ButtonPanelComponent,
    FooterComponent,
    SearchBarComponent,
    RowExpansionComponent,
    HeaderComponent,
    SideBarMenuComponent,
    MainLayoutComponent,
    PublicLayoutComponent,
    ViewSidebarComponent,
    DryadToastComponent,
    DryadChartsDirective,
    RelativeTimePipe,
    AnimatedIllustration404Component,
    SiteNotFoundComponent,
    DryadLogoSpinner,
    ButtonComponent,
    PeacefullForestComponent,
    AccessDeniedComponent,
    DistancePipe,
    AreaPipe,
    DryadDatePipe,
    BreadcrumbModule,
    DryadPickListComponent,
    TemperaturePipe,
    AirPressurePipe,
    ShortenStringPipe,
    ExtractPathPipe,
    DynamicPipePipe,
    JobStatusPipe,
    InputTextModule,
    SliderModule,
    ColorPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiDryadUiModule {}
