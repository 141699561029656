import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UserService, UserSettings } from "@dryad-web-app/shared/data-access"
@Pipe({
  name: 'dryad_date'
})
export class DryadDatePipe implements PipeTransform {
  dateFormat: UserSettings["dateFormat"]
  timeFormat: UserSettings["timeFormat"]
  constructor(
    private userService: UserService,
    private datePipe: DatePipe,
    ) {
    this.dateFormat = this.userService.getLocalSettings().dateFormat
    this.timeFormat = this.userService.getLocalSettings().timeFormat
  }
  transform(date: number | string, ...args: unknown[]): string | null {
    const dateTimeFormat =  this.dateFormat + " " + this.timeFormat
    return this.datePipe.transform(
      date,
      dateTimeFormat
    )
  }
}