<div class="layout-sidebar">
  <div class="layout-tabmenu">
    <ul class="layout-tabmenu-nav full-height" [ngClass]="{ 'show-labels': showLabels }">
      <div>
        <li
          *ngFor="let menuItem of menuItems" routerLinkActive="active-item"
        >
          <a *ngIf="rbacService.permit(menuItem.auth)" [routerLink]="'/' + menuItem.slug" title="{{menuItem.label}}"
            class="ripplelink tabmenuitem-link">
            <i class="{{menuItem.icon}}"></i>
            <span *ngIf="menuItem.label === 'Alert Center' && activeAlertsCounter" class="alert-count-badge">{{activeAlertsCounter}}</span>
            <span class="label">{{menuItem.label}}</span>
          </a>
          <div class="layout-tabmenu-tooltip">
            <div class="layout-tabmenu-tooltip-arrow"></div>
            <div class="layout-tabmenu-tooltip-text">{{menuItem.label}}</div>
          </div>

          <ul *ngIf="menuItem.subMenus?.length > 0" class="sub-menu">
            <li *ngFor="let submenu of menuItem.subMenus" routerLinkActive="active-sub-item">
              <a *ngIf="rbacService.permit(submenu.auth)" [routerLink]="'/' + submenu.slug" title="{{submenu.label}}"
                 class="ripplelink tabmenuitem-link" >
                <i class="{{submenu.icon}}"></i>
                <span class="label">{{submenu.label}}</span>
              </a>
            </li>
          </ul>
        </li>
      </div>

      <li class="collapse">
        <button (click)="toggleShowLabels()" [title]="(showLabels ? 'Collapse' : 'Open') + ' sidebar'">
          <i class="ph ph-caret-double-left" [ngClass]="{ 'rotate-180': !showLabels}"></i>
          <span class="label">Collapse</span>
        </button>
      </li>
    </ul>
  </div>
</div>
