<span class="formly-field p-float-label input-wrapper">
  <p-multiSelect
    appendTo="body"
    [defaultLabel]="'.'"
    [options]="to.options | formlySelectOptions: field | async"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [filter]="true"
    (onChange)="to.change && to.change(field, $event)"
  >
  </p-multiSelect>
  <label>{{ to.label }}<span *ngIf="to.required">*</span></label>
  <small class="p-invalid">{{
    formUtils.fieldErrorMessage(to.label, formControl)
    }}</small>
</span>
