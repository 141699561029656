<dryad-web-app-dryad-formly
  [showCancelButton]="false"
  [showSubmitButton]="false"
  [fields]="fields"
  [model]="metaData"
>
</dryad-web-app-dryad-formly>
<div class="grid ">
  <div class="col-md-3 flex-center-content">
    <div class="form-buttons p-p-3">
      <dryad-button
        label="Reset to default"
        (click)="handleReset()"
      ></dryad-button>
    </div>
  </div>
</div>

