import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-me-page",
  templateUrl: "./me-page.component.html",
  styleUrls: ["./me-page.component.css"],
})
export class MePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
