import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssVariableService {

  constructor() { }

  public set = (variableName: string, value: string): void => {
    const root = document.documentElement
    root.style.setProperty(variableName, value)
  }

  public get = (variableName: string): string => {
    const root = document.documentElement
    return root.style.getPropertyValue(variableName)
  }
}
