import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core"

@Component({
  selector: 'dryad-web-app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SearchBarComponent implements OnInit {
  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>()
  // Autocomplete
  @Output() completeMethod: EventEmitter<any> = new EventEmitter<any>()
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>()

  @Input() isAutoComplete: boolean = false
  @Input() suggestions: any
  @Input() minLength: number = 1
  @Input() field: string = ''
  selectedInput: any
  constructor() { }

  ngOnInit(): void {
  }

  searchInputText(value: any) {
    this.inputChange.emit(value)
  }
  onCompleteMethod(value:any) {
    this.completeMethod.emit(value)
  }
  onSelectMethods(value:any) {
    this.onSelect.emit(value)
  }

  onClearMethods($event: any) {
    this.onClear.emit($event)
  }

  clearInput() {
    this.selectedInput = null
    this.onClear.emit({})
  }
}
