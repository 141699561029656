export enum RoleNames {
    ClientAdmin = "Admin",
    StandardUser = "Standard User",
    SuperAdmin = "Super Admin",
    DryadAdmin = "Dryad Admin",
} 

export enum ResellerFlag {
    DirectCustomer = 0,
    Reseller = 1,
    Internal = 2,
}