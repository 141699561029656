<div class="model-validation-chart">
  <div class="validation-controls">
    <label for="validation-record-date"
      ><span>Training record date / end device id :</span></label
    >
    <div class="grid">
      <div class="col-12 md:col-4">
        <p-calendar
          class="validation-record-date"
          inputId="validation-record-date"
          dateFormat="yy-mm-dd"
          [(ngModel)]="validationRecordDate"
          [maxDate]="maxDate"
          (onSelect)="handleValidationRecordDateSelect()"
        >
        </p-calendar>
      </div>
      <div class="col-12 md:col-4">
        <p-dropdown
          class="select-site"
          [options]="sites"
          [(ngModel)]="selectedSite"
          placeholder="Select a site"
          optionLabel="name"
          (onChange)="onSelectedSiteChange($event)"
        >
        </p-dropdown>
      </div>
      <div class="col-12 md:col-4">    <p-dropdown
        class="select-sensor-node"
        [options]="sensorNodes"
        [(ngModel)]="selectedSensorNode"
        placeholder="Select a sendor node"
        optionLabel="name"
        (onChange)="onSelectedSensorNodeChange($event)"
      >
      </p-dropdown></div>
    </div>
  </div>
  <div>
    <p-progressSpinner *ngIf="loadingChartData"></p-progressSpinner>
  </div>
  <div *ngIf="chartOpt">
    <div
      appDryadCharts
      [chartOptions]="chartOpt"
      (chartEvent)="onChartEvent($event)"
      class="charts"
    ></div>
    <span *ngIf="!chartOpt">{{ chartMessage }}</span>
  </div>
  <dryad-web-app-dryad-formly
    [model]="model"
    [fields]="experimentDataFormFields"
    [form]="experimentDataForm"
    (submitEvent)="handleSubmit($event)"
    (cancelEvent)="handleCancel()"
  >
  </dryad-web-app-dryad-formly>
</div>
