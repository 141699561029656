import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms"
import { Organization } from "../service/http/organizations.service"

export const noWhitespaceValidator = (control: FormControl): ValidationErrors | null => {
  if (control.value && control.value.trim() === "")
    return { "whitespace": true }

  return null
}

export const validateExistingOrgRequired = (): ValidatorFn => (form: FormGroup): ValidationErrors | null => {
  const newOrgchecked = form.get("newOrganizationChecked")
  const selectedOrg = form.get("selectedOrganization")
  const newOrgName = form.get("newOrgName")

  if (!newOrgchecked.value && !selectedOrg.value) {
    return {
      "err": true,
    }
  }
  if (newOrgchecked.value) newOrgName.setErrors(noWhitespaceValidator(newOrgName as FormControl) || Validators.required(newOrgName))
  else newOrgName.setErrors(null)

  return null
}

export const checkNewOrgNameExists = (organizations: Organization[], form: FormGroup): boolean =>
  organizations.some((org) => org.name.toLowerCase() === form.value.newOrgName.trim().toLowerCase())
