import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from "@ngx-formly/core"

@Component({
  selector: 'dryad-web-app-dryad-formly-group-wrapper',
  templateUrl: './dryad-formly-group-wrapper.component.html',
  styleUrls: ['./dryad-formly-group-wrapper.component.scss']
})
export class DryadFormlyGroupWrapperComponent extends FieldWrapper implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
