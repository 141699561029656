import { Component, OnInit, ViewChild } from "@angular/core"
import {
  Gateway,
  ObservableState,
  SensorNode,
  SignalStatus,
  UpMessage,
} from "@dryad-web-app/shared/state"
import { combineLatest } from "rxjs"
import { DirectusApiService, GatewayService, SensorNodeService, SiteService, UplinkMessageService } from "@dryad-web-app/shared/data-access"
import _ from "lodash"
import { MapComponent } from "../map/map.component"
import { startOnboarding } from "./map-page.onboarding"

@Component({
  selector: "app-map",
  templateUrl: "./map-page.component.html",
  styleUrls: [ "./map-page.component.scss" ],
})
export class MapPageComponent implements OnInit {
  allGateways: Gateway[]
  allSensors: SensorNode[]
  _sensorNodeRecord: Record<number, SensorNode> = {}

  @ViewChild("map") mapComponent: MapComponent

  constructor(
    private oss: ObservableState,
    private siteService: SiteService,
    private gatewayService: GatewayService,
    private sensorNodeService: SensorNodeService,
    private directusService: DirectusApiService,
    private upMessageService: UplinkMessageService,
  ) { }

  async ngOnInit(): Promise<void> {
    const [sites, gateways, sensors] = await Promise.all([
      this.siteService.sites().toPromise(),
      this.gatewayService.gateways().toPromise(),
      this.directusService.getMapSensorNodes().toPromise(),
    ])
    const siteIds = new Set(sites.map(site => site.id))
    const activeSiteIds = new Set<number>()
    this.allGateways = gateways.filter(gw => siteIds.has(gw.site))
    this.sensorNodeRecord = _.fromPairs(sensors
      .filter(sn => siteIds.has(sn.site as number))
      .map(sensor => {
        activeSiteIds.add(sensor.site as number)
        return [sensor.id, {
          ...sensor,
          signal_status: SignalStatus.OFFLINE,
          latitude: Number(sensor.latitude),
          longitude: Number(sensor.longitude),
        }]
      }))

    sites.filter(site => activeSiteIds.has(site.id)).forEach(site => {
      const siteId = site.id
      combineLatest([
        this.sensorNodeService.sensorNodesForSiteWithHealthDetails(siteId),
        this.upMessageService.fireAlertsForSite(siteId),
        this.oss.fireAlerts$(),
      ]).subscribe(([healthDetails, allMessages, fireAlerts]) => {
        const updatedSensors: Record<string, SensorNode> = {}
        healthDetails.forEach(value => {
          updatedSensors[value.id] = { ...this._sensorNodeRecord[value.id], ...value }
        })
        allMessages
          .filter(msg => msg.sensorAlerts.smoke && msg.alertUpdate && msg.sensorId)
          .forEach(({ sensorId }) =>
            updatedSensors[sensorId] = { ...this._sensorNodeRecord[sensorId], signal_status: SignalStatus.FIRE_ALERT }
          )
        fireAlerts.forEach((upMessages: UpMessage[]) => {
          const orderedMessages = _.orderBy(upMessages, "time", [ "desc" ])
          const upMessage = orderedMessages[0]
          if (upMessage.siteId !== siteId.toString()) return
          if (upMessage.sensorAlerts.smoke && upMessage.alertUpdate)
            updatedSensors[upMessage.sensorId] = { ...this._sensorNodeRecord[upMessage.sensorId], signal_status: SignalStatus.FIRE_ALERT }
        })
        this.sensorNodeRecord = { ...this._sensorNodeRecord, ...updatedSensors }
      })
    })
  }

  set sensorNodeRecord(value: Record<number, SensorNode>) {
    this._sensorNodeRecord = value
    this.allSensors = Object.values(value)
  }

  onMapReady(): void {
    startOnboarding(this.mapComponent)
  }
}
