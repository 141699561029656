import { Component, Input, OnInit } from "@angular/core"
import { BaseButton } from "@dryad-web-app/shared/state"


@Component({
  selector: "app-button-panel",
  templateUrl: "./button-panel.component.html",
  styleUrls: ["./button-panel.component.scss"],
})
export class ButtonPanelComponent implements OnInit {
  @Input() buttonList: BaseButton[] = []
  @Input() selectedEntity: any
  constructor() {
  }

  ngOnInit(): void {

  }
}
