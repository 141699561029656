import { Client, SensorDetails } from "@dryad-web-app/shared/state"

/* eslint-disable @typescript-eslint/naming-convention */
export enum AlertStateEnum {
  new = "new",
  closed = "closed",
  timed_out = "timed-out",
}

export enum AlertCloseReason {
  fe = "fe",
  fa = "fa",
  ft = "ft",
}

export interface Alert {
  readonly uuid?: string
  readonly url?: string
  readonly urn?: string
  legacy_site_id: number
  readonly state?: string
  readonly reason?: ReasonEnum | NullEnum
  readonly created_at?: string
  readonly updated_at?: string
  site: string
}

export interface EnrichedAlert {
  alert: Alert
  alert_events: AlertEvent[]
  alert_site: AlertSite
  site_clients: Client[]
  show_modal?: boolean
  alert_sensor?: SensorDetails
}

export interface AlertSite {
  legacy_site_id: number
  uuid: string
  name: string
}

export interface PaginatedAlertEventList {
  count: number
  next?: string
  previous?: string
  results: Array<AlertEvent>
}

export interface AlertEvent {
  readonly simulated?: boolean
  readonly payload?: any
  readonly alert?: string
  readonly device_eui?: string
  readonly is_fire_alert?: boolean
  readonly fire_probability?: number
  readonly confidence_level?: number
  readonly uuid?: string
  readonly url?: string
  readonly urn?: string
  readonly created_at?: string
  readonly updated_at?: string
}

export interface PaginatedAlertList {
  count: number
  next?: string
  previous?: string
  results: Array<Alert>
}

export type ReasonEnum = "fe" | "fa" | "ft"

export const ReasonEnum = {
  Fe: "fe" as ReasonEnum,
  Fa: "fa" as ReasonEnum,
  Ft: "ft" as ReasonEnum,
}

export type NullEnum = "null"

export const NullEnum = {
  Null: "null" as NullEnum,
}

export enum AlertCenterTabNames {
  current = "current-alerts",
  past = "past-alerts",
}

export interface AlertTransition {
  state: string
  transition: string
  timestamp: string
}


export interface AlertNotification {
  alert: Alert
  alert_site: AlertSite
  alert_event: AlertEvent
  sensor_details: SensorDetails
}
