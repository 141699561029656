<span class="formly-field p-float-label input-wrapper">
  <p-dropdown
    appendTo="body"
    [options]="to.options | formlySelectOptions: field | async"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [showClear]="!to.required"
    [filter]="to.filter"
    [autoDisplayFirst]="false"
    (onChange)="to.change && to.change(field, $event)"
  >
  </p-dropdown>
  <label>{{ to.label }}<span *ngIf="to.required">*</span></label>
  <small class="p-invalid">{{
    FormUtils.fieldErrorMessage(to.label, formControl)
    }}</small>
</span>
