import { HttpClientModule } from "@angular/common/http"
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { SharedAuthModule } from "@dryad-web-app/shared/auth"
import { DynamicScriptLoaderService, MenuItemsService } from "@dryad-web-app/shared/data-access"
import {
  ENVIRONMENTS,
  ServiceLocator,
  SharedHelpersModule,
} from "@dryad-web-app/shared/helpers"
import { ObservableState, rootReducer } from "@dryad-web-app/shared/state"
import { UiDryadFormModule } from "@dryad-web-app/ui/dryad-form"
import { StoreModule } from "@ngrx/store"
import { FormlySelectModule as FormlyCoreSelectModule } from "@ngx-formly/core/select"
import { FormlyPrimeNGModule } from "@ngx-formly/primeng"
import { AccordionModule } from "primeng/accordion"
import { MessageService } from "primeng/api"
import { AutoCompleteModule } from "primeng/autocomplete"
import { CalendarModule } from "primeng/calendar"
import { CardModule } from "primeng/card"
import { CheckboxModule } from "primeng/checkbox"
import { DataViewModule } from "primeng/dataview"
import { DividerModule } from "primeng/divider"
import { DropdownModule } from "primeng/dropdown"
import { FileUploadModule } from "primeng/fileupload"
import { InputTextareaModule } from "primeng/inputtextarea"
import { MultiSelectModule } from "primeng/multiselect"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { PanelModule } from "primeng/panel"
import { PasswordModule } from "primeng/password"
import { PickListModule } from "primeng/picklist"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { RadioButtonModule } from "primeng/radiobutton"
import { ScrollPanelModule } from "primeng/scrollpanel"
import { SelectButtonModule } from "primeng/selectbutton"
import { SidebarModule } from "primeng/sidebar"
import { SplitButtonModule } from "primeng/splitbutton"
import { StepsModule } from "primeng/steps"
import { TabViewModule } from "primeng/tabview"
import { ToggleButtonModule } from "primeng/togglebutton"
import { NgxFaviconModule } from "ngx-favicon"

import { UiDryadUiModule } from "@dryad-web-app/ui/dryad-ui"
import { environment } from "../environments/environment"
import { JobStatusPipe } from "../../../../libs/ui/dryad-ui/src/lib/pipes/job-status.pipe"
import { AppComponent } from "./app.component"
import { appRoutes } from "./app.routes"
import { DashboardComponent } from "./dashboard/dashboard.component"
import { ExperimentSessionsComponent } from "./experiments/experiment-sessions/experiment-sessions.component"
import {
  CreateExperimentRecordComponent,
} from "./experiments/experiments-list/create-experiment-record/create-experiment-record.component"
import { ExperimentsListComponent } from "./experiments/experiments-list/experiments-list.component"
import { ModelValidationComponent } from "./experiments/model-validation/model-validation.component"
import { SiteDeviceComponent } from "./filter/site-device/site-device.component"
import { SafePipe } from "./common/pipe/safe-pipe"
import { GatewaySelectionComponent } from "./sites/planing/gateway-selection/gateway-selection.component"
import { AssignPacketComponent } from "./sites/planing/packet-list/assign-packet/assign-packet.component"
import { PacketListComponent } from "./sites/planing/packet-list/packet-list.component"
import { PlaningMapComponent } from "./sites/planing/planing-map/planing-map.component"
import { PlaningComponent } from "./sites/planing/planing.component"
import { WebMenuItemsService } from "./service/web-menu-items.service"
import { UplinkMessagesComponent } from "./uplink-messages/uplink-messages.component"
import { UserManagementModule } from "./user management/user-management.module"
import { favicons, AvailableCustomFavicons } from "./favicon.config"
import { HomeComponent } from "./pages/home/home.component"
import { MePageComponent } from "./pages/me-page/me-page.component"
import { UserSettingsComponent } from "./pages/me-page/user-settings/user-settings.component"
import { GatewaySettingsComponent } from "./settings/gateway-settings/gateway-settings.component"
import { SharedModule } from "./shared/shared.module"
import { SensorNodesModule } from "./sensor-nodes/sensor-nodes.module"
import { AlphaTestWarningsComponent } from "./misc/alpha-test-warnings/alpha-test-warnings.component"
import { GatewayTechDetailsComponent } from "./gateway/gateway-tech-details/gateway-tech-details.component"
import { LayerManagementService } from "./pre-planning/services/layer-management.service"
import { AlertNotificationComponent } from "./standalone-components/alerts/alert-notification/alert-notification.component"

@NgModule({
  imports: [
    SharedAuthModule,
    // Ng Imports
    StoreModule.forRoot(rootReducer),
    SharedHelpersModule,
    BrowserModule,
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: "favicon",
      defaultUrl: "/favicons/default.png",
      custom: favicons,
    }),
    FormsModule,
    appRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    DropdownModule,
    CardModule,
    CheckboxModule,
    SelectButtonModule,
    MultiSelectModule,
    FileUploadModule,
    CalendarModule,
    SidebarModule,
    InputTextareaModule,
    StepsModule,
    DividerModule,
    PanelModule,
    TabViewModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    AccordionModule,
    ToggleButtonModule,
    PasswordModule,
    FormlyCoreSelectModule,
    FormlyPrimeNGModule,
    // Others
    RadioButtonModule,
    DataViewModule,
    SplitButtonModule,
    UiDryadUiModule,
    AutoCompleteModule,
    UiDryadFormModule,
    PickListModule,
    SharedModule,
    SensorNodesModule,
    UserManagementModule,
    AlertNotificationComponent,
  ],
  declarations: [
    AppComponent,
    UplinkMessagesComponent,
    SafePipe,
    SiteDeviceComponent,
    ModelValidationComponent,
    ExperimentSessionsComponent,
    DashboardComponent,
    ExperimentsListComponent,
    CreateExperimentRecordComponent,
    PlaningComponent,
    PlaningMapComponent,
    PacketListComponent,
    AssignPacketComponent,
    GatewaySelectionComponent,
    HomeComponent,
    MePageComponent,
    UserSettingsComponent,
    GatewaySettingsComponent,
    AlphaTestWarningsComponent,
    GatewayTechDetailsComponent,
  ],
  providers: [
    ObservableState,
    MessageService,
    { provide: ENVIRONMENTS, useValue: environment },
    { provide: MenuItemsService, useClass: WebMenuItemsService },
    JobStatusPipe,
    LayerManagementService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [
    SafePipe,
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector
    this.injector.get(DynamicScriptLoaderService).loadScript("google-maps").then(() => {
      console.log("Google script loaded")
    })
  }
}
