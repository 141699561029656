<div class="button-list">
  <span *ngFor="let button of buttonList" [ngSwitch]="button.type">
    <p-inputSwitch
      *ngSwitchCase="'switch'"
      class="p-mr-2"
      (click)="button.command()"
    ></p-inputSwitch>
    <a
      *ngSwitchCase="'link'"
      class="p-mr-2"
      (click)="button.command()"
    >
          <i class="ph {{ button.icon }}"></i>
    </a>

    <span *ngSwitchDefault>
      <button
        pButton
        pRipple
        icon="ph {{ button.icon }}"
        class="{{ button.styleClass }}"
        (click)="button.command(selectedEntity)"
        *ngIf="button.isVisible"
        [disabled]="button.isDisabled"
        [ngClass]="{'button-disabled': button.isDisabled === true }"
        label="{{button.label}}"
      ></button>
    </span>
  </span>
</div>
