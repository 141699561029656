import { Injectable } from "@angular/core"
import { MenuItem } from "@dryad-web-app/shared/state"

@Injectable()
export class MenuItemsService {

  constructor() {
  }

  getMenuItems(): MenuItem[] {
    return []
  }
}
