<div *ngIf="viewSidebarState" class="side-bar">
  <div [ngSwitch]="position">
    <div *ngSwitchCase="'left'">
      <ng-container *ngTemplateOutlet="left"> </ng-container>
    </div>
    <div *ngSwitchCase="'right'">
      <ng-container *ngTemplateOutlet="right"> </ng-container>
    </div>
    <div *ngSwitchCase="'top'">
      <ng-container *ngTemplateOutlet="top"> </ng-container>
    </div>
  </div>
</div>

<ng-template #left>
  <p-sidebar
    *ngIf="viewSidebarState?.entity"
    [(visible)]="visible"
    [baseZIndex]="10000"
    [modal]="false"
    [transitionOptions]="'900ms cubic-bezier(0, 0, 0.2, 1)'"
    (onHide)="handleDialogHide()"
  >
    <ng-container *ngTemplateOutlet="sidebarContent"> </ng-container>
  </p-sidebar>
</ng-template>

<ng-template #right>
  <p-sidebar
    *ngIf="viewSidebarState?.entity"
    [(visible)]="visible"
    [baseZIndex]="10000"
    position="right"
    [modal]="false"
    [transitionOptions]="'900ms cubic-bezier(0, 0, 0.2, 1)'"
    (onHide)="handleDialogHide()"
  >
    <ng-container *ngTemplateOutlet="sidebarContent"> </ng-container>
  </p-sidebar>
</ng-template>

<ng-template #top>
  <p-sidebar
    *ngIf="viewSidebarState?.entity"
    [(visible)]="visible"
    [baseZIndex]="10000"
    position="top"
    [modal]="false"
    [transitionOptions]="'900ms cubic-bezier(0, 0, 0.2, 1)'"
    (onHide)="handleDialogHide()"
  >
    <ng-container *ngTemplateOutlet="sidebarContent"> </ng-container>
  </p-sidebar>
</ng-template>

<ng-template #sidebarContent>
  <h3>{{ title }}</h3>
</ng-template>
