import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { QRCodeModule } from "angularx-qrcode"
import { SharedHelpersModule } from "@dryad-web-app/shared/helpers"
import { UiDryadUiModule } from "@dryad-web-app/ui/dryad-ui"
import { UiDryadFormModule } from "@dryad-web-app/ui/dryad-form"
import {
  BatteryWidgetComponent,
} from "../sensor-nodes/sensor-node/sensor-status/battery-widget/battery-widget.component"
import { DeviceMetadataComponent } from "../device/device-edit/device-metadata/device-metadata.component"
import { DeviceLocationComponent } from "../device/device-edit/device-location/device-location.component"
import { DeviceTechInfoComponent } from "../device/device-edit/device-tech-info/device-tech-info.component"
import { DeviceEditComponent } from "../device/device-edit/device-edit.component"
import { GatewayComponent } from "../gateway/gateway.component"
import { DevicePhotosComponent } from "../device/device-edit/device-photos/device-photos.component"
import {
  GatewayTechInfoComponent,
} from "../device/device-edit/device-tech-info/gateway-tech-info/gateway-tech-info.component"
import {
  SensorNodeTechInfoComponent,
} from "../device/device-edit/device-tech-info/sensor-node-tech-info/sensor-node-tech-info.component"
import { GatewayDataComponent } from "../gateway/gateway-data/gateway-data.component"
import { JobsModule } from "../jobs/jobs.module"


@NgModule({
  declarations: [
    BatteryWidgetComponent,
    DeviceMetadataComponent,
    DeviceLocationComponent,
    DeviceTechInfoComponent,
    GatewayTechInfoComponent,
    SensorNodeTechInfoComponent,
    GatewayDataComponent,
    DeviceEditComponent,
    GatewayComponent,
    DevicePhotosComponent],
  imports: [
    CommonModule,
    QRCodeModule,
    UiDryadUiModule,
    UiDryadFormModule,
    SharedHelpersModule,
    JobsModule,
  ],
  exports: [
    QRCodeModule,
    BatteryWidgetComponent,
    UiDryadUiModule,
    UiDryadFormModule,
    SharedHelpersModule,
    DeviceMetadataComponent,
    DeviceLocationComponent,
    DeviceTechInfoComponent,
    DeviceEditComponent,
    GatewayComponent,
    GatewayTechInfoComponent,
    SensorNodeTechInfoComponent,
    GatewayDataComponent,
    DevicePhotosComponent,
    JobsModule,
  ],
})
export class SharedModule {
}
