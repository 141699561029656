<span class="p-float-label input-wrapper">
  <textarea
    [ngClass]="{ 'ng-invalid': !formControl.valid }"
    pInputTextarea
    [formControl]="formControl"
    [formlyAttributes]="field"
  ></textarea>
  <label>{{ to.label }}<span *ngIf="to.required">*</span></label>
  <small class="p-invalid" *ngIf="showError">{{
    FormUtils.fieldErrorMessage(to.label, formControl)
    }}</small>
</span>
