import { Component, OnInit } from "@angular/core"
import { AuthService } from "../../services/auth.service"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { Router } from "@angular/router"
import { serviceRootUrl } from "../../../../../../../apps/silvanet-web/src/app/utils/api-utils"

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Component({
  selector: "forestfloor-callback",
  templateUrl: "./forestfloor-callback.component.html",
  styleUrls: ["./forestfloor-callback.component.scss"]
})
export class ForestFloorCallbackComponent implements OnInit {
  environment: any

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
  }

  async ngOnInit() {
    const response = await fetch(`${forestFloorBaseUrl}/api/auth/keycloak/login/`, { credentials: "include", method: "GET", headers: { Accept: "application/json; version=1.0"} })
    const payload = await response.json()
    if (payload.access) {
      localStorage.setItem("forestfloor_token", payload.access)
      if (this.environment.dataApiKey) {
        this.router.navigate(["/"], { replaceUrl: true })
      } else {
        this.authService.redirectDirectusAuth()
      }
    } else {
      // TODO: Error handling
    }
  }
}
