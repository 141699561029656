import { Component, Input } from "@angular/core"
import { Feature, Point } from "geojson"
import { point } from "@turf/turf"
import { SiteService } from "@dryad-web-app/shared/data-access"
import { DeviceTypes, Gateway, GatewayTypes, SensorNode, SignalStatus } from "@dryad-web-app/shared/state"
import { LngLatBounds, Map as MGLMap, MapMouseEvent } from "maplibre-gl"
import { MapService } from "@maplibre/ngx-maplibre-gl"
import Utils from "apps/silvanet-web/src/app/device-map/device-layer/utils"
import markerIcons from "apps/silvanet-web/src/app/device-map/device-layer/marker"
import { urlToImage } from "apps/silvanet-web/src/app/device-map/map/utils"
import { showAlertIndicator, showWarningIndicator } from "../../sites/site-utils"
import {
  alertBadge,
  borderGatewayIcon,
  meshGatewayIcon,
  sensorFireIcon,
  sensorIcon,
  warningBadge,
} from "../../sites/site-list/site/site-devices/device-table-icons/icons"

const DEVICE_ZOOM_LEVEL = 14

const signalStatusLookup: Record<SignalStatus, string> = {
  [SignalStatus.ACTIVE]: "active",
  [SignalStatus.PENDING]: "active",
  [SignalStatus.OFFLINE]: "offline",
  [SignalStatus.FIRE_ALERT]: "fire",
}

const markers = [
  urlToImage("mesh-gateway-active", meshGatewayIcon(SignalStatus.ACTIVE)),
  urlToImage("mesh-gateway-offline", meshGatewayIcon(SignalStatus.OFFLINE)),
  urlToImage("border-gateway-active", borderGatewayIcon(SignalStatus.ACTIVE)),
  urlToImage("border-gateway-offline", borderGatewayIcon(SignalStatus.OFFLINE)),
  urlToImage("sensor-active", sensorIcon(SignalStatus.ACTIVE, true)),
  urlToImage("sensor-offline", sensorIcon(SignalStatus.OFFLINE, true)),
  urlToImage("sensor-calibrating", sensorIcon(SignalStatus.ACTIVE, false)),
  urlToImage("sensor-fire", sensorFireIcon()),
  urlToImage("alert-badge", alertBadge(), 32),
  urlToImage("warning-badge", warningBadge(), 32),
]

const makeFeatures = (devices: Array<Gateway | SensorNode>): Feature<Point, Gateway | SensorNode>[] => {
  const features = devices.map(device => {
    if (!device.latitude || !device.longitude) return null
    const status = signalStatusLookup[device.signal_status]
    const showAlert = showAlertIndicator(device)
    const showWarning = !showAlert && showWarningIndicator(device)
    let sortKey = 0
    let iconName = `sensor-${status}`
    if ((device as SensorNode).is_calibrated === false)
      sortKey = 1
    else if ((device as Gateway).gateway_type === GatewayTypes.BORDER_GATEWAY) {
      iconName = `border-gateway-${status}`
      sortKey = 2
    } else if ((device as Gateway).gateway_type === GatewayTypes.MESH_GATEWAY) {
      iconName = `mesh-gateway-${status}`
      sortKey = 3
    } else if (device.signal_status === SignalStatus.FIRE_ALERT)
      sortKey = 4

    if((status === SignalStatus.ACTIVE || status === SignalStatus.PENDING)
      && !(device as SensorNode).is_calibrated && device.type === DeviceTypes.SENSOR_NODE)
      iconName = "sensor-calibrating"
    return point([device.longitude, device.latitude], { ...device, iconName, showAlert, showWarning, sortKey })
  })
  return features.filter(feature => !!feature)
}

@Component({
  selector: "app-device-layer",
  templateUrl: "./device-layer.component.html",
})
export class DeviceLayerComponent {

  mglMap: MGLMap
  focus?: Feature<Point> = undefined
  currentZoom = 1
  ready = false

  private _sensors: SensorNode[]
  private _gateways: Gateway[]

  constructor(
    private mapService: MapService,
    private siteService: SiteService,
  ) {
    this.mapService.mapCreated$.subscribe(() => {
      this.mglMap = this.mapService.mapInstance
      this.onZoom()
      this.mglMap.on("zoom", () => this.onZoom())
      this.mglMap.on("mousemove", evt => this.onHover(evt))
      this.mglMap.on("mouseleave",  evt => this.onHover(evt))
      this.mglMap.on("click", evt => this.onClick(evt))
      this.mglMap.fire("layerReady", { ready: false })
      Promise.all(markers)
        .then(images => images.forEach(([imageId, data]) => this.mglMap.addImage(imageId, data)))
      this.siteService.mapClickDeviceId$.subscribe((id => {
        const clickedDevice = this.devices.find(device => device.properties.id === id)
        this.focus = clickedDevice
        if (clickedDevice) {
          const currentZoom = this.mglMap.getZoom()
          const renderedFeatures = this.mglMap.queryRenderedFeatures({ filter: ["==", ["get", "id"], id] })
          const zoomTarget = Math.max(DEVICE_ZOOM_LEVEL + 0.5, currentZoom)
          // Don't scroll into view if feature is visible
          if (renderedFeatures.length && zoomTarget === currentZoom) return
          this.mglMap.easeTo({
            center: clickedDevice.geometry.coordinates as [number, number],
            zoom: zoomTarget,
          })
        }
      }))
    })
  }

  @Input()
  set sensors(value: SensorNode[]) {
    this._sensors = value
    this.checkIfReady()
  }

  @Input()
  set gateways(value: Gateway[]) {
    this._gateways = value
    this.checkIfReady()
  }

  get devices(): Feature<Point>[] {
    const sensorNodeFeatures = makeFeatures(this._sensors || [])
    const gatewayFeatures = makeFeatures(this._gateways || [])
    return [...sensorNodeFeatures, ...gatewayFeatures]
  }

  checkIfReady(): void {
    if (!this._sensors || !this._gateways || this.ready || !this.mapService.mapInstance) return
    this.ready = true
    const allDevices = this.devices
    if (allDevices.length) {
      const bounds = new LngLatBounds()
      allDevices.forEach(device => bounds.extend(device.geometry.coordinates as [number, number]))
      this.mapService.fitBounds(bounds, { animate: false, padding: { top: 70, bottom: 70, left: 70, right: 70 } })
    }
    setTimeout(() => this.mglMap?.fire("layerReady", { ready: true }), 50)
  }

  onZoom(): void {
    this.currentZoom = this.mglMap?.getZoom() || this.currentZoom
  }

  onHover(event: MapMouseEvent): void {
    const features = this.mglMap?.queryRenderedFeatures(event.point, { filter: ["to-boolean", ["get", "type"]] })
    if (!features?.length)
      this.mglMap.getCanvas().style.cursor = "default"
    else
      this.mglMap.getCanvas().style.cursor = "pointer"

  }

  onClick(event: MapMouseEvent): void {
    const features = this.mglMap?.queryRenderedFeatures(event.point, { filter: ["to-boolean", ["get", "type"]] })
    if (!features?.length)
      this.focus = undefined
    else {
      const feature = features[0]
      this.focus = feature as Feature<Point>
      this.siteService.setClickedDeviceId(feature.properties.id)
    }
  }

  getMarkerColor = Utils.getMarkerColor
  markerIcons = markerIcons
}
