import { Component, Input, OnChanges } from "@angular/core"
import { GatewayDataDetails } from "@dryad-web-app/shared/state"
import { NetworkServerService } from "@dryad-web-app/shared/data-access"

@Component({
  selector: "app-gateway-tech-info",
  templateUrl: "./gateway-tech-info.component.html",
  styleUrls: ["./gateway-tech-info.component.scss"],
})
export class GatewayTechInfoComponent implements OnChanges {
  @Input() gatewayTechInfo: GatewayDataDetails
  gatewayEUI: string
  constructor(private networkServerService: NetworkServerService,) {
  }

  ngOnChanges(): void {
    if (typeof this.gatewayTechInfo?.data?.site === "number") {
      this.networkServerService.frequencyPlans(this.gatewayTechInfo?.data?.site).subscribe(frequencies => {
        const frequency = frequencies.filter(f => f.id === this.gatewayTechInfo?.details?.frequencyPlanId)
        if (frequency.length > 0) this.gatewayTechInfo.details["frequencyName"] = frequency[0].name
      })
    }
    if (this.gatewayTechInfo?.details?.gatewayEUI) this.gatewayEUI = this.gatewayTechInfo?.details?.gatewayEUI?.toLowerCase()
    else this.gatewayEUI = this.gatewayTechInfo?.data?.eui?.toLowerCase()
  }

}
