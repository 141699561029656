import {Component} from "@angular/core"
import {FieldWrapper} from "@ngx-formly/core"
import {FormEvent, FORMLY_WRAPPER_EVENT, FormlyFieldStatus, ObservableState} from "@dryad-web-app/shared/state"
import * as _ from "lodash"

@Component({
  selector: "dryad-web-app-dryad-formly-table-edit-wrapper",
  templateUrl: "./dryad-formly-table-edit-wrapper.component.html",
  styleUrls: ["./dryad-formly-table-edit-wrapper.component.scss"],
})
export class DryadFormlyTableEditWrapperComponent extends FieldWrapper {
  isEditable = false

  constructor(private oss: ObservableState) {
    super()
  }

  edit(): void {
    this.isEditable = false
    this.oss.dispatch({
      type: FORMLY_WRAPPER_EVENT,
      payload: new FormlyFieldStatus(_.cloneDeep(this.field), FormEvent.UPDATE),
    })
  }
}
