import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'
import { FilterService } from "primeng/api"
import { ObjectUtils } from "primeng/utils"
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop"
import { DomHandler } from "primeng/dom"

export interface PickListFilterOptions {
  filter?: (value?: any) => void
  reset?: () => void
}

@Component({
  selector: 'dryad-web-app-pick-list',
  templateUrl: './dryad-pick-list.component.html',
  styleUrls: [ './dryad-pick-list.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'p-element'
  },
})
export class DryadPickListComponent implements AfterViewChecked, AfterContentInit {

  @Input() source: any[]

  @Input() target: any[]

  @Input() sourceHeader: string

  @Input() targetHeader: string

  @Input() responsive: boolean

  @Input() filterBy: string

  @Input() filterLocale: string

  @Input() trackBy: Function = (index: number, item: any) => item

  @Input() sourceTrackBy: Function

  @Input() targetTrackBy: Function

  @Input() showSourceFilter: boolean = true

  @Input() showTargetFilter: boolean = true

  @Input() metaKeySelection: boolean = true

  @Input() dragdrop: boolean = false

  @Input() style: any

  @Input() styleClass: string

  @Input() sourceStyle: any

  @Input() targetStyle: any

  @Input() showSourceControls: boolean = true

  @Input() showTargetControls: boolean = true

  @Input() sourceFilterPlaceholder: string

  @Input() targetFilterPlaceholder: string

  @Input() disabled: boolean = false

  @Input() ariaSourceFilterLabel: string

  @Input() ariaTargetFilterLabel: string

  @Input() filterMatchMode: string = "contains"

  @Input() breakpoint: string = "960px"

  @Input() stripedRows: boolean

  @Input() keepSelection: boolean = false

  @Input() sourceTotalRecords: number = 0

  @Input() targetTotalRecords: number = 0

  @Output() sourceOnPageChangeEvent: EventEmitter<any> = new EventEmitter()

  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter()

  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter()

  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter()

  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter()

  @Output() onSourceReorder: EventEmitter<any> = new EventEmitter()

  @Output() onTargetReorder: EventEmitter<any> = new EventEmitter()

  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter()

  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter()

  @Output() onSourceFilter: EventEmitter<any> = new EventEmitter()

  @Output() onTargetFilter: EventEmitter<any> = new EventEmitter()

  @ViewChild('sourcelist') listViewSourceChild: ElementRef

  @ViewChild('targetlist') listViewTargetChild: ElementRef

  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef

  @ViewChild('targetFilter') targetFilterViewChild: ElementRef

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any> | undefined


  public visibleOptionsSource: any[]

  public visibleOptionsTarget: any[]

  selectedItemsSource: any[] = []

  selectedItemsTarget: any[] = []

  reorderedListElement: any

  movedUp: boolean

  movedDown: boolean

  itemTouched: boolean

  styleElement: any

  id: string = UniqueComponentId()

  filterValueSource: string

  filterValueTarget: string

  fromListType: number

  emptyMessageSourceTemplate: TemplateRef<any>

  emptyFilterMessageSourceTemplate: TemplateRef<any>

  emptyMessageTargetTemplate: TemplateRef<any>

  emptyFilterMessageTargetTemplate: TemplateRef<any>

  sourceHeaderTemplate: TemplateRef<any>

  targetHeaderTemplate: TemplateRef<any>

  sourceFilterTemplate: TemplateRef<any>

  targetFilterTemplate: TemplateRef<any>

  sourceFilterOptions: PickListFilterOptions

  targetFilterOptions: PickListFilterOptions

  readonly SOURCE_LIST = -1

  readonly TARGET_LIST = 1

  selectedItems: any[] = []

  currentPage: number = 0

  constructor(public el: ElementRef, public cd: ChangeDetectorRef, public filterService: FilterService) {
  }

  ngOnInit() {

    if (this.filterBy) {
      this.sourceFilterOptions = {
        filter: (value) => this.filterSource(value),
        reset: () => this.resetSourceFilter()
      }

      this.targetFilterOptions = {
        filter: (value) => this.filterTarget(value),
        reset: () => this.resetTargetFilter()
      }
    }
  }

  ngAfterContentInit() {}

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      const listItems = DomHandler.find(this.reorderedListElement, 'li.p-highlight')
      let listItem

      if (this.movedUp)
        listItem = listItems[0]
      else
        listItem = listItems[listItems.length - 1]

      DomHandler.scrollInView(this.reorderedListElement, listItem)
      this.movedUp = false
      this.movedDown = false
      this.reorderedListElement = null
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>, isSource: boolean) {
    if (this.disabled) {
      return
    }

    const index = this.findIndexInSelection(item, selectedItems)
    const selected = (index != -1)
    const metaSelection = this.itemTouched ? false : this.metaKeySelection

    if (metaSelection) {
      const metaKey = (event.metaKey || event.ctrlKey || event.shiftKey)

      if (selected && metaKey) {
        selectedItems.splice(index, 1)
      } else {
        if (!metaKey) {
          selectedItems.length = 0
        }
        selectedItems.push(item)
      }
    } else {
      if (selected)
        selectedItems.splice(index, 1)
      else
        selectedItems.push(item)
    }

    callback.emit({ originalEvent: event, items: selectedItems })

    this.itemTouched = false
    if (isSource) this.moveRight()
    else this.moveLeft()
  }

  onSourceItemDblClick() {
    if (this.disabled) {
      return
    }
    this.moveRight()
  }

  onTargetItemDblClick() {
    if (this.disabled) {
      return
    }
    this.moveLeft()
  }

  onFilter(event: KeyboardEvent, listType: number) {
    const query = (<HTMLInputElement>event.target).value
    if (listType === this.SOURCE_LIST)
      this.filterSource(query)
    else if (listType === this.TARGET_LIST)
      this.filterTarget(query)
  }

  filterSource(value: any = '') {
    this.filterValueSource = value.trim().toLocaleLowerCase(this.filterLocale)
    this.filter(this.source, this.SOURCE_LIST)
  }

  filterTarget(value: any = '') {
    this.filterValueTarget = value.trim().toLocaleLowerCase(this.filterLocale)
    this.filter(this.target, this.TARGET_LIST)
  }

  filter(data: any[], listType: number) {
    const searchFields = this.filterBy.split(',')

    if (listType === this.SOURCE_LIST) {
      this.visibleOptionsSource = this.filterService.filter(data, searchFields, this.filterValueSource, this.filterMatchMode, this.filterLocale)
      this.onSourceFilter.emit({ query: this.filterValueSource, value: this.visibleOptionsSource })
    } else if (listType === this.TARGET_LIST) {
      this.visibleOptionsTarget = this.filterService.filter(data, searchFields, this.filterValueTarget, this.filterMatchMode, this.filterLocale)
      this.onTargetFilter.emit({ query: this.filterValueTarget, value: this.visibleOptionsTarget })
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST)
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource)
    else
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget)
  }

  isEmpty(listType: number) {
    if (listType == this.SOURCE_LIST)
      return this.filterValueSource ? (!this.visibleOptionsSource || this.visibleOptionsSource.length === 0) : (!this.source || this.source.length === 0)
    else
      return this.filterValueTarget ? (!this.visibleOptionsTarget || this.visibleOptionsTarget.length === 0) : (!this.target || this.target.length === 0)
  }


  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue && filterValue.trim().length) {
      for (let i = 0; i < data.length; i++) {
        if (item == data[i]) {
          return true
        }
      }
    } else {
      return true
    }
  }

  onItemTouchEnd() {
    if (this.disabled) {
      return
    }

    this.itemTouched = true
  }


  moveRight() {
    if (this.selectedItemsSource && this.selectedItemsSource.length) {
      for (let i = 0; i < this.selectedItemsSource.length; i++) {
        const selectedItem = this.selectedItemsSource[i]
        if (ObjectUtils.findIndexInList(selectedItem, this.target) == -1) {
          this.target.push(this.source.splice(ObjectUtils.findIndexInList(selectedItem, this.source), 1)[0])

          if (this.visibleOptionsSource)
            this.visibleOptionsSource.splice(ObjectUtils.findIndexInList(selectedItem, this.visibleOptionsSource), 1)
        }
      }

      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      })

      if (this.keepSelection) {
        this.selectedItemsTarget = [ ...this.selectedItemsTarget, ...this.selectedItemsSource ]
      }

      this.selectedItemsSource = []

      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST)
      }
    }
  }

  moveLeft() {
    if (this.selectedItemsTarget && this.selectedItemsTarget.length) {
      for (let i = 0; i < this.selectedItemsTarget.length; i++) {
        const selectedItem = this.selectedItemsTarget[i]
        if (ObjectUtils.findIndexInList(selectedItem, this.source) == -1) {
          this.source.push(this.target.splice(ObjectUtils.findIndexInList(selectedItem, this.target), 1)[0])

          if (this.visibleOptionsTarget)
            this.visibleOptionsTarget.splice(ObjectUtils.findIndexInList(selectedItem, this.visibleOptionsTarget), 1)[0]
        }
      }

      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      })

      if (this.keepSelection) {
        this.selectedItemsSource = [ ...this.selectedItemsSource, ...this.selectedItemsTarget ]
      }

      this.selectedItemsTarget = []

      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST)
      }
    }
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return ObjectUtils.findIndexInList(item, selectedItems)
  }

  onDrop(event: CdkDragDrop<string[]>, listType: number) {
    const isTransfer = event.previousContainer !== event.container
    const dropIndexes = this.getDropIndexes(event.previousIndex, event.currentIndex, listType, isTransfer, event.item.data)

    if (listType === this.SOURCE_LIST) {
      if (isTransfer) {
        transferArrayItem(event.previousContainer.data, event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex)
        const selectedItemIndex = ObjectUtils.findIndexInList(event.item.data, this.selectedItemsTarget)

        if (selectedItemIndex != -1) {
          this.selectedItemsTarget.splice(selectedItemIndex, 1)

          if (this.keepSelection) {
            this.selectedItemsTarget.push(event.item.data)
          }
        }

        if (this.visibleOptionsTarget)
          this.visibleOptionsTarget.splice(event.previousIndex, 1)

        this.onMoveToSource.emit({ items: [ event.item.data ] })
      } else {
        moveItemInArray(event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex)
        this.onSourceReorder.emit({ items: [ event.item.data ] })
      }

      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST)
      }
    } else {
      if (isTransfer) {
        transferArrayItem(event.previousContainer.data, event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex)

        const selectedItemIndex = ObjectUtils.findIndexInList(event.item.data, this.selectedItemsSource)

        if (selectedItemIndex != -1) {
          this.selectedItemsSource.splice(selectedItemIndex, 1)

          if (this.keepSelection) {
            this.selectedItemsTarget.push(event.item.data)
          }
        }

        if (this.visibleOptionsSource)
          this.visibleOptionsSource.splice(event.previousIndex, 1)

        this.onMoveToTarget.emit({ items: [ event.item.data ] })
      } else {
        moveItemInArray(event.container.data, dropIndexes.previousIndex, dropIndexes.currentIndex)
        this.onTargetReorder.emit({ items: [ event.item.data ] })
      }

      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST)
      }
    }
  }

  getDropIndexes(fromIndex: number, toIndex: number, droppedList: any, isTransfer: any, data: any) {
    let previousIndex, currentIndex

    if (droppedList === this.SOURCE_LIST) {
      previousIndex = isTransfer ? this.filterValueTarget ? ObjectUtils.findIndexInList(data, this.target) : fromIndex : this.filterValueSource ? ObjectUtils.findIndexInList(data, this.source) : fromIndex
      currentIndex = this.filterValueSource ? this.findFilteredCurrentIndex(this.visibleOptionsSource, toIndex, this.source) : toIndex
    } else {
      previousIndex = isTransfer ? this.filterValueSource ? ObjectUtils.findIndexInList(data, this.source) : fromIndex : this.filterValueTarget ? ObjectUtils.findIndexInList(data, this.target) : fromIndex
      currentIndex = this.filterValueTarget ? this.findFilteredCurrentIndex(this.visibleOptionsTarget, toIndex, this.target) : toIndex
    }

    return { previousIndex, currentIndex }
  }

  findFilteredCurrentIndex(visibleOptions: any, index: any, options: any) {
    if (visibleOptions.length === index) {
      const toIndex = ObjectUtils.findIndexInList(visibleOptions[index - 1], options)

      return toIndex + 1
    } else {
      return ObjectUtils.findIndexInList(visibleOptions[index], options)
    }
  }

  resetSourceFilter() {
    this.visibleOptionsSource = []
    this.filterValueSource = null
    this.sourceFilterViewChild && ((<HTMLInputElement>this.sourceFilterViewChild.nativeElement).value = '')
  }

  resetTargetFilter() {
    this.visibleOptionsTarget = []
    this.filterValueTarget = null
    this.targetFilterViewChild && ((<HTMLInputElement>this.targetFilterViewChild.nativeElement).value = '')
  }

  onItemKeydown(event: KeyboardEvent, item: any, selectedItems: any[], callback: EventEmitter<any>, isSource: boolean) {
    const listItem = <HTMLLIElement>event.currentTarget

    switch (event.which) {
      //down
      case 40:
        const nextItem = this.findNextItem(listItem);
        if (nextItem) {
          nextItem.focus()
        }

        event.preventDefault()
        break

      //up
      case 38:
        const prevItem = this.findPrevItem(listItem);
        if (prevItem) {
          prevItem.focus()
        }

        event.preventDefault()
        break

      //enter
      case 13:
        this.onItemClick(event, item, selectedItems, callback, isSource)
        event.preventDefault()
        break
    }
  }

  findNextItem(item) {
    const nextItem = item.nextElementSibling

    if (nextItem)
      return !DomHandler.hasClass(nextItem, 'p-picklist-item') || DomHandler.isHidden(nextItem) ? this.findNextItem(nextItem) : nextItem
    else
      return null
  }

  findPrevItem(item) {
    const prevItem = item.previousElementSibling

    if (prevItem)
      return !DomHandler.hasClass(prevItem, 'p-picklist-item') || DomHandler.isHidden(prevItem) ? this.findPrevItem(prevItem) : prevItem
    else
      return null
  }

  destroyStyle() {
    if (this.styleElement) {
      document.head.removeChild(this.styleElement)
      this.styleElement = null
    }
  }

  ngOnDestroy() {
    this.destroyStyle()
  }

  moveAllRight() {
    if (this.source) {
      const movedItems = [];

      for (let i = 0; i < this.source.length; i++) {
        if (this.isItemVisible(this.source[i], this.SOURCE_LIST)) {
          const removedItem = this.source.splice(i, 1)[0];
          this.target.push(removedItem);
          movedItems.push(removedItem);
          i--;
        }
      }

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      if (this.keepSelection) {
        this.selectedItemsTarget = [ ...this.selectedItemsTarget, ...this.selectedItemsSource ]
      }

      this.selectedItemsSource = [];

      if (this.filterValueTarget) {
        this.filter(this.target, this.TARGET_LIST);
      }

      this.visibleOptionsSource = [];
    }
  }

  moveAllLeft() {
    if (this.target) {
      const movedItems = [];

      for (let i = 0; i < this.target.length; i++) {
        if (this.isItemVisible(this.target[i], this.TARGET_LIST)) {
          const removedItem = this.target.splice(i, 1)[0];
          this.source.push(removedItem);
          movedItems.push(removedItem);
          i--;
        }
      }

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      if (this.keepSelection) {
        this.selectedItemsSource = [ ...this.selectedItemsSource, ...this.selectedItemsTarget ]
      }

      this.selectedItemsTarget = [];

      if (this.filterValueSource) {
        this.filter(this.source, this.SOURCE_LIST);
      }

      this.visibleOptionsTarget = [];
    }
  }

  sourceOnPageChange(event:any): void {
    this.currentPage = event.first
    this.sourceOnPageChangeEvent.emit(event)
  }

  targetOnPageChange(event: any) {
    console.log(event)
  }
}


function UniqueComponentId(): string {
  return '111'
}

