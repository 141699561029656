import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from "@dryad-web-app/shared/data-access";

@Pipe({
  name: 'airPressure'
})
export class AirPressurePipe implements PipeTransform {
  constructor(private userService: UserService) {
  }

  transform(value: number, ...args: unknown[]): string {
   return  (value) ? this.convertAirPressure(value) + this.getSymbol(): 'N/A'
  }

  convertAirPressure(value: number): number {
    const unitSystem = this.userService.getLocalSettings().unitSystem
    if (unitSystem === "imperial") {
      return Math.round(value  * 0.00029529983071445)
    } else {
      return Math.round(value) / 100 // hPa
    }
  }

  getSymbol(): string {
    const unitSystem = this.userService.getLocalSettings().unitSystem
    if (unitSystem === "imperial") return " inHg"
    else return  " hPa"
  }
}
