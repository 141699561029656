import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core"
import { UplinkMessageService } from "@dryad-web-app/shared/data-access"
import {
  Assets, BaseButton,
  MapUtils, SensorNode,
  DeviceFilter, DirectusDeviceResponse,
} from "@dryad-web-app/shared/state"
import * as _ from "lodash"
import { Router } from "@angular/router"
import { DeviceListService } from "../shared/device-list.service"
import {
  DeviceDirectusApiService,
} from "../../../../../libs/shared/data-access/src/lib/services/common-services/device-directus-api.service"

@Component({
  selector: "app-sensor-node-list",
  templateUrl: "./sensor-node-list.component.html",
  styleUrls: [ "./sensor-node-list.component.scss" ],
})
export class SensorNodeListComponent implements OnInit {
  @Input() siteId: number
  sensorNodes: SensorNode[]
  buttonList: BaseButton[] = []
  numberOfRows = 20
  totalRecords = 0

  constructor(
    private sensorListService: DeviceListService,
    private uplinkMessageService: UplinkMessageService,
    private changeDetectRef: ChangeDetectorRef,
    private router: Router,
    private deviceDirectusApiService: DeviceDirectusApiService,
  ) {
  }

  ngOnInit(): void {
    this.initSensorList(0)
    this.initButtonList()
  }

  initSensorList(offset: number = 0): void {
    const filterObj: DeviceFilter = new DeviceFilter()
    filterObj["site"] = {
      "_eq": this.siteId,
    }
    this.deviceDirectusApiService.sensorList(this.numberOfRows, offset, filterObj).subscribe((nodes: DirectusDeviceResponse) => {
      this.totalRecords = nodes.meta.filter_count
      // @ts-ignore
      this.sensorNodes = nodes.data
      this.getLastMessage(this.sensorNodes)
    })
  }

  initButtonList(): void {
    this.buttonList.push(
      new BaseButton(
        "Go to Sensor",
        (selectedEntity: SensorNode) => this.goToSensor(selectedEntity),
        true,
        false,
        "ph ph-arrow-square-out ph-2x",
        "p-button p-button-rounded p-button-text p-button-info"
      )
    )
  }

  editSensorNode(sensorNode: SensorNode): void {
    this.sensorListService.editSensorNode(sensorNode)
  }

  getLastMessage(ids: SensorNode[]): void {
    this.uplinkMessageService
      .updateSensorNodesWithStatus(ids)
      .subscribe((sensorNodes) => {
        sensorNodes.map((sensor) => {
          const selectedSensor = _.find(
            this.sensorNodes,
            (e) => e.id === sensor.id
          )
          selectedSensor.state = sensor.state // Assets.MARKER_BASE_URL + MapUtils.sensorMarkerState(sensor.state) + ".png"
          selectedSensor["icon"] =
            Assets.MARKER_BASE_URL +
            MapUtils.sensorMarkerState(sensor.state) +
            ".svg"
        })
        this.changeDetectRef.detectChanges()
      })
  }

  deleteSensor(sensorNode: SensorNode): void {
    this.sensorListService.deleteSensor(sensorNode)
  }

  pageChange($event: any): void {
    this.initSensorList($event.first)
  }

  goToSensor(sensor: SensorNode): void {
    this.router.navigate([ "sites/" + this.siteId + "/sensors/" + sensor.id ])
  }
}
