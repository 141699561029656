import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core"
import { BatteryLevel, SensorNode, UpMessage } from "@dryad-web-app/shared/state"
import { SensorNodeService, UplinkMessageService } from "@dryad-web-app/shared/data-access"
import { AirPressurePipe, TemperaturePipe } from "@dryad-web-app/ui/dryad-ui"
import * as _ from "lodash"
import { EChartsOption } from "echarts"
import { ActivatedRoute } from "@angular/router"
import * as moment from "moment/moment"
import { DryadDatePipe } from "libs/ui/dryad-ui/src/lib/pipes/date.pipe"
import { ChartService } from "../../../service/chart.service"
import { utility } from "../../../common/utility"

interface Times {
  name: string
  code: string
}

@Component({
  selector: "app-sensor-data",
  templateUrl: "./sensor-data.component.html",
  styleUrls: ["./sensor-data.component.scss"],
})
export class SensorDataComponent implements OnInit, OnChanges {
  @Input() sensor: SensorNode
  public chartOptAir: any
  public chartOptTemp: any
  public chartOptHumidity: any
  public chartOptIAQ: any
  public chartOptBattery: any
  nodataMsg = "Loading data ..."
  selectedTime = {
    name: "24H",
    code: "24h",
  }
  times: Times[]
  fullScreen = false
  timeRange = false
  fromDate: string
  toDate: string

  constructor(
    private chartService: ChartService,
    private uplinkMessageService: UplinkMessageService,
    private temperaturePipe: TemperaturePipe,
    private airPressurePipe: AirPressurePipe,
    private route: ActivatedRoute,
    private sensorNodeService: SensorNodeService,
    private dryadDatePipe: DryadDatePipe
  ) {
    this.initCharts()
    this.route.params.subscribe(params => {
      if (params["sensorId"]) {
        this.fullScreen = true
        this.sensorNodeService.sensorNode(params["sensorId"]).subscribe((sensor: SensorNode) => {
          this.sensor = sensor
          this.getUplinkMessages()
        })
      } else this.fullScreen = false

      this.populateTimes()
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sensor?.previousValue && changes.sensor.previousValue.id !== changes.sensor.currentValue.id) {
      this.initCharts()
      this.getUplinkMessages()
    }
  }


  ngOnInit(): void {
    this.getUplinkMessages()
  }

  initCharts(): void {
    this.chartOptAir = this.chartService.airPressureChatConfig()
    this.chartOptTemp = this.chartService.temperatureChatConfig()
    this.chartOptHumidity = this.chartService.humidityChatConfig()
    this.chartOptIAQ = this.chartService.iaqChatConfig()
    this.chartOptBattery = this.chartService.batteryChatConfig()
  }

  populateTimes(): void {
    this.times = [
      {
        name: "6H",
        code: "6h",
      },
      {
        name: "12H",
        code: "12h",
      },
      {
        name: "24H",
        code: "24h",
      },
      {
        name: "3D",
        code: "72h",
      },
      {
        name: "10D",
        code: "240h",
      },
    ]
  }

  getUplinkMessages(): void {
    const time = this.selectedTime ? this.selectedTime.code : "24h"
    if (!this.sensor) {
      this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
      return
    }
    this.uplinkMessageService
      .uplinkMessages(this.sensor.ns_end_device_id, time)
      .subscribe(
        (upMessages: UpMessage[]) => {
          this.resetCharts()
          upMessages.sort(function (a, b) {
            // @ts-ignore
            return new Date(a.time) - new Date(b.time)
          })
          upMessages.forEach((upMessage) => {
            this.updateChart(upMessage)
          })
        },
        (error) => console.log(error),
        () => {
          this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
        }
      )
  }

  updateChart(upMessage: UpMessage, isRealTime= false): void {
    if (upMessage?.sensorValues["air-pressure"] !== undefined) {
      this.chartOptAir = this.computeData(_.cloneDeep(this.chartOptAir),
        upMessage.time, this.airPressurePipe.convertAirPressure(upMessage?.sensorValues["air-pressure"]),isRealTime)
    }
    if (upMessage?.sensorValues["temperature"] !== undefined) {
      this.chartOptTemp = this.computeData(_.cloneDeep(this.chartOptTemp),
        upMessage.time, this.temperaturePipe.convertTemperature(upMessage?.sensorValues["temperature"]),isRealTime)
    }
    if (upMessage?.sensorValues["humidity"] !== undefined) {
      this.chartOptHumidity = this.computeData(_.cloneDeep(this.chartOptHumidity),
        upMessage.time, upMessage?.sensorValues["humidity"],isRealTime)
    }
    if (upMessage?.sensorValues["static-iaq"] !== undefined) {
      this.chartOptIAQ = this.computeData(_.cloneDeep(this.chartOptIAQ),
        upMessage.time, upMessage?.sensorValues["static-iaq"],isRealTime)
    }
    if (upMessage?.sensorValues["battery"] && upMessage?.sensorValues["battery"] > BatteryLevel.MIN_BATTERY_LEVEL) {
      this.chartOptBattery = this.computeData(_.cloneDeep(this.chartOptBattery),
        upMessage.time, utility.calculateBatteryPercentage(upMessage?.sensorValues["battery"]),isRealTime)
    }

    // this.setMin()
  }

  computeData(options: EChartsOption, time: string, value: number,isRealTime= false): EChartsOption {
    if (options.series[0].data) {
      if(isRealTime) options.series[0].data.shift()
      options.series[0].data.push(
        [utility.toTimeStamp(time), value]
      )
    } else {
      options.series[0]["data"] = []
      options.series[0].data.push(
        [utility.toTimeStamp(time), value]
      )
    }
    return options
  }

  resetCharts(): void {
    this.chartOptAir.series[0].data = []
    this.chartOptTemp.series[0].data = []
    this.chartOptHumidity.series[0].data = []
    this.chartOptIAQ.series[0].data = []
    this.chartOptBattery.series[0].data = []
  }

  changeTime(rangeUpdate?: boolean): void {
    if (rangeUpdate) {
      this.uplinkMessageService.uplinkMessagesForTimeRange(this.sensor.ns_end_device_id, moment(this.fromDate).utc().format()
        , moment(this.toDate).utc().format()).subscribe((upMessages: UpMessage[]) => {
        this.initCharts()
        upMessages.forEach((upMessage) => {
          this.updateChart(upMessage)
        })
      })
      return
    }
    this.uplinkMessageService
      .uplinkMessages(this.sensor.ns_end_device_id, this.selectedTime.code)
      .subscribe(
        (upMessages: UpMessage[]) => {
          this.initCharts()
          upMessages.forEach((upMessage) => {
            this.updateChart(upMessage)
          })
        },
        (error) => console.log(error),
        () => {
          this.nodataMsg = `No data available at ${this.dryadDatePipe.transform(new Date().toString())}`
        }
      )
  }

  getMinY(arr): number {
    if (arr.length > 0) {
      let min = arr[0][1]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][1] < min)
          min = arr[i][1]
      }
      return min
    }
  }

  setMin(): void {
    this.chartOptAir.yAxis.min = Math.ceil(this.getMinY(this.chartOptAir.series[0].data) * 0.1)
    this.chartOptTemp.yAxis.min = Math.ceil(this.getMinY(this.chartOptTemp.series[0].data) * 0.1)
    this.chartOptHumidity.yAxis.min = Math.ceil(this.getMinY(this.chartOptHumidity.series[0].data) * 0.1)
    this.chartOptIAQ.yAxis.min = Math.ceil(this.getMinY(this.chartOptIAQ.series[0].data) * 0.1)
    this.chartOptBattery.yAxis.min = Math.ceil(this.getMinY(this.chartOptIAQ.series[0].data) * 0.1)
  }
}
