import { Component, Input, OnChanges, OnInit } from "@angular/core"
import { FirmwareVersion, ObservableState, SensorNodeDataDetails } from "@dryad-web-app/shared/state"
import { MetaDataService, NetworkServerService } from "@dryad-web-app/shared/data-access"

@Component({
  selector: "app-sensor-node-tech-info",
  templateUrl: "./sensor-node-tech-info.component.html",
  styleUrls: ["./sensor-node-tech-info.component.scss"],
})
export class SensorNodeTechInfoComponent implements OnInit, OnChanges {
  @Input() sensorTechInfo: SensorNodeDataDetails
  devEUI: string
  firmwareVersions: FirmwareVersion[]
  mlVersion: FirmwareVersion
  fmVersion: FirmwareVersion

  constructor(
    private networkServerService: NetworkServerService,
    private oss: ObservableState,
    private metaDataService: MetaDataService,
  ) {
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (typeof this.sensorTechInfo?.data?.site === "number") {
      this.networkServerService.frequencyPlans(this.sensorTechInfo?.data?.site).subscribe(frequencies => {
        const frequency = frequencies.filter(f => f.id === this.sensorTechInfo?.details?.frequencyPlanId)
        if (frequency.length > 0) this.sensorTechInfo.details["frequencyName"] = frequency[0].name
      })
    }
    if (this.sensorTechInfo?.details?.devEUI) this.devEUI = this.sensorTechInfo?.details?.devEUI
    else this.devEUI = this.sensorTechInfo?.data?.eui

    this.getFirmwareVersions()
  }

  getFirmwareVersions(): void {
    this.oss.firmwareVersion$().subscribe((versions: FirmwareVersion[]) => {
      this.firmwareVersions = versions
      if (this.sensorTechInfo) {
        this.fmVersion = this.metaDataService.matchSensorFirmwareVersion(this.sensorTechInfo.data, this.firmwareVersions)
        this.mlVersion = this.metaDataService.matchMLVersion(this.sensorTechInfo.data, this.firmwareVersions)
      }
    })
  }

}
