<div [class]="styleClass" [ngStyle]="style"
     [ngClass]="{'p-picklist p-component': true, 'p-picklist-striped': stripedRows}" class="grid" cdkDropListGroup>
  <div class="p-picklist-list-wrapper p-picklist-source-wrapper col-12 md:col-6 lg:col-6 dry-widget">
    <div class="picklist-content">
      <div class="p-picklist-header" *ngIf="sourceHeader || sourceHeaderTemplate">
        <div class="p-picklist-title" *ngIf="!sourceHeaderTemplate">{{sourceHeader}}</div>
        <ng-container *ngTemplateOutlet="sourceHeaderTemplate"></ng-container>
      </div>
      <div class="p-picklist-filter-container" *ngIf="filterBy && showSourceFilter !== false">
        <ng-container *ngIf="sourceFilterTemplate; else builtInSourceElement">
          <ng-container
            *ngTemplateOutlet="sourceFilterTemplate; context: {options: sourceFilterOptions}"></ng-container>
        </ng-container>
        <ng-template #builtInSourceElement>
          <div class="p-picklist-filter">
            <input #sourceFilter type="text" role="textbox" (keyup)="onFilter($event,SOURCE_LIST)"
                   class="p-picklist-filter-input p-inputtext p-component" [disabled]="disabled"
                   [attr.placeholder]="sourceFilterPlaceholder" [attr.aria-label]="ariaSourceFilterLabel">
          </div>
        </ng-template>
      </div>
      <ul #sourcelist class="p-picklist-list p-picklist-source" cdkDropList [cdkDropListData]="source"
          (cdkDropListDropped)="onDrop($event, SOURCE_LIST)"
          [ngStyle]="sourceStyle" role="listbox" aria-multiselectable="multiple">
        <li class="p-picklist-item" *ngIf="source?.length > 0">
          <div class="field-checkbox">
            <p-checkbox name="groupname" label="Select All" (click)="moveAllRight()"></p-checkbox>
          </div>
        </li>
        <ng-template ngFor let-item [ngForOf]="source" [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                     let-l="last">
          <li
            [ngClass]="{'p-picklist-item':true,'p-highlight':isSelected(item,selectedItemsSource),'p-disabled': disabled}"
            pRipple cdkDrag [cdkDragData]="item" [cdkDragDisabled]="!dragdrop"
            (click)="onItemClick($event,item,selectedItemsSource,onSourceSelect,true)"
            (dblclick)="onSourceItemDblClick()"
            (touchend)="onItemTouchEnd()"
            (keydown)="onItemKeydown($event,item,selectedItemsSource,onSourceSelect,true)"
            *ngIf="isItemVisible(item, SOURCE_LIST)" tabindex="0" role="option"
            [attr.aria-selected]="isSelected(item, selectedItemsSource)">
            <div class="field-checkbox">
              <p-checkbox name="groupname" [value]="item"></p-checkbox>
              <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index: i}"></ng-container>
            </div>
          </li>
        </ng-template>
        <ng-container *ngIf="isEmpty(SOURCE_LIST) && (emptyMessageSourceTemplate || emptyFilterMessageSourceTemplate)">
          <li class="p-picklist-empty-message" *ngIf="!filterValueSource || !emptyFilterMessageSourceTemplate">
            <ng-container *ngTemplateOutlet="emptyMessageSourceTemplate"></ng-container>
          </li>
          <li class="p-picklist-empty-message" *ngIf="filterValueSource">
            <ng-container *ngTemplateOutlet="emptyFilterMessageSourceTemplate"></ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="p-picklist-filter-container">
      <p-paginator [totalRecords]="sourceTotalRecords" (onPageChange)="sourceOnPageChange($event)" [rows]="10" alwaysShow="false" [first]="currentPage"></p-paginator>
    </div>
  </div>
  <div class="p-picklist-list-wrapper p-picklist-target-wrapper col-12 md:col-6 lg:col-6 dry-widget">
    <div class="picklist-content">
      <div class="p-picklist-header" *ngIf="targetHeader || targetHeaderTemplate">
        <div class="p-picklist-title" *ngIf="!targetHeaderTemplate">{{targetHeader}}</div>
        <ng-container *ngTemplateOutlet="targetHeaderTemplate"></ng-container>
      </div>
      <div class="p-picklist-filter-container" *ngIf="filterBy && showTargetFilter !== false">
        <ng-container *ngIf="targetFilterTemplate; else builtInTargetElement">
          <ng-container
            *ngTemplateOutlet="targetFilterTemplate; context: {options: targetFilterOptions}"></ng-container>
        </ng-container>
        <ng-template #builtInTargetElement>
          <div class="p-picklist-filter">
            <input #targetFilter type="text" role="textbox" (keyup)="onFilter($event,TARGET_LIST)"
                   class="p-picklist-filter-input p-inputtext p-component" [disabled]="disabled"
                   [attr.placeholder]="targetFilterPlaceholder" [attr.aria-label]="ariaTargetFilterLabel">
          </div>
        </ng-template>
      </div>
      <ul #targetlist class="p-picklist-list p-picklist-target" cdkDropList [cdkDropListData]="target"
          (cdkDropListDropped)="onDrop($event, TARGET_LIST)"
          [ngStyle]="targetStyle" role="listbox" aria-multiselectable="multiple">
        <li class="p-picklist-item" *ngIf="target?.length > 0">
          <div class="field-checkbox">
            <p-checkbox name="groupname" label="Clear All" (click)="moveAllLeft()"></p-checkbox>
          </div>
        </li>
        <ng-template ngFor let-item [ngForOf]="target" [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                     let-l="last">
          <li
            [ngClass]="{'p-picklist-item':true,'p-highlight':isSelected(item,selectedItemsTarget), 'p-disabled': disabled}"
            pRipple cdkDrag [cdkDragData]="item" [cdkDragDisabled]="!dragdrop"
            (click)="onItemClick($event,item,selectedItemsTarget,onTargetSelect,false)"
            (dblclick)="onTargetItemDblClick()" (touchend)="onItemTouchEnd()"
            (keydown)="onItemKeydown($event,item,selectedItemsTarget,onTargetSelect,false)"
            *ngIf="isItemVisible(item, TARGET_LIST)" tabindex="0" role="option"
            [attr.aria-selected]="isSelected(item, selectedItemsTarget)">
            <div class="field-checkbox">
              <p-checkbox name="groupname" [value]="item"  [(ngModel)]="selectedItemsTarget" ></p-checkbox>
              <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index: i}"></ng-container>
            </div>
          </li>
        </ng-template>
        <ng-container *ngIf="isEmpty(TARGET_LIST) && (emptyMessageTargetTemplate || emptyFilterMessageTargetTemplate)">
          <li class="p-picklist-empty-message" *ngIf="!filterValueTarget || !emptyFilterMessageTargetTemplate">
            <ng-container *ngTemplateOutlet="emptyMessageTargetTemplate"></ng-container>
          </li>
          <li class="p-picklist-empty-message" *ngIf="filterValueTarget">
            <ng-container *ngTemplateOutlet="emptyFilterMessageTargetTemplate"></ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="p-picklist-filter-container" *ngIf="target?.length > 0">
      <p-paginator [totalRecords]="target?.length" rows="10" (onPageChange)="targetOnPageChange($event)" alwaysShow="false"></p-paginator>
    </div>
  </div>
</div>
