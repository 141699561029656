import { Injectable } from "@angular/core"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"

interface Scripts {
  name: string
  src: string
}

// export const ScriptStore: Scripts[] = [
//   {
//     name: "google-maps",
//     src:
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyCfGtbAlVVyJ52oeiCZFfAGAPurbbBTYXA"
//   }
// ];

declare let document: any

/**
 *
 * Attribution : https://gist.githubusercontent.com/zainzafar90/1f58a4a04ac17fcf8e7424a053620822/raw/b6d314c3483b5be0917129f041aa10be8a9835e1/dynamic-script-loader.service.ts
 */
@Injectable({
  providedIn: "root",
})
export class DynamicScriptLoaderService {
  private scripts: any = {}
  // eslint-disable-next-line @typescript-eslint/member-ordering
  environment: any
  constructor() {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    this.scriptStore(this.environment.gmapApiKey).forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      }
    })
  }

  scriptStore(gmapApiKey: string): Scripts[] {
    return [
      {
        name: "google-maps",
        src:
          "https://maps.googleapis.com/maps/api/js?key=" +
          gmapApiKey +
          "&libraries=places,geometry",
      },
    ]
  }

  load(...scripts: string[]): Promise<any> {
    const promises: any[] = []
    scripts.forEach((script) => promises.push(this.loadScript(script)))
    return Promise.all(promises)
  }

  loadScript(name: string): Promise<any> {
    return new Promise((resolve) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = this.scripts[name].src
        if (script.readyState) {
          // IE
          script.onreadystatechange = (): void => {
            if (
              script.readyState === "loaded" ||
              script.readyState === "complete"
            ) {
              script.onreadystatechange = null
              this.scripts[name].loaded = true
              resolve({ script: name, loaded: true, status: "Loaded" })
            }
          }
        } else {
          // Others
          script.onload = (): void => {
            this.scripts[name].loaded = true
            resolve({ script: name, loaded: true, status: "Loaded" })
          }
        }
        script.onerror = (): void =>
          resolve({ script: name, loaded: false, status: "Loaded" })
        document.getElementsByTagName("head")[0].appendChild(script)
      } else resolve({ script: name, loaded: true, status: "Already Loaded" })
    })
  }
}
