import { HttpParams } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { TypeService } from "@dryad-web-app/shared/data-access"
import { FormlyFieldConfig } from "@ngx-formly/core"
import * as moment from "moment"
import { MessageService } from "primeng/api"
import { DynamicDialogRef } from "primeng/dynamicdialog"
import { ExperimentService } from "../../../service/http/experiment.service"

@Component({
  selector: "app-create-experiment-record",
  templateUrl: "./create-experiment-record.component.html",
  styleUrls: ["./create-experiment-record.component.css"],
})
export class CreateExperimentRecordComponent implements OnInit {
  model: any = {}
  experimentFormFields: FormlyFieldConfig[] = []
  experimentForm: UntypedFormGroup = new UntypedFormGroup({})

  constructor(
    private typeService: TypeService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    private experimentService: ExperimentService
  ) {
    this.experimentFormFields = this.getFormFields()
  }

  ngOnInit(): void {}

  private venuesFilter = {
    type: {
      type_id: {
        _eq: "experiment_venue",
      },
    },
  }
  private burnMaterialFilter = {
    type: {
      type_id: {
        _eq: "experiment_burn_material",
      },
    },
  }
  private distanceMaterialFilter = {
    type: {
      type_id: {
        _eq: "experiment_sensor_hotplate_distance",
      },
    },
  }

  getFormFields(): FormlyFieldConfig[] {
    return [
      {
        key: "start_time",
        type: "primeng-calendar",
        templateOptions: {
          label: "Start Time",
          placeholder: "",
          description: "Start Time",
          required: true,
          disabled: false,
          showTime: true,
        },
      },
      {
        key: "end_time",
        type: "primeng-calendar",
        templateOptions: {
          label: "End Time",
          placeholder: "",
          description: "End Time",
          required: true,
          disabled: false,
          showTime: true,
        },
      },
      {
        key: "venue",
        type: "primeng-dropdown",
        templateOptions: {
          label: "Venues",
          placeholder: "",
          description: "Venues",
          required: true,
          options: this.typeService.types(
            "tag_label",
            new HttpParams().append("filter", JSON.stringify(this.venuesFilter))
          ),
          valueProp: "id",
          labelProp: "label",
        },
      },
      {
        key: "burn_material",
        type: "primeng-dropdown",
        templateOptions: {
          label: "Burn Material",
          placeholder: "",
          description: "Burn Material",
          required: true,
          options: this.typeService.types(
            "tag_label",
            new HttpParams().append(
              "filter",
              JSON.stringify(this.burnMaterialFilter)
            )
          ),
          valueProp: "id",
          labelProp: "label",
        },
      },
      {
        key: "burn_material_amount",
        type: "primeng-input",
        templateOptions: {
          label: "Burn Material Amount",
          placeholder: "",
          description: "Burn Material Amount",
          required: true,
          disabled: false,
        },
      },
      {
        key: "sensor_hotplate_distance",
        type: "primeng-dropdown",
        templateOptions: {
          label: "Sensor Hotplate Distance",
          placeholder: "",
          description: "Sensor Hotplate Distance",
          required: true,
          options: this.typeService.types(
            "tag_label",
            new HttpParams().append(
              "filter",
              JSON.stringify(this.distanceMaterialFilter)
            )
          ),
          valueProp: "id",
          labelProp: "label",
        },
      },
      {
        key: "hotplate_start",
        type: "primeng-calendar",
        templateOptions: {
          label: "Hotplate Start",
          placeholder: "",
          description: "Hotplate Start",
          required: true,
          disabled: false,
          showTime: true,
        },
      },
      {
        key: "hotplate_temp",
        type: "primeng-input",
        templateOptions: {
          label: "Hotplate Temp",
          placeholder: "",
          description: "Hotplate Temp",
          required: true,
          disabled: false,
        },
      },
      {
        key: "notes",
        type: "primeng-text-area",
        templateOptions: {
          label: "Notes",
          placeholder: "",
          description: "Notes",
          required: false,
          disabled: false,
        },
      },
    ]
  }

  handleSubmit($event: any): void {
    $event.start_time = moment($event.start_time).utc().format()
    $event.end_time = moment($event.end_time).utc().format()
    $event.hotplate_start = moment($event.hotplate_start).utc().format()
    console.log($event.start_time)
    this.experimentService.create($event).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success",
      })
      this.ref.close()
    })
  }
}
