import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable, of } from "rxjs"
import { serviceRootUrl } from "../../utils/api-utils"


export interface UserFF {
  readonly first_name?: string
  readonly last_name?: string
  readonly email?: string
  readonly uuid?: string
  readonly url?: string
  readonly urn?: string
  readonly create_at?: Date
  readonly updated_at?: Date
}

export interface PaginatedUsersList {
  readonly count: number
  next?: string | null
  previous?: string | null
  results: UserFF[]
}

export interface UserRequest {
  first_name: string
  last_name: string
  email: string
  role: string
  organization?: string
}

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private readonly forestFloorBaseURLUsers = `${forestFloorBaseUrl}/api/users/`

  constructor(
    private http: HttpClient,
  ) { }

  getAllUsers(page?: number, page_size?: number,): Observable<PaginatedUsersList> {
    let params = new HttpParams()
    if (page !== undefined && page !== null)
      params = params.set("page", <any>page)

    if (page_size !== undefined && page_size !== null)
      params = params.set("page_size", page_size)
    return this.http.get<PaginatedUsersList>(this.forestFloorBaseURLUsers, { params: params })
  }

  // Engine Compatible endpoint to ease transition for the web app
  createUser(userCreationPayload: UserRequest): Observable<UserFF> {
    return this.http.post<UserFF>(`${this.forestFloorBaseURLUsers}engine_create/`, userCreationPayload)
  }

  update(user): Observable<UserFF> {
    // Placeholder for when FF endpoint is available
    console.log(user)
    return of(user)
  }
}
