import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { DryadRoutes } from "@dryad-web-app/shared/state"
import { serviceRootUrl } from "../../../../../../../apps/silvanet-web/src/app/utils/api-utils"

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Component({
  selector: "dryad-web-app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class LoginComponent implements OnInit {
  @ViewChild("ffForm") ffForm: HTMLFormElement
  environment: any
  redirectUrl: string
  callbackUrl = `${window.location.origin}/oauth/callback/v2`
  csrfToken: string

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    this.redirectUrl = `${forestFloorBaseUrl}/_allauth/browser/v1/auth/provider/redirect`
  }
  
  async ngOnInit(): Promise<void> {
    if (this.activatedRoute.snapshot.queryParams.reason) return
    const status = await fetch(`${forestFloorBaseUrl}/_allauth/browser/v1/auth/session`, { credentials: "include" })
    if (!status.ok) return
    const payload = await status.json()
    console.log(payload)
    if (payload.meta.is_authenticated) {
      this.router.navigate([DryadRoutes.OAUTH_CALLBACK_V2])
    }
  }

  getCookie(name: string): string | null {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()

        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  }

  async login() {
    const configUrl = `${forestFloorBaseUrl}/_allauth/browser/v1/config`
    await fetch(configUrl, {
      method: 'GET',
      credentials: 'include'
    })
    const xCSRFToken = this.getCookie("csrftoken")
    this.csrfToken = xCSRFToken || ""
    requestAnimationFrame(() => this.ffForm.nativeElement.submit())
  }
}
