import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { SensorNodeService, UplinkMessageService } from "@dryad-web-app/shared/data-access"
import { SensorValue, UpMessage } from "@dryad-web-app/shared/state"
import { SelectItem } from "primeng/api/selectitem"
import { MultiSelect } from "primeng/multiselect"
import { Table } from "primeng/table"

@Component({
  selector: "app-uplink-messages",
  templateUrl: "./uplink-messages.component.html",
  styleUrls: ["./uplink-messages.component.scss"],
})
export class UplinkMessagesComponent implements OnInit {
  @Input() selectedDeviceId: string
  @ViewChild(MultiSelect) filterButton: MultiSelect
  @ViewChild(Table) private dv: DataView

  readonly DEFAULT_DEVICE_ID_ITEM = { label: "all", value: ".*" }
  readonly DEFAULT_TIME_PERIOD_ITEM = { label: "24h", value: "24h" }

  displayAlertsOnly = false
  displayGatewayInfo = false
  filteredSensorNodes = []
  sensorNodes = []
  messages: UpMessage[] = []
  selectedMessage: UpMessage
  filterOptions: any[] = [
    { label: "Gateways", value: "gateways" },
    { label: "Alerts Only", value: "alerts" },
  ]
  selectedFilter: any[] = []
  loading = true
  loadingMessage = "Loading Messages ..."

  deviceIds: SelectItem[] = []

  showDeviceIds = true

  timePeriods: SelectItem[] = []

  singleDeviceMode = false

  selectedTimePeriod = this.DEFAULT_TIME_PERIOD_ITEM.value

  constructor(
    private uplinkMessageService: UplinkMessageService,
    private sensorNodeService: SensorNodeService
  ) {
    this.deviceIds.push(this.DEFAULT_DEVICE_ID_ITEM)
    this.timePeriods = [
      this.DEFAULT_TIME_PERIOD_ITEM,
      { label: "12h", value: "12h" },
      { label: "6h", value: "6h" },
      { label: "1h", value: "1h" },
    ]
  }

  ngOnInit(): void {
    if (this.selectedDeviceId) {
      this.singleDeviceMode = true
      this.displayAlertsOnly = false
      this.showDeviceIds = false
    } else this.selectedDeviceId = this.DEFAULT_DEVICE_ID_ITEM.value

    this.updateMessages()
    this.sensorNodeService
      .sensorNodes()
      .subscribe((sensorNodes) => (this.sensorNodes = sensorNodes))
  }

  updateDeviceId(deviceId: any): void {
    this.updateMessages(deviceId.value, this.selectedTimePeriod)
  }

  updateTimePeriod(timePeriod: any): void {
    this.updateMessages(this.selectedDeviceId, timePeriod.value)
  }

  openMultiselect(e): void {
    this.filterButton.hide()
    e.stopPropagation()
  }

  updateMessages(
    deviceId: string = this.selectedDeviceId,
    timePeriod: string = this.selectedTimePeriod
  ): void {
    if (this.selectedFilter.length === 0) {
      this.displayAlertsOnly = false
      this.displayGatewayInfo = false
    }
    this.selectedFilter.map((f) => {
      if (f.value === "alerts") this.displayAlertsOnly = true
      if (f.value === "gateways") this.displayGatewayInfo = true
    })

    this.uplinkMessageService
      .uplinkMessages(deviceId, timePeriod, "desc")
      .subscribe(
        (umesgs: UpMessage[]) => {
          if (this.displayAlertsOnly) {
            this.messages = umesgs.filter(
              (um: UpMessage) => um.sensorValues.iaq >= 100
            )
          } else this.messages = umesgs
          this.loading = false
          if (this.messages.length === 0)
            this.loadingMessage = "No messages found."
        },
        () => {
          console.log("error getting device uplink messages!")
        }
      )
  }

  dateParse(timeStamp: string): string {
    return new Date(timeStamp).toString().split("GMT")[0]
  }

  sensorValue(sensorValues: SensorValue[], smType: string): string {
    const smv = sensorValues.find((sv: SensorValue) => smType === sv.smType)
    return smv ? smv.value.toString() : ""
  }

  filterUplinkMessages(event: any): void {
    const filtered: any[] = []
    const query = event.query
    for (let i = 0; i < this.sensorNodes.length; i++) {
      const sensor = this.sensorNodes[i]
      if (
        sensor.ns_end_device_id?.toLowerCase().search(query.toLowerCase()) !==
        -1
      )
        filtered.push(sensor)
    }
    this.filteredSensorNodes = filtered
  }
}
