<div id="header" class="topbar clearfix">
  <div class="logo" [ngClass]="{ loading: isPageLoading }">
    <a routerLink="/sites">
      <img src="assets/ui/images/logo/dryad-logo.svg" />
      <dryad-logo-spinner> </dryad-logo-spinner>
    </a>
  </div>
  <h1 class="app-name">{{ pageTitleService.pageTitle }}</h1>
  <a
    pButton
    *ngIf="alertCount > 0"
    routerLink="/alert-center"
    class="p-button-outlined p-button-danger fire-alert blink"
    [attr.data-cy]= "'fire-alert'"
  >
    <i class="dy dy-fire"></i>
    Fire alert
  </a>

  <a id="topbar-menu-button" href="#" (click)="onTopBarMenuButtonClick($event)">
    <i class="ph ph-3x ph-user-circle"></i>
  </a>
  <ul
    class="topbar-menu fadeInDown"
    [ngClass]="{ 'topbar-menu-visible': topBarMenuActive }"
  >

    <li class="profile-item">
      <a href="https://docs.dryad.app/"  pTooltip="View user guide"  target="_blank"><i class="ph ph-3x ph-book-open-text"></i></a>
    </li>
    <li class="profile-item">
      <a href="https://dryadnetworks.zendesk.com/hc/en-001/requests/new"  pTooltip="Technical support"  target="_blank">
       <i class="ph ph-3x ph-headset"></i>
      </a>
    </li>
    <li
      #dbsettings
      *ngIf="rbacService.permit('DB_SETTINGS_UPDATE')"
      class="profile-item"
      [ngClass]="{
        'active-topmenuitem': activeTopBarItem === dbsettings
      }"
    >
      <a
        [href]="authService.apiBaseUrl"
        target="_blank"
        rel="noopener noreferrer hide-on-mobile"
      >
        <i class="ph ph-3x ph-gear"></i>
      </a>
    </li>
    <li class="profile-item" id="userMenu">
      <button>
        <div class="profile-image">
          <i class="ph ph-3x ph-user-circle"></i>
        </div>
        <div class="profile-info">
          <span class="topbar-item-name profile-name">{{ userName() }}</span>
          <span class="topbar-item-name profile-role">{{ userRole }}</span>
        </div>
      </button>

      <ul class="fadeInDown">

        <li role="menuitem">
          <a routerLink="/me/settings">
            <i class="ph ph-gear"></i>
            <span>Settings</span>
          </a>
        </li>
        <li>
          <hr />
        </li>
        <li class="logout" role="menuitem">
          <a (click)="logout()" class="flex flex-row flex-start">
            <i class="ph ph-sign-out"></i>
            <span>{{loggingOut? "Logging out" : "Logout"}}</span>
            <dryad-logo-spinner *ngIf="loggingOut"
            class="color-primary"
            style="width: 25%; height: 15%; padding: 3px 0 0 5px"
          ></dryad-logo-spinner>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
