<div class="map-container">
  <app-dryad-map
    #map
    [interactive]="true"
    [searchbar]="true"
  >
    <ng-template>
      <app-device-layer [gateways]="allGateways" [sensors]="allSensors"></app-device-layer>
    </ng-template>
  </app-dryad-map>
</div>
