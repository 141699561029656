<div class="grid flex-center-content">
  <div class="col-3">
    <span class="label">{{ to.label }}</span>
  </div>
  <div class="col-8">
    <span *ngIf="!isEditable" (click)="isEditable = true">
      {{model[field.key] ?? 'No data'}}
    </span>
    <ng-container #fieldComponent *ngIf="isEditable"></ng-container>
  </div>
  <div class="col-1">
    <button
      pButton
      icon="ph ph-pencil-simple ph-2x"
      class="p-button p-button-rounded p-button-text button-blue"
      (click)="isEditable = true"
      *ngIf="!isEditable && !field?.templateOptions?.disabled"
    ></button>
    <button
      pButton
      label="Save"
      class="p-button p-button-rounded"
      *ngIf="isEditable && !field?.templateOptions?.disabled"
      (click)="edit()"
    ></button>
  </div>
</div>
