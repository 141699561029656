import { Component, OnDestroy, OnInit, ViewChild, ElementRef, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core"
import { Subscription } from "rxjs"
import { CommonModule } from "@angular/common"
import { Router } from "@angular/router"
import { AlertsService } from "../../../service/http/alerts.service"
import { AlertNotification } from "../../../alerts/types"
import i18n from "../../../../i18n"
import { getTimeSinceAlert } from "../../../utils/utils"

@Component({
  selector: "app-alert-notification",
  templateUrl: "./alert-notification.component.html",
  styleUrls: ["./alert-notification.component.scss"],
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AlertNotificationComponent implements OnInit, OnDestroy {
  i18n = i18n
  getTimeSinceAlert = getTimeSinceAlert

  alertNotification: AlertNotification
  private alertSubscription: Subscription

  @ViewChild("alertToast", { static: true }) toastNotification: ElementRef

  constructor(
    private alertsService: AlertsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.alertSubscription = this.alertsService.getAlertNotification().subscribe({
      next: (data) => {
        if (data.alert_event === null) return
        this.alertNotification = data
        const toastElement = this.toastNotification.nativeElement
        toastElement.toast()
      },
      error: (err) => {
        console.log("Error occurred:", err)
      },
    })
  }

  ngOnDestroy(): void {
    if (this.alertSubscription)
      this.alertSubscription.unsubscribe()
  }

  goToAlertDetails(uuid: string): void {
    const link = "/alert-center/"
    this.router.navigate([]).then(() => {
      window.open(`${link}${uuid}`, "_blank")
    })
  }
}
