import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core"
import {
  CacheService,
  DynamicScriptLoaderService,
  GatewayService,
  SensorNodeService,
} from "@dryad-web-app/shared/data-access"
import {
  Assets,
  DeviceLocationData, DeviceState,
  DeviceTypes, FormEvent, FORMLY_WRAPPER_EVENT, FormlyFieldStatus,
  GatewayTypes,
  LatLong, MapUtils,
  ObservableState,
} from "@dryad-web-app/shared/state"
import { FormFactoryService } from "@dryad-web-app/ui/dryad-form"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { MessageService } from "primeng/api"
import { Subscription } from "rxjs"
import {
  DirectusApiService,
} from "@dryad-web-app/shared/data-access"
import { LayerMap } from "@dryad-web-app/ui/dryad-ui"

@Component({
  selector: "app-device-location",
  templateUrl: "./device-location.component.html",
  styleUrls: [ "./device-location.component.scss" ],
})
export class DeviceLocationComponent extends LayerMap implements OnInit, OnDestroy, OnChanges {
  @Input() locationData: DeviceLocationData
  @Input() deviceType: DeviceTypes
  @Input() isActive: boolean
  @Input() gatewayType: GatewayTypes
  fields: FormlyFieldConfig[]
  subscription = new Subscription()
  isGMapScriptLoaded = false
  options: any
  isSubmitting = false
  isFormSubmitDisabled: boolean

  constructor(
    private formFactory: FormFactoryService,
    private sensorNodeService: SensorNodeService,
    private gatewayService: GatewayService,
    private messageService: MessageService,
    private oss: ObservableState,
    private dynamicScriptLoaderService: DynamicScriptLoaderService,
    private directusApiService: DirectusApiService,
    private cacheService: CacheService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.fields = this.formFactory.sensorNodeLocationFields()
    this.initDeviceData()
    this.subscription.add(
      this.oss.formlyEditWrapperEvent$().subscribe((data) => {
        if (this.isActive) if (data && data.type === FormEvent.UPDATE) this.setLocationData(this.locationData)
      })
    )

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  initDeviceData(): void {
    if (this.deviceType === DeviceTypes.SENSOR_NODE) {
      this.sensorNodeService.sensorNode(this.locationData.id).subscribe(data => {
        this.setLocationData(data)
      })
    } else {
      this.gatewayService.gateway(this.locationData.id).subscribe(data => {
        this.setLocationData(data)
      })
    }
  }

  setLocationData(data): void {
    this.locationData.longitude = data.longitude ? Number(data.longitude) : null
    this.locationData.latitude = data.latitude ? Number(data.latitude) : null
    this.locationData = { ...this.locationData }
    if (this.locationData.latitude && this.locationData.longitude) this.loadGMapScript()
    else this.isGMapScriptLoaded = false
  }

  updateDevice(): void {
    const latLong = new LatLong(
      Number(Number(this.locationData.latitude).toFixed(10)),
      Number(Number(this.locationData.longitude).toFixed(10))
    )
    this.isSubmitting = true
    if (this.deviceType === DeviceTypes.SENSOR_NODE) {
      this.directusApiService.updateCollection("sensor_node", latLong, this.locationData.id).subscribe(() => {
        this.cacheService.clearSensorNodeCache(this.locationData.id).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: "Device successfully updated",
          })
          this.isSubmitting = false
          this.isFormSubmitDisabled = true
          this.initDeviceData()
        })
      }, () => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong, please try again.",
        })
        this.isSubmitting = false
      })
    } else {
      this.directusApiService.updateCollection("gateway", latLong, this.locationData.id).subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: "Device successfully updated.",
        })
        this.isSubmitting = false
        this.initDeviceData()
      }, () => {
        this.messageService.add({
          severity: "error",
          summary: "Something went wrong, please try again.",
        })
        this.isSubmitting = false
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isActive = changes.isActive.currentValue
  }

  setMap(event): void {
    this.map = event.map
  }

  private initGMap(): void {
    this.options = {
      center: {
        lat: this.locationData.latitude,
        lng: this.locationData.longitude,
      },
      zoom: 18,
      minZoom: 6,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT,
      },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
      scaleControl: true,
      maxZoom: 25,
      zoomControl: true,
    }
    this.overlays = []
    this.overlays.push(
      new google.maps.Marker({
        position: {
          lat: this.locationData.latitude,
          lng: this.locationData.longitude,
        },
        icon: {
          url:
            this.deviceType === DeviceTypes.SENSOR_NODE
              ? Assets.MARKER_BASE_URL +
              MapUtils.sensorMarkerState(DeviceState.ACTIVE) +
              ".svg"
              : (this.gatewayType === GatewayTypes.BORDER_GATEWAY ? Assets.MARKER_BASE_URL +
                MapUtils.borderGatewayMarkerState(DeviceState.ACTIVE) +
                ".svg" : Assets.MARKER_BASE_URL + MapUtils.meshGatewayMarkerState(DeviceState.ACTIVE) + ".svg"),
          scaledSize: new google.maps.Size(50, 50),
        },
        draggable: true,
      })
    )
    if (this.map) {
      this.map.setCenter({
        lat: this.locationData.latitude,
        lng: this.locationData.longitude,
      })
    }
  }

  private loadGMapScript(): void {
    this.dynamicScriptLoaderService
      .load("google-maps")
      .then(() => {
        this.isGMapScriptLoaded = true
        // this.mapMarkerService.mapsClassloader()
        this.initGMap()
      })
      .catch((error) => console.log(error))
  }

  dragEnd(e): void {
    this.locationData.latitude = Number(Number(e.overlay.position.lat().toFixed(10)))
    this.locationData.longitude = Number(Number(e.overlay.position.lng().toFixed(10)))
    this.oss.dispatch({
      type: FORMLY_WRAPPER_EVENT,
      payload: new FormlyFieldStatus(null, FormEvent.UPDATE),
    })
    this.isFormSubmitDisabled = false
  }

  handleSubmit(): void {
    this.updateDevice()
  }
}
