import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { RestApiInterceptor } from "./interceptor/rest-api.interceptor"
import { LoginComponent } from "./components/login/login.component"
import { Route } from "@angular/router"
import { CardModule } from "primeng/card"
import { ButtonModule } from "primeng/button"
import { UiDryadFormModule } from "@dryad-web-app/ui/dryad-form"
import { SharedHelpersModule } from "@dryad-web-app/shared/helpers"
import { AuthCallbackComponent } from "./components/auth-callback/auth-callback.component"
import { AppErrorComponent } from "./components/pages/app.error.component"
import { AppAccessDeniedComponent } from "./components/pages/app.access-denied.component"
import { AppNotfoundComponent } from "./components/pages/app.notfound.component"
import { DryadRoutes } from "@dryad-web-app/shared/state"
import { UiDryadUiModule } from "@dryad-web-app/ui/dryad-ui"
import { ForestFloorCallbackComponent } from "./components/forestfloor-callback/forestfloor-callback.component"

export const authRoutes: Route[] = [
  { path: "", component: LoginComponent },
  { path: DryadRoutes.LOGIN, component: LoginComponent }
]
export const publicRoutes: any = [
  { path: DryadRoutes.OAUTH_CALLBACK, component: AuthCallbackComponent },
  { path: DryadRoutes.OAUTH_CALLBACK_V2, component: ForestFloorCallbackComponent },
  { path: DryadRoutes.ERROR, component: AppErrorComponent },
  { path: DryadRoutes.ACCESS_DENIED, component: AppAccessDeniedComponent },
  { path: DryadRoutes.NOT_FOUND, component: AppNotfoundComponent },
  { path: "**", redirectTo: "/" + DryadRoutes.NOT_FOUND }
]

@NgModule({
  imports: [
    CommonModule, 
    CardModule, 
    ButtonModule, 
    UiDryadFormModule, 
    SharedHelpersModule, 
    UiDryadUiModule, 
    FormsModule, 
    ReactiveFormsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RestApiInterceptor, multi: true },
  ],
  declarations: [
    LoginComponent,
    AppAccessDeniedComponent,
    AuthCallbackComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    ForestFloorCallbackComponent,
  ],
  exports: [
    LoginComponent,
    AppAccessDeniedComponent,
    AuthCallbackComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    ForestFloorCallbackComponent,
  ],
})
export class SharedAuthModule {
}

