import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { EntityType, LatLong, SensorNode, SensorNodeDataDetails, SensorNodeHealthDetails, SignalStatus } from "@dryad-web-app/shared/state"
import { AlertsService } from "apps/silvanet-web/src/app/service/http/alerts.service"
import { plainToClass, plainToInstance } from "class-transformer"
import _ from "lodash"
import { Observable, combineLatest } from "rxjs"
import { map } from "rxjs/operators"

const addHealthDetailstoSensorNodes = (sensors: SensorNode[], sensorsWithHealthDetails: SensorNodeHealthDetails[]): SensorNode[] => {
  return sensors.map(sensor => {
    const matchedSensor =
      sensorsWithHealthDetails.find(sensorWithHealthDetails => sensorWithHealthDetails.ns_end_device_id === sensor.ns_end_device_id)
    if (matchedSensor)
      return { ...sensor, ...matchedSensor }
  }).filter(sensor => !!sensor) as SensorNode[]
}

@Injectable({
  providedIn: "root",
})
export class SensorNodeService extends ApiHttpService {
  private readonly SENSOR_NODE_API_PATH = "sensornodes"

  constructor(
    private alertsService: AlertsService,
  ) {
    super()
  }

  sensorNodesForSite(
    siteId: number,
    params = new HttpParams(),
    limit?: number,
    offset?: number,
    deploymentStatus?: string,
  ): Observable<SensorNode[]> {
    const sensorNodeFilter = {
      site: {
        id: {
          _eq: siteId,
        },
      },
    }
    if (deploymentStatus) {
      // @ts-ignore
      sensorNodeFilter["deployment_status"] = {
        label: { _eq: deploymentStatus },
      }
    }
    let cloneParams = params ?? new HttpParams()
    cloneParams = cloneParams.append("limit", limit || -1)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    cloneParams = cloneParams.append("filter", JSON.stringify(sensorNodeFilter))
    return this.get<SensorNode[]>(
      this.SENSOR_NODE_API_PATH,
      cloneParams,
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  sensorNodesForSiteWithHealthDetails(siteId: number): Observable<SensorNodeHealthDetails[]> {
    return this.get<SensorNodeHealthDetails[]>(this.SENSOR_NODE_API_PATH + "/details/" + siteId).pipe(map((response) => plainToInstance(SensorNodeHealthDetails, response)))
  }

  sensorNodesWithStatus(siteId: number): Observable<SensorNode[]> {
    return combineLatest([
      this.sensorNodesForSite(siteId),
      this.sensorNodesForSiteWithHealthDetails(siteId),
      this.alertsService.activeAlerts$
    ]).pipe(map(([sensorNodes, healthDetails, activeAlerts]) => {
      const alertForSite = activeAlerts.find(alert => alert.alert_site.legacy_site_id === siteId)
      const alertEventsWithFire = alertForSite?.alert_events.filter(event => event.is_fire_alert)
      const alertEventsMap = new Map(alertEventsWithFire?.map(event => [event.payload.end_device_ids.device_id, event]))
      healthDetails.forEach(sensor  => {
        if(alertEventsMap.has(sensor.ns_end_device_id)) {
          sensor.signal_status = SignalStatus.FIRE_ALERT
        }
      })
      return addHealthDetailstoSensorNodes(sensorNodes, healthDetails)
    }))
  }

  sensorNodesForSiteWithTag(
    siteId: number,
    tag: string,
    params = new HttpParams(),
  ): Observable<SensorNode[]> {
    const sensorNodeFilter = {
      _and: [
        {
          site: {
            id: {
              _eq: siteId,
            },
          },
        },
        {
          tag_labels: {
            tag_label_id: {
              label: {
                _eq: tag,
              },
            },
          },
        },
      ],
    }
    return this.get<SensorNode[]>(
      this.SENSOR_NODE_API_PATH,
      params.append("filter", JSON.stringify(sensorNodeFilter)),
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  sensorNodes(limit: number = -1, offset: number = 0): Observable<SensorNode[]> {
    let params = new HttpParams().set("sort", "site,id")
    params = params.set("limit", limit)
    if (offset > 0) params = params.set("offset", offset)
    return this.get<SensorNode[]>(this.SENSOR_NODE_API_PATH, params).pipe(
      map((response) => plainToClass(SensorNode, response)),
    )
  }

  sensorNode(
    sensorNodeId: number,
    params = new HttpParams(),
  ): Observable<SensorNode> {
    return this.get<SensorNode>(
      this.SENSOR_NODE_API_PATH + "/" + sensorNodeId,
      params,
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  /**
   * @deprecated
   */
  sensorNodeFromEndDeviceId(
    endDeviceId: string,
    params = new HttpParams(),
  ): Observable<SensorNode> {
    const sensorNodeFilter = {
      "ns_end_device_id": {
        "_eq": endDeviceId
      }
    }
    return this.get<SensorNode>(
      this.SENSOR_NODE_API_PATH + "/enddevice/" + endDeviceId,
      params.append("filter", JSON.stringify(sensorNodeFilter)),
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  sensorNodeWithDetails(
    sensorNodeId: number,
    params = new HttpParams(),
  ): Observable<SensorNodeDataDetails> {
    return this.get<SensorNodeDataDetails>(
      this.SENSOR_NODE_API_PATH + "/" + sensorNodeId + "/details",
      params,
    ).pipe(map((response) => plainToClass(SensorNodeDataDetails, response)))
  }

  createSensorNode(
    sensorNodeDataDetails: SensorNodeDataDetails,
  ): Observable<SensorNode> {
    return this.post<SensorNode>(
      this.SENSOR_NODE_API_PATH,
      sensorNodeDataDetails,
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  updateSensorNode(sensorNode: any): Observable<SensorNode> {
    return this.put<SensorNode>(
      this.SENSOR_NODE_API_PATH + "/" + sensorNode.id,
      sensorNode,
    ).pipe(map((response) => plainToClass(SensorNode, response)))
  }

  deleteSensorNode(id: number): Observable<boolean> {
    return this.delete(this.SENSOR_NODE_API_PATH + "/" + id)
  }

  types(): Observable<EntityType[]> {
    return this.get<EntityType[]>(this.SENSOR_NODE_API_PATH + "/types").pipe(
      map((response) => plainToClass(EntityType, response)),
    )
  }

  activationModes(): Observable<EntityType[]> {
    return this.get<EntityType[]>(
      this.SENSOR_NODE_API_PATH + "/activationmodes",
    ).pipe(map((response) => plainToClass(EntityType, response)))
  }

  /**
   * @deprecated
   * @param latLong
   * @param sensorNodeId
   */
  updateSensorLocation(latLong: LatLong, sensorNodeId: number) {
    return this.put<LatLong>(
      this.SENSOR_NODE_API_PATH + "/" + sensorNodeId + "/latlong",
      latLong,
    ).pipe(map((response) => plainToClass(LatLong, response)))
  }
}
