import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core"
import { MenuItem } from "primeng/api"
import { UntypedFormGroup } from "@angular/forms"
import { FormlyFieldConfig } from "@ngx-formly/core"

@Component({
  selector: "dryad-web-app-dryad-formly-field-stepper",
  templateUrl: "./dryad-formly-field-stepper.component.html",
  styleUrls: ["./dryad-formly-field-stepper.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DryadFormlyFieldStepperComponent implements OnInit {
  items: MenuItem[] = []

  activeIndex = 0

  form = new UntypedFormGroup({})

  @Input()
  model: any = {}

  @Input()
  fields: FormlyFieldConfig[] = []

  @Output() submitEvent = new EventEmitter<any>()

  @Output() cancelEvent = new EventEmitter<boolean>()

  constructor() {
  }

  ngOnInit(): void {
    this.items = this.fields.map((ffc: FormlyFieldConfig, i) => {
      if (!this.model.hasOwnProperty(ffc.key))
        this.model[ffc.key as string] = {}
      // @ts-ignore
      return {
        label: ffc?.templateOptions?.label || "",
        command: (event: any) => {
          this.activeIndex = i
        },
      }
    })
    this.items.push({
      label: "Review / Create",
      command: (event: any) => {
        this.activeIndex = this.fields.length
      },
    })
  }

  // @ts-ignore
  isValid(field: FormlyFieldConfig) {
    // hidden fields are exempt from validation check.
    if (this.form.valid) return true
    // disabled fields are exempt from validation check.
    if (field.formControl)
      return field.formControl.disabled || field.formControl.valid
    // @ts-ignore
    return field.fieldGroup.every((f) => this.isValid(f))
  }

  prev() {
    this.activeIndex--
  }

  next() {
    this.activeIndex++
  }

  submit() {
    this.submitEvent.emit(this.model)
  }

  cancel() {
    this.cancelEvent.emit(true)
  }
}
