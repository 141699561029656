import { Injectable } from "@angular/core"
import { MenuItemsService } from "@dryad-web-app/shared/data-access"
import { DryadRoutes, MenuItem } from "@dryad-web-app/shared/state"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable({ providedIn: "root" })

export class WebMenuItemsService extends MenuItemsService {
  private dismissedAlertsCount = new BehaviorSubject<number>(0)
  public dismissedAlertsCount$: Observable<number> = this.dismissedAlertsCount.asObservable()

  constructor() {
    super()
  }

  getMenuItems(): MenuItem[] {
    return [
      {
        icon: "ph ph-house",
        id: 8,
        label: "Dashboard",
        slug: DryadRoutes.DASHBOARD,
        auth: "DEFAULT_PERMIT",
      },
      {
        icon: "dy dy-site-circle-flag",
        id: 0,
        label: "Sites",
        slug: DryadRoutes.SITES,
        auth: "DEFAULT_PERMIT",
      },
      {
        icon: "ph ph-map-trifold",
        id: 3,
        label: "Map",
        slug: DryadRoutes.MAP,
        auth: "DEFAULT_PERMIT",
      },
      {
        icon: "ph ph-bell",
        id: 3,
        label: "Alert Center",
        slug: DryadRoutes.ALERT_CENTER,
        auth: "DEFAULT_PERMIT",
      },
      {
        icon: "ph ph-funnel",
        id: 4,
        label: "Devices",
        slug: DryadRoutes.DEVICES,
        auth: "ACCESS_EXPERIMENT_VIEW",
      },
      {
        icon: "ph ph-users",
        id: 5,
        label: "User Management",
        slug: DryadRoutes.USER_MANAGEMENT,
        auth: "DEFAULT_PERMIT",
      },
      {
        icon: "ph ph-list-dashes",
        id: 6,
        label: "Sensor Data",
        slug: DryadRoutes.SENSOR_DATA,
        auth: "ACCESS_SENSOR_DATA_LOGS_VIEW",
      },
      {
        icon: "ph ph-chalkboard-teacher",
        id: 7,
        label: "Experiments",
        slug: DryadRoutes.EXPERIMENTS,
        auth: "ACCESS_EXPERIMENT_VIEW",
      },
      {
        icon: "ph ph-download-simple",
        id: 9,
        label: "Device Management",
        slug: DryadRoutes.DEVICE_MANAGEMENT_HOME,
        auth: "ACCESS_USER_MGT_VIEW",
        subMenus: [
          {
            icon: "ph ph-command",
            id: 9,
            label: "Down links",
            slug: DryadRoutes.DEVICE_MANAGEMENT_HOME + "/" + DryadRoutes.DOWNLINK,
            auth: "ACCESS_USER_MGT_VIEW",
          },
          {
            icon: "ph ph-file-arrow-up",
            id: 9,
            label: "File Management",
            slug: DryadRoutes.DEVICE_MANAGEMENT_HOME + "/" + DryadRoutes.FILE_MANAGEMENT,
            auth: "ACCESS_USER_MGT_VIEW",
          },
          {
            icon: "ph ph-airplay",
            id: 9,
            label: "Fuota",
            slug:  DryadRoutes.DEVICE_MANAGEMENT_HOME + "/" + DryadRoutes.FUOTA,
            auth: "ACCESS_USER_MGT_VIEW",
          },
        ],
      },
      {
        icon: "dy dy-border-gateway-device",
        id: 9,
        label: "Gateway Settings",
        slug: DryadRoutes.GATEWAY_SETTINGS,
        auth: "ACCESS_USER_MGT_VIEW",
      },
    ]
  }

  setDismissedAlertsCount(alertsCount: number): void {
    this.dismissedAlertsCount.next(alertsCount)
  }
}
