import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core"
import * as echarts from "echarts/core"
import { LineChart } from "echarts/charts"
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
} from "echarts/components"
import { LabelLayout, UniversalTransition } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"
import { EChartsOption } from "echarts"

echarts.use([
  LineChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
])

@Directive({
  selector: "[appDryadCharts]",
})
export class DryadChartsDirective implements OnChanges, OnInit, AfterViewInit {
  private readonly chart: echarts.ECharts
  @Input() chartOptions: EChartsOption | undefined

  @Output()
  chartEvent: EventEmitter<any> = new EventEmitter<any>()

  @HostListener("window:resize")
  onResize() {
    this.resizeChart()
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.chart = echarts.init(this.el.nativeElement)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resizeChart()
    // TODO - investigate more and make this better
    if (this.chartOptions?.mode === "real-time")
      this.setChartOptions(changes.chartOptions?.currentValue, true)
   // this.setEmptyMessage(true)
  }

  ngOnInit(): void {
    this.chart.on(
      "dataZoom",
      function (evt: any) {
        const option = this.chart.getOption()
        this.chartEvent.emit(option.dataZoom[0])
      }.bind(this)
    )
    this.chart.on(
      "click",
      function (evt: any) {
        this.chartEvent.emit(evt)
      }.bind(this)
    )
  }

  ngAfterViewInit(): void {
    if (this.chartOptions) this.setChartOptions(this.chartOptions)
    // @ts-ignore
    // if (this.chartOptions.series[0].data.length === 0)
    //   this.setEmptyMessage(false)
  }

  resizeChart = (): void => {
    if (this.chart) this.chart.resize()
  }

  setChartOptions = (opts: any, marge = false): void => {
    if (this.chart) this.chart.setOption(opts, marge)
  }

  setEmptyMessage(clear: boolean) {
    const mainDiv = (<HTMLElement>this.el.nativeElement).querySelector("div")
    const nodDataDiv = this.renderer.createElement("div")
    if (clear) {
      const noData = (<HTMLElement>this.el.nativeElement).querySelector(
        ".no-data"
      )
      if (noData) this.renderer.removeChild(mainDiv, noData)
    } else {
      // @ts-ignore
      mainDiv.style.paddingTop = "20%"
      mainDiv.style.paddingLeft = "30%"
      this.renderer.addClass(nodDataDiv, "no-data")
      this.renderer.appendChild(
        nodDataDiv,
        this.renderer.createText("No data available")
      )
      this.renderer.appendChild(mainDiv, nodDataDiv)
    }
  }
}
