import { Pipe, PipeTransform } from '@angular/core';
import { DistancePipe } from './distance.pipe';

export type MetricUnit = "m²" | "ha" | "km²"
export type ImperialUnit = "ft²" | "acre" | "mi²"
export type Unit = ImperialUnit | MetricUnit
export type ConversionResult = { value: number; unit: Unit }

@Pipe({
  name: 'area',
})
export class AreaPipe implements PipeTransform {
  constructor(
    private distancePipe: DistancePipe
  ) { }

  transform(distanceInMeters: number): string {
    if (!distanceInMeters) return '--'
    const convertion = this.getNormalized(distanceInMeters)
    return this.distancePipe.getCleanedFloat(convertion.value) + '\u202f' + convertion.unit
  }

  public getNormalized(distanceInMeters: number): ConversionResult {
    const { system } = this.distancePipe.getNormalized(distanceInMeters)

    if (distanceInMeters > 1_000_000) {
      return {
        value: system === "imperial" ? this.squareMeterToSquareMile(distanceInMeters) : distanceInMeters / 1_000_000,
        unit: system === "imperial" ? "mi²" : "km²"
      }
    }

    if (distanceInMeters > 10_000) {
      return {
        value: system === "imperial" ? this.squareMeterToAcre(distanceInMeters) : distanceInMeters / 10000,
        unit: system === "imperial" ? "acre" : "ha"
      }
    }

    return {
      value: system === "imperial" ? this.squareMeterToSquareFeet(distanceInMeters) : distanceInMeters,
      unit: system === "imperial" ? "ft²" : "m²"
    }
  }

  private squareMeterToAcre(squareMeter: number): number {
    return squareMeter * 0.000247
  }

  private squareMeterToSquareMile(squareMeter: number): number {
    return squareMeter * (3.861 * (10 ** -7))
  }

  private squareMeterToSquareFeet(squareMeter: number): number {
    return squareMeter * 10.7639
  }
}
