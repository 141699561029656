import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { plainToInstance } from "class-transformer"
import { Observable, Subject } from "rxjs"
import { map } from "rxjs/operators"
import { Client, CreateSite, SensorNodeStatus, Site, SiteDataAggregate, SiteDataDetails, SiteStatus } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class SiteService extends ApiHttpService {
  private readonly SITE_API_PATH = "sites"

  private mapClickDeviceId: Subject<number> = new Subject<number>()
  public mapClickDeviceId$: Observable<number> = this.mapClickDeviceId.asObservable()

  constructor() {
    super()
  }

  sites(params = new HttpParams()): Observable<Site[]> {
    return this.get<SiteDataAggregate[]>(this.SITE_API_PATH+ "?limit=-1", params).pipe(
      map((response) => plainToInstance(SiteDataAggregate, response)),
      map((sdds: SiteDataAggregate[]) =>
        sdds.map((sdd: SiteDataAggregate) => sdd.data)
      )
    )
  }
  siteList(params = new HttpParams()): Observable<Site[]> {
    return this.get<SiteDataAggregate[]>(this.SITE_API_PATH+ "?limit=-1", params).pipe(
      map((sdds: SiteDataAggregate[]) =>
        sdds.map((sdd: SiteDataAggregate) => sdd.data)
      )
    )
  }

  sitesWithAggregate(
    params = new HttpParams()
  ): Observable<SiteDataAggregate[]> {
    return this.get<SiteDataAggregate[]>(this.SITE_API_PATH + "?limit=-1", params).pipe(
      map((response) => plainToInstance(SiteDataAggregate, response))
    )
  }

  site(siteId: number, params = new HttpParams()): Observable<Site> {
    return this.get<Site>(this.SITE_API_PATH + "/" + siteId, params).pipe(
      map((response) => plainToInstance(Site, response))
    )
  }

  sitesStatus(params = new HttpParams()): Observable<SiteStatus[]> {
    return this.get<SiteStatus[]>(this.SITE_API_PATH + "/status", params).pipe(
      map((response) => plainToInstance(SiteStatus, response))
    )
  }


  siteStatus(siteId: number, params = new HttpParams()): Observable<SiteStatus> {
    return this.get<SiteStatus>(`${this.SITE_API_PATH}/${siteId}/status`, params).pipe(
      map((response) => plainToInstance(SiteStatus, response))
    )
  }

  siteSensorNodesStatus(siteId: number, params = new HttpParams()): Observable<SensorNodeStatus[]> {
    return this.get<SensorNodeStatus[]>(this.SITE_API_PATH + "/" + siteId + "/sensornodes/status", params).pipe(
      map((response) => plainToInstance(SensorNodeStatus, response))
    )
  }

  siteWithAggregate(
    siteId: number,
    params = new HttpParams()
  ): Observable<SiteDataAggregate> {
    return this.get<SiteDataAggregate>(
      this.SITE_API_PATH + "/" + siteId + "/details",
      params
    ).pipe(map((response) => plainToInstance(SiteDataAggregate, response)))
  }

  createSite(createSite: CreateSite): Observable<Site> {
    return this.post<SiteDataDetails>(this.SITE_API_PATH, createSite).pipe(
      map((response) => plainToInstance(Site, response))
    )
  }

  updateSite(site: Site): Observable<Site> {
    return this.put<Site>(this.SITE_API_PATH + "/" + site.id, site).pipe(
      map((response) => plainToInstance(Site, response))
    )
  }

  deleteSite(id: number): Observable<boolean> {
    return this.delete(this.SITE_API_PATH + "/" + id)
  }

  getSiteClientsById(id: number): Observable<Client[]> {
    return this.get<Client[]>(this.SITE_API_PATH + "/clients/" + id).pipe(
      map((response) => plainToInstance(Client, response))
    )
  }

  setClickedDeviceId(clickedDeviceId: number) {
    this.mapClickDeviceId.next(clickedDeviceId)
  }
}
