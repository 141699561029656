import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from "@angular/core"
import { animate, state, style, transition, trigger } from "@angular/animations"

@Component({
  selector: 'dryad-web-app-row-expansion',
  templateUrl: './row-expansion.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger("rowExpansionTrigger", [
      state(
        "void",
        style({
          transform: "translateX(-10%)",
          opacity: 0,
        })
      ),
      state(
        "active",
        style({
          transform: "translateX(0)",
          opacity: 1,
        })
      ),
      transition("* <=> *", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
    ]),
  ],
})
export class RowExpansionComponent implements OnInit {
  items: any[] = [""]
  @Input() label: string | undefined
  @Input() headerLabelList: any[] | undefined
  @Output() clickAddNew: EventEmitter<any> = new EventEmitter<any>()
  @Output() clickExpandEvent: EventEmitter<any> = new EventEmitter<any>()
  @Input() showAddButton: boolean | undefined
  @Input() showIndicators = false
  @ContentChild(TemplateRef) tmpl: TemplateRef<any> | undefined
  @Input() headerActions: TemplateRef<any> | undefined
  @Input() headerButtons: TemplateRef<any> | undefined

  constructor() {}

  ngOnInit(): void {}

  clickExpand($event: MouseEvent) {
    this.clickExpandEvent.emit($event)
  }
}
