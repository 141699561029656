import { NgModule } from "@angular/core"
import { ToastModule } from "primeng/toast"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import { TableModule } from "primeng/table"
import { DialogModule } from "primeng/dialog"
import { PaginatorModule } from "primeng/paginator"
import { RouterModule } from "@angular/router"
import { ConfirmationService } from "primeng/api"
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { TabViewModule } from "primeng/tabview"
import { CalendarModule } from "primeng/calendar"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { InputSwitchModule } from "primeng/inputswitch"
import { SidebarModule } from "primeng/sidebar"
import { RippleModule } from "primeng/ripple"
import { TooltipModule } from "primeng/tooltip"
import { TimelineModule } from "primeng/timeline"
import { TagModule } from "primeng/tag"
import { GMapModule } from "primeng/gmap"
import { DragDropModule } from "primeng/dragdrop"
import { CardModule } from "primeng/card"
import { MenuModule } from "primeng/menu"
import { ListboxModule } from "primeng/listbox"
import {OverlayPanelModule} from "primeng/overlaypanel"
import { ChipModule } from "primeng/chip"
import { PickListModule } from "primeng/picklist"
import { DataViewModule } from "primeng/dataview"
import { DividerModule } from "primeng/divider"
import { ToggleButtonModule } from "primeng/togglebutton"
import { RadioButtonModule } from "primeng/radiobutton"
import { PanelModule } from "primeng/panel"
import { FileUploadModule } from "primeng/fileupload"
import { TreeModule } from "primeng/tree"
import { CheckboxModule } from "primeng/checkbox"
import { AccordionModule } from "primeng/accordion"
import { FieldsetModule } from "primeng/fieldset"
import { NgxMapLibreGLModule } from "@maplibre/ngx-maplibre-gl"
import { InViewportModule } from 'ng-in-viewport'
@NgModule({
  imports: [
    ToastModule,
    TableModule,
    DialogModule,
    PaginatorModule,
    ConfirmDialogModule,
    TabViewModule,
    RouterModule,
    CalendarModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    SidebarModule,
    RippleModule,
    TooltipModule,
    TimelineModule,
    TagModule,
    GMapModule,
    DragDropModule,
    CardModule,
    MenuModule,
    ListboxModule,
    ChipModule,
    OverlayPanelModule,
    PickListModule,
    DataViewModule,
    DividerModule,
    ToggleButtonModule,
    RadioButtonModule,
    PanelModule,
    TableModule,
    FileUploadModule,
    TreeModule,
    CheckboxModule,
    AccordionModule,
    FieldsetModule,
    NgxMapLibreGLModule,
    InViewportModule,
  ],
  exports: [
    ToastModule,
    DialogModule,
    TableModule,
    PaginatorModule,
    ConfirmDialogModule,
    TabViewModule,
    RouterModule,
    CalendarModule,
    ProgressSpinnerModule,
    InputSwitchModule,
    SidebarModule,
    RippleModule,
    TooltipModule,
    TimelineModule,
    TagModule,
    GMapModule,
    DragDropModule,
    CardModule,
    MenuModule,
    ListboxModule,
    ChipModule,
    OverlayPanelModule,
    DataViewModule,
    DividerModule,
    ToggleButtonModule,
    RadioButtonModule,
    PanelModule,
    FileUploadModule,
    TreeModule,
    CheckboxModule,
    AccordionModule,
    FieldsetModule,
    PickListModule,
    NgxMapLibreGLModule,
    InViewportModule,
  ],
  providers: [
    ConfirmationService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig
  ]
})
export class SharedHelpersModule {
}
