<div class="grid" *ngIf="gatewayTechInfo">
  <div class="col-12 md:col-4"><span class="tech-info-name">Gateway ID</span></div>
  <div class="col-12 md:col-8">{{gatewayTechInfo?.data?.ns_gateway_id}}</div>

  <div class="col-12 md:col-4"><span class="tech-info-name">EUI</span></div>
  <div class="col-12 md:col-8">{{gatewayEUI  || "-" }}</div>

  <div class="col-12 md:col-4"><span class="tech-info-name">Schedule Time Delay</span></div>
  <div class="col-12 md:col-8">{{gatewayTechInfo?.details?.scheduleAnytimeDelayMillis}} ms</div>

  <div class="col-12 md:col-4"><span class="tech-info-name">GWID</span></div>
  <div class="col-12 md:col-8">{{gatewayTechInfo?.data.gwid}} </div>

  <div class="col-12 md:col-4"><span class="tech-info-name">Serial ID</span></div>
  <div class="col-12 md:col-8">{{gatewayTechInfo?.data.serial_id}} </div>

  <div class="col-12 md:col-4"><span class="tech-info-name">NS Gateway ID</span></div>
  <div class="col-12 md:col-8">{{gatewayTechInfo?.data.ns_gateway_id || "-" }} </div>
</div>
