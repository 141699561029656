import * as moment from "moment"
import { Gateway, SensorNode, Site, SiteDataAggregate } from "./model"

export class Constants {
  static readonly NULL_LAT_LONG = 1000
}

export enum SidebarItem {
  SITES = 0,
  GATEWAYS = 1,
  PROJECTS = 2,
  MAP = 3,
  DEVICES = 4,
  USER_MANAGEMENT = 5,
  SENSOR_DATA = 6,
  EXPERIMENTS = 7,
  DASHBOARD = 8,
  SETTINGS = 9,
}

export enum ViewSidebarDataType {
  ANY = 0,
  MAP_SENSOR_NODE = 1,
  DEVICE_FILTER = 2,
}

export enum ViewSidebarPositions {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

export class ViewSidebarState {
  constructor(
    public viewSidebarDataType: ViewSidebarDataType,
    public active: boolean,
    public entity: SiteDataAggregate | SensorNode | any,
    public position?: ViewSidebarPositions,
    public onClose?: () => void
  ) {
  }
}

export enum TimeRange {
  LAST_15MIN = 0,
  LAST_10DAYS = 1,
  LAST_1MONTH = 2,
}

export enum DeviceState {
  INACTIVE,
  ACTIVE,
  NO_SIGNAL_6H_TO_12H,
  NO_SIGNAL_GT_12H,
  FIRE,
  WARNING,
}

export class DateUtils {
  static readonly SIX_HOURS_IN_MS = 6 * 60 * 60 * 1000
  static readonly TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000

  static before(tr: TimeRange): Date {
    const now = new Date()
    const d = new Date()
    switch (tr) {
      case TimeRange.LAST_15MIN:
        d.setTime(now.getTime() - 15 * 60 * 1000)
        return d
      case TimeRange.LAST_10DAYS:
        d.setDate(now.getDate() - 10)
        return d
      case TimeRange.LAST_1MONTH:
        d.setMonth(now.getMonth() - 1)
        return d
      default:
        throw new Error("TimeRange not recognized!")
    }
  }

  static deviceState(lastSignalDate: Date): DeviceState {
    const lastSignalTime = lastSignalDate.getTime()
    const nowTime = new Date().getTime()
    if (lastSignalDate) {
      const timeDiff = nowTime - lastSignalTime
      if (timeDiff <= DateUtils.SIX_HOURS_IN_MS)
        return DeviceState.ACTIVE
    }
    return DeviceState.INACTIVE
  }

  static timeDiffAsString(time: string): string {
    const now = moment(Date.now())
    const compareTime = moment(time)
    const days = now.diff(compareTime, "days")
    if (days === 0) {
      const hours = now.diff(compareTime, "hours")
      if (hours === 0) {
        const minutes = now.diff(compareTime, "minutes")
        if (minutes === 0) {
          const seconds = now.diff(compareTime, "seconds")
          return seconds + " seconds"
        } else return minutes + " minutes"
      } else return hours + " hours"
    } else return days + " days"
  }

  static currentYYYYMMDD(): string {
    return moment(new Date()).format("YYYY-MM-DD")
  }
}

export class MapUtils {
  static deviceMarkerState(
    deviceState: DeviceState,
    deviceMarkerPrefix: string
  ): string {
    switch (deviceState) {
      case DeviceState.INACTIVE:
        return deviceMarkerPrefix + "inactive"
      case DeviceState.ACTIVE:
        return deviceMarkerPrefix + "active"
      case DeviceState.NO_SIGNAL_6H_TO_12H:
      case DeviceState.NO_SIGNAL_GT_12H:
        return deviceMarkerPrefix + "inactive"
      case DeviceState.FIRE:
        return deviceMarkerPrefix + "fire"
      case DeviceState.WARNING:
        return deviceMarkerPrefix + "fire-warning"
      default:
        return deviceMarkerPrefix + "inactive"
    }
  }

  static sensorMarkerState(deviceState: DeviceState): string {
    const sensorMarkerPrefix = "sensor-node-marker-"
    return MapUtils.deviceMarkerState(deviceState, sensorMarkerPrefix)
  }

  static borderGatewayMarkerState(deviceState: DeviceState): string {
    const borderGatewayMarkerPrefix = "border-gateway-marker-"
    return MapUtils.deviceMarkerState(deviceState, borderGatewayMarkerPrefix)
  }

  static meshGatewayMarkerState(deviceState: DeviceState): string {
    const meshGatewayMarkerPrefix = "mesh-gateway-marker-"
    return MapUtils.deviceMarkerState(deviceState, meshGatewayMarkerPrefix)
  }

  // @ts-ignore
  static getBoundsZoomLevel = (bounds: google.maps.LatLngBounds, mapDim: { height: number, width: number }): number => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;

    function latRad(lat: number) {
      const sin = Math.sin(lat * Math.PI / 180);
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    const lngDiff = ne.lng() - sw.lng();
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }
}

export class UserAuth {
  constructor(
    public access_token: string,
    public token_type: string,
    public expires_in: string,
    public refresh_token: string
  ) {
  }
}

export class DryadRoutes {
  public static readonly HOME = "home"
  public static readonly SITES = "sites"
  public static readonly SITE = ":siteId"
  public static readonly SITE_MAP = ":siteId/map"
  public static readonly SENSOR_NODES = DryadRoutes.SITE + "/sensors"
  public static readonly SITE_GATEWAYS = DryadRoutes.SITE + "/gateways"
  public static readonly GATEWAYS = "gateways"
  public static readonly PROJECTS = "projects"
  public static readonly MAP = "map"
  public static readonly DEVICES = "devices"
  public static readonly USER_MANAGEMENT = "users"
  public static readonly USER_CREATE = "users/create"
  public static readonly USER_EDIT = "users/:userId"
  public static readonly SENSOR_DATA = "sensor-data"
  public static readonly EXPERIMENTS = "experiments"
  public static readonly DASHBOARD = "dashboard"
  public static readonly SETTINGS = "settings"
  public static readonly ALERT_CENTER = "alert-center"
  public static readonly ALERT_CENTER_SITE = ":siteId"
  public static readonly ALERT_DETAILS = ":alertId"
  public static readonly SITE_PACKETS = ":siteId/packets"
  public static readonly PACKET_CREATION = ":siteId/packets/_create"
  public static readonly PACKET_EDIT = ":siteId/packets/:packetId"
  public static readonly PACKETS = "packet-list"
  public static readonly LOGIN = "login"
  public static readonly PACKET = "packet/:packetId"
  public static readonly DEPLOYMENT_MAP_DEVICE = "deployment_map/:deviceId"
  public static readonly DEPLOYMENT_MAP = "deployment_map"
  public static readonly DEPLOYMENT_SCAN = "scan/:deviceId"
  public static readonly DEPLOYMENT_INSTALL = "install/:deviceId/:deviceQr"
  public static readonly DEPLOYMENT_INSTALL_MANUALLY = "install_manually/:deviceId"
  public static readonly DEPLOYMENT_EDIT_MANUALLY = "install_edit"
  public static readonly DEPLOYMENT_EDIT = "install_edit/:id"
  public static readonly QR_EXAMPLE = "example"
  public static readonly SENSOR_NODE = ":siteId/sensors/:sensorId"
  public static readonly GATEWAY = ":siteId/gateway/:gatewayId"
  public static readonly SITE_SETTINGS = ":siteId/settings"
  public static readonly SENSOR_NODE_GRAPHS = ":siteId/sensors-data/:sensorId"
  public static readonly DEVICE_MANAGEMENT_HOME = "device-management"
  public static readonly DOWNLINK = "down-link-home"
  public static readonly FUOTA = "fuota-home"
  public static readonly FUOTA_NEW = "fuota-home/new"
  public static readonly FUOTA_NEW_JOB_ID = "fuota-home/new/:jobId"
  public static readonly FILE_MANAGEMENT = "file-management"
  public static readonly RELEASE_MANAGEMENT = "file-management/release-management"
  public static readonly FILE_TRANSFER = "file-management/file-transfer/:id"
  public static readonly FILE_TRANSFERS = "file-management/file-transfer"
  public static readonly FILE_WAVE_NEW = "file-management/file-transfer/new"
  public static readonly FILE_WAVE = "file-management/file-wave"
  public static readonly JOB_TRACKER = "job-tracker"
  public static readonly JOB_TRACKER_TYPE = "job-tracker/:type"
  public static readonly JOB_STATUS = "job-tracker/:type/:jobId"
  public static readonly DOWNLINK_CMD_EXECUTE = "device-down-link-home/execute"
  public static readonly DOWNLINK_CMD_TRACE = "device-down-link-home/:type/:traceId"
  public static readonly GATEWAY_SETTINGS = "gateway-settings"
  public static readonly SITE_PRE_PLANNING = ":siteId/pre-planning"

  public static readonly USER = "me"
  public static readonly USER_SETTINGS = DryadRoutes.USER + "/settings"

  public static readonly SITES_CREATE = "site/:siteId"

  public static readonly OAUTH_CALLBACK = "oauth/callback"
  public static readonly OAUTH_CALLBACK_V2 = "oauth/callback/v2"

  public static readonly ERROR = "error"
  public static readonly ACCESS_DENIED = "accessdenied"
  public static readonly NOT_FOUND = "404"

  public static CURRENT_ROUTE = "/"
}

export class Assets {
  public static readonly MARKER_BASE_URL = "assets/images/"
}

export enum MapOverlayType {
  UNKNOWN = 0,
  SITE = 1,
  GATEWAY = 2,
  SENSOR_NODE = 3,
}

export enum MapEventType {
  MOUSEOVER = 0,
  MOUSEOUT = 1,
  MOUSEMOVE = 2,
  MOUSECLICK = 3,
  MOUSEDBLCLICK = 4,
  RECENTER = 5,
  ZOOM_TO_SENSOR = 6,
}

export class MapEvent {
  constructor(
    public overlayType: MapOverlayType,
    public eventType: MapEventType,
    public event: any,
    public entity: Site | SensorNode | Gateway | any
  ) {
  }
}

export class MapDialogPosition {
  constructor(public marginLeft: number, public marginTop: number) {
  }
}

export class MenuItem {
  constructor(
    public icon: string | undefined,
    public id: number | undefined,
    public label: string | undefined,
    public slug: DryadRoutes,
    public auth?: string | undefined,
    public subMenus?: MenuItem[],
  ) {
  }
}

export class FloatingPanel {
  constructor(
    public buttonLabel: string | undefined,
    public command: ((event?: any) => void) | undefined,
    public type: string,
    public buttonCssClass: string = "button-primary") {
  }
}
