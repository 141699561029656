import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { SharedModule } from "../shared/shared.module"
import { SensorNodeListComponent } from "./sensor-node-list.component"
import { SensorNodeCreateComponent } from "./sensor-node-create/sensor-node-create.component"
import { SensorNodeComponent } from "./sensor-node/sensor-node.component"
import { SensorDataComponent } from "./sensor-node/sensor-data/sensor-data.component"
import { SensorStatusComponent } from "./sensor-node/sensor-status/sensor-status.component"


@NgModule({
  declarations: [
    SensorNodeListComponent,
    SensorNodeCreateComponent,
    SensorDataComponent,
    SensorStatusComponent,
    SensorNodeComponent ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SensorNodeListComponent,
    SensorNodeCreateComponent,
    SensorDataComponent,
    SensorNodeComponent ],
})
export class SensorNodesModule {
}
