import { Component, ViewEncapsulation } from "@angular/core"
import { FieldType } from "@ngx-formly/core"
import { FormUtils } from "@dryad-web-app/shared/state"

@Component({
  selector: "dryad-web-app-dryad-formly-text-area",
  templateUrl: "./dryad-formly-field-text-area.component.html",
  styleUrls: ["./dryad-formly-field-text-area.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DryadFormlyFieldTextAreaComponent extends FieldType {
  FormUtils = FormUtils

  constructor() {
    super()
  }

  ngOnInit(): void {
  }
}

