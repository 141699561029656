import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { EntityType } from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

export class EntityTypes {
  static RESOLVED_ALERT_TYPE = "resolved_alert_type"
}

@Injectable({
  providedIn: "root",
})
export class TypeService extends ApiHttpService {
  private readonly TYPES_API_PATH = "types"

  constructor() {
    super()
  }

  types(
    entityTypeKey: string,
    params = new HttpParams()
  ): Observable<EntityType[]> {
    return this.get<EntityType[]>(
      this.TYPES_API_PATH + "/" + entityTypeKey,
      params
    ).pipe(map((response) => plainToClass(EntityType, response)))
  }

  resolvedAlertType(): Observable<EntityType[]> {
    return this.types(EntityTypes.RESOLVED_ALERT_TYPE)
  }
}
