import { Component, OnInit } from "@angular/core"
import { TypeService } from "@dryad-web-app/shared/data-access"
import { EntityType } from "@dryad-web-app/shared/state"
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { ExperimentService } from "../../service/http/experiment.service"
import { CreateExperimentRecordComponent } from "./create-experiment-record/create-experiment-record.component"

@Component({
  selector: "app-experiments-list",
  templateUrl: "./experiments-list.component.html",
  styleUrls: ["./experiments-list.component.css"],
})
export class ExperimentsListComponent implements OnInit {
  experiments: any
  tags: EntityType[] = []
  limit = 10
  totalRecords = 0
  loading: boolean

  constructor(
    private dialogService: DialogService,
    private experimentService: ExperimentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private typeService: TypeService
  ) {
    this.typeService.types("tag_label").subscribe((data) => {
      this.tags = data
    })
    // FixMe remove this after meta data add
    this.experimentService.list(-1, 0).subscribe((data) => {
      this.totalRecords = data.length
    })
  }

  ngOnInit(): void {
    this.init(this.limit, 0)
  }

  init(limit, offset, filter?: any): void {
    this.loading = true
    this.experimentService.list(limit, offset, filter).subscribe((data) => {
      data.map((d) => {
        d.venue = Number(this.findTag(d.venue).label)
        d.burn_material = Number(this.findTag(d.burn_material).label)
        d.sensor_hotplate_distance = Number(this.findTag(d.sensor_hotplate_distance).label)
      })
      this.experiments = data
      this.loading = false
    })
  }

  findTag(id: number): EntityType {
    return this.tags.find((o) => o.id === id)
  }

  handleNewRecord(): void{
    const ref = this.dialogService.open(CreateExperimentRecordComponent, {
      header: "Add Experiment",
      width: "70%",
    })
    ref.onClose.subscribe(() => {
      // FixMe remove this after meta data add
      this.totalRecords += 1
      this.init(this.limit, 0)
    })
  }

  deleteRecord(record: any): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete the record?",
      header: "Delete Confirmation",
      icon: "ph ph-info",
      accept: () => {
        this.experimentService.destroy(record.id).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: "Deleted",
          })
          // FixMe remove this after meta data add
          this.totalRecords -= 1
          this.init(this.limit, 0)
        })
      },
    })
  }

  loadExperiment($event: LazyLoadEvent): void {
    this.loading = true
    this.init(this.limit, $event.first, this.generateFilters($event.filters))
  }

  generateFilters(filter: any): any {
    const filters = {}
    for (const [key, val] of Object.entries(filter)) {
      if (val["value"]) {
        filters[key] = {
          _eq: val["value"],
        }
      }
    }
    return filters
  }
}
