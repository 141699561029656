<div class="filter filter-messages margin-t-m">
  <div class="flex-center-end">
    <div class="filter-message-item">
      <p-dropdown [options]="times" [(ngModel)]="selectedTime" optionLabel="name" (onChange)="changeTime()"
                  appendTo="body"></p-dropdown>
    </div>
  </div>
</div>

<div class="refresh-graph pt-2 pb-3">
  <div class="flex align-items-center justify-content-center" (click)="getUplinkMessages()">
    <i class="ph ph-arrows-clockwise pr-2"></i><span>Refresh graph data</span>
  </div>
</div>

<div *ngIf="chartOptBattery.series[0]?.data?.length > 0" class="sensor-node">
  <div class="grid">
    <div class="col-12 md:col-12 lg:col-12 sensor-node-chart">
      <div appDryadCharts [chartOptions]="chartOptBattery" class="charts"></div>
    </div>
  </div>
</div>
<div
  class="grid sensor-node"
  *ngIf="chartOptBattery.series[0]?.data?.length === 0"
>
  <span>{{nodataMsg}}</span>
</div>
