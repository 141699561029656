import { Injectable } from "@angular/core"
import { plainToInstance } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { Role } from "@dryad-web-app/shared/state"
import { RoleNames } from "./constants"
@Injectable({
  providedIn: "root",
})
export class RoleService extends ApiHttpService {
  private readonly ROLES_API_PATH = "roles"

  constructor() {
    super()
  }

  find(roleId: string): Observable<Role> {
    return this.get<Role>(this.ROLES_API_PATH + "/" + roleId).pipe(
      map((response) => plainToInstance(Role, response))
    )
  }

  list(): Observable<Role[]> {
    return this.get<Role[]>(this.ROLES_API_PATH).pipe(
      map((response) => plainToInstance(Role, response))
    )
  }

  getclientAdminUserRoles(): Observable<Role[]> {
    return this.list().pipe(map(response => {
      return response.filter(role => {
        return role.name === RoleNames.ClientAdmin || role.name === RoleNames.StandardUser
      })
    }))
  }
}
