<div class="border-round-s widget-bg padding-m">
  <form [formGroup]="settingsForm">
    <h2>
      Unit system
      <button
        pTooltip="Select which unit to use to display distances and area measures"
        class="clickable"
      >
        <i class="ph ph-info"></i>
      </button>
    </h2>
    <ul *ngFor="let unitSystem of unitSystems" class="list-style-none">
      <li>
        <p-radioButton
          name="unitSystem"
          [label]="unitSystem.label"
          [value]="unitSystem.value"
          formControlName="unitSystem"
        ></p-radioButton>
      </li>
    </ul>

    <h2 class="margin-t-xl">
      Date format
      <button
        pTooltip="Select which date format to use to display"
        class="clickable"
      >
        <i class="ph ph-info"></i>
      </button>
    </h2>
    <ul *ngFor="let dateFormat of dateFormats" class="list-style-none">
      <li>
        <p-radioButton
          name="dateFormat"
          [label]="dateFormat.label"
          [value]="dateFormat.value"
          formControlName="dateFormat"
        ></p-radioButton>
      </li>
    </ul>

    <h2 class="margin-t-xl">
      Time format
      <button
        pTooltip="Select which date format to use to display"
        class="clickable"
      >
        <i class="ph ph-info"></i>
      </button>
    </h2>
    <ul *ngFor="let timeFormat of timeFormats" class="list-style-none">
      <li>
        <p-radioButton
          name="timeFormat"
          [label]="timeFormat.label"
          [value]="timeFormat.value"
          formControlName="timeFormat"
        ></p-radioButton>
      </li>
    </ul>
  </form>
</div>
