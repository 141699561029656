import { Injectable } from '@angular/core'
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"
import { Observable } from "rxjs"
import { Client } from "@dryad-web-app/shared/state"
import { map } from "rxjs/operators"
import { plainToInstance } from "class-transformer"
import { HttpParams } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ClientService extends ApiHttpService {
  CLIENT_API_PATH = "items/client"

  constructor() {
    super()
    this.apiBaseUrl = ApiBase.DATA_API_BASE_URL
  }

  list(): Observable<Client[]> {
    const params = new HttpParams().set('limit', '-1');
    return this.get<Client[]>(this.CLIENT_API_PATH, params).pipe(
      // @ts-ignore
      map((response) => plainToInstance(Client, response['data'] as Array<Client>)),
    )
  }

  getClient(clientId: any): Observable<Client> {
    return this.get<Client>(this.CLIENT_API_PATH + "/" + clientId+"?fields=*,sites.site_id.name").pipe(
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/ban-types
      map((response) => plainToInstance(Client, response['data'] as Object)),
    )
  }
}
