import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { EntityType, FrequencyPlan, FrequencyPlans, NetworkServer } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class NetworkServerService extends ApiHttpService {
  private readonly NETWORK_SERVER_API_PATH = "networkservers"

  constructor() {
    super()
  }

  networkServers(params = new HttpParams()): Observable<NetworkServer[]> {
    return this.get<NetworkServer[]>(this.NETWORK_SERVER_API_PATH, params).pipe(
      map((response) => plainToClass(NetworkServer, response))
    )
  }

  frequencyPlans(siteId: number): Observable<FrequencyPlan[]> {
    return this.get<FrequencyPlans>(
      this.NETWORK_SERVER_API_PATH + "/freqplans/" + siteId
    ).pipe(
      map((response) => plainToClass(FrequencyPlans, response)),
      map((fps) => fps.frequency_plans)
    )
  }

  macVersions(): Observable<EntityType[]> {
    return this.get<EntityType[]>(
      this.NETWORK_SERVER_API_PATH + "/macversions"
    ).pipe(map((response) => plainToClass(EntityType, response)))
  }

  phyVersions(): Observable<EntityType[]> {
    return this.get<EntityType[]>(
      this.NETWORK_SERVER_API_PATH + "/phyversions"
    ).pipe(map((response) => plainToClass(EntityType, response)))
  }
}
