import { Component, OnInit, ViewEncapsulation } from "@angular/core"
import { FormUtils } from "@dryad-web-app/shared/state"
import { FieldType } from "@ngx-formly/core"

@Component({
  selector: "dryad-web-app-dryad-formly-field-calender",
  templateUrl: "./dryad-formly-field-calender.component.html",
  styleUrls: ["./dryad-formly-field-calender.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DryadFormlyFieldCalenderComponent extends FieldType implements OnInit {
  FormUtils = FormUtils
  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
