import { Injectable } from "@angular/core"
import { CanActivate, Router } from "@angular/router"
import { UserService } from "@dryad-web-app/shared/data-access"
import { ObservableState, USER_UPDATE, UserDetails, DryadRoutes } from "@dryad-web-app/shared/state"

/**
 * Router guard services which validates user login.
 */
@Injectable({
  providedIn: "root"
})
export class DirectusAuthGuard implements CanActivate {
  constructor(
    public oss: ObservableState,
    public userService: UserService,
    public router: Router,
  ) {}

  public canActivate(): Promise<boolean> {
    if (!this.oss.userLoggedIn()) {
      return new Promise((resolve) => {
        const goToLoginPage = (): void => {
          this.router.navigate([DryadRoutes.LOGIN])
          resolve(false)
        }

        this.userService.me().subscribe(
          (me: UserDetails) => {
            if (me) {
              this.oss.dispatch({
                type: USER_UPDATE,
                payload: me
              })

              const isFirstLogin = this.userService.getStoredSettings() === null
              if (isFirstLogin) {
                const userDefaultSettings = this.userService.getDefaultSettings()
                this.userService.setLocalSettings(userDefaultSettings)
              }
              resolve(true)
            } else {
              goToLoginPage()
            }
          },
          goToLoginPage
        )
      })
    }

    return Promise.resolve(true)
  }
}
