import { Component } from '@angular/core'
import { FieldType } from "@ngx-formly/core"
import {
  FormEvent,
  FORMLY_WRAPPER_EVENT,
  FormlyFieldStatus,
  FormUtils,
  ObservableState
} from "@dryad-web-app/shared/state"
import * as _ from "lodash";
@Component({
  selector: 'dryad-web-app-dryad-formly-field-scanner-input',
  templateUrl: './dryad-formly-field-scanner-input.component.html',
  styleUrls: ['./dryad-formly-field-scanner-input.component.scss']
})
export class DryadFormlyFieldScannerInputComponent extends FieldType {
  FormUtils = FormUtils

  constructor(private oss: ObservableState) {
    super()
  }

  clickOnScanner():void {
    this.oss.dispatch({
      type: FORMLY_WRAPPER_EVENT,
      payload: new FormlyFieldStatus(_.cloneDeep(this.field), FormEvent.CLICK_SCANNER),
    })
  }
}
