import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core"
import { DeviceLocationData, DeviceMetadata } from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { DynamicDialogConfig } from "primeng/dynamicdialog"

@Component({
  selector: "app-device-edit",
  templateUrl: "./device-edit.component.html",
  styleUrls: ["./device-edit.component.scss"],
})
export class DeviceEditComponent implements OnInit, OnDestroy {
  metadata = new DeviceMetadata()
  locationData = new DeviceLocationData()
  dialogData: any
  deviceType: any
  index = 0

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.dialogData = this.dialogConfig.data
    this.deviceType = this.dialogConfig.data.deviceType
    this.metadata = plainToClass(DeviceMetadata, this.dialogData.data, {
      excludeExtraneousValues: true,
    })
    this.locationData = plainToClass(DeviceLocationData, this.dialogData.data, {
      excludeExtraneousValues: true,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleChange($event: any): void { }

  ngOnDestroy(): void {
    this.changeDetector.detectChanges()
  }
}
