import moment from "moment"
import _ from "lodash"

import { AlertEvent, PaginatedAlertEventList } from "../alerts/types"

export const getTimeSinceAlert = (time): any => {
  const pastDate = moment(time)
  const currentDate = moment()

  const duration = moment.duration(currentDate.diff(pastDate))

  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  if (hours < 0 && minutes < 1) return "A few moments"
  else return { hours, minutes }
}


export const filterAndSortAlertEvents = (alertEventResponse: PaginatedAlertEventList): AlertEvent[] => {
  const sortedEvents =  _.orderBy(alertEventResponse.results, ["is_fire_alert", "created_at"], [ "desc", "asc" ])
  const uniqueEvents =  _.uniqBy(sortedEvents, "payload.end_device_ids.device_id")

  return uniqueEvents
}

export const getDecimalPart = (index: number): number => Math.round(index * 100)
