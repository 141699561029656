import { Boarding, BoardingSteps } from "boarding.js"
import { MapComponent } from "../map/map.component"

const LOCAL_STORAGE_KEY = "onboarding:map:terrain"

const boarding = new Boarding({
  opacity: 0.5,
  padding: 3,
  onPopoverRender: (el): void => {
    setTimeout(() => {
      if (!boarding.hasNextStep()) {
        el.popoverDescription.insertAdjacentHTML(
          "beforeend",
          `<label style="display: block; padding: 1em 0; font-size: 0.9em;">
            <input type="checkbox" onchange="localStorage.setItem('${LOCAL_STORAGE_KEY}', this.checked ? 'true' : '')">
            Don't show again
          </label>`)
      }
    }, 0)
  },
})

export const startOnboarding = (mapComponent: MapComponent): void => {
  const steps: BoardingSteps = []

  if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
    steps.push(
      {
        element: ".maplibregl-ctrl-terrain,.maplibregl-ctrl-terrain-enabled",
        popover: {
          title: "New feature: 3D terrain",
          description: "Press the 'Enable Terrain' button to switch to a 3D view of the map.",
          prefferedSide: "left",
        },
        onNext: () => mapComponent.showTerrain = true,
      },
      {
        element: "mgl-map",
        popover: {
          title: "3D Navigation",
          description: "Use the right mouse button or hold the Control key to rotate the map.",
          prefferedSide: "top",
        },
      },
      {
        element: ".maplibregl-ctrl-compass",
        popover: {
          title: "Reset view",
          description: "Click the compass to reset the map to a top-down view.",
          prefferedSide: "left",
        },
      })
  }

  if (steps.length) {
    boarding.defineSteps(steps)
    setTimeout(() => {
      boarding.start()
    }, 50)
  }
}
