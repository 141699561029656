<sl-alert
  [variant]="alertNotification?.alert_event.is_fire_alert ? 'danger': 'warning'"
 #alertToast duration="30000" closable class="alert-notification">
  <div class="flex flex-row align-items-center" (click)="goToAlertDetails(alertNotification.alert.uuid)">
    <div class="mr-2">
      <img
        [src]="alertNotification?.alert_event.is_fire_alert ? 'assets/images/SN-alert-current.svg' : 'assets/images/SN-warning-current.svg' "
        alt="Marker icon" width="24" height="24">
    </div>
    <div
      *ngIf="alertNotification?.alert_event.is_fire_alert; else warningMessage"
      class="flex flex-column notification-container">
      <span>
        <strong>{{i18n.t('alerts.alert_notification.alert.title', { sensor_name:  alertNotification?.sensor_node.name, site_name:
          alertNotification?.alert_site.name })}}</strong><br />
        </span>
        <span>{{ alertNotification?.alert_event.created_at | date:
        'medium' }}</span>
    </div>
  </div>
</sl-alert>

<ng-template #warningMessage>
  <div class="flex flex-column">
    <span>
      <strong>{{i18n.t('alerts.alert_notification.warning.title', { sensor_name:  alertNotification?.sensor_node.name, site_name:
        alertNotification?.alert_site.name })}}</strong><br />
      </span>
      <span>{{ alertNotification?.alert_event.created_at | date:
      'medium' }}</span>
  </div>
</ng-template>