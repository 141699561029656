import { AlertPhases, DateUtils, Gateway, GatewayTypes, SensorNodeStatus, SiteStatus } from "@dryad-web-app/shared/state"
import { buffer, concave, lineString } from "@turf/turf"
import type { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from "geojson"

export interface SiteProperties {
  type?: string
  site_id: number
  name: string
  lat: number
  lng: number
  state: FeatureState
  fire_alert?: AlertPhases
  offline?: boolean
  active?: boolean
  calibrating?: boolean
  cluster?: boolean
  cluster_id?: number
  point_count?: number
  children?: string
}

export type DeviceProperties = SiteProperties & { id: number; gatewayType?: GatewayTypes }
export type DeviceFeature = Feature<Point, DeviceProperties>
export type SiteFeature = Feature<Point, SiteProperties>
export type PolygonFeature = Feature<Polygon | MultiPolygon>

export const enum FeatureState {
  Fire = "fire",
  FireWarning = "fire_warning",
  Offline = "offline",
  Active = "active",
  Calibrating = "calibrating",
}

export const FEATURE_COLORS: Record<FeatureState, string> = {
  [FeatureState.Fire]: "#DC5854",
  [FeatureState.FireWarning]: "#E2CB68",
  [FeatureState.Offline]: "#A6A6A6",
  [FeatureState.Active]: "#479A52",
  [FeatureState.Calibrating]: "#FF9F46",
}

export const FEATURE_ORDER: Record<FeatureState, number> = {
  [FeatureState.Fire]: 1,
  [FeatureState.FireWarning]: 2,
  [FeatureState.Offline]: 3,
  [FeatureState.Active]: 4,
  [FeatureState.Calibrating]: 5,
}

export type RemoveListenerCallback = () => void

class DeviceLayerUtils {
  static ZIndex = {
    Marker: {
      SITE_AREA: 0,
      DEVICE: 1,
      DEVICE_CLUSTER: 2,
      SITE: 3,
      SITE_CLUSTER: 4,
    },
    InfoWindow: {
      SITE_CLUSTER: 10,
      SITE: 11,
      DEVICE_CLUSTER: 12,
    },
  }

  static getState = (props: Pick<SiteProperties, "fire_alert" | "offline" | "calibrating">): FeatureState => {
    if (props.fire_alert === AlertPhases.PHASE_TWO) return FeatureState.Fire
    if (props.fire_alert === AlertPhases.PHASE_ONE) return FeatureState.FireWarning
    if (props.offline) return FeatureState.Offline
    if (props.calibrating) return FeatureState.Calibrating
    return FeatureState.Active
  }

  static getMarkerColor = (props: SiteProperties): string =>
    FEATURE_COLORS[this.getState(props)]

  static isClusterPartiallyOnline = (properties: SiteProperties): boolean =>
    !properties.fire_alert && properties.offline && properties.active

  static isOffline = (elmt: SiteStatus | SensorNodeStatus): boolean => {
    if (elmt.lastMessageTimestamp) {
      const now = Date.now()
      const messageTimestamp = Date.parse(elmt.lastMessageTimestamp)
      return messageTimestamp < now - DateUtils.SIX_HOURS_IN_MS
    }
    return true
  }

  static isGatewayOffline = (elmt: Gateway): boolean => {
    if (elmt.lastSignal) {
      const now = Date.now()
      const messageTimestamp = new Date(elmt.lastSignal).getTime()
      return messageTimestamp < now - DateUtils.SIX_HOURS_IN_MS
    }
    return true
  }

  static getSiteClusterPoint = (site: SiteStatus): SiteFeature => {
    const {
      id,
      name,
      fireAlertStatus,
      centerLatitude,
      centerLongitude,
    } = site

    const props = {
      fire_alert: fireAlertStatus === 0 ? undefined : fireAlertStatus,
      offline: DeviceLayerUtils.isOffline(site),
    }

    return {
      type: "Feature",
      properties: {
        type: "site",
        site_id: id,
        name,
        lat: centerLatitude,
        lng: centerLongitude,
        state: DeviceLayerUtils.getState(props),
        ...props,
      },
      id: `site-${id}`,
      geometry: {
        type: "Point",
        coordinates: [ centerLongitude, centerLatitude ],
      },
    }
  }

  static getGatewayPoints = (gateways: Gateway[]): DeviceFeature[] =>
    gateways
      .filter(({ latitude, longitude }) => latitude && longitude)
      .map(gateway => {
        const {
          id,
          name,
          latitude,
          longitude,
          site,
        } = gateway
        const isOffline = DeviceLayerUtils.isGatewayOffline(gateway)
        const props = {
          offline: isOffline,
          active: !isOffline,
        }

        return {
          type: "Feature",
          properties: {
            type: `gateway-${gateway.gateway_type}`,
            name: name ? name : id.toString(),
            id,
            site_id: site,
            lat: latitude,
            lng: longitude,
            gatewayType: gateway.gateway_type,
            state: DeviceLayerUtils.getState(props),
            ...props,
          },
          id: `gateway-${id}`,
          geometry: {
            type: "Point",
            coordinates: [ longitude, latitude ],
          },
        }
      })

  static getSensorPoints = (devices: Array<SensorNodeStatus>): DeviceFeature[] =>
    devices
      .filter(({ latitude, longitude }) => latitude && longitude)
      .map(device => {
        const {
          id,
          name,
          fireAlertStatus,
          latitude,
          longitude,
          siteId,
        } = device
        const isOffline = DeviceLayerUtils.isOffline(device)
        const props = {
          fire_alert: fireAlertStatus === 0 ? undefined : fireAlertStatus,
          offline: isOffline,
          active: !isOffline,
        }

        return {
          type: "Feature",
          properties: {
            type: "sensor",
            name: name ? name : id.toString(),
            id,
            site_id: siteId,
            lat: latitude,
            lng: longitude,
            state: DeviceLayerUtils.getState(props),
            ...props,
          },
          id: `sensor-${id}`,
          geometry: {
            type: "Point",
            coordinates: [ longitude, latitude ],
          },
        }
      })

  static getWrappingPolygon = (sensors: DeviceFeature[], properties: Record<string, any>): PolygonFeature => {
    const devicesCollection: FeatureCollection<Point, DeviceProperties> = {
      "type": "FeatureCollection",
      features: sensors,
    }
    let feature: Feature = concave(devicesCollection)
    if (!feature) {
      if (sensors.length > 1)
        feature = lineString(sensors.map(device => device.geometry.coordinates))
      else if (sensors.length === 1)
        feature = sensors[0]
      else
        return null

    }
    return { ...buffer(feature, 0.07), properties }
  }
}

export default DeviceLayerUtils
