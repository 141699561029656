<p-button [label]="label" [icon]="icon" [type]="type" [iconPos]="iconPos" [badge]="badge" [badgeClass]="badgeClass"
  [attr.data-cy]= "dataCy" 
  [disabled]="disabled" [style]="style" 
  styleClass="dryad-button {{loading ? 'loading' : ''}}
   {{showCancelButton ? 'cancel-button' : ''}}
   {{styleClass}}"
  (onClick)="onClick.emit($event)" (onFocus)="onFocus.emit($event)" (onBlur)="onBlur.emit($event)">
  <div *ngIf="loading" class="loader">
    <dryad-logo-spinner></dryad-logo-spinner>
  </div>
</p-button>
