import { EnergyStatus, SensorNode, SignalStatus } from "../../../../../libs/shared/state/src"

export const showAlertIndicator =
    (device: any): boolean => device.connectivity && device.connectivity  <= -130
    || device.signal_status === SignalStatus.OFFLINE
    || device.energy_status === EnergyStatus.ALERT

export const showWarningIndicator = (device: any): boolean  => device.firmware_version === 2
    ||  ((device.connectivity && device.connectivity <= -120 ) &&
        (device.connectivity &&  device.connectivity > -130))
    || device.energy_status === EnergyStatus.WARNING



export const isActive = (signalStatus?: SignalStatus): boolean => [SignalStatus.ACTIVE, SignalStatus.PENDING].includes(signalStatus)
&& signalStatus !== SignalStatus.FIRE_ALERT
export const isOffline = (signalStatus?: SignalStatus): boolean => [SignalStatus.OFFLINE, undefined].includes(signalStatus)
export const isCalibrating = (sensor: SensorNode): boolean => [SignalStatus.ACTIVE, SignalStatus.PENDING].includes(sensor.signal_status)
&& sensor.is_calibrated === false
