import { Component, ViewEncapsulation } from "@angular/core"
import { FieldArrayType, FormlyFormBuilder } from "@ngx-formly/core"

@Component({
  selector: "dryad-web-app-dryad-formly-field-repeat",
  templateUrl: "./dryad-formly-field-repeat.component.html",
  styleUrls: ["./dryad-formly-field-repeat.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DryadFormlyFieldRepeatComponent extends FieldArrayType {
  constructor(private builder: FormlyFormBuilder) {
    super(builder)
  }

  addFn() {
    if (!this.to.addBtn.onClick)
      this.add()
    else
      this.to.addBtn.onClick()
  }
}
