<button
  *ngIf="floatingPanel?.type === 'button'"
  pButton
  pRipple
  type="button"
  class="{{floatingPanel.buttonCssClass}}"
  data-cy="add-button"
  [label]="floatingPanel?.buttonLabel"
  (click)="floatingPanel?.command()"
>
</button>
