import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Button } from 'primeng/button';

@Component({
  selector: 'dryad-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent extends Button {
  @Input() dataCy = "button"
  @Input() showCancelButton: boolean

  constructor() {
    super()
  }
}
