export class LayerMap {
  overlays: any[] | undefined

  map: google.maps.Map | undefined

  zoom = 4

  options: any = {
    mapTypeControl: false
  }
}
