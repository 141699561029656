<div *ngIf="deviceType === 'Gateway'">
<app-gateway-tech-info [gatewayTechInfo]="gatewayTechInfo"></app-gateway-tech-info>
</div>
<div *ngIf="deviceType === 'SensorNode'">
<app-sensor-node-tech-info *ngIf="sensorTechInfo"  [sensorTechInfo]="sensorTechInfo"></app-sensor-node-tech-info>
</div>

<div *ngIf="isLoading" class="flex justify-content-center align-items-center">
    <dryad-logo-spinner class="color-primary" style="width: 5rem"></dryad-logo-spinner>
</div>
