import { FormlyExtension, FormlyFieldConfig } from "@ngx-formly/core"

export const DataCyExtension: FormlyExtension = {
  prePopulate: function(field: FormlyFieldConfig) {
    const key = field?.key?.toString() || ""
    // @ts-ignore
    field.templateOptions = {
      ...(field.templateOptions || {}),
      attributes: {
        "data-cy": key,
      },
    }
  },
}
