import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { MessageService } from "primeng/api"
import { AuthService } from "@dryad-web-app/shared/auth"
import { DryadRoutes, ObservableState, USER_UPDATE, UserDetails } from "@dryad-web-app/shared/state"
import { SiteService, UserService } from "@dryad-web-app/shared/data-access"

@Component({
  selector: "auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"]
})
export class AuthCallbackComponent implements OnInit {
  error: string | undefined
  showDialog = false

  constructor(
    private oss: ObservableState,
    private userService: UserService,
    private siteService: SiteService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    console.log("init auth callback");

    this.userService.me().subscribe(
      (me: UserDetails) => {
        if (me) {
          console.log("valid me");

          this.oss.dispatch({
            type: USER_UPDATE,
            payload: me
          })
          this.route.queryParams.subscribe((params) => {
            if (params.redirect_path && params.redirect_path !== "") {
              console.log("navigate redirect_path", params.redirect_path);

              this.router.navigate([params.redirect_path])
            } else {
              console.log("redirect to /");

              this.router.navigate(["/"])
            }
          })
        }
      },
      () => {
        this.messageService.add({
          severity: "error",
          summary: "Login Error",
          detail: "User Authentication Failure"
        })
        this.route.queryParams.subscribe((params) => {
          const queryParams: Params = {
            reason: "login_failed",
            service: "engine"
          }
          if (params.redirect_path) queryParams.redirect_path = params.redirect_path
          this.router.navigate(["/" + DryadRoutes.LOGIN], { queryParams })
        })
      }
    )
  }
}
