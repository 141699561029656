import { Component, OnInit } from "@angular/core"
import {
  DropDownOptions,
  GateSettingActions,
  Gateway,
  GatewaySetting, GatewayTypes,
} from "@dryad-web-app/shared/state"
import { DirectusApiService, GatewayService } from "@dryad-web-app/shared/data-access"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { FormFactoryService } from "@dryad-web-app/ui/dryad-form"
import { UntypedFormGroup } from "@angular/forms"
import { MessageService } from "primeng/api"

@Component({
  selector: "app-gateway-settings",
  templateUrl: "./gateway-settings.component.html",
  styleUrls: [ "./gateway-settings.component.scss" ],
})
export class GatewaySettingsComponent implements OnInit {
  gatewayList: Gateway[]
  selectedGatewayList: Gateway[] = []
  actions: DropDownOptions[] = []
  selectedAction: DropDownOptions
  model: any = {}
  gatewaySettingFields: FormlyFieldConfig[] = []
  form = new UntypedFormGroup({})
  loading = false
  // items: DropDownOptions[] = []
  // selectedItem: DropDownOptions

  constructor(
    private gatewayService: GatewayService,
    private formFactoryService: FormFactoryService,
    private directusApiService: DirectusApiService,
    private messageService: MessageService,
  ) {
    this.actions.push(new DropDownOptions(GateSettingActions.BULK_UPDATE, "Update"))
    this.actions.push(new DropDownOptions(GateSettingActions.DOWNLOAD_SETTINGS, "Download Settings"))
  }

  ngOnInit(): void {
    this.loadGateways()
  }

  loadGateways(): void {
    this.directusApiService.getGateways().subscribe(gateways => {
      this.gatewayList = gateways
    })
  }

  handelChangeAction(): void {
    if (this.selectedAction.value === GateSettingActions.DOWNLOAD_SETTINGS) this.downloadFile()
    if (this.selectedAction.value === GateSettingActions.BULK_UPDATE) this.renderUpdateForm()
  }

  downloadFile(): void {
    const gatewaySettingList: GatewaySetting[] = []
    this.gatewaySettingFields = []
    this.selectedGatewayList.forEach(gateway => {
      const gatewaySetting: GatewaySetting = new GatewaySetting()
      gatewaySetting.id = gateway.id
      gatewaySetting.name = gateway.name
      gatewaySetting.eui = gateway.eui
      gatewaySetting.ns_gateway_id = gateway.ns_gateway_id
      gatewaySetting.freq_max = Number(gateway.frequency_max + "000000")
      gatewaySetting.freq_min = Number(gateway.frequency_min + "000000")
      gatewaySetting.hw_version = gateway.hw_version
      if (gateway.gateway_type !== GatewayTypes.MESH_GATEWAY)
        gatewaySetting.mac_address = gateway.mac_address

      gatewaySettingList.push(gatewaySetting)
    })

    const sJson = JSON.stringify(gatewaySettingList)
    const element = document.createElement("a")
    element.setAttribute("href", "data:text/json;charset=UTF-8," + encodeURIComponent(sJson))
    element.setAttribute("download", "settings.json")
    element.style.display = "none"
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  renderUpdateForm(): void {
    this.gatewaySettingFields = this.formFactoryService.getGatewaySettingsField()
  }

  updateSettings(): void {
    const gatewayList: Gateway[] = []
    this.selectedGatewayList.forEach(gateway => {
      gateway.frequency_min = this.model.frequency_min
      gateway.frequency_max = this.model.frequency_max
      gateway.hw_version = this.model.hw_version
      gatewayList.push(gateway)
    })
    this.loading = true
    this.directusApiService.batchUpdateGateways(gatewayList).subscribe(() => {
      this.loading = false
      // this.selectedGatewayList = []
      this.messageService.add({
        summary: "Updated",
        severity: "success",
      })
    }, () => this.loading = false)
  }
}

