import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"

import { Site, SiteDeviceFilterSettings } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class DataService {
  public panelData = new BehaviorSubject(false)
  currentPanelState = this.panelData.asObservable()

  public siteDeviceFilterSettings = new BehaviorSubject({})
  currentData = this.siteDeviceFilterSettings.asObservable()

  public selectedSiteData = new BehaviorSubject([])
  currentSiteData = this.selectedSiteData.asObservable()

  constructor() {}

  changePanelState(toggle: boolean) {
    this.panelData.next(toggle)
  }

  setSettingsData(data: SiteDeviceFilterSettings) {
    this.siteDeviceFilterSettings.next(data)
  }

  getSiteData(site: Site[]) {
    // @ts-ignore
    this.selectedSiteData.next(site)
  }
}
