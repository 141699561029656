import { Injectable } from "@angular/core"
import { datadogRum } from "@datadog/browser-rum"
import { plainToClass, plainToInstance } from "class-transformer"
import { Observable, of } from "rxjs"
import { catchError, map, mergeMap } from "rxjs/operators"
import { RoleService } from "./role.service"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { Client, Country, Credentials, Role, User, UserDetails } from "@dryad-web-app/shared/state"
import {  ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { serviceRootUrl } from "apps/silvanet-web/src/app/utils/api-utils"

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

export interface UserSettings {
  unitSystem: "metric" | "imperial"
  dateFormat: "yyyy-MM-dd" | "dd/MM/yyyy"
  timeFormat: "HH:mm:ss" | "hh:mm:ss a"
}
export const DEFAULT_SETTINGS: UserSettings = {
  unitSystem: "metric",
  dateFormat: "dd/MM/yyyy",
  timeFormat: "HH:mm:ss"
}

@Injectable({
  providedIn: "root",
})
export class UserService extends ApiHttpService {
  static readonly SETTINGS_KEY = "user_settings"
 
  private readonly USERS_API_PATH = "users"
  environment: any

  constructor(private roleService: RoleService) {
    super()
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
  }

  me(): Observable<UserDetails> {
    return this.get(this.USERS_API_PATH + "/me").pipe(
      map((response) => plainToClass(User, response as Object)),
      mergeMap((me: User) =>
        this.roleService.find(me.role).pipe(
          map((response) => plainToClass(Role, response as Object)),
          map((role: Role) => {
            me.roleName = role.name
            return me
          }),
        ),
      ),
      map((user: User) => {
        datadogRum.setUser({
          id: user.id,
          role: user.roleName
        })
        return new UserDetails(user, "")
      }),
    )
  }

  find(userId: string): Observable<User> {
    return this.get(this.USERS_API_PATH + "/" + userId).pipe(
      map((response) => plainToClass(User, response as Object)),
    )
  }

  list(): Observable<User[]> {
    return this.get<User[]>(this.USERS_API_PATH).pipe(
      map((response) => plainToClass(User, response)),
    )
  }

  listUsersByClient(userId: string): Observable<User[]> {
    return this.get<User[]>(this.USERS_API_PATH + "/byclient/" + userId).pipe(
      map((response) => plainToClass(User, response))
    )
  }


  listClientsByUserId(userId: string): Observable<Client[]> {
    return this.get<Client[]>(this.USERS_API_PATH + "/clients/" + userId)
  }

  create(user: User): Observable<User> {
    delete user.id
    return this.post<User>(this.USERS_API_PATH, user).pipe(
      map((response) => plainToClass(User, response)),
    )
  }

  update(user: User): Observable<User> {
    const userId = user.id
    delete user.id
    // @ts-ignore
    delete user.email
    return this.put<User>(this.USERS_API_PATH + "/" + userId, user).pipe(
      map((response) => plainToClass(User, response)),
    )
  }

  updatePassword(credentials: Credentials): Observable<boolean> {
    return this.put<User>(
      this.USERS_API_PATH + "/password/me",
      credentials,
    ).pipe(map((response) => true))
  }

  deleteUser(userId: string): Observable<boolean> {
    return this.delete(this.USERS_API_PATH + "/" + userId)
  }

  logout(): Observable<boolean> {
    datadogRum.clearUser()
    const configUrl = `${forestFloorBaseUrl}/_allauth/browser/v1/config`
    fetch(configUrl, {
      method: 'GET',
      credentials: 'include'
    }).then(() => {
      const csrftoken = document.cookie?.split(";").map(s => s.trim()).filter(s => s.startsWith("csrftoken=")).map(s => s.split("=")[1])[0]
      fetch(`${forestFloorBaseUrl}/_allauth/browser/v1/auth/session`, { method: "DELETE", credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken
        }
      })
      localStorage.removeItem("forestfloor_token")
    })
    return this.post(this.USERS_API_PATH + "/logout/me", {}).pipe(
      map(() => true),
      catchError(() => of(true)),
    )
  }

  getFullUser(userId: string): Observable<any> {
    return this.get<any>("users/" + userId).pipe(map((response) => {
      return plainToClass(User, response.data)
    }))
  }

  setLocalSettings = (settings: Partial<UserSettings>): void => {
    localStorage.setItem(UserService.SETTINGS_KEY, JSON.stringify({
      ...DEFAULT_SETTINGS,
      ...settings
    }))
  }

  getDefaultSettings = (): UserSettings => {
    const preferedLanguage = navigator.language
    const settings: UserSettings = {...DEFAULT_SETTINGS}

    if (preferedLanguage === 'en-US') {
      settings.unitSystem = "imperial"
      settings.dateFormat = "yyyy-MM-dd"
    }

    return settings
  }

  getStoredSettings = (): string | null => {
    return localStorage.getItem(UserService.SETTINGS_KEY)
  }

  getLocalSettings = (): UserSettings => {
    const storedSettings = this.getStoredSettings()

    if (storedSettings) {
      try {
        return this.getDefaultValues(JSON.parse(storedSettings))
      } catch (error) {
        this.setLocalSettings(DEFAULT_SETTINGS)
        return DEFAULT_SETTINGS
      }
    }
    return DEFAULT_SETTINGS
  }
  /**
   * Get default if not available
   */
  getDefaultValues(availableSetting:UserSettings): UserSettings {
    for (const key in DEFAULT_SETTINGS) {
      if (!availableSetting.hasOwnProperty(key)) {
        // @ts-ignore
        availableSetting[key] = DEFAULT_SETTINGS[key]
      }
    }
    return availableSetting
  }

  getCountriesList(): Observable<Country[]> {
    return this.get<Country[]>("countries").pipe(
      map((response) => plainToInstance(Country, response)),
    )
  }
}
