import { ModuleWithProviders } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import {
  authRoutes,
  DirectusAuthGuard,
  publicRoutes,
  AppNotfoundComponent,
  LoginComponent,
} from "@dryad-web-app/shared/auth"
import { DryadRoutes } from "@dryad-web-app/shared/state"
import {
  MainLayoutComponent,
  PublicLayoutComponent,
} from "@dryad-web-app/ui/dryad-ui"
import { DashboardComponent } from "./dashboard/dashboard.component"
import { ExperimentSessionsComponent } from "./experiments/experiment-sessions/experiment-sessions.component"
import { SiteDeviceComponent } from "./filter/site-device/site-device.component"
import { GatewayComponent } from "./gateway/gateway.component"
import { HomeComponent } from "./pages/home/home.component"
import { MePageComponent } from "./pages/me-page/me-page.component"
import { UserSettingsComponent } from "./pages/me-page/user-settings/user-settings.component"
import { SensorNodeListComponent } from "./sensor-nodes/sensor-node-list.component"
import { UplinkMessagesComponent } from "./uplink-messages/uplink-messages.component"
import { MAP_ROUTES } from "./device-map/device-map.routes"
import { GatewaySettingsComponent } from "./settings/gateway-settings/gateway-settings.component"
import { UserManagementComponent } from "./user management/user-management table/user-management.component"
import { UserCreateComponent } from "./user management/user-create/user-create.component"

// default path changed for development purposes
export const routes: Routes = [
  { path: "", canActivate: [ DirectusAuthGuard ], component: HomeComponent },
  { path: DryadRoutes.LOGIN, component: LoginComponent },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      ...authRoutes,
    ],
  },
  {
    path: "",
    canActivate: [ DirectusAuthGuard ],
    component: MainLayoutComponent,
    children: [
      { path: DryadRoutes.DASHBOARD, component: DashboardComponent },
      {
        path: DryadRoutes.SITES,
        loadChildren: () => import("./sites/sites.module").then(m => m.SitesModule),
      },
      {
        path: DryadRoutes.MAP,
        children: MAP_ROUTES,
      },
      {
        path: DryadRoutes.ALERT_CENTER,
        loadChildren: () => import("./alerts/alerts.module").then(m => m.AlertsModule),
      },
      {
        path: DryadRoutes.DEVICE_MANAGEMENT_HOME,
        loadChildren: () => import("./device-management/device-management.module").then(m => m.DeviceManagementModule),
      },
      { path: DryadRoutes.GATEWAYS, component: GatewayComponent },
      { path: DryadRoutes.PROJECTS, component: SensorNodeListComponent },
      { path: DryadRoutes.DEVICES, component: SiteDeviceComponent },
      { path: DryadRoutes.USER_MANAGEMENT, component: UserManagementComponent },
      { path: DryadRoutes.USER_CREATE, component: UserCreateComponent },
      { path: DryadRoutes.USER_EDIT, component: UserCreateComponent },
      { path: DryadRoutes.SENSOR_DATA, component: UplinkMessagesComponent },
      { path: DryadRoutes.EXPERIMENTS, component: ExperimentSessionsComponent },
      { path: DryadRoutes.NOT_FOUND, component: AppNotfoundComponent },
      { path: DryadRoutes.USER, component: MePageComponent },
      { path: DryadRoutes.USER_SETTINGS, component: UserSettingsComponent },
      { path: DryadRoutes.SENSOR_NODES, component: SensorNodeListComponent },
      { path: DryadRoutes.GATEWAY_SETTINGS, component: GatewaySettingsComponent },
    ],
  },
]
routes.push(...publicRoutes)
export const appRoutes: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
  })
