<p-card styleClass="border-round-s">
  <div class="flex align-center space-between header">
    <h2 class="p-card-title margin-0 full-width">
        Update / Download setting
    </h2>
  </div>
  <div class="col-12 md:col-1 lg:col-12 ">
    <p-card [styleClass]="'white-bg boarder-md'">
      <dryad-web-app-pick-list
        [source]="gatewayList"
        [target]="selectedGatewayList"
        [showSourceControls]="false"
        [showTargetControls]="false"
        [metaKeySelection]="true"
        [showSourceFilter]="true"
        [showTargetFilter]="true"
        [filterBy]="'name'"
        [dragdrop]="true"
        [styleClass]="'white-bg'"
        #pl
        sourceFilterPlaceholder="Type gateway’s ID"
        targetFilterPlaceholder="Type gateway’s ID"
        sourceHeader="Available Gateways" targetHeader="Selected Gateways">
        <ng-template let-device #itemTemplate>
          <label>{{device.name}}</label>
        </ng-template>
      </dryad-web-app-pick-list>
    </p-card>
  </div>

  <div class="grid  margin-0">
    <div class="col-12 md:col-12 lg:col-12 " *ngIf="selectedGatewayList?.length > 0">
      <form>
        <p-card [styleClass]="'white-bg boarder-md'">
          <div class="grid">
            <div class="col-12 md:col-12 lg:col-12 ">
              <div class="">
                <p-dropdown [options]="actions" [(ngModel)]="selectedAction"
                            [styleClass]="'full-width'"
                            optionLabel="label" name="selectCommand"
                            placeholder="Select action"
                            [filter]="true" filterBy="label"
                            (onChange)="handelChangeAction()"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12 md:col-12 lg:col-12 ">
              <div class="white-bg">
                <dryad-web-app-dryad-formly
                  *ngIf="gatewaySettingFields.length > 0"
                  [fields]="gatewaySettingFields"
                  [model]="model"
                  [showCancelButton]="false"
                  [showSubmitButton]="false"
                  [form]="form"
                ></dryad-web-app-dryad-formly>
              </div>
            </div>
            <div class="col-12 md:col-12 lg:col-12 ">
              <div
                class="grid p-nogutter justify-content-center card-buttons">
                <dryad-button
                  label="Update"
                  data-cy="btn-submit"
                  class="button-small-filled"
                  [loading]="loading"
                  (click)="updateSettings()"
                ></dryad-button>
              </div>
            </div>
          </div>
        </p-card>
      </form>
    </div>
  </div>
</p-card>

