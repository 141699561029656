import { Injectable } from "@angular/core"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { TTNGateway } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root",
})
export class GatewayTtnService extends ApiHttpService {
  private readonly GATEWAY_COLLECTION = "gateways"

  constructor() {
    super(ApiHttpService.ENGINE_API_BASE_URL)
  }

  gateway(id: string): Observable<TTNGateway> {
    return this.get("gateways/ttn/" + id).pipe(
      map((response) => plainToClass(TTNGateway, response as Object))
    )
  }
}
