import { Expose, Type } from "class-transformer"
import { DeviceState } from "./ui-state"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { IsInt, IsString, IsOptional, IsArray } from 'class-validator'
import { DeviceTypes, EnergyStatus, GatewayTypes, SignalStatus } from "./enums"

export class Role {
  constructor(
    public name: string,
    public admin_access: boolean,
    public app_access: boolean,
    public id?: string,
  ) {
  }
}

export class User {
  constructor(
    public first_name: string,
    public last_name: string,
    public email: string,
    public userDetails?: UserPermissionDetail,
    public role?: string,
    public roleName?: string,
    public id?: string,
    public token?: string,

    public client?: Client,
    public client_id?: string,
    public reseller_flag?: number,
  ) {
  }

  name() {
    return this.first_name + " " + this.last_name
  }

  get sortableName() {
    return `${this.last_name} ${this.first_name}`
  }
}

export interface UserRole {
  name: string;
  value: string;
}

export class Credentials {
  constructor(public oldPassword: string, public newPassword: string) {
  }
}

export class UserDetails {
  @Type(() => UserDetails) public user: User

  constructor(user: User, public token: string) {
    this.user = user
  }
}

export class Token {
  constructor(public access_token: string, public expires: number) {
  }
}

export class TokenData {
  @Type(() => Token) public data: Token | undefined
}


export class SensorValue {
  constructor(public smType: string, public value: number) {
  }
}

export enum SensorMeasurementType {
  None = "",
  Humidity = "Humidity",
  Smoke = "Smoke",
  Temperature = "Temperature",
  Pressure = "Pressure",
}

export class SensorMeasurement {
  constructor(
    public smType: SensorMeasurementType,
    public minValue: number,
    public maxValue: number,
  ) {
  }
}
export enum SensorAlertLevel {
  none = 0,
  warning = 1,
  alert = 2,
}


export class SensorNode {
  constructor(
    public name: string,
    public sensor_node_type: number,
    public site: number | Site,
    public active: boolean,
    public latitude: number,
    public longitude: number,
    public notes: string,
    public ns_end_device_id?: string,
    public id?: number,
    public lastSignal?: Date,
    public state: DeviceState = DeviceState.INACTIVE,
    public serial_id?: string,
    public device_address?: string,
    public eui?: string,
    public firmware_version?: number,
    public ml_version?: number,
    public signal_status?: SignalStatus,
    public type?: DeviceTypes,
    public firmware_label?: string,
    public battery?: number,
    public connectivity?: number,
    public energy_status?: EnergyStatus,
    public is_calibrated?: boolean,
    public alert_level?: SensorAlertLevel,
  ) {
  }
}

export class SensorNodeHealthDetails {
  constructor(
    public id: number,
    public ns_end_device_id: string,
    public signal_status: SignalStatus,
    public battery: number,
    public connectivity: number
  ){}
}

export interface SensorDetails {
  name: string
  latitude:  string
  longitude: string
}

export class DeviceMetadata {
  @Expose() name: string | undefined
  @Expose() notes: string | undefined
  @Expose() id: number | undefined
}

export class DeviceLocationData {
  @Expose() latitude: number | undefined
  @Expose() longitude: number | undefined
  @Expose() id: number | undefined
}

export class SensorNodeDetails {
  constructor(
    public activationMode: string,
    public devEUI: string,
    public devAddr: string,
    public lorawanVersion: string,
    public lorawanPhyVersion: string,
    public frequencyPlanId: string,
    public frameNetworkSIntKey: string,
    public appSKey: string,
    public appKey: string,
    public nwkKey: string,
    public joinEUI: string = "0000000000000000",
    public macSupports32BitFCnt: boolean = true,
    public supportsClassC: boolean = false,
    public supportsClassB: boolean = false,
    public resetsFrameCnt: boolean = false,
    public pingSlotPeriodicity: string = "PING_EVERY_4S",
    public skipPayloadCrypto: boolean = false,
    public networkServerKekLabel: string = "",
    public applicationServerKekLabel: string = "",
    public applicationServerId: string = "",
  ) {
  }
}

export class SensorNodeDataDetails {
  @Type(() => SensorNode) data: SensorNode
  @Type(() => SensorNodeDetails) details: SensorNodeDetails

  constructor(data: SensorNode, details: SensorNodeDetails) {
    this.data = data
    this.details = details
  }
}

export class TagType {
  constructor(public type_id: string) {
  }
}

export class TagLabel {
  @Type(() => TagType) type: TagType

  constructor(public label: string, type: TagType) {
    this.type = type
  }
}

export class TagLabelId {
  @Type(() => TagType) tag_label_id: TagLabel

  constructor(tag_label_id: TagLabel) {
    this.tag_label_id = tag_label_id
  }
}

export class Experiment {
  constructor(
    public start_time: string,
    public end_time: string,
    public venue: number,
    public burn_material: number,
    public burn_material_amount: string,
    public sensor_hotplate_distance: number,
    public hotplate_start: string,
    public hotplate_temp: string,
    public notes: string,
    public id?: number,
  ) {
  }
}

export class ExperimentDataRecord {
  @Type(() => TagLabelId) tag_labels: TagLabelId[]

  constructor(
    public start_time: string,
    public end_time: string,
    public log_file: string,
    public specimen: string,
    public specimen_class: string,
    public data_type: string,
    public sensor_node: number,
    tag_labels: TagLabelId[],
    public id?: number,
  ) {
    this.tag_labels = tag_labels
  }
}

export class Gateway {
  constructor(
    public name: string,
    public gateway_type: GatewayTypes,
    public site: number,
    public notes: string,
    public latitude: number,
    public longitude: number,
    public ns_gateway_id?: string,
    public id?: number,
    public lastSignal?: Date | string,
    public state: DeviceState = DeviceState.ACTIVE,
    public eui?: string,
    public mac_address?: string,
    public frequency_min?: string,
    public frequency_max?: string,
    public hw_version?: string,
    public fw_version?: string,
    public gwid?: number,
    public serial_id?: string,
    public signal_status: SignalStatus = SignalStatus.OFFLINE,
    public type?: DeviceTypes,
    public firmware_label? : string,
    public firmware_version?: number,
    public battery?: number,
    public energy_status?: EnergyStatus,
  ) {
  }
}


export class GatewayDetails {
  constructor(
    public gatewayEUI: string,
    public frequencyPlanId: string,
    public scheduleAnytimeDelayMillis: number = 530,
    public autoUpdate: boolean = false,
    public updateLocationFromStatus: boolean = false,
    public scheduleDownlinkLate: boolean = false,
    public statusPublic: boolean = false,
    public locationPublic: boolean = false,
    public enforceDutyCycle: boolean = true,
  ) {
  }
}

export class GatewayDataDetails {
  @Type(() => Gateway) data: Gateway
  @Type(() => GatewayDetails) details: GatewayDetails

  constructor(data: Gateway, details: GatewayDetails) {
    this.data = data
    this.details = details
  }
}

export class EntityType {
  constructor(public id: number, public label: string) {
  }
}

export class Site {
  constructor(
    public network_server: number,
    public name: string,
    public client_id?: number,
    public latitude?: number,
    public longitude?: number,
    public ns_application_id?: string,
    public id?: number,
    public serial_id?: string,
    public base_site?: Site,
    public region?: Region,
    public region_id?: number,
    public date_created?: string,
    public date_updated?: string,
    public user_is_reseller?: number,
    public user_client_id?: number,
    public status?: string,
  ) {
  }
}

export class SiteStatus {
  constructor(
    public id: number,
    public name: string,
    public centerLatitude: number,
    public centerLongitude: number,
    public fireAlertStatus: number,
    public lastMessageTimestamp: string,
    public batteryLevel: number
  ) {
  }
}

export class SensorNodeStatus {
  constructor(
    public id: number,
    public name: string,
    public latitude: number,
    public longitude: number,
    public siteId: number,
    public fireAlertStatus: number,
    public lastMessageTimestamp: string,
    public batteryLevel: number,
    public lastFireAlertTimestamp?: string,
    public sensorNodeEndDeviceId? :string,
  ) {
  }
}

export class SiteDeviceAggregate {
  constructor(
    public nbOfDevices: number,
    public minLatitude: number,
    public maxLatitude: number,
    public centerLatitude: number,
    public minLongitude: number,
    public maxLongitude: number,
    public centerLongitude: number,
    public radius: number,
    public area: number,
    public lastUpMessage: UpMessage,
  ) {
    this.lastUpMessage = lastUpMessage
  }
}

export class SiteAggregate {
  @Type(() => SiteDeviceAggregate) sensorNodeDetails:
    | SiteDeviceAggregate
    | undefined
  @Type(() => SiteDeviceAggregate) gatewayDetails:
    | SiteDeviceAggregate
    | undefined

  constructor(
    sensorNodeDetails?: SiteDeviceAggregate,
    gatewayDetails?: SiteDeviceAggregate,
  ) {
    this.sensorNodeDetails = sensorNodeDetails
    this.gatewayDetails = gatewayDetails
  }
}

export class SiteDataAggregate {
  @Type(() => Site) data: Site
  @Type(() => SiteAggregate) aggregate: SiteAggregate | undefined

  constructor(data: Site, aggregate?: SiteAggregate) {
    this.data = data
    this.aggregate = aggregate
  }
}

export class SiteContact {
  constructor(
    public name: string,
    public phone: string,
    public email: string,
  ) {
  }
}

export class SiteDataDetails {
  @Type(() => Site) data: Site
  @Type(() => SiteContact) contacts?: SiteContact[] | undefined

  constructor(data: Site, contacts?: SiteContact[]) {
    this.data = data
    this.contacts = contacts
  }
}

export class CreateSite {
  @Type(() => SiteDataDetails) siteDataDetails: SiteDataDetails

  constructor(siteDataDetails: SiteDataDetails) {
    this.siteDataDetails = siteDataDetails
  }
}

export class NetworkServer {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public mqtt_broker_url: string,
    public api_base_url: string,
    public tenant: string,
  ) {
  }
}

export class FrequencyPlan {
  constructor(public id: string, public name: string) {
  }
}

export class FrequencyPlans {
  @Type(() => FrequencyPlan) frequency_plans: FrequencyPlan[]

  constructor(frequency_plans: FrequencyPlan[]) {
    this.frequency_plans = frequency_plans
  }
}

export class MessageLocation {
  constructor(
    public latitude: number,
    public longitude: number,
    public altitude: number,
    public accuracy: number,
  ) {
  }
}

export class UpMessageGateway {
  @Type(() => MessageLocation) location?: MessageLocation

  constructor(
    public id: string,
    public eui: string,
    public rssi: number,
    public snr: number,
    public time?: string,
    public timestamp?: number,
    public channelRssi?: number,
    public signalRssi?: number,
    location?: MessageLocation,
  ) {
    this.location = location
  }
}

export class UpGateway {
  constructor(
    public messageType: string,
    public time: string,
    public id: number,
    public deviceId: string,
    public name: string,
    public gatewayType: string,
    public voltage: number
  ) {
  }
}

export class UpMessageDataRate {
  constructor(
    public bitrate?: number,
    public bandwidth?: number,
    public spreadingFactor?: number,
  ) {
  }
}

export class UpMessageEndDevice {
  @Type(() => MessageLocation) location?: MessageLocation
  @Type(() => MessageLocation) dataRate: UpMessageDataRate | undefined

  constructor(
    public id: string,
    public appId: string,
    public frequency?: number,
    public codingRate?: string,
    dataRate?: UpMessageDataRate,
    public devEui?: string,
    public joinEui?: string,
    public devAddr?: string,
    location?: MessageLocation,
    public receivedAt?: string,
  ) {
    this.dataRate = dataRate
    this.location = location
  }
}

export class UpMessage {
  @Type(() => UpMessageEndDevice) endDevice: UpMessageEndDevice
  @Type(() => UpMessageGateway) gateways: UpMessageGateway[]

  constructor(
    public confirmed: boolean,
    public frameCount: number,
    public framePort: number,
    public payloadRaw: string,
    public time: string,
    public timeSource: string,
    endDevice: UpMessageEndDevice,
    gateways: UpMessageGateway[],
    public sensorValues: any,
    public sensorAlerts: SensorAlert,
    public simulated: boolean,
    public alertUpdate: boolean,
    public receivedAt?: string,
    public airtime?: string,
    public siteId?: string,
    public sensorId?: string,
    public siteName?: string,
    public sensorName?: string,
  ) {
    this.endDevice = endDevice
    this.gateways = gateways
  }
}

export class ResolvedAlert {
  constructor(
    public entity_type: number,
    public entity_ids: number[],
    public resolved_alert_type: string,
    public id?: string,
    public entity_id?: number,
  ) {
  }
}

export class ItemsDataObj {
  constructor(public data: any[]) {
  }
}

export class ItemDataObj {
  constructor(public data: any) {
  }
}

export class Device {
  constructor(
    public name: string,
    public site: string,
    public deviceType: string,
    public deviceData: any,
  ) {
  }
}

export class GlobalDefaults {
  constructor(public network_server: number) {
  }
}

export class SiteDefaults {
  constructor(
    public site: number,
    public network_server: number,
    public sensor_node_type: number,
    public activation_mode: number,
    public sensor_node_lorawan_mac_version: string,
    public sensor_node_lorawan_phy_version: string,
    public frequency_plan: string,
    public sensor_node_ns_id_prefix: string,
    public gateway_ns_id_prefix: string,
    public id?: number,
  ) {
  }
}

export class SiteDeviceFilterSettings {
  constructor(public deviceType: string[], public sites: string[]) {
  }
}

export class PacketObject {
  constructor(public data: Packet | Packet[]) {
  }
}

export class Packet {
  constructor(public name: string, public site: number, public assign_to?: string, public id?: number) {
  }
}

export class Deployment {
  constructor(public entity_type: number,
    public entity_id: any,
    public name: string,
    public planed_longitude: number,
    public planed_latitude: number,
    public deployment_status?: number,
    public packet?: number,
    public gateway_type?: number,
    public notes?: string,
    public id?: number,
    public uuid?: any,
    public changed?: boolean,
    public installed_longitude?: number,
    public installed_latitude?: number,
    public isManually?: boolean,
    public edited?: boolean,
    public serial_id?: string,
  ) {
  }
}

export class DeploymentObject {
  constructor(public data: Deployment | Deployment[]) {
  }
}

export class NetworkStatus {
  constructor(public isConnected: boolean) {
  }
}

export class FormlyFieldStatus {
  constructor(public field: FormlyFieldConfig, public type: string) {
  }
}

export class NSRole {
  constructor(
    public role: Role,
    public name: string,
    public network_server: NetworkServer,
    public api_key: string,
    public role_user: string,
    public id?: string,
    public ns_role_id?: number,
  ) {
  }
}

export class Client {
  constructor(
    /** @deprecated use `client` instead */
    public name: string,
    /** @deprecated use `clientId` instead */
    public id: number,
    public client: string,
    public clientId: number,
    public isReseller: number,
    public sites?: Site[],
  ) {
  }
}

export class UserPermissionDetail {
  constructor(
    public ns_role: NSRole[],
    public client: number,
    public country: number,
    public user: User,
    public phone_number?: string,
  ) {
  }
}

export class FileType {
  constructor(public id: number, public key: string, public label: string) {
  }
}

export class Region {
  constructor(public id: number, public frequency: number, public label: string) {
  }
}

export class LatLong {
  constructor(public latitude: number, public longitude: number) {
  }
}

export class DevicesTypeOptions {
  @Expose() type?: number
  @Expose() name?: string
  @Expose() icon?: string
  @Expose() key?: string
}

export interface AlertCount {
  phaseOne: number
  phaseTwo: number
}

export interface FormlyFieldConfigExtended extends FormlyFieldConfig {
  dataSource?: string
  path?: string
  filterQuery?: any

}

export interface FormlyFieldGroupExtended {
  fieldGroupClassName: string
  fieldGroup: FormlyFieldConfigExtended[]
}

export class FormConfig {
  constructor(public key: string,
    public type: string,
    public formDefinition: FormlyFieldGroupExtended[],
    public label: string,
    public executeService?: string,
    public command_type?: string) {
  }
}

export class DownLinkCommandConfig {
  constructor(public key: string,
    public type: string,
    public templateOptions: string,
    public formConfig: FormConfig,
  ) {
  }
}

export class DownLinkCommand {
  constructor(public key: string, public config: DownLinkCommandConfig) {
  }
}

export class CommandArg {
  constructor(public arg: string, public value: string) {
  }
}

export class CommandExecuteObject {
  constructor(public entityType: string, public entityIds: number[], public cmdKeys: string[], public args: CommandArg[], public withAck: boolean = false) {
  }
}

export class CommandTraceObject {
  constructor(public cmdTraceId: string, public sensorMsgTraceIds: SensorMessageTraceObject[], public cmdKeys: string[]) {
  }
}

export class SensorMessageTraceObject {
  constructor(public sensorId: string, public msgTraceId: string) {
  }
}


export class SensorAlert {
  constructor(public smoke: boolean,
    public wildfire: boolean,
    public polefire: boolean,
    public warning: boolean,
  ) {
  }
}

export class CommandTraceStatus {
  constructor(public deviceId: string,
    public summary: string,
    public msgType: string,
    public cmdTraceId: string,
    public msgTraceId: string,
    public status: boolean,
    public deviceName?: string,
    public time?: string
  ) {
  }
}

export class FuotaResponse {
  constructor(public requestStatus: string, public jobId: string, public msg: string, public stage: string) {
  }
}

export class MLFuotaRequest {
  @IsString()
  euId: string

  @IsString()
  gatewayId: string

  @IsString()
  fileName: any

  @IsString()
  sensorIds: string[]

  @IsInt()
  mg: number

  @IsString()
  version: string

  @IsInt()
  outputFileNumber: number

  @IsInt()
  dataFreq: number

  @IsString()
  tag: string[]

  @IsInt()
  frequency: number

}

export class SNFuotaRequest {
  @IsString()
  euId: string

  @IsString()
  gatewayId: string

  @IsString()
  gatewayEui: string

  @IsString()
  fileName: string

  @IsString()
  sensorIds: string[]

  @IsInt()
  dataFreq: number

  @IsInt()
  fragDelay: number

  @IsInt()
  dataRate: number

  @IsInt()
  siteId: number

  @IsString()
  version: string

  @IsInt()
  frequency: number

  @IsString()
  tag: string[]

  @IsString()
  fileTransferJobId: string

  @IsInt()
  gwId: number

}

export class GatewayFuotaRequest {
  @IsString()
  gatewayEui: string

  @IsArray()
  @IsInt()
  receivers: number[]

  @IsInt()
  siteId: number

  @IsInt()
  startDelay = 5000

  @IsInt()
  supremeMode = 0

  @IsInt()
  acknowledgement = 1

  @IsArray()
  @IsString()
  tag: string[]

  @IsString()
  label: string

  @IsInt()
  fileId = 101
}

export class FileTransferRequest {
  @IsString()
  gatewayEui: string

  @IsString()
  siteId: number

  @IsString()
  fileName: string

  @IsString()
  version: string

  @IsInt()
  fileId: number

  @IsOptional()
  @IsInt()
  part?: number
}

export class FileWaveRequest {
  @IsString()
  gatewayEui: string

  @IsString()
  siteId: number

  @IsInt()
  receivers: number[]

  @IsInt()
  fileId: number

  @IsInt()
  gwid: number

  @IsInt()
  sndPart = 0

  @IsInt()
  rcvPart = 0

  @IsString()
  version: string

  @IsInt() 
  type: string 
}

export class FileWaveStatusResponse {
  constructor(
    public gwid: number,
    public id: string,
    public gatewayEui: string,
    public fileId: number,
    public finishedInPer: number,
    public runningSts: number,
    public nbMg: number,
    public nbMgList: number[],
    public createdAt: string,
  ) { }
}
export class JobStatusResponse {
  constructor(
    public jobStatus: string,
    public id: string,
    public outputFileName: string,
    public gatewayEui: string,
    public createdAt: string,
    public updatedAt: string,
    public jobStageTracker: JobStage[],
    public sensorsStatus: JobSensorStatus,
    public sensorIds: string[],
    public tag: string[],
    public type: string,
    public fileName: string,
    public gwId: string,
    public mlId: string,
    public dataFrequency: number,
    public version: string,
    public fileId: number,
    public jobType: string,
    public siteId: string,
    public versionWithLabel: FirmwareFileDetails,
    public activateFallback?:  number,
    public receiversList?: number[],
    public runningStatus?: string,
    public jobResult? : string,
    public finishedInPercentage?: number,
    public successGateways?: string[],
  ) {
  }
}
export class JobStatusResponseList {
  constructor(public totalHits: number,
    public page: number,
    public size: number,
    public fuotaJobStatusResponseList: JobStatusResponse[]) {
  }
}
export class JobSensorStatus {
  constructor(
    public old_version: string,
    public new_version: string,
    public restarted: string,
  ) {
  }
}

export class JobStage {
  constructor(
    public stage: string,
    public status: boolean,
    public completedAt: string,
    public fragId: number,
  ) {
  }
}

export class DropDownOptions {
  constructor(public value: any, public label: string) {
  }
}

export class CommandHistorySummery {
  @Expose() label: string | undefined
  @Expose() tags: string[] | undefined
  @Expose() completedAt: number | undefined
  @Expose() userName: string | undefined
  @Expose() jobStatus: string | undefined
  @Expose() type: string | undefined
  @Expose() jobId: string | undefined
}

export class GatewaySetting {
  @Expose() id: number | undefined
  @Expose() name: string | undefined
  @Expose() ns_gateway_id: string | undefined
  @Expose() eui: string | undefined
  @Expose() mac_address: string | undefined
  @Expose() freq_min: number | undefined
  @Expose() freq_max: number | undefined
  @Expose() hw_version: string | undefined
}

export class FuotaHeader {
  @Expose() tti: string | undefined
  @Expose() gtw: string | undefined
}

export class DeviceFilter {
  [field: string]: {
    [operator: string]: any
  }
}

export class DirectusMeta {
  constructor(public filter_count: number, public total_count: number) {
  }
}

export class DirectusDeviceResponse {
  constructor(public data: SensorNode[] | Gateway[], public meta: DirectusMeta) {
  }
}

export class SiteFile {
  site: Site
  gateway: Gateway
  fileType: FileType
  fwVersion: string
  fwLabel: string
  fileId: number
  sendToGateway: boolean
  fileInChunks: boolean
  skipFallback: boolean
  skipDownload: boolean
  disableJobCheck: boolean
  version: number
  releaseNote: string
  siteFilter: DeviceFilter
  file: any
}

export class FirmwareFile {
  constructor(
    public type: number,
    public version: string,
    public file_id: number,
    public file_name: string,
    public release_note: string,
    public label: string,
    public file_path: string,
    public id?: number,
  ) {
  }
}

export class FirmwareFileDetails {
  constructor(
    public type: FirmwareFileType,
    public version: string,
    public file_id: number,
    public file_name: string,
    public release_note: string,
    public label: string,
    public file_path: string,
    public id: number,
    public site: FirmwareFileSiteDetails[],
  ) {
  }
}

export class FirmwareFileType {
  constructor(public id: number, public label: string, public key: string) {
  }
}

export class FirmwareFileSiteDetails {
  constructor(public site_id: FirmwareFileSite) {
  }
}

export class FirmwareFileSite {
  constructor(public name: string, public region: Region) {
  }
}


export class FirmwareCreateRequest {
  constructor(public region: number,
    public firmwareVersion: FirmwareFile) {
  }
}

export interface BGTopologyPayload {
  gatewayEui: string,
  siteId: string,
  type: string,
}

export class FileCopyRequest {
  constructor(public fwid: number,
    public siteId: number,
    public eui: string,
    public folderName: string,
    public fileId?: number,
  ) {
  }
}

export class JobStatusFilter {
  constructor(
    public fuotaType: string = "",
    public chunksType: boolean | undefined = undefined,
    public jobStatus: string = "",
    public jobTypes: string[] = [],
    public startDateTime: string = "",
    public endDateTime: string = "",
    public eui: string = "",
    public jobId: string = "",
    public siteId?: string,
  ) {
  }
}

export class JobField {
  constructor(
    public label: string,
    public key: string,
    public pipe?: any,
    public pipeFormat?: any[]
  ) {
  }
}

export class GatewaySensorTopology {
  constructor(
    public gatewayId: string,
    public gatewayEmbId: string,
    public gatewayEui: string,
    public sensorNodes: SensorTopology[]
  ) {
  }
}

export class SensorTopology {
  constructor(
    public sensorNodeDeviceId: string,
    public sensorId: number,
    public fwVersion?: string,
    public mlVersion?: string
  ) {
  }
}

export class FirmwareVersion {
  constructor(public id: number,
    public version: string,
    public label: string,
  ) { }
}
export class FUOTAParams {
  constructor(
    public dataFreq: number,
    public dataRate: number,
    public fragDelay: number,
  ) { }
}

export class Country {
  constructor(
    public id: number,
    public name: string,
  ) { }
}
