import { GatewayTypes } from "@dryad-web-app/shared/state"
import { FEATURE_COLORS, FeatureState } from  "./utils"

const STATE_LOOKUP: Record<string, [FeatureState, boolean]> = {
  "0-false-false": [FeatureState.Offline, false],
  "0-true-false": [FeatureState.Offline, false],
  "0-false-true": [FeatureState.Active, false],
  "0-true-true": [FeatureState.Offline, true],
  "1-false-false": [FeatureState.FireWarning, false],
  "1-true-false": [FeatureState.FireWarning, false],
  "1-false-true": [FeatureState.FireWarning, false],
  "1-true-true": [FeatureState.FireWarning, false],
  "2-false-false": [FeatureState.Fire, false],
  "2-true-false": [FeatureState.Fire, false],
  "2-false-true": [FeatureState.Fire, false],
  "2-true-true": [FeatureState.Fire, false],
}

/* eslint-disable max-len */
const createMarkerImage = (type: string, state: FeatureState): HTMLImageElement => {
  const color = FEATURE_COLORS[state]
  let icon = ""
  if (type === "sensor") {
    if (state === FeatureState.Fire)
      icon = "<path d=\"M 68.198 62.075 C 66.875 61.739 54.788 81.049 59.293 103.772 C 60.44 109.542 62.739 121.127 73.167 129.569 C 87.128 140.855 104.221 137.305 106.519 136.777 C 109.065 136.224 123.954 132.599 131.374 119.234 C 132.769 116.733 136.494 109.805 134.903 101.691 C 133.74 95.755 129.966 90.358 128.688 90.753 C 127.992 90.967 128.451 92.746 127.645 96.327 C 127.316 97.824 125.978 103.918 123.503 104.172 C 121.836 104.341 119.878 101.702 119.16 99.217 C 118.083 95.493 119.822 92.294 120.827 89.722 C 125.09 78.669 120.273 67.044 119.582 65.37 C 116.164 57.097 109.097 51.338 107.776 52.155 C 107.107 52.572 108.292 54.431 108.192 57.938 C 108.051 62.711 105.577 68.432 102.8 68.667 C 100.03 68.908 96.735 63.741 95.565 58.969 C 93.498 44.838 104.077 32.083 103.026 30.486 C 100.863 28.16 91.923 31.349 86.655 37.091 C 80.134 44.179 80.057 54.338 80.008 57.121 C 79.981 60.449 80.447 63.171 80.216 70.121 C 79.986 77.187 79.343 79.068 77.935 79.826 C 75.871 80.934 72.464 79.441 70.48 77.35 C 65.432 72.025 69.118 62.31 68.198 62.075 Z\" style=\"fill: rgb(255, 255, 255);\"></path>"
    else if (state === FeatureState.FireWarning)
      icon = "<path d=\"M 96.935 86.318 C 99.118 86.318 100.888 84.548 100.888 82.365 C 100.888 82.365 100.888 75.776 100.888 72.482 C 100.888 69.189 100.888 65.894 100.888 62.6 C 100.888 60.417 99.118 58.647 96.935 58.647 C 94.752 58.647 92.982 60.417 92.982 62.6 C 92.982 69.189 92.982 82.365 92.982 82.365 C 92.982 84.548 94.752 86.318 96.935 86.318 Z M 96.935 100.153 C 99.118 100.153 100.888 98.383 100.888 96.2 C 100.888 94.017 99.118 92.247 96.935 92.247 C 94.752 92.247 92.982 94.017 92.982 96.2 C 92.982 98.383 94.752 100.153 96.935 100.153 Z M 91.159 30.421 C 92.606 29.582 94.769 29 96.441 29 C 98.113 29 100.277 29.582 101.723 30.421 C 103.168 31.259 104.679 32.863 105.57 34.282 L 141.965 97.145 C 142.743 98.625 143.314 100.751 143.382 102.422 C 143.317 104.091 142.808 106.251 141.975 107.699 C 141.143 109.147 139.532 110.673 138.12 111.57 C 136.644 112.351 134.507 112.927 132.836 113 L 60.034 113 C 58.362 112.927 56.239 112.351 54.762 111.57 C 53.35 110.673 51.739 109.147 50.907 107.699 C 50.074 106.251 49.565 104.091 49.5 102.422 C 49.569 100.75 50.143 98.619 50.918 97.142 L 87.302 34.301 C 88.192 32.882 89.714 31.259 91.159 30.421 Z M 94.166 38.224 L 57.761 101.103 C 57.377 101.633 57.339 101.78 57.406 102.428 C 57.339 103.077 57.437 103.196 57.76 103.758 C 58.084 104.32 58.136 104.466 58.731 104.732 C 59.258 105.117 59.412 105.159 60.061 105.094 L 132.836 105.094 C 133.483 105.159 133.623 105.118 134.15 104.733 C 134.744 104.467 134.799 104.32 135.123 103.758 C 135.446 103.196 135.544 103.077 135.476 102.428 C 135.543 101.779 135.503 101.627 135.121 101.1 L 98.727 38.243 C 98.467 37.65 98.316 37.584 97.757 37.26 C 97.199 36.937 97.086 36.906 96.441 36.906 C 95.797 36.906 95.683 36.937 95.126 37.26 C 94.566 37.584 94.426 37.631 94.166 38.224 Z\" style=\"fill: rgb(255, 255, 255);\"></path>"
    else
      icon = "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M 67.319 57.755 C 68.937 57.805 70.368 58.832 70.937 60.352 L 88.304 106.683 L 97.108 88.798 C 97.781 87.428 99.168 86.565 100.693 86.565 L 106.658 86.565 C 107.89 83.731 110.716 81.753 114.003 81.753 C 118.417 81.753 122.002 85.333 122.002 89.753 C 122.002 94.174 118.417 97.753 114.003 97.753 C 111.39 97.753 109.063 96.5 107.606 94.56 L 103.183 94.56 L 91.399 118.519 C 90.698 119.95 89.205 120.829 87.613 120.75 C 86.018 120.667 84.623 119.651 84.066 118.159 L 66.866 72.282 L 57.959 92.197 C 57.318 93.637 55.888 94.56 54.312 94.56 L 44.001 94.56 C 41.792 94.56 40.001 92.77 40.001 90.565 C 40.001 88.356 41.792 86.565 44.001 86.565 L 51.716 86.565 L 63.545 60.119 C 64.207 58.641 65.694 57.706 67.319 57.755 Z M 127.998 89.753 C 127.998 86.57 126.883 83.576 124.976 81.372 C 123.536 79.703 123.711 77.176 125.381 75.731 C 127.055 74.283 129.579 74.464 131.026 76.133 C 134.254 79.856 135.999 84.741 135.999 89.753 C 135.999 94.764 134.254 99.648 131.026 103.374 C 129.579 105.042 127.055 105.225 125.381 103.777 C 123.711 102.329 123.536 99.803 124.976 98.135 C 126.883 95.931 127.998 92.936 127.998 89.753 Z M 144.002 89.753 C 144.002 82.581 141.182 75.689 136.143 70.552 C 134.597 68.978 134.625 66.447 136.2 64.9 C 137.779 63.353 140.31 63.374 141.856 64.949 C 148.359 71.58 152.001 80.482 152.001 89.753 C 152.001 99.023 148.359 107.927 141.856 114.551 C 140.31 116.13 137.779 116.154 136.2 114.606 C 134.625 113.06 134.597 110.528 136.143 108.952 C 141.182 103.815 144.002 96.924 144.002 89.753 Z\" style=\"fill: rgb(255, 255, 255);\"></path>"

  } else if (type === `gateway-${GatewayTypes.BORDER_GATEWAY}`)
    icon = "<path d=\"M 108.483 125.851 C 109.545 129.242 107.014 132.689 103.456 132.689 L 63.707 132.689 C 60.156 132.689 57.619 129.242 58.68 125.851 L 71.521 84.89 C 67.621 78.519 65.5 71.14 65.5 63.546 C 65.5 52.721 69.801 42.344 77.45 34.689 L 105.453 62.693 C 105.639 62.354 105.872 62.035 106.154 61.753 L 119.677 48.23 C 119.547 47.652 119.473 47.05 119.473 46.436 C 119.473 42.068 123.012 38.535 127.374 38.535 C 131.737 38.535 135.275 42.068 135.275 46.436 C 135.275 50.798 131.737 54.331 127.374 54.331 C 126.576 54.331 125.808 54.214 125.083 53.993 L 111.739 67.337 C 111.456 67.619 111.137 67.853 110.798 68.032 L 135.171 92.404 C 127.516 100.059 117.133 104.36 106.314 104.36 C 104.753 104.36 103.199 104.268 101.663 104.096 L 108.483 125.851 Z\" style=\"fill: rgb(255, 255, 255);\"></path>"
  else if (type === `gateway-${GatewayTypes.MESH_GATEWAY}`)
    icon = "<path d=\"M 70.21 106.162 C 67.687 103.364 67.911 99.053 70.709 96.532 C 77.449 90.769 87.26 86.822 96.298 86.822 C 100.837 86.822 105.902 87.759 110.037 89.326 C 114.18 90.894 119.736 95.218 119.736 95.218 C 120.291 95.487 119.736 95.218 119.736 95.218 C 122.683 97.301 125.033 100.486 123.702 104.01 C 122.368 107.53 118.433 109.305 114.91 107.973 C 114.91 107.973 114.356 107.704 114.91 107.973 C 114.91 107.973 108.206 103.213 105.211 102.082 C 102.208 100.945 99.593 100.46 96.298 100.46 C 89.673 100.46 84.428 102.21 79.841 106.658 C 77.045 109.182 72.733 108.957 70.21 106.162 Z M 50.118 84.943 C 47.391 82.347 47.283 78.031 49.879 75.305 C 62.07 62.872 79.426 55 96.298 55 C 104.766 55 113.76 56.93 121.431 60.293 C 129.092 63.651 140.518 73.757 140.518 73.757 C 141.103 74.088 140.518 73.757 140.518 73.757 C 142.374 75.255 143.97 77.171 143.999 79.602 C 144.03 82.035 142.754 84.295 140.659 85.53 C 138.563 86.765 135.405 87.132 133.855 85.579 C 133.855 85.579 127.799 79.877 124.875 77.82 C 121.956 75.761 119.169 74.192 115.956 72.783 C 109.536 69.97 103.336 68.637 96.298 68.637 C 82.165 68.637 69.748 73.817 59.759 84.702 C 57.161 87.43 52.847 87.538 50.118 84.943 Z M 88.91 123.755 C 88.91 119.677 92.22 116.368 96.298 116.368 C 100.38 116.368 103.685 119.677 103.685 123.755 C 103.685 127.836 100.38 131.143 96.298 131.143 C 92.22 131.143 88.91 127.836 88.91 123.755 Z\" style=\"fill: rgb(255, 255, 255);\" transform=\"matrix(1, 0, 0, 1, 5.684341886080802e-14, 0)\"></path>"

  const svg = `<svg viewBox="-32 -32 256 256" width="52" height="52" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" style="filter: drop-shadow(0 0 3px rgb(0 0 0 / 0.7));">
    <path fill="${color}" stroke="${color}" style="stroke-width: 13px; opacity: 0.6;" d="M 95.98 182.866 C 95.98 182.866 28 134.257 28 79.857 C 28 29.343 64.915 7 96 7 C 127.085 7 164 29.343 164 79.857 C 164 134.257 95.98 182.866 95.98 182.866 Z"></path>
    <path fill="${color}" d="M 95.974 175.452 C 82.198 165.531 34 131.808 34 79.856 C 34 55.95 41.973 39.957 54.395 28.912 C 66.436 17.684 81.382 13 96 13 C 110.618 13 125.598 17.715 137.639 28.944 C 150.061 39.987 158 55.949 158 79.856 C 158 131.806 109.728 165.526 95.974 175.452 Z"></path>
    ${icon}
  </svg>`
  const img = new Image(52, 52)
  img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`
  return img
}

const createClusterImage = (type: string, state: FeatureState, partial?: boolean): HTMLImageElement => {
  const color = FEATURE_COLORS[state]
  const color2 = partial ? FEATURE_COLORS[FeatureState.Active] : color
  let icon = ""
  if (type === "device-cluster") {
    icon = `<g clip-path="url(#clip0_2654_4263)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4395 28.3083C19.1986 28.3083 19.9204 28.0316 20.4564 27.5484C20.7991 27.2393 21.3275 27.2667 21.6365 27.6094C21.9456 27.9522 21.9182 28.4806 21.5755 28.7896C20.7235 29.5578 19.5997 29.9795 18.4395 29.9795C17.2792 29.9795 16.1554 29.5578 15.3034 28.7896C14.9607 28.4806 14.9333 27.9522 15.2424 27.6094C15.5514 27.2667 16.0798 27.2393 16.4225 27.5484C16.9585 28.0316 17.6803 28.3083 18.4395 28.3083Z" fill="black" fill-opacity="0.2"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3002 12.5706C17.541 12.5706 16.8192 12.8473 16.2832 13.3305C15.9405 13.6396 15.4121 13.6122 15.1031 13.2695C14.794 12.9267 14.8214 12.3983 15.1641 12.0893C16.0161 11.3211 17.1399 10.8994 18.3002 10.8994C19.4604 10.8994 20.5842 11.3211 21.4362 12.0893C21.779 12.3984 21.8063 12.9267 21.4973 13.2695C21.1882 13.6122 20.6599 13.6396 20.3171 13.3305C19.7811 12.8473 19.0594 12.5706 18.3002 12.5706Z" fill="black" fill-opacity="0.2"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3699 32.0685C20.0789 32.0685 21.7193 31.4031 22.9404 30.2159C23.2713 29.8942 23.8004 29.9017 24.122 30.2326C24.4437 30.5635 24.4363 31.0925 24.1054 31.4142C22.5714 32.9055 20.5128 33.7397 18.3699 33.7397C16.2271 33.7397 14.1684 32.9055 12.6345 31.4142C12.3036 31.0925 12.2961 30.5635 12.6178 30.2326C12.9395 29.9017 13.4685 29.8942 13.7994 30.2159C15.0206 31.4031 16.661 32.0685 18.3699 32.0685Z" fill="black" fill-opacity="0.2"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3698 8.8104C16.6608 8.8104 15.0205 9.47576 13.7993 10.663C13.4684 10.9847 12.9394 10.9772 12.6177 10.6463C12.296 10.3154 12.3035 9.78637 12.6344 9.46468C14.1683 7.97338 16.2269 7.13916 18.3698 7.13916C20.5127 7.13916 22.5713 7.97338 24.1053 9.46468C24.4362 9.78637 24.4436 10.3154 24.1219 10.6463C23.8002 10.9772 23.2712 10.9847 22.9403 10.663C21.7191 9.47577 20.0788 8.8104 18.3698 8.8104Z" fill="black" fill-opacity="0.2"></path>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4503 20.3699L16.3404 23.6437L20.1207 23.6437L22.0108 20.3699L20.1207 17.0961L16.3404 17.0961L14.4503 20.3699ZM12.8421 19.8128C12.6431 20.1575 12.6431 20.5822 12.8421 20.927L15.0539 24.7579C15.2529 25.1026 15.6207 25.3149 16.0188 25.3149L20.4423 25.3149C20.8404 25.3149 21.2082 25.1026 21.4072 24.7579L23.619 20.927C23.818 20.5822 23.818 20.1575 23.619 19.8128L21.4072 15.9819C21.2082 15.6372 20.8404 15.4248 20.4423 15.4248L16.0188 15.4248C15.6207 15.4248 15.2529 15.6372 15.0539 15.9819L12.8421 19.8128Z" fill="black" fill-opacity="0.2"></path>
    </g>`
  } else if (state === FeatureState.Fire)
    icon = "<path d=\"M12.1874 14.4894C11.8949 14.4123 9.22654 18.8819 10.2209 24.1411C10.4741 25.4777 10.9817 28.1586 13.285 30.1128C16.3663 32.7257 20.1407 31.904 20.6484 31.782C21.21 31.6536 24.4966 30.8139 26.1362 27.7208C26.4435 27.1417 27.2657 25.538 26.9142 23.6596C26.6573 22.2857 25.824 21.0364 25.5425 21.1276C25.3889 21.1776 25.4897 21.5885 25.3127 22.418C25.239 22.7646 24.944 24.1757 24.3983 24.2335C24.0295 24.2733 23.5969 23.6621 23.4384 23.0869C23.2011 22.2254 23.5846 21.4845 23.8071 20.8887C24.7473 18.331 23.6842 15.6398 23.5318 15.2521C22.7771 13.3377 21.2174 12.0049 20.9249 12.1936C20.7774 12.2899 21.0392 12.7201 21.0171 13.5315C20.9864 14.637 20.4394 15.9608 19.8273 16.016C19.2153 16.0712 18.4876 14.8759 18.2295 13.7704C17.7731 10.4996 20.1086 7.54681 19.8765 7.17712C19.3996 6.63913 17.4257 7.37742 16.263 8.70634C14.8225 10.3473 14.8053 12.6982 14.7943 13.3428C14.7881 14.1132 14.8914 14.7436 14.841 16.3524C14.7893 17.9882 14.648 18.4235 14.337 18.5981C13.8811 18.8549 13.1289 18.5095 12.6913 18.0255C11.5765 16.7928 12.3902 14.5433 12.1874 14.4894Z\" fill=\"black\" fill-opacity=\"0.7\"/>"

  const svg = `<svg width="57" height="61" viewBox="-10 -10 57 61" fill="none" xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(0 0 3px rgb(0 0 0 / 0.7));">
    <defs>
      <clipPath id="clip0_2654_4263"><rect width="26.7398" height="26.7398" fill="white" transform="translate(31.7397 7) rotate(90)"></rect></clipPath>
      <linearGradient gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="37" y2="0" id="gradient0">
        <stop offset="0" style="stop-color: ${color};"></stop>
        <stop offset="0.5" style="stop-color: ${color};"></stop>
        <stop offset="0.5" style="stop-color: ${color2};"></stop>
        <stop offset="1" style="stop-color: ${color2};"></stop>
      </linearGradient>
    </defs>
    <path d="M19.8501 37.2421C18.9075 37.7836 17.7487 37.7793 16.8102 37.2307L4.5482 30.0632C3.6097 29.5147 3.03408 28.5052 3.03818 27.4151L3.09168 13.1725C3.09577 12.0824 3.67896 11.0773 4.62155 10.5358L16.937 3.46065C17.8796 2.91914 19.0384 2.92349 19.9769 3.47207L32.2388 10.6395C33.1773 11.1881 33.753 12.1976 33.7489 13.2877L33.6954 27.5302C33.6913 28.6203 33.1081 29.6255 32.1655 30.167L19.8501 37.2421Z" fill="url('#gradient0')"></path>
    <path d="M19.9082 40.4566C18.7945 41.0964 17.4254 41.0912 16.3166 40.4431L1.82929 31.9748C0.720464 31.3267 0.0403805 30.134 0.0452181 28.8461L0.108424 12.0187C0.113261 10.7307 0.802286 9.54322 1.91595 8.90343L16.4665 0.544252C17.5801 -0.0955399 18.9492 -0.0903961 20.0581 0.557744L34.5454 9.02599C35.6542 9.67413 36.3343 10.8668 36.3295 12.1547L36.2663 28.9821C36.2614 30.2701 35.5724 31.4576 34.4587 32.0974L19.9082 40.4566Z" fill-opacity="0.6" fill="url('#gradient0')"></path>
    ${icon}
  </svg>`
  const img = new Image(57, 61)
  img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`
  return img
}
/* eslint-enable max-len */

const makeMarkerIcons = (): Record<string, HTMLImageElement> => {
  const deviceTypes = ["sensor", `gateway-${GatewayTypes.MESH_GATEWAY}`, `gateway-${GatewayTypes.BORDER_GATEWAY}`]
  const clusterTypes = ["site", "site-cluster", "device-cluster"]
  const featureStates = Object.keys(STATE_LOOKUP)
  const result = {}
  for (const stateId of featureStates) {
    const [state, partial] = STATE_LOOKUP[stateId]
    for (const type of deviceTypes) {
      const iconName = `${type}-${stateId}`
      result[iconName] = createMarkerImage(type, state)
    }
    for (const type of clusterTypes) {
      const iconName = `${type}-${stateId}`
      result[iconName] = createClusterImage(type, state, partial)
    }
  }
  return result
}

const makeSelectionIcon = (): Record<string, HTMLImageElement> => {
  const svg = `
    <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(0 0 6px rgb(0 0 0 / 1))">
      <ellipse cx="30" cy="50" rx="20" ry="5" stroke="rgba(0,0,0, .7)" stroke-width="2" fill="rgba(255,255,255, 0.9)"/>
    </svg>`
  const img = new Image(57, 61)
  img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`
  return {
    "selection": img,
  }
}

export const iconExpression = [
  "concat",
  ["get", "type"],
  "-",
  ["coalesce", ["get", "fire_alert"], 0],
  "-",
  ["get", "offline"],
  "-",
  ["get", "active"],
]

export default {
  ...makeMarkerIcons(),
  ...makeSelectionIcon(),
}
