import { Injectable } from '@angular/core'
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"
import {
  Deployment,
  DeviceDeploymentStatusTag, DeviceFilter,
  DeviceTypes, DirectusDeviceResponse, DownLinkCommand, DropDownOptions, FileType,
  Gateway,
  LatLong, Region,
  SensorNode, UserPermissionDetail
} from "@dryad-web-app/shared/state"
import { concatMap, map } from "rxjs/operators"
import { plainToClass, plainToInstance } from "class-transformer"
import { from, Observable } from "rxjs"
import { HttpParams } from "@angular/common/http";
import { utility } from "../../../../../../../apps/silvanet-web/src/app/common/utility";
/**
 * TODO move to different services
 */
@Injectable({
  providedIn: 'root'
})
export class DirectusApiService extends ApiHttpService {

  constructor() {
    super(ApiBase.DATA_API_BASE_URL)
  }

  updateCollection(collection: string, body: Object, id: number): Observable<any> {
    return this.patch<LatLong>(
      "items/" + collection + "/" + id,
      body,
    ).pipe(map((response) => plainToClass(LatLong, response)))
  }

  getSensorNodesFromNsId(filter?: any): Observable<SensorNode[]> {
    let params = new HttpParams()
    if (filter)
      params = params.set("filter", JSON.stringify(filter))
    // @ts-ignore
    return this.get<SensorNode[]>("items/sensor_node", params).pipe(map((response) => plainToInstance(SensorNode, response["data"])))
  }

  resetDeviceStatus(sensorId: number[], deviceType: DeviceTypes): Observable<any> {
    let collection = "sensor_node"
    if (deviceType === DeviceTypes.GATEWAY) collection = "gateway"
    const updateSensor = {
      keys: sensorId,
      data: {
        latitude: null,
        longitude: null,
        deployment_status: DeviceDeploymentStatusTag.PENDING
      }
    }
    return this.patch<any>(`items/${collection}`, updateSensor).pipe(map(response => response))
  }

  getDownLinkCommands(): Observable<DownLinkCommand[]> {
    // @ts-ignore
    return this.get<DownLinkCommand[]>(  "items/downlink_cmd").pipe(map((response) => plainToInstance(DownLinkCommand, response["data"])),)
  }

  batchUpdateGateways(devices: Gateway[], batchSize = 10): Observable<Deployment> {
    const batches = utility.createBatch(devices, batchSize)
    return from(batches).pipe(concatMap(batch => {
      console.log("Updating deployment... ", batch)
      return this.patch<Deployment>("items/gateway", batch)
    }))
  }

  getGateways(limit: number = -1, offset: number = 0): Observable<Gateway[]> {
    let params = new HttpParams().set("sort", "site,id")
    params = params.set("limit", limit)
    if (offset > 0) params = params.set("offset", offset)
    // @ts-ignore
    return this.get<Gateway[]>("items/gateway", params).pipe(map((response) => plainToInstance(Gateway, response["data"])))
  }

  getMapSensorNodes(): Observable<SensorNode[]> {
    let params = new HttpParams().set("sort", "site,id")
    params = params.set("limit", -1).set("fields", "id,ns_end_device_id,site,latitude,longitude,name").set("filter", `{"latitude":{"_nnull":true},"longitude":{"_nnull":true}}`)
    // @ts-ignore
    return this.get<SensorNode[]>("items/sensor_node", params).pipe(map((response) => plainToInstance(SensorNode, response["data"])))
  }

  getGatewayByEUI(eui: string): Observable<Gateway> {
    let params = new HttpParams().set("sort", "site,id")
    params = params.set("filter", JSON.stringify({eui: {_eq: eui}}))
    // @ts-ignore
    return this.get<Gateway>("items/gateway", params).pipe(map((response) => plainToInstance(Gateway, response["data"])))
  }
  getOptions(path: string,filter?: any): Observable<DropDownOptions[]> {
    let params = new HttpParams()
    if (filter)
      params = params.set("filter", JSON.stringify(filter))
    return this.get<DropDownOptions[]>(path,params).pipe(map((response) => {
      const options: DropDownOptions[] = []
      // @ts-ignore
      response["data"].forEach(res => {
        options.push({ value: res, label: res.label })
      })
      return plainToInstance(DropDownOptions, options)
    }))
  }

  getUserDetails(userId: string): Observable<UserPermissionDetail> {
    let params = new HttpParams()
      params = params.set("filter", JSON.stringify({user: {id: {_eq: userId}}}))
    // @ts-ignore
    return this.get<UserPermissionDetail>("items/user_details?fields=*,user.*,ns_role.*",params).pipe(
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/ban-types
      map((response) => plainToInstance(UserPermissionDetail, response["data"][0] as Object)),
    )
  }
  getFileTypes(): Observable<FileType[]> {
    // @ts-ignore
    return this.get<FileType[]>("items/firmware_type").pipe(map((response) => plainToInstance(FileType, response["data"])))
  }

  getRegions(): Observable<Region[]> {
    // @ts-ignore
    return this.get<Region[]>("items/region").pipe(map((response) => plainToInstance(Region, response["data"])))
  }

  getSites(limit: number = -1, offset: number = 0,filter?: DeviceFilter): Observable<DirectusDeviceResponse> {
    let params = new HttpParams().set("sort", "id,name")
    params = params.set("limit", limit)
    if (offset > 0) params = params.set("offset", offset)
    if(filter) params = params.append("filter", JSON.stringify(filter))
    // @ts-ignore
    return this.get<DirectusDeviceResponse>("items/site?meta=*",params).pipe(map((response) => plainToInstance(DirectusDeviceResponse, response)))
  }
}
