import { unflatten } from "flat"
import { I18n } from "i18n-js"

import en from "./locales/en.json"

const i18n = new I18n(unflatten({ en }), {
  locale: "en",
  defaultLocale: "en",
  missingTranslationPrefix: "EE: ",
})

export default i18n
export const t = i18n.t.bind(i18n)
export const l = i18n.l.bind(i18n)
