<p-tabView  (onChange)="handleChange($event)">

  <p-tabPanel header="Training">
    <div class="training-controls">
      <button
        pButton
        type="button"
        label="New Training Record"
        (click)="handleNewExperimentRecord()"
      ></button>
      <button
        pButton
        type="button"
        icon="ph ph-arrows-clockwise"
        (click)="refreshValidationRecords(limit,0,TRAINING_DATA_TYPE)"
      ></button>
    </div>
    <p-table
      #validationtable
      [value]="validationDataRecords"
      [rows]="limit"
      [paginator]="true"
      [lazy]="true"
      [loading]="loading"
      (onLazyLoad)="loadExperiment($event,TRAINING_DATA_TYPE)"
      [totalRecords]="totalRecords"
      dataKey="id"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>Log File</th>
          <th>Specimen</th>
          <th>Specimen Class</th>
          <th>Tag</th>
          <th></th>
        </tr>
        <tr>
          <th>
            <columnFilter type="text" field="id" [showMenu]="false"></columnFilter>
          </th>
          <th>
            <columnFilter type="text" field="start_time" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar
                  #calendar
                  [ngModel]="value"
                  (onClose)="filter(calendar.value)"
                  [showTime]="true"
                  dataType="string"
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                ></p-calendar>
              </ng-template>
            </columnFilter>
          </th>
          <th>
            <columnFilter type="text" field="end_time" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar
                  #calendar
                  [ngModel]="value"
                  (onClose)="filter(calendar.value)"
                  [showTime]="true"
                  dataType="string"
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                ></p-calendar>
              </ng-template>
            </columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{ record.id }}</td>
          <td>{{ record.start_time | date : 'dd.MM.yyyy HH:mm' }}</td>
          <td>{{ record.end_time | date : 'dd.MM.yyyy HH:mm'}}</td>
          <td>{{ record.log_file }}</td>
          <td>{{ record.specimen }}</td>
          <td>{{ specimenClass(record) }}</td>
          <td>{{ specimenTag(record) }}</td>
          <td>
            <a
              *ngIf="downloadJsonHref && currentJsonRecordId == record.id"
              pButton
              class="p-button-rounded p-button-success p-mr-2"
              icon="ph ph-download-simple"
              [href]="downloadJsonHref"
              [download]="downloadJsonFileName"
            >
            </a>
            <button
              pButton
              pRipple
              icon="ph ph-file"
              class="p-button-rounded p-button-success p-mr-2"
              (click)="downloadValidationRecord(record)"
            ></button>
            <button
              pButton
              pRipple
              icon="ph ph-trash"
              class="p-button-rounded p-button-warning p-mr-2"
              (click)="deleteValidationRecord(record)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
            In total there are {{products ? products.length : 0 }} products.
        </div>
    </ng-template> -->
    </p-table>
  </p-tabPanel>
  <p-tabPanel header="Validation">
    <div class="training-controls">
      <button
        pButton
        type="button"
        label="New Validation Record"
        (click)="handleNewValidationRecord()"
      ></button>
      <button
        pButton
        type="button"
        icon="ph ph-arrows-clockwise"
        (click)="refreshValidationRecords(limit,0,VALIDATION_DATA_TYPE)"
      ></button>
    </div>
    <p-table
      #validationtable
      [value]="validationDataRecords"
      [rows]="limit"
      [paginator]="true"
      [lazy]="true"
      [loading]="loading"
      (onLazyLoad)="loadExperiment($event,VALIDATION_DATA_TYPE)"
      [totalRecords]="totalRecords"
      dataKey="id"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th>Log File</th>
          <th>Specimen</th>
          <th>Specimen Class</th>
          <th>Tag</th>
          <th></th>
        </tr>
        <tr>
          <th>
            <columnFilter type="text" field="id" [showMenu]="false"></columnFilter>
          </th>
          <th>
            <columnFilter type="text" field="start_time" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar
                  #calendar
                  [ngModel]="value"
                  (onClose)="filter(calendar.value)"
                  [showTime]="true"
                  dataType="string"
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                ></p-calendar>
              </ng-template>
            </columnFilter>
          </th>
          <th>
            <columnFilter type="text" field="end_time" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar
                  #calendar
                  [ngModel]="value"
                  (onClose)="filter(calendar.value)"
                  [showTime]="true"
                  dataType="string"
                  appendTo="body"
                  dateFormat="dd.mm.yy"
                ></p-calendar>
              </ng-template>
            </columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{ record.id }}</td>
          <td>{{ record.start_time | date : 'dd.MM.yyyy HH:mm' }}</td>
          <td>{{ record.end_time | date : 'dd.MM.yyyy HH:mm'}}</td>
          <td>{{ record.log_file }}</td>
          <td>{{ record.specimen }}</td>
          <td>{{ specimenClass(record) }}</td>
          <td>{{ specimenTag(record) }}</td>
          <td>
            <a
              *ngIf="downloadJsonHref && currentJsonRecordId == record.id"
              pButton
              class="p-button-rounded p-button-success p-mr-2"
              icon="ph ph-download-simple"
              [href]="downloadJsonHref"
              [download]="downloadJsonFileName"
            >
            </a>
            <button
              pButton
              pRipple
              icon="ph ph-file"
              class="p-button-rounded p-button-success p-mr-2"
              (click)="downloadValidationRecord(record)"
            ></button>
            <button
              pButton
              pRipple
              icon="ph ph-trash"
              class="p-button-rounded p-button-warning p-mr-2"
              (click)="deleteValidationRecord(record)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
            In total there are {{products ? products.length : 0 }} products.
        </div>
    </ng-template> -->
    </p-table>
  </p-tabPanel>
  <p-tabPanel header="Experiment">
    <app-experiments-list></app-experiments-list>
  </p-tabPanel>
</p-tabView>
