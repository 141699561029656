/* eslint-disable no-case-declarations */
import { Action, ActionReducer } from "@ngrx/store"
import * as _ from "lodash"
import {
  FirmwareVersion,
  FormlyFieldStatus,
  NetworkStatus,
  SiteAggregate,
  SiteDataAggregate,
  UpMessage,
  UserDetails,
} from "./model"
import { initialAppState } from "./observable.state"
import { FloatingPanel, ViewSidebarState } from "./ui-state"

export const VIEW_SIDEBAR_ITEM_UPDATE = "VIEW_SIDEBAR_ITEM_UPDATE"

export const LAST_SENSOR_MESSAGE_UPDATE = "LAST_SENSOR_MESSAGE_UPDATE"
export const LAST_FIRE_ALARM_MESSAGE_UPDATE = "LAST_Fire_ALARM_MESSAGE_UPDATE"

export const DEFAULT_PERMIT = "DEFAULT_PERMIT"

export const GET_SITES = "GET_SITES"
export const ADD_SITE = "ADD_SITE"
export const SITE_UPDATE = "SITE_UPDATE"
export const SITE_DELETE = "SITE_DELETE"

export const GATEWAY_ADDED = "GATEWAY_ADDED"
export const GATEWAY_DELETE = "GATEWAY_DELETE"

export const SENSOR_NODE_ADD = "SENSOR_NODE_ADD"
export const SENSOR_NODE_DELETE = "SENSOR_NODE_DELETE"

export const USER_UPDATE = "USER_UPDATE"

export const FIRE_ALERT_CLOSING = "FIRE_ALERT_CLOSING"

export const FIRE_ALERT_MINIMISE = "FIRE_ALERT_MINIMISE"

export const ADD_FIRE_ALERTS = "ADD_FIRE_ALERTS"
export const REMOVE_FIRE_ALERTS = "REMOVE_FIRE_ALERTS"
export const INIT_FIRE_ALERTS = "INIT_FIRE_ALERTS"

export const ADMIN_USER_CREATE = "ADMIN_USER_CREATE"
export const ADMIN_USER_UPDATE = "ADMIN_USER_UPDATE"
export const SELF_USER_UPDATE = "SELF_USER_UPDATE"

export const DB_SETTINGS_UPDATE = "DB_SETTINGS_UPDATE"

export const ACCESS_SENSOR_DATA_LOGS_VIEW = "ACCESS_SENSOR_DATA_LOGS_VIEW"
export const ACCESS_EXPERIMENT_VIEW = "ACCESS_EXPERIMENT_VIEW"
export const ACCESS_USER_MGT_VIEW = "ACCESS_USER_MGT_VIEW"

export const FLOATING_PANEL_ACTIVATE = "FLOATING_PANEL_ACTIVATE"

export const NETWORK_STATUS = "NETWORK_STATUS"

export const FORMLY_WRAPPER_EVENT = "FORMLY_WRAPPER_EVENT"

export const ALL_SITE_SAFE = "ALL_SITE_SAFE"

export const UPDATE_SITE_SENSOR_DEVICE_STATUS = "UPDATE_SITE_SENSOR_DEVICE_STATUS"
export const UPDATE_SITE_GATEWAY_DEVICE_STATUS = "UPDATE_SITE_GATEWAY_DEVICE_STATUS"
export const FIRMWARE_VERSION = "FIRMWARE_VERSION"
export const ACKNOWLEDGED_ALERT_IDS = "ACKNOWLEDGED_ALERT_IDS"

export class AppAction implements Action {
  type: string
  payload: any

  constructor(type: string, payload: any) {
    this.type = type
    this.payload = payload
  }
}

export function userLoggedIn(
  state: UserDetails = initialAppState.userLoggedIn,
  action: AppAction,
): UserDetails | undefined {
  switch (action.type) {
    case USER_UPDATE:
      return _.cloneDeep(action.payload)
    default:
      return state
  }
}

export function currentSiteList(
  state: SiteDataAggregate[] = initialAppState.currentSiteList,
  action: AppAction,
): SiteDataAggregate[] {
  switch (action.type) {
    case GET_SITES:
      return action.payload
    case ADD_SITE:
      const existingSites = _.cloneDeep(state)
      existingSites.unshift(
        new SiteDataAggregate(action.payload, new SiteAggregate()),
      )
      return existingSites
    case SITE_UPDATE:
      const index = state
        .map((site) => site.data?.id)
        .indexOf(action.payload.id)
      // console.log(index)
      return [
        ..._.filter(state, (e) => e.data.id != action.payload.id),
        _.merge(
          _.cloneDeep(state[index]),
          new SiteDataAggregate(action.payload),
        ),
      ]
    default:
      return state
  }
}

export function viewSidebarState(
  state: ViewSidebarState = initialAppState.viewSidebarState,
  action: AppAction,
): ViewSidebarState {
  switch (action.type) {
    case VIEW_SIDEBAR_ITEM_UPDATE:
      return action.payload
    default:
      return state
  }
}

export function fireAlertEventState(
  state: UpMessage = initialAppState.fireAlertEventState,
  action: AppAction,
): any {
  switch (action.type) {
    case FIRE_ALERT_CLOSING:
      return action.payload
    default:
      return state
  }
}

export function fireAlerts(
  state: Map<string, UpMessage[]> = initialAppState.fireAlerts,
  action: AppAction,
): Map<string, UpMessage[]> {
  switch (action.type) {
    case INIT_FIRE_ALERTS:
      const alerts = _.groupBy(action.payload, (b) => b.endDevice.id)
      const alertsMap = new Map<string, UpMessage[]>()
      for (const prop in alerts) {
        alertsMap.set(prop, alerts[prop])
      }
      return alertsMap
    case ADD_FIRE_ALERTS:
      const fireAlertMessages: UpMessage[] = action.payload
      const fireAlertsMap = _.cloneDeep(state)
      fireAlertMessages.map((fireAlertMessage: UpMessage) => {
        const existingFireAlertMessages = fireAlertsMap.get(
          fireAlertMessage.endDevice.id,
        )
        if (existingFireAlertMessages)
          existingFireAlertMessages.push(fireAlertMessage)
        else
          fireAlertsMap.set(fireAlertMessage.endDevice.id, [ fireAlertMessage ])
      })
      return fireAlertsMap
    case REMOVE_FIRE_ALERTS:
      const allAlerts = _.cloneDeep(state)
      if(action.payload) {
        allAlerts.delete(action.payload)
      }
      
      return allAlerts
    default:
      return state
  }
}

export function fireAlertsMinimise(
  state: any = initialAppState.fireAlertsMinimise,
  action: AppAction,
): any {
  switch (action.type) {
    case FIRE_ALERT_MINIMISE:
      return action.payload
    default:
      return state
  }
}
export function acknowledgedAlertIds(
  state: any = initialAppState.acknowledgedAlertIds,
  action: AppAction,
): any {
  switch (action.type) {
    case ACKNOWLEDGED_ALERT_IDS:
      return action.payload
    default:
      return state
  }
}

export function floatingPanelEvents(state: any = initialAppState.floatingPanelEvents,
                                    action: AppAction): FloatingPanel {
  switch (action.type) {
    case FLOATING_PANEL_ACTIVATE:
      return action.payload
    default:
      return state
  }
}

export function networkConnectionState(state: any = initialAppState.networkConnectionState, action: AppAction): NetworkStatus {
  switch (action.type) {
    case NETWORK_STATUS:
      return action.payload
    default:
      return state
  }
}

export function formlyEditWrapperEvent(state: any = initialAppState.formlyEditWrapperEvent, action: AppAction): FormlyFieldStatus {
  switch (action.type) {
    case FORMLY_WRAPPER_EVENT:
      return action.payload
    default:
      return state
  }
}

export function allSiteSafe(state: any = initialAppState.allSiteSafe, action: AppAction): boolean {
  switch (action.type) {
    case ALL_SITE_SAFE:
      return action.payload
    default:
      return state
  }
}

export function firmwareVersion(state: any = initialAppState.firmwareVersion, action: AppAction): FirmwareVersion[] {
  switch (action.type) {
    case FIRMWARE_VERSION:
      return action.payload
    default:
      return state
  }
}

export const rootReducer = {
  userLoggedIn,
  currentSiteList,
  viewSidebarState,
  fireAlertEventState,
  fireAlerts,
  fireAlertsMinimise,
  acknowledgedAlertIds,
  floatingPanelEvents,
  networkConnectionState,
  formlyEditWrapperEvent,
  allSiteSafe,
  firmwareVersion,
}

export function withParent<T>(
  parentReducer: ActionReducer<T>,
  reducer: ActionReducer<T>,
) {
  return function parentChildReducer(state: T, action: Action) {
    return reducer(parentReducer(state, action), action)
  }
}
