import { Injectable } from "@angular/core"
import { HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApiBase, ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { ObservableState } from "@dryad-web-app/shared/state"

@Injectable({
  providedIn: "root"
})
export class ApiHttpService extends ApiBase {
  apiBaseUrl = ""

  protected oss: ObservableState

  protected environment: any

  /**
   * this.requiresToken = (apiBaseUrl === ApiHttpService.ENGINE_API_BASE_URL) ? true : false
   * Using the normal angular injection for the http client
   * will not work for a usual (not a component / injectable) class
   * so we inject in programmatically
   * @param apiBaseUrl
   */
  constructor(apiBaseUrl = ApiBase.ENGINE_API_BASE_URL) {
    super()
    this.apiBaseUrl = apiBaseUrl
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    this.oss = ServiceLocator.injector.get(ObservableState)
  }

  options(withCredentials = false): any {
    return { observe: "response", withCredentials: withCredentials }
  }

  private _get<T>(
    path: string,
    ps: HttpParams = new HttpParams()
  ): Observable<T> {
  
    const observable = this.http.get<T>(this.apiBaseUrl + path, {
      params: ps,
      withCredentials: true
    })
    return observable
  }

  get<T>(
    path: string,
    ps: HttpParams = new HttpParams(),
  ): Observable<T> {
    return this._get<T>(path, ps)
  }

  _post<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams(),
    hh?: any
  ): Observable<T> {
    return this.http.post<T>(this.apiBaseUrl + path, body, {
      params: ps,
      withCredentials: true,
      headers: hh
    })
  }

  post<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams(),
    hh?: any
  ): Observable<T> {
    return this._post<T>(path, body, ps, hh)
  }

  _put<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams()
  ): Observable<T> {
    return this.http.put<T>(this.apiBaseUrl + path, body, {
      params: ps,
      withCredentials: true
    })
  }

  put<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams(),
  ): Observable<T> {
    return this._put<T>(path, body, ps)
  }

  _patch<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams()
  ): Observable<T> {
    return this.http.patch<T>(this.apiBaseUrl + path, body, {
      params: ps,
      withCredentials: true
    })
  }

  patch<T>(
    path: string,
    body: any,
    ps: HttpParams = new HttpParams(),
  ): Observable<T> {
    return this._patch<T>(path, body, ps)
  }

  _delete(
    path: string,
    ps: HttpParams = new HttpParams(),
    body?:any
  ): Observable<boolean> {
    return this.http
      .delete(this.apiBaseUrl + path, {
        params: ps,
        withCredentials: true,
        body:body
      })
      .pipe(map((response) => true))
  }

  delete(
    path: string,
    ps: HttpParams = new HttpParams(),
    body?: any,
  ): Observable<boolean> {
    return this._delete(path, ps, body)
  }

  getFileFromServer<T>(path: string,ps: HttpParams = new HttpParams()) : Observable<ArrayBuffer> {
    return this._getFile<T>(path, ps)
  }

  private _getFile<T>(
    path: string,
    ps: HttpParams = new HttpParams()
  ): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(this.apiBaseUrl + path, {
      params: ps,
      withCredentials: true,
      responseType:  "blob" as "json"
    })
  }
}
