import { Component } from '@angular/core';

@Component({
  selector: 'app-animated-illustration-access-denied',
  templateUrl: './access-denied.component.svg',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {

  constructor() { }
}
