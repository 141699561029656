<p-tabView  [(activeIndex)]="index" (onChange)="handleChange($event)">
  <p-tabPanel header="Metadata" >
    <app-device-metadata [metaData]="metadata" [deviceType]="deviceType" [isActive]="index === 0"></app-device-metadata>
  </p-tabPanel>
  <p-tabPanel header="Location">
    <app-device-location [locationData]="locationData"  [deviceType]="deviceType"  [isActive]="index === 1"></app-device-location>
  </p-tabPanel>
  <p-tabPanel header="Technical Information">
    <app-device-tech-info [deviceType]="deviceType" [deviceId]="locationData.id" [isActive]="index === 2" ></app-device-tech-info>
  </p-tabPanel>
  <p-tabPanel header="QR Code">
    <qrcode [qrdata]="dialogData?.data?.serial_id" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </p-tabPanel>
</p-tabView>
