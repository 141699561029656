import { Component, ViewEncapsulation } from "@angular/core"
import { FieldType } from "@ngx-formly/core"
import { FormUtils } from "@dryad-web-app/shared/state"

@Component({
  selector: "dryad-web-app-dryad-formly-field-dropdown",
  templateUrl: "./dryad-formly-field-dropdown.component.html",
  styleUrls: ["./dryad-formly-field-dropdown.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DryadFormlyFieldDropdownComponent extends FieldType {
  FormUtils = FormUtils

  constructor() {
    super()
  }
}
