import { HttpParams } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import {
  SensorNodeService,
  UplinkMessageService,
} from "@dryad-web-app/shared/data-access"
import {
  ExperimentDataRecord,
  SensorNode,
  TagLabelId,
  UpMessage,
} from "@dryad-web-app/shared/state"
import { LazyLoadEvent, MessageService } from "primeng/api"
import { DialogService } from "primeng/dynamicdialog"
import { Subscription } from "rxjs"
import { ExperimentDataRecordService } from "../../service/http/experiment-data-record.service"
import { ModelValidationComponent } from "../model-validation/model-validation.component"

@Component({
  selector: "app-experiment-sessions",
  templateUrl: "./experiment-sessions.component.html",
  styleUrls: ["./experiment-sessions.component.scss"],
})
export class ExperimentSessionsComponent implements OnInit, OnDestroy {
  validationDataRecords: ExperimentDataRecord[] = []

  downloadJsonHref: SafeUrl
  currentJsonRecordId: number
  downloadJsonFileName: string

  VALIDATION_DATA_TYPE = "validation"
  TRAINING_DATA_TYPE = "training"

  private subscription = new Subscription()
  limit = 10
  totalRecords = 0
  loading: boolean

  constructor(
    private sensorNodeService: SensorNodeService,
    private experimentDataRecordsService: ExperimentDataRecordService,
    private uplinkeMessageService: UplinkMessageService,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) {
    // FixMe remove this after meta data add
    this.subscription.add(
      this.experimentDataRecordsService
        .list(this.TRAINING_DATA_TYPE, -1, 0)
        .subscribe((edrs: ExperimentDataRecord[]) => {
          this.totalRecords = edrs.length
        })
    )
  }

  ngOnInit(): void {
    this.refreshValidationRecords(this.limit, 0, this.TRAINING_DATA_TYPE)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  specimenClass(record: ExperimentDataRecord): string {
    return (
      record.tag_labels?.find(
        (tlid: TagLabelId) =>
          tlid.tag_label_id.type.type_id === "validation_record_specimen_class"
      ).tag_label_id.label ?? ""
    )
  }

  specimenTag(record: ExperimentDataRecord): string {
    return (
      record.tag_labels?.find(
        (tlid: TagLabelId) =>
          tlid.tag_label_id.type.type_id === "validation_record"
      ).tag_label_id.label ?? ""
    )
  }

  refreshValidationRecords(limit, offset, type, filter?: any): void {
    this.loading = true
    this.subscription.add(
      this.experimentDataRecordsService
        .list(type, limit, offset, new HttpParams(), filter)
        .subscribe((edrs: ExperimentDataRecord[]) => {
          this.validationDataRecords = edrs
          this.loading = false
        })
    )
  }

  handleNewExperimentRecord(): void {
    const ref = this.dialogService.open(ModelValidationComponent, {
      header: "Add Training Record",
      width: "70%",
    })
    ref.onClose.subscribe(() => {
      // FixMe remove this after meta data add
      this.totalRecords += 1
      this.refreshValidationRecords(this.limit, 0, this.TRAINING_DATA_TYPE)
    })
  }

  downloadValidationRecord(validationRecord: ExperimentDataRecord): void {
    this.subscription.unsubscribe()
    this.subscription = new Subscription()
    this.clearDownloadJson()
    this.subscription.add(
      this.sensorNodeService
        .sensorNode(validationRecord.sensor_node)
        .subscribe((sensorNode: SensorNode) => {
          this.subscription.add(
            this.uplinkeMessageService
              .uplinkMessagesForTimeRange(
                sensorNode.ns_end_device_id,
                validationRecord.start_time,
                validationRecord.end_time
              )
              .subscribe((upMessages: UpMessage[]) => {
                console.log("validation uplink messages")
                this.generateDownloadJsonUri(upMessages)
                this.currentJsonRecordId = validationRecord.id
                this.downloadJsonFileName =
                  validationRecord.log_file +
                  "_" +
                  validationRecord.start_time +
                  "_" +
                  validationRecord.end_time +
                  "_" +
                  validationRecord.specimen +
                  "_" +
                  validationRecord.specimen_class +
                  "_" +
                  ".json"
              })
          )
        })
    )
  }

  deleteValidationRecord(validationRecord: ExperimentDataRecord): void {
    this.subscription.add(
      this.experimentDataRecordsService
        .destroy(validationRecord.id)
        .subscribe(() => {
          this.validationDataRecords = this.validationDataRecords.filter(
            (edr: ExperimentDataRecord) => edr.id !== validationRecord.id
          )
          // FixMe remove this after meta data add
          this.totalRecords -= 1
          this.messageService.add({
            severity: "success",
            summary: "Validation Data Record Deleted",
            detail: "Record Id " + validationRecord.id,
          })
        })
    )
  }

  generateDownloadJsonUri(upMessages: UpMessage[]): void {
    const json = JSON.stringify(upMessages)
    const blob = new Blob([json], { type: "text/json" })
    const url = window.URL.createObjectURL(blob)
    this.downloadJsonHref = this.sanitizer.bypassSecurityTrustUrl(url)
  }

  clearDownloadJson(): void {
    this.downloadJsonHref = undefined
    this.downloadJsonFileName = undefined
    this.currentJsonRecordId = undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadExperiment($event: LazyLoadEvent, type): void {
    this.loading = true
    this.refreshValidationRecords(
      this.limit,
      $event.first,
      this.generateFilters($event.filters)
    )
  }

  generateFilters(filter: any): any {
    const filters = {}
    for (const [key, val] of Object.entries(filter)) {
      if (val["value"]) {
        filters[key] = {
          _eq: val["value"],
        }
      }
    }
    return filters
  }

  handleNewValidationRecord(): void {
    const ref = this.dialogService.open(ModelValidationComponent, {
      header: "Add Validation Record",
      width: "70%",
      data: { type: "validation" },
    })
    ref.onClose.subscribe(() => {
      // FixMe remove this after meta data add
      this.totalRecords += 1
      this.refreshValidationRecords(this.limit, 0, this.VALIDATION_DATA_TYPE)
    })
  }

  handleChange($event: any): void {
    if ($event.index === 0)
      this.refreshValidationRecords(this.limit, 0, this.TRAINING_DATA_TYPE)
    if ($event.index === 1)
      this.refreshValidationRecords(this.limit, 0, this.VALIDATION_DATA_TYPE)
  }
}
