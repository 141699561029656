import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { serviceRootUrl } from "apps/silvanet-web/src/app/utils/api-utils"
import { Observable } from "rxjs"

export interface PaginatedOrganizationList {
  count: number
  next?: string
  previous?: string
  results: Organization[]
}
export interface Organization {
  readonly uuid?: string
  readonly url?: string
  readonly urn?: string
  readonly created_at?: Date
  readonly updated_at?: Date
  readonly client_id: number
  parent?: string
  name: string
}

export interface OrganizationCreationPayload {
  name: string
}

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Injectable({
  providedIn: "root",
})
export class OrganizationsService {
  private readonly forestFloorBaseURLOrganizations = `${forestFloorBaseUrl}/api/organizations/`

  constructor(
    private http: HttpClient,
  ){}

  getAllOrganizations(page?: number, page_size?: number): Observable<PaginatedOrganizationList> {
    let params = new HttpParams()
    if (page !== undefined && page !== null)
      params = params.set("page", <any>page)

    if(page_size !== undefined && page_size !== null)
      params = params.set("page_size", page_size)

    return this.http.get<PaginatedOrganizationList>(this.forestFloorBaseURLOrganizations, { params: params })
  }

  // Engine Compatible endpoint to ease transition for the web app
  createNewOrganization(name: string): Observable<Organization> {
    const requestBody = {
      name: name,
    }
    return this.http.post<Organization>(`${this.forestFloorBaseURLOrganizations}engine_create/`, requestBody)
  }
}
