import { Component, OnDestroy, OnInit } from "@angular/core"
import { Subscription } from "rxjs"
import {
  FIRE_ALERT_MINIMISE, ObservableState, SensorNode,
  VIEW_SIDEBAR_ITEM_UPDATE,
  ViewSidebarDataType,
  ViewSidebarPositions,
  ViewSidebarState,
} from "@dryad-web-app/shared/state"


@Component({
  selector: "app-view-sidebar",
  templateUrl: "./view-sidebar.component.html",
  styleUrls: ["./view-sidebar.component.scss"],
})
export class ViewSidebarComponent implements OnInit, OnDestroy {
  ViewSidebarDataType = ViewSidebarDataType

  viewSidebarState: ViewSidebarState | undefined

  visible: boolean | undefined

  title = ""

  position: ViewSidebarPositions | undefined = ViewSidebarPositions.LEFT

  transitionOptions: string | undefined

  private subscription = new Subscription()

  constructor(private oss: ObservableState) {}

  ngOnInit(): void {
    this.subscription.add(
      this.oss
        .viewSidebarState$()
        .subscribe((viewSidebarState: ViewSidebarState) => {
          // FIXME refactor this
          if(viewSidebarState && viewSidebarState.viewSidebarDataType !== ViewSidebarDataType.MAP_SENSOR_NODE) {
            this.viewSidebarState = viewSidebarState
            this.position = viewSidebarState.position
            this.visible = viewSidebarState?.active
            if (viewSidebarState?.entity instanceof SensorNode) {
              this.title = viewSidebarState.entity.name
            } else if (viewSidebarState?.entity) {
              this.title = viewSidebarState.entity.title
            }
          }
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  handleDialogHide(): void {
    this.oss.dispatch({
      type: VIEW_SIDEBAR_ITEM_UPDATE,
      payload: new ViewSidebarState(
        ViewSidebarDataType.MAP_SENSOR_NODE,
        false,
        undefined
      ),
    })
  }
  minimiseAlerts() {
    this.oss.dispatch({
      type: FIRE_ALERT_MINIMISE,
      payload: { isMinimised: true },
    })
  }
}
