import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"
import { FirmwareVersion, SensorNode } from "@dryad-web-app/shared/state"
import { plainToInstance } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class MetaDataService extends ApiHttpService {
  constructor() {
    super(ApiBase.ENGINE_API_BASE_URL)
  }

  getVersions(): Observable<FirmwareVersion[]> {
    return this.get<FirmwareVersion[]>("metadata/firmwareversion")
      .pipe(map((response: FirmwareVersion[]) => plainToInstance(FirmwareVersion, response)))
  }

  matchSensorFirmwareVersion(sensor: SensorNode, versions: FirmwareVersion[]): FirmwareVersion | undefined {
   return versions.find(version => version.id === sensor.firmware_version)
  }

  matchMLVersion(device: SensorNode, versions: FirmwareVersion[]): FirmwareVersion | undefined {
   return versions.find(version => version.id === device.ml_version)
  }
}
