import { Pipe, PipeTransform } from '@angular/core'
import { UserService } from "@dryad-web-app/shared/data-access"

@Pipe({
  name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {
  constructor(private userService: UserService) {
  }

  transform(value: number, ...args: unknown[]): string {
    return  (value) ? this.convertTemperature(value) + this.getSymbol(): "N/A"
  }

  convertTemperature(value: number): number {
    const unitSystem = this.userService.getLocalSettings().unitSystem
    if (unitSystem === "imperial") {
      return Math.round((value * (9 / 5)) + 32)
    } else {
      return Math.round(value)
    }
  }

  getSymbol(): string {
    const unitSystem = this.userService.getLocalSettings().unitSystem
    if (unitSystem === "imperial") return " °F"
    else return  " °C"
  }

}
