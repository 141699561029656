import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from "@angular/core"
import { MenuItemsService, UplinkMessageService, MetaDataService } from "@dryad-web-app/shared/data-access"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import {
  FIRMWARE_VERSION,
  FirmwareVersion,
  MenuItem,
  MenuOrientation,
  ObservableState, UserDetails,
} from "@dryad-web-app/shared/state"
import { AlertsService } from "apps/silvanet-web/src/app/service/http/alerts.service"

@Component({
  selector: "dryad-web-app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MainLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  menuItems: MenuItem[] | undefined
  environment: any
  sidebarActive: boolean | undefined

  layoutMode: MenuOrientation = MenuOrientation.STATIC

  darkMenu = false

  topBarMenuActive: boolean | undefined

  sidebarClick: boolean | undefined

  topBarItemClick: boolean | undefined

  activeTopBarItem: any

  documentClickListener: any

  rippleInitListener: any

  rippleMouseDownListener: any

  userLoggedIn: UserDetails | undefined

  isPrePlanningRoute = false;

  constructor(
    public renderer: Renderer2,
    public zone: NgZone,
    private oss: ObservableState,
    private uplinkMessageService: UplinkMessageService,
    private menuItemsService: MenuItemsService,
    private metaDataService: MetaDataService,
    private alertsService: AlertsService,
  ) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    if (this.environment.dataApiKey) this.uplinkMessageService.fireAlerts()
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      this.bindRipple()
    })

    this.oss
      .userLoggedIn$()
      .subscribe((user: UserDetails) => (this.userLoggedIn = user))

    this.initMenuItems()

    this.metaDataService.getVersions().subscribe((response:FirmwareVersion[]) => {
      this.oss.dispatch({
        type: FIRMWARE_VERSION,
        payload: response
      })
    })
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener()
    }
    this.unbindRipple()
  }

  bindRipple() {
    this.rippleInitListener = this.init.bind(this)
    document.addEventListener("DOMContentLoaded", this.rippleInitListener)
  }

  init() {
    this.rippleMouseDownListener = this.rippleMouseDown.bind(this)
    document.addEventListener("mousedown", this.rippleMouseDownListener, false)
  }

  rippleMouseDown(e: any) {
    const parentNode = "parentNode"
    for (
      let target = e.target;
      target && target !== this;
      target = target[parentNode]
    ) {
      if (!this.isVisible(target)) {
        continue
      }
      // Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
      if (
        this.selectorMatches(
          target,
          ".ripplelink, .ui-button, .ui-listbox-item, .ui-multiselect-item, .ui-fieldset-toggler",
        )
      ) {
        this.rippleEffect(target, e)
        break
      }
    }
  }

  selectorMatches(el: any, selector: any) {
    const matches = "matches"
    const webkitMatchesSelector = "webkitMatchesSelector"
    const mozMatchesSelector = "mozMatchesSelector"
    const msMatchesSelector = "msMatchesSelector"
    const p = Element.prototype

    const f =
      p[matches] ||
      p[webkitMatchesSelector] ||
      // @ts-ignore
      p[mozMatchesSelector] ||
      // @ts-ignore
      p[msMatchesSelector] ||
      function (s) {
        // @ts-ignore
        return [].indexOf.call(document.querySelectorAll(s), this) !== -1
      }
    return f.call(el, selector)
  }

  isVisible(el: any) {
    return !!(el.offsetWidth || el.offsetHeight)
  }

  rippleEffect(element: any, e: any) {
    if (element.querySelector(".ink") === null) {
      const inkEl = document.createElement("span")
      this.addClass(inkEl, "ink")

      if (
        this.hasClass(element, "ripplelink") &&
        element.querySelector("span")
      ) {
        element
          .querySelector("span")
          .insertAdjacentHTML("afterend", "<span class='ink'></span>")
      } else {
        element.appendChild(inkEl)
      }
    }

    const ink = element.querySelector(".ink")
    this.removeClass(ink, "ripple-animate")

    if (!ink.offsetHeight && !ink.offsetWidth) {
      const d = Math.max(element.offsetWidth, element.offsetHeight)
      ink.style.height = d + "px"
      ink.style.width = d + "px"
    }

    const x = e.pageX - this.getOffset(element).left - ink.offsetWidth / 2
    const y = e.pageY - this.getOffset(element).top - ink.offsetHeight / 2

    ink.style.top = y + "px"
    ink.style.left = x + "px"
    ink.style.pointerEvents = "none"
    this.addClass(ink, "ripple-animate")
  }

  hasClass(element: any, className: any) {
    if (element.classList) {
      return element.classList.contains(className)
    } else {
      return new RegExp("(^| )" + className + "( |$)", "gi").test(
        element.className,
      )
    }
  }

  addClass(element: any, className: any) {
    if (element.classList) {
      element.classList.add(className)
    } else {
      element.className += " " + className
    }
  }

  removeClass(element: any, className: any) {
    if (element.classList) {
      element.classList.remove(className)
    } else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi",
        ),
        " ",
      )
    }
  }

  getOffset(el: any) {
    const rect = el.getBoundingClientRect()

    return {
      top:
        rect.top +
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0),
      left:
        rect.left +
        (window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0),
    }
  }

  unbindRipple() {
    if (this.rippleInitListener) {
      document.removeEventListener("DOMContentLoaded", this.rippleInitListener)
    }
    if (this.rippleMouseDownListener) {
      document.removeEventListener("mousedown", this.rippleMouseDownListener)
    }
  }

  ngAfterViewInit() {
    this.documentClickListener = this.renderer.listen(
      "body",
      "click",
      (event) => {
        if (!this.topBarItemClick) {
          this.activeTopBarItem = null
          this.topBarMenuActive = false
        }

        if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
          this.sidebarActive = false
        }

        this.topBarItemClick = false
        this.sidebarClick = false
      },
    )
  }


  closeSidebar(event: Event) {
    this.sidebarActive = false
    // FIXME : This is specific to the device dashboard
    //         sidebar - should be generalized
    // switch (this.activeTabIndex) {
    //   case SidebarItem.DEVICE_DASHBOARD:
    //     this.oss.dispatch({
    //       type: SIDEBAR_ITEM_UPDATE,
    //       payload: new SidebarState(SidebarItem.MAP),
    //     })
    //     this.activeTabIndex = SidebarItem.MAP
    // }
    event.preventDefault()
  }

  onSidebarClick($event: any) {
    this.sidebarClick = true
  }

  get overlay(): boolean {
    return this.layoutMode === MenuOrientation.OVERLAY
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY
  }

  isDesktop() {
    return window.innerWidth > 1024
  }

  initMenuItems() {
    this.menuItems = this.menuItemsService.getMenuItems()
  }
}
