import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ApiBase } from "@dryad-web-app/shared/helpers"
import {
  Deployment,
  DeploymentObject,
  DeviceDeploymentStatusLabels,
  Gateway,
  Packet,
  PacketObject,
  SensorNode,
} from "@dryad-web-app/shared/state"
import { from, Observable } from "rxjs"
import { concatMap, map } from "rxjs/operators"
import { plainToClass } from "class-transformer"
import { HttpParams } from "@angular/common/http"
import { utility } from "../../../../../../../apps/silvanet-web/src/app/common/utility";

@Injectable({
  providedIn: "root",
})
export class PlaningService extends ApiHttpService {
  constructor() {
    super(ApiBase.DATA_API_BASE_URL)
  }

  create(packet: Packet): Observable<PacketObject> {
    return this.post<PacketObject>("items/packet", packet).pipe(
      map((response) => plainToClass(PacketObject, response)),
    )
  }

  list(filter?: any): Observable<PacketObject> {
    const params = new HttpParams()
      .set("filter", JSON.stringify(filter))
      .set("sort", "-id")
    return this.get<PacketObject>("items/packet", params).pipe(
      map((response) => plainToClass(PacketObject, response)),
    )
  }

  remove(id: number): Observable<boolean> {
    return this.delete("items/packet/" + id)
  }

  updatePacket(id: number, packet: any): Observable<PacketObject> {
    return this.patch("items/packet/" + id, packet).pipe(
      map((response) => plainToClass(PacketObject, response)),
    )
  }

  addDevices(devices: Deployment[]): Observable<Deployment> {
    return this.post<Packet>("items/deployment", devices).pipe(
      map((response) => plainToClass(Deployment, response)),
    )
  }

  batchAddDevices(devices: Deployment[], batchSize = 10): Observable<Deployment> {
    const batches = utility.createBatch(devices, batchSize)
    return from(batches).pipe(concatMap(batch => {
      console.log("Updating deployment... ", batch)
      return this.post<Deployment>("items/deployment", batch)
    }))
  }

  removeDevices(ids: number[]): Observable<boolean> {
    return this.delete("items/deployment", new HttpParams(), ids).pipe(map((response) => response))
  }

  updateDevices(devices: Deployment[]): Observable<Deployment> {
    return this.patch<Packet>("items/deployment", devices).pipe(
      map((response) => plainToClass(Deployment, response)),
    )
  }

  batchUpdateDevices(devices: Deployment[], batchSize = 10): Observable<Deployment> {
    const batches = utility.createBatch(devices, batchSize)
    return from(batches).pipe(concatMap(batch => {
      console.log("Updating deployment... ", batch)
      return this.patch<Deployment>("items/deployment", batch)
    }))
  }

  getDevices(packetId: number): Observable<DeploymentObject> {
    const filter = { packet: { _eq: packetId } }
    let params = new HttpParams().set("filter", JSON.stringify(filter))
    params = params.append("limit", -1)
    return this.get<DeploymentObject>(
      "items/deployment",
      params,
    ).pipe(map((response) => plainToClass(DeploymentObject, response)))
  }

  assignPacket(packetId: number, userId: number): Observable<PacketObject> {
    return this.patch("items/packet/" + packetId, { assign_to: userId }).pipe(
      map((res) => plainToClass(PacketObject, res)),
    )
  }

  getPacket(packetId: number): Observable<PacketObject> {
    return this.get<PacketObject>("items/packet/" + packetId).pipe(
      map((response) => plainToClass(PacketObject, response)),
    )
  }

  getDeployments(packetId: number): Observable<DeploymentObject> {
    let params = new HttpParams().set(
      "filter",
      JSON.stringify({ packet: { _eq: packetId } }),
    )
    params = params.append("limit", -1)
    return this.get<DeploymentObject>(
      "items/deployment",
      params,
    ).pipe(map((response) => plainToClass(DeploymentObject, response)))
  }

  getDeviceNames(collection: string, idList: number[]): Observable<any[]> {
    const params = new HttpParams().set(
      "filter",
      JSON.stringify({ id: { _in: idList } }),
    )
    return this.get<any[]>("items/?fields=name,id" + collection, params)
  }

  deviceSite(
    siteId: number,
    tableName: string,
    params = new HttpParams(),
    limit?: number,
    offset?: number,
    gateWayType?: number,
  ): Observable<any[]> {
    const sensorNodeFilter: any = {
      _and: [
        { site: { id: { _eq: siteId } } },
        { deployment_status: { label: { _eq: DeviceDeploymentStatusLabels.PENDING } } },
      ],
    }
    if (gateWayType) {
      sensorNodeFilter["_and"].push({ gateway_type: { _eq: gateWayType } })
    }
    let cloneParams = params ?? new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    // if (gateWayType) cloneParams = cloneParams.append("gateway_type",  { _eq: gateWayType })
    cloneParams = cloneParams.append("filter", JSON.stringify(sensorNodeFilter))
    return this.get<any[]>(`items/${ tableName }`, cloneParams).pipe(
      map((response) => plainToClass(SensorNode, response)),
    )
  }

  getGatewayForSite(siteId: number, type: number, limit?: number, offset?: number): Observable<Gateway[]> {
    const filter = {
      _and: [
        { site: { id: { _eq: siteId } } },
        { deployment_status: { label: { _eq: DeviceDeploymentStatusLabels.PENDING } } },
        { gateway_type: { _eq: type } },
      ],
    }
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    cloneParams = cloneParams.append("filter", JSON.stringify(filter))
    return this.get<any[]>(`items/gateway`, cloneParams).pipe(
      map((response) => plainToClass(Gateway, response)),
    )
  }

  getPlanedDevices(packetIds: number[], deviceType?: number): Observable<any> {
    const filter = {
      _or: [
        { entity_id: { _eq: 0 } },
        { entity_id: { _null: true } },
      ],
    }
    let params = new HttpParams()
    if (deviceType) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter["entity_type"] = { _eq: deviceType }
    }
    if (packetIds.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter["packet"] = { _in: packetIds }
    }
    params = params.append("limit", -1)
    return this.get<any[]>(`items/deployment`, params.append("filter", JSON.stringify(filter))).pipe(
      map((response) => plainToClass(SensorNode, response)),
    )
  }
}
