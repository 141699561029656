<div class="layout-wrapper" >
  <dryad-web-app-header></dryad-web-app-header>
  <dryad-web-app-side-bar-menu [menuItems]="menuItems"></dryad-web-app-side-bar-menu>
  <app-view-sidebar></app-view-sidebar>
  <div class="layout-main">
    <div class="layout-main-content">
      <router-outlet></router-outlet>
      <dryad-web-app-footer></dryad-web-app-footer>
    </div>
  </div>
</div>
