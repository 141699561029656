import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import {
  DateUtils,
  DeviceState, Gateway,
  INIT_FIRE_ALERTS,
  LAST_FIRE_ALARM_MESSAGE_UPDATE,
  LAST_SENSOR_MESSAGE_UPDATE,
  SensorMeasurementType,
  SensorNode,
  SensorValue,
  TTNUplinkMessage, UpGateway,
  UpMessage,
} from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { MessageService } from "primeng/api"
import { Observable, of, timer } from "rxjs"
import { map } from "rxjs/operators"
import { HttpParams } from "@angular/common/http"

@Injectable({
  providedIn: "root",
})
export class UplinkMessageService extends ApiHttpService {
  readonly LAST_MESSAGES_UPDATE_INTERVAL = 3000

  private smokeAlarmPopupSensorIds: string[] = []

  private madeFirstCallForLastUplinkMessages = false

  constructor(
    private messageService: MessageService,
  ) {
    super()
    timer(
      this.LAST_MESSAGES_UPDATE_INTERVAL,
      this.LAST_MESSAGES_UPDATE_INTERVAL,
    ).subscribe(() => {
      this.lastUplinkMessages().subscribe((umesgs: TTNUplinkMessage[]) => {
        umesgs.forEach((um: TTNUplinkMessage) => {
          if (this.isSmokeAlarm(um)) {

            const time = Math.abs(
              // @ts-ignore
              Math.floor((Date.now() - Date.parse(um.metadata.time)) / 60000),
            )
            if (this.madeFirstCallForLastUplinkMessages && time >= 1) {
              this.smokeAlarmPopupSensorIds =
                this.smokeAlarmPopupSensorIds.filter(
                  (sid: string) => um.devId !== sid,
                )
            } else if (
              !this.smokeAlarmPopupSensorIds.some(
                (sid: string) => um.devId === sid,
              )
            ) {
              this.messageService.add({
                severity: "error",
                summary: "Fire Alarm!",
                detail: "Smoke Alert at device " + um.devId,
                sticky: true,
              })
              this.smokeAlarmPopupSensorIds.push(um.devId)
            }
          } else {
            this.smokeAlarmPopupSensorIds =
              this.smokeAlarmPopupSensorIds.filter(
                (sid: string) => um.devId !== sid,
              )
          }
        })

        this.madeFirstCallForLastUplinkMessages = true

        this.oss.dispatch({
          type: LAST_SENSOR_MESSAGE_UPDATE,
          payload: umesgs,
        })

        const lastFireAlarmMessages = umesgs.filter((um: TTNUplinkMessage) =>
          this.isSmokeAlarm(um),
        )

        if (lastFireAlarmMessages.length > 0) {
          this.oss.dispatch({
            type: LAST_FIRE_ALARM_MESSAGE_UPDATE,
            payload: lastFireAlarmMessages,
          })
        }
      })
    })
  }

  uplinkMessages(
    sensorId: string,
    time: string = "5m",
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    let cloneParams = new HttpParams()
    cloneParams = cloneParams.append("limit", 5000)
    return this.get<UpMessage[]>(
      "uplink/messages/" + sensorId + "/" + time,
      cloneParams
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  gatewayUplinkMessages(
    gatewayId: string,
    time: string = "5m",
    sort: string = "asc",
  ): Observable<UpGateway[]> {
    let cloneParams = new HttpParams()
    cloneParams = cloneParams.append("limit", 5000)
    return this.get<UpGateway[]>(
      "gateways/messages/" + gatewayId + "/time/" + time + "/now",
      cloneParams
    ).pipe(
      map((response) =>
        plainToClass(UpGateway, response).sort((a: UpGateway, b: UpGateway) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }
  gatewayLastUplinkMessages(
    gatewayId: string,
  ): Observable<UpGateway> {
    let cloneParams = new HttpParams()
    cloneParams = cloneParams.append("limit", 5000)
    return this.get<UpGateway>(
      "gateways/messages/last/"+gatewayId,
      cloneParams
    ).pipe(
      map((response) =>
        plainToClass(UpGateway, response)
      ),
    )
  }

  fireAlerts() {
    this.uplinkMessagesForAlert("smoke").subscribe(
      (upMessages: UpMessage[]) => {
        this.oss.dispatch({
          type: INIT_FIRE_ALERTS,
          payload: upMessages,
        })
      },
      () => console.log("Error retrieving fire alerts"),
    )
  }

  fireAlertsForSite(siteId: number, sort: string = "asc", limit?: number, offset?: number): Observable<UpMessage[]> {
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    return this.get<UpMessage[]>("uplink/messages/alert/smoke/" + siteId, cloneParams).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }
  fireWarningsForSite(siteId: number, sort: string = "asc", limit?: number, offset?: number): Observable<UpMessage[]> {
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    return this.get<UpMessage[]>("uplink/messages/alert/warning/" + siteId, cloneParams).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  uplinkMessagesForAlert(
    alertType: string,
    sort: string = "asc",
    limit?: number,
    offset?: number,
  ): Observable<UpMessage[]> {
    let cloneParams = new HttpParams()
    if (limit) cloneParams = cloneParams.append("limit", limit)
    if (offset) cloneParams = cloneParams.append("offset", offset)
    return this.get<UpMessage[]>("uplink/messages/alert/" + alertType, cloneParams).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  uplinkMessagesForDate(
    sensorId: string,
    date: string,
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    return this.get<UpMessage[]>(
      "uplink/messages/" + sensorId + "/date/" + date,
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  uplinkMessagesForTimeRange(
    sensorId: string,
    startDateTime: string,
    endDateTime: string,
    sort: string = "asc",
  ): Observable<UpMessage[]> {
    return this.get<UpMessage[]>(
      "uplink/messages/" +
      sensorId +
      "/time/" +
      startDateTime +
      "/" +
      endDateTime,
    ).pipe(
      map((response) =>
        plainToClass(UpMessage, response).sort((a: UpMessage, b: UpMessage) => {
          if (sort === "desc") return Date.parse(b.time) - Date.parse(a.time)
          else return Date.parse(a.time) - Date.parse(b.time)
        }),
      ),
    )
  }

  lastUplinkMessageForSensorNode(endDeviceId: string): Observable<UpMessage> {
    return this.get<UpMessage>(
      `uplink/messages/${endDeviceId}/5y?limit=1`
    ).pipe(map((response) => plainToClass(UpMessage, response)))
  }

  lastUplinkMessageForSensorNodes(
    endDeviceIds: string[],
  ): Observable<UpMessage> {
    return this.put<UpMessage>(
      "uplink/messages/sensorNode/last",
      {
        'ids': endDeviceIds
      }
    ).pipe(map((response) => plainToClass(UpMessage, response)))
  }

  updateSensorNodesWithStatus(
    sensorNodes: SensorNode[],
  ): Observable<SensorNode[]> {
    return this.put<SensorNode[]>(
      "uplink/messages/sensorNode/last",
      {
        ids: sensorNodes
          .map((sensorNode: SensorNode) => sensorNode.ns_end_device_id)
      }
    ).pipe(
      map((response) => plainToClass(UpMessage, response)),
      map((upMsgs: UpMessage[]) =>
        this.updateSensorNodeStatus(sensorNodes, upMsgs),
      ),
    )
  }

  updateGatewaysWithStatus(
    gateways: Gateway[],
  ): Observable<Gateway[]> {
    return this.put<Gateway[]>(
      "gateways/messages/last",
      gateways
        .map((gateway: Gateway) => gateway.ns_gateway_id)
    ).pipe(
      map((response) => plainToClass(UpMessage, response)),
      map((upMsgs: UpMessage[]) =>
        this.updateGatewayStatus(gateways, upMsgs),
      ),
    )
  }

  /**
   * @deprecated use lastUplinkMessageForSiteId
   */
  lastUplinkMessageForSite(applicationId: string): Observable<UpMessage> {
    return this.get<UpMessage>(
      "uplink/messages/" + applicationId + "/site/last",
    ).pipe(map((response) => plainToClass(UpMessage, response)))
  }

  lastUplinkMessageForSiteId(siteId: number): Observable<UpMessage> {
    return this.get<UpMessage>(
      "uplink/messages/" + siteId + "/site/last",
    ).pipe(map((response) => plainToClass(UpMessage, response)))
  }

  lastUplinkMessages(): Observable<TTNUplinkMessage[]> {
    return of([])
  }

  private updateSensorNodeStatus(
    sensorNodes: SensorNode[],
    upMessages: UpMessage[],
  ): SensorNode[] {
    const sensorNodesWithStatus: SensorNode[] = []
    return sensorNodes.map((sensorNode: SensorNode) => {
      const upMessage = upMessages.find(
        (upMsg: UpMessage) => upMsg.endDevice.id === sensorNode.ns_end_device_id,
      )
      if (upMessage) {
        sensorNode.lastSignal = new Date(upMessage.time)
        sensorNode.state = DateUtils.deviceState(sensorNode.lastSignal)
      } else {
        sensorNode.lastSignal = undefined
        sensorNode.state = DeviceState.INACTIVE
      }
      return sensorNode
    })
  }

  private updateGatewayStatus(
    gateways: Gateway[],
    upMessages: UpMessage[],
  ): Gateway[] {
    return gateways.map((gateway: Gateway) => {
      const upMessage = upMessages.find(
        // @ts-ignore
        (upMsg: UpMessage) => upMsg.deviceId === gateway.ns_gateway_id,
      )
      if (upMessage) {
        gateway.lastSignal = new Date(upMessage.time)
        gateway.state = DateUtils.deviceState(gateway.lastSignal)
      } else {
        gateway.lastSignal = undefined
        gateway.state = DeviceState.INACTIVE
      }
      return gateway
    })
  }

  private isSmokeAlarm(um: TTNUplinkMessage): boolean {
    // @ts-ignore
    return um.sensorValues.some(
      (sv: SensorValue) =>
        sv.smType === SensorMeasurementType.Smoke && sv.value === 1,
    )
  }
}
