import { Injectable } from "@angular/core"
import { EChartsOption } from "echarts"
import { AxisTypes, ChartAxis, SeriesTypes, TimeFormats } from "@dryad-web-app/shared/state"
import { UserService } from "@dryad-web-app/shared/data-access"
import { DatePipe } from "@angular/common"
import { AirPressurePipe, TemperaturePipe } from "@dryad-web-app/ui/dryad-ui"
import { utility } from "../common/utility"

@Injectable({
  providedIn: "root",
})
export class ChartService {
  constructor(private userService: UserService,
    private datePipe: DatePipe,
    private temperaturePipe: TemperaturePipe,
    private airPressurePipe: AirPressurePipe,) {
  }

  airPressureChatConfig(): EChartsOption {
    return {
      title: { text: "Air Pressure ( " + this.airPressurePipe.getSymbol() + " )" },
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
        minorTick: {
          show: true,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        min: 0,
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value) => Math.ceil(value).toString(),
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Air Pressure " + this.airPressurePipe.getSymbol(),
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#07C2B7" },
          itemStyle: { color: "#07C2B7" },
        },
      ],
      mode: "real-time",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params, "AP"),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div >Air Pressure <span class="bold" > ${Math.ceil(params[0].value[1]) + this.airPressurePipe.getSymbol()}</span></div>
               <div class="chart-tooltip"><i class="ph-clock ph-icon"></i>
               <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)} </span>
               </div>`,
      },
    }
  }

  temperatureChatConfig(): EChartsOption {
    return {
      title: { text: "Temperature (" + this.temperaturePipe.getSymbol() + " )" },
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
        minorTick: {
          show: true,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        axisLabel: {
          formatter: (value) => Math.round(Number(value)).toString(),
        },
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Temperature ",
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#DC802D" },
          itemStyle: { color: "#DC802D" },
        },
      ],
      mode: "real-time",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params, "T"),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div>Temperature <span class="bold">${Math.round(params[0].value[1]) + this.temperaturePipe.getSymbol()}</span></div>
                <div class="chart-tooltip"><i class="ph-clock ph-icon"></i>
                <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)}</span></div>`,
      },
    }
  }

  humidityChatConfig(): EChartsOption {
    return {
      title: { text: "Humidity (%)" },
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        min: 0,
        axisLabel: {
          formatter: "{value} ",
        },
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Humidity %",
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#0795C2" },
          itemStyle: { color: "#0795C2" },
        },
      ],
      mode: "real-time",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params, "H"),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div >Humidity <span class="bold" > ${params[0].value[1]}%</span></div>
                <div class="chart-tooltip"><i class="ph-clock ph-icon"></i>
                <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)}
                </span></div>`,
      },
    }
  }

  iaqChatConfig(): EChartsOption {
    return {
      title: { text: "Air Quality" },
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        min: 0,
        axisLabel: {
          formatter: (value) => Math.round(value).toString(),
        },
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Air Quality",
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#0795C2" },
          itemStyle: { color: "#0795C2" },
        },
      ],
      mode: "real-time",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div >Air Quality <span class="bold" > ${Math.round(params[0].value[1])}</span></div>
                <div class="chart-tooltip"><i class="ph-clock ph-icon"></i>
                <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)}
                </span></div>`,
      },
    }
  }

  batteryChatConfig(): EChartsOption {
    return {
      title: { text: "Energy Level %" },
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        min: 0,
        axisLabel: {
          formatter: (value) => Math.round(value).toString(),
        },
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Energy Level",
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#0795C2" },
          itemStyle: { color: "#0795C2" },
        },
      ],
      mode: "real-time",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div >Energy Level <span class="bold" > ${Math.round(params[0].value[1])} %</span></div>
                <div class="chart-tooltip"><i class="ph-clock ph-icon"></i>
                <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)}
                </span></div>`,
      },
    }
  }

  private axisPointerFormatter(params, type?: string): string {
    if (params.axisDimension === ChartAxis.X) {
      return this.datePipe.transform(params.value, this.userService.getLocalSettings().dateFormat + " "
        + this.userService.getLocalSettings().timeFormat)
    } else return this.formatY(params.value, type)
  }

  private formatY(value, type?: string): string {
    switch (type) {
      case "T":
        return Math.round(value).toString() + this.temperaturePipe.getSymbol()
      case "AP":
        return Math.round(value).toString() + this.airPressurePipe.getSymbol()
      case "H":
        return Math.round(value).toString() + " %"
      default:
        return Math.round(value).toString()
    }
  }

  private getTimeFormatForX(): string {
    if (this.userService.getLocalSettings().timeFormat === TimeFormats.H24) return "HH:mm"
    else return "hh:mm"
  }

  gatewayPowerChatConfig(): EChartsOption {
    return {
      title: { text: "Energy Level %" },
      mode: "real-time",
      xAxis: {
        type: AxisTypes.TIME,
        axisLabel: {
          formatter: (value) => utility.formatDateString(value, this.getTimeFormatForX()),
          showMinLabel: true,
          showMaxLabel: true,
          rotate: 30,
        },
      },
      yAxis: {
        type: AxisTypes.VALUE,
        min: 0,
        axisLabel: {
          formatter: (value) => Math.round(value).toString(),
        },
        splitLine: {
          show: false,
        },
        minorTick: {
          show: true,
        },
        alignTicks: true,
        axisLine: {
          show: true,
        },
        boundaryGap: [ "5%", "5%" ],
      },
      series: [
        {
          name: "Energy Level",
          type: SeriesTypes.LINE,
          smooth: true,
          data: [],
          lineStyle: { color: "#0795C2" },
          itemStyle: { color: "#0795C2" },
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          animation: false, type: "cross", label: {
            formatter: params => this.axisPointerFormatter(params),
          },
        },
        position: (pos, params, el, elRect, size): any => {
          const obj = { top: 10 }
          obj[[ "left", "right" ][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        formatter: (params) => `
                <div >Energey Level <span class="bold" > ${Math.round(params[0].value[1])} %</span></div>
                <div class="flex-center"><i class="ph-clock ph-icon"></i>
                <span>${utility.formatDateString(params[0].axisValue, this.userService.getLocalSettings().timeFormat)}
                </span></div>`,
      },
    }
  }
}
