import * as memoizee from 'memoizee';

export function memoize(config: memoizee.Options<any> & {object?: boolean} = {}) {
  return function(target, key, descriptor) {
    const oldFunction = descriptor.value;
    if (config.object && config.normalizer === undefined) {
      config.normalizer = (args: any) => { return JSON.stringify(args[0]) }
    }

    const newFunction = memoizee(oldFunction, config);
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
};
