import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { DryadFormlyComponent } from "./dryad-formly/dryad-formly.component"
import { FormlyModule } from "@ngx-formly/core"
import { DryadFormlyFieldCheckboxComponent } from "./fields/dryad-formly-field-checkbox/dryad-formly-field-checkbox.component"
import { DryadFormlyFieldCalenderComponent } from "./fields/dryad-formly-field-calender/dryad-formly-field-calender.component"
import { DryadFormlyFieldDropdownComponent } from "./fields/dryad-formly-field-dropdown/dryad-formly-field-dropdown.component"
import { DryadFormlyFieldFileComponent } from "./fields/dryad-formly-field-file/dryad-formly-field-file.component"
import { DryadFormlyFieldInputComponent } from "./fields/dryad-formly-field-input/dryad-formly-field-input.component"
import { DryadFormlyFieldMultiselectDropdownComponent } from "./fields/dryad-formly-field-multiselect-dropdown/dryad-formly-field-multiselect-dropdown.component"
import { DryadFormlyFieldPasswordComponent } from "./fields/dryad-formly-field-password/dryad-formly-field-password.component"
import { DryadFormlyFieldRadioComponent } from "./fields/dryad-formly-field-radio/dryad-formly-field-radio.component"
import { DryadFormlyFieldTextAreaComponent } from "./fields/dryad-formly-field-text-area/dryad-formly--field-text-area.component"
import { DryadFormlyFieldRepeatComponent } from "./fields/dryad-formly-field-repeat/dryad-formly-field-repeat.component"
import { DryadFormlyFieldStepperComponent } from "./fields/dryad-formly-field-stepper/dryad-formly-field-stepper.component"
import { CalendarModule } from "primeng/calendar"
import { ReactiveFormsModule } from "@angular/forms"
import { CheckboxModule } from "primeng/checkbox"
import { DropdownModule } from "primeng/dropdown"
import { FormlySelectModule } from "@ngx-formly/core/select"
import { FileUploadModule } from "primeng/fileupload"
import { MultiSelectModule } from "primeng/multiselect"
import { RadioButtonModule } from "primeng/radiobutton"
import { StepsModule } from "primeng/steps"
import { CardModule } from "primeng/card"
import { DryadFormlyReviewComponent } from "./dryad-formly-review/dryad-formly-review.component"
import { DryadFormlyPanelWrapperComponent } from "./wrappers/dryad-formly-panel-wrapper/dryad-formly-panel-wrapper.component"
import { DataCyExtension } from "./extensions/data-cy.extention"
import { PasswordModule } from "primeng/password"
import { InputTextareaModule } from "primeng/inputtextarea"
import { DryadFormlyTableEditWrapperComponent } from './wrappers/dryad-formly-table-edit-wrapper/dryad-formly-table-edit-wrapper.component'
import { DryadFormlyGroupWrapperComponent } from './wrappers/dryad-formly-group-wrapper/dryad-formly-group-wrapper.component'
import { UiDryadUiModule } from "@dryad-web-app/ui/dryad-ui"
import { DryadFormlyFieldScannerInputComponent } from './fields/dryad-formly-field-scanner-input/dryad-formly-field-scanner-input.component'

@NgModule({
  imports: [CommonModule, FormlyModule, CalendarModule, ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: "primeng-input", component: DryadFormlyFieldInputComponent },
        { name: "primeng-password", component: DryadFormlyFieldPasswordComponent },
        { name: "primeng-text-area", component: DryadFormlyFieldTextAreaComponent },
        { name: "primeng-dropdown", component: DryadFormlyFieldDropdownComponent },
        { name: "primeng-radio", component: DryadFormlyFieldRadioComponent },
        { name: "primeng-multiselect-dropdown", component: DryadFormlyFieldMultiselectDropdownComponent },
        { name: "primeng-calendar", component: DryadFormlyFieldCalenderComponent },
        { name: "primeng-file", component: DryadFormlyFieldFileComponent },
        { name: "primeng-checkbox", component: DryadFormlyFieldCheckboxComponent },
        { name: "primeng-stepper", component: DryadFormlyFieldStepperComponent },
        { name: "primeng-repeat", component: DryadFormlyFieldRepeatComponent },
        { name: "primeng-scanner", component: DryadFormlyFieldScannerInputComponent },
      ],
      wrappers: [
        { name: "primeng-panel", component: DryadFormlyPanelWrapperComponent },
        { name: "primeng-table", component: DryadFormlyTableEditWrapperComponent },
        { name: "primeng-group", component: DryadFormlyGroupWrapperComponent },
      ],
      validationMessages: [{ name: "required", message: "This field is required" }],
      extensions: [{ name: "data-cy-extention", extension: DataCyExtension }],
    }),
    CheckboxModule, DropdownModule, FormlySelectModule, FileUploadModule, MultiSelectModule, RadioButtonModule, StepsModule, CardModule, PasswordModule, InputTextareaModule, UiDryadUiModule],
  declarations: [
    DryadFormlyComponent,
    DryadFormlyFieldCheckboxComponent,
    DryadFormlyFieldCalenderComponent,
    DryadFormlyFieldDropdownComponent,
    DryadFormlyFieldFileComponent,
    DryadFormlyFieldInputComponent,
    DryadFormlyFieldMultiselectDropdownComponent,
    DryadFormlyFieldPasswordComponent,
    DryadFormlyFieldRadioComponent,
    DryadFormlyFieldTextAreaComponent,
    DryadFormlyFieldRepeatComponent,
    DryadFormlyFieldStepperComponent,
    DryadFormlyReviewComponent,
    DryadFormlyPanelWrapperComponent,
    DryadFormlyTableEditWrapperComponent,
    DryadFormlyGroupWrapperComponent,
    DryadFormlyFieldScannerInputComponent,
  ],
  exports: [
    DryadFormlyComponent,
    DryadFormlyFieldCalenderComponent,
    DryadFormlyFieldCheckboxComponent,
    DryadFormlyFieldDropdownComponent,
    DryadFormlyFieldFileComponent,
    DryadFormlyFieldInputComponent,
    DryadFormlyFieldMultiselectDropdownComponent,
    DryadFormlyFieldPasswordComponent,
    DryadFormlyFieldRadioComponent,
    DryadFormlyFieldTextAreaComponent,
    DryadFormlyFieldRepeatComponent,
    DryadFormlyFieldStepperComponent,
    DryadFormlyReviewComponent,
    DryadFormlyPanelWrapperComponent,
  ],
})
export class UiDryadFormModule {
}
