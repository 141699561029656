import { HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { ApiHttpService } from "@dryad-web-app/shared/data-access"
import { ExperimentDataRecord } from "@dryad-web-app/shared/state"
import { plainToClass } from "class-transformer"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class ExperimentDataRecordService extends ApiHttpService {
  private readonly EXPERIMENT_DATA_RECORD_API_PATH = "expdatarecords"

  constructor() {
    super()
  }

  create(
    experimentDataRecord: ExperimentDataRecord,
    data_type: string
  ): Observable<ExperimentDataRecord> {
    experimentDataRecord.data_type = data_type
    return this.post<ExperimentDataRecord>(
      this.EXPERIMENT_DATA_RECORD_API_PATH,
      experimentDataRecord
    ).pipe(map((response) => plainToClass(ExperimentDataRecord, response)))
  }

  list(
    dataType: string,
    limit: number,
    offset: number,
    params = new HttpParams(),
    filter?: any
  ): Observable<ExperimentDataRecord[]> {
    const dataTypeFilter = {
      data_type: {
        _eq: dataType,
      },
    }
    if (filter) Object.assign(dataTypeFilter, filter)
    return this.get<ExperimentDataRecord[]>(
      this.EXPERIMENT_DATA_RECORD_API_PATH +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        "&meta=*&sort=sort,-id",
      params.append("filter", JSON.stringify(dataTypeFilter))
    ).pipe(map((response) => plainToClass(ExperimentDataRecord, response)))
  }

  destroy(id: number): Observable<boolean> {
    return this.delete(this.EXPERIMENT_DATA_RECORD_API_PATH + "/" + id)
  }
}
