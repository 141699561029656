import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { BehaviorSubject, forkJoin, from, Observable, of, Subject, throwError } from "rxjs"
import { catchError, concatMap, finalize, map, switchMap, tap, toArray } from "rxjs/operators"

import { DeviceDirectusApiService, SiteService } from "@dryad-web-app/shared/data-access"
import { RestApiInterceptor } from "@dryad-web-app/shared/auth"
import { PaginatedAlertList, AlertStateEnum, AlertCloseReason,
  EnrichedAlert, PaginatedAlertEventList, AlertEvent } from "../../alerts/types"
import { filterAndSortAlertEvents } from "../../utils/utils"
import { serviceRootUrl, COLLECTION_FORMATS } from "../../utils/api-utils"

const forestFloorBaseUrl = serviceRootUrl("forestfloor")

@Injectable({
  providedIn: "root",
})
export class AlertsService  {
  private readonly wsURL = `${forestFloorBaseUrl.replace("http", "ws")}/ws/`
  private readonly forestFloorBaseURLAlerts = `${forestFloorBaseUrl}/api/alerts/`
  private readonly forestFloorBaseURLAlertEvents = `${forestFloorBaseUrl}/api/alert-events/`

  private webSocket: WebSocket
  public webSocketSubject: Subject<any> = new Subject()
  private webSocketBackoffTime = 250

  private activeAlerts = new BehaviorSubject<EnrichedAlert[]>([])
  public activeAlerts$ = this.activeAlerts.asObservable()

  private alertMapClickDeviceId: Subject<string> = new Subject<string>()
  public alertMapClickDeviceId$: Observable<string> = this.alertMapClickDeviceId.asObservable()

  private loadingCurrentAlertsSubject = new BehaviorSubject<boolean>(false)
  loadingCurrentAlerts$ = this.loadingCurrentAlertsSubject.asObservable()

  private existingAlertUpdated = new BehaviorSubject<boolean>(false)
  existingAlertUpdated$ = this.existingAlertUpdated.asObservable()

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private deviceDirectusApiService: DeviceDirectusApiService,
    private restApiInterceptor: RestApiInterceptor,
  ) {
    this.create(this.wsURL)
    this.getAllActiveAlerts()
  }

  private create(url: string): void {
    this.webSocket = new WebSocket(`${url}`)
    const closeIfNotConnectedTimeout = setTimeout(() => this.webSocket.close(), 5_000)
    let connectionSuccessTimeout

    // Send FF token as first message to authenticate
    this.webSocket.onopen = async (event): Promise<void> => {
      clearTimeout(closeIfNotConnectedTimeout)
      console.log("socket opened", event)
      const { forestfloorToken } = await this.restApiInterceptor.ensureTokens()
      this.webSocket.send(forestfloorToken)
      connectionSuccessTimeout = setTimeout(() => this.webSocketBackoffTime = 250, 2_000)
    }

    // Listen for WebSocket messages and push them to the webSocketSubject
    this.webSocket.onmessage = (event): void => {
      console.log("message", event.data)
      const message = JSON.parse(event.data)
      if (!message.message.hasOwnProperty("alert")) return

      else {
        this.webSocketSubject.next(message)
        this.getAllActiveAlerts()
        this.getAlertNotification()
      }
    }

    this.webSocket.onerror = (event): void => {
      console.log("on error", event)
      this.webSocket.close()
    }

    this.webSocket.onclose = (event): void => {
      console.log("closing socket", event)
      this.webSocket = undefined
      if (connectionSuccessTimeout) clearTimeout(connectionSuccessTimeout)
      setTimeout(() => this.create(url), this.webSocketBackoffTime)
      this.webSocketBackoffTime = Math.min(this.webSocketBackoffTime * 2, 15_000)
    }
  }

  async getAllAlerts(state: AlertStateEnum[], siteIDs?: number[], status__in?: string[], reason?: string[],
    page?: number, page_size?: number,  o?: string[], created_at_after?: string, created_at_before?: string): Promise<any> {
    const resultPage = await this.getAlertsByState(state, siteIDs, status__in, reason,
      page, page_size, o, created_at_after, created_at_before).toPromise()
    const enrichedAlerts = await Promise.all(resultPage.results.map(async (alert) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [alert_site, site_clients, alert_events] = await Promise.all([
        this.getLegacySite(alert.site).toPromise(),
        this.siteService.getSiteClientsById(alert.legacy_site_id).toPromise(),
        this.getAlertEventsForAlert(alert.uuid, true, undefined, ["fire_alert_first"]).toPromise(),
      ])
      if (alert_events) {
        const filteredAndSortedAlertEvents = filterAndSortAlertEvents(alert_events)

        return {
          alert,
          alert_site,
          site_clients,
          alert_events: filteredAndSortedAlertEvents,
        }
      }

    }))
    return {
      count: resultPage.count,
      alerts: enrichedAlerts,
    }
  }

  getAlertsByState(state: AlertStateEnum[], siteIDs?: number[],
    reason?: string[],
    status__in?: string[],
    page?: number, page_size?: number, o?: string[],
    created_at_after?: string, created_at_before?: string
  ): Observable<PaginatedAlertList> {
    let params = new HttpParams()
    if (page !== undefined && page !== null)
      params = params.set("page", <any>page)

    if(page_size !== undefined && page_size !== null)
      params = params.set("page_size", page_size)

    if (siteIDs?.length)
      params = params.set("legacy_site_id__in", siteIDs.join(","))

    if (state?.length > 0) params = params.set("state__in", state.join(","))

    if (status__in?.length > 0)
      params =  params.set("status__in", status__in.join(COLLECTION_FORMATS["csv"]))

    if (reason?.length > 0)
      params =  params.set("reason__in", reason.join(COLLECTION_FORMATS["csv"]))

    if (created_at_after !== undefined && created_at_after !== null)
      params =  params.set("created_at_after", created_at_after)

    if (created_at_before !== undefined && created_at_before !== null)
      params =  params.set("created_at_before", created_at_before)

    if (o)
      params = params.set("o", o.join(COLLECTION_FORMATS["csv"]))

    return this.http.get<PaginatedAlertList>(`${this.forestFloorBaseURLAlerts}`, { params: params })
  }

  getLegacySite(url: string): any {
    return this.http.get(url)
  }

  async getAlertById(uuid: string): Promise<EnrichedAlert>{
    const alert: any = await this.http.get(`${this.forestFloorBaseURLAlerts}${uuid}/`).toPromise()

    if (alert) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [alert_site, site_clients, alert_events] = await Promise.all([
        this.getLegacySite(alert.site).toPromise(),
        this.siteService.getSiteClientsById(alert.legacy_site_id).toPromise(),
        this.getAlertEventsForAlert(alert.uuid, true, undefined, ["fire_alert_first"]).toPromise(),
      ])
      if (alert_events) {
        const filteredAndSortedAlertEvents = filterAndSortAlertEvents(alert_events)

        return {
          alert,
          alert_site,
          site_clients,
          alert_events: filteredAndSortedAlertEvents,
        }
      }
    }
  }

  getAlertTransitions(uuid: string): any {
    return this.http.get(`${this.forestFloorBaseURLAlerts}${uuid}/transition_logs/`)
  }

  getAlertEventsForAlert(alertUuid?: string, short_distance?: boolean, uuid?: string, o?: string[], page?: number, ): Observable<any> {
    let params = new HttpParams()

    if (alertUuid !== undefined && alertUuid !== null)
      params = params.set("alert", alertUuid)

    if (page !== undefined && page !== null)
      params = params.set("page", <any>page)

    if(short_distance !== undefined && short_distance !== null)
      params = params.set("short_distance", short_distance)

    if(uuid !== undefined && uuid !== null)
      params = params.set("uuid", uuid)

    if (o)
      params = params.set("o", o.join(COLLECTION_FORMATS["csv"]))

    return this.http.get(`${this.forestFloorBaseURLAlertEvents}`, { params: params })
  }

  getAlertEventById(uuid: string): any {
    return this.http.get(`${this.forestFloorBaseURLAlertEvents}${uuid}/`)
  }

  clearAlert(uuid: string, reason: AlertCloseReason): Observable<any> {
    const requestBody = {
      name: "close",
      reason: reason,
    }
    return this.http.post(`${this.forestFloorBaseURLAlerts}${uuid}/transition/`, requestBody).pipe(
      tap(() => {
        const filteredActiveAlerts = this.activeAlerts.getValue().filter(alert => alert.alert.uuid !== uuid)
        this.activeAlerts.next(filteredActiveAlerts)
        const acknowledgedIds: string[] = localStorage.getItem("acknowledgedAlertsIds")?.split(",") || []
        acknowledgedIds.filter(id => id === uuid)
        localStorage.setItem("acknowledgedAlertsIds",acknowledgedIds.join(","))
      }),
      catchError((error) => {
        console.error(error)
        return throwError(error)
      }),
    )
  }

  getAllActiveAlerts(state: AlertStateEnum[] = [AlertStateEnum.new]): Observable<EnrichedAlert[]> {
    this.setCurrentAlertsLoading(true)
    this.getAlertsByState(state).pipe(
    // Convert array of alerts into individual observables
      concatMap((allAlerts: any) => from(allAlerts.results)),
      concatMap((alert: any) =>
        forkJoin({
          alert: of(alert), // Include the original alert
          alert_site: this.getLegacySite(alert.site),
          site_clients:  this.siteService.getSiteClientsById(alert.legacy_site_id),
          alert_events: this.getAlertEventsForAlert(alert.uuid, true, undefined, ["fire_alert_first"]).pipe(
            map((events: PaginatedAlertEventList) => filterAndSortAlertEvents(events)),
          ), // Fetch alert events
        }),
      ),
      toArray(),
      finalize(() => {
        this.setCurrentAlertsLoading(false)
      })
    ).subscribe((activeAlerts: EnrichedAlert[]) => {
      this.activeAlerts.next(activeAlerts)
    })
    return this.activeAlerts$
  }

  updateActiveAlerts(): void {
    if (this.webSocketSubject) {
      const websocketMessages = this.webSocketSubject.asObservable()
      websocketMessages.pipe(
        map(messageJSON => messageJSON.message.alert?.split(":").pop()), // Extract the alert UUID
        switchMap(alertUuid => {
          const alerts = this.activeAlerts.getValue()
          const alertIndex = alerts.findIndex(alert => alert.alert.uuid === alertUuid)

          if (alertIndex === -1) {
            // Alert doesn't exist, fetch the new alert and add it to the BehaviorSubject

            return this.getAlertById(alertUuid).then(newAlert =>({ newAlert, alertUuid, isNew: true }))
          } else {
            // Alert exists, fetch updated events and replace the existing alert

            return this.getAlertEventsForAlert(alertUuid, true, undefined, ["fire_alert_first"]).pipe(
              map((alertEvents) => ({ alertEvents, alertUuid, isNew: false })),
            )
          }
        }),
        tap((result: any) => {
          const alerts = this.activeAlerts.getValue()
          const updatedAlerts = result.isNew
            ? [...alerts, result.newAlert]
            : alerts.map(alert =>
              alert.alert.uuid === result.alertUuid
                ? { ...alert, events: filterAndSortAlertEvents(result.alertEvents) }
                : alert,
            )
          this.activeAlerts.next(updatedAlerts)
        })
      ).subscribe({
        error: (err) => console.error("Error in WebSocket connection:", err),
      })
    }
  }

  getAlertNotification(): Observable<any> {
    if (this.webSocketSubject) {
      const websocketMessages = this.webSocketSubject.asObservable()

      return websocketMessages.pipe(
        concatMap((message) => {
          const alertUuid = message.message.alert?.split(":").pop()
          const alertEventUuid = message.message.urn.split(":").pop()

          return forkJoin({
            alert: this.http.get(`${this.forestFloorBaseURLAlerts}${alertUuid}/`),
            alert_events: this.getAlertEventsForAlert(alertUuid, true, alertEventUuid, ["created_at"]),
            all_events: this.getAlertEventsForAlert(alertUuid, true, undefined, ["created_at"]),
          })
        }),
        concatMap((result: any) => {
          const alert = result.alert
          const alertEvents = result.alert_events.results
          const allEvents = result.all_events.results
          // Check if alertEvents array is empty or this is the first alert event for the alert
          if (!alertEvents || alertEvents.length === 0 || allEvents.length === 1) {
            console.log("No alert events within active perimeter found for this alert or this was the first alert event")
            return of({ alert, alert_event: null, sensor_node: null })
          }

          const alertEvent: AlertEvent = alertEvents[alertEvents.length - 1]
          this.existingAlertUpdated.next(true)
          return this.deviceDirectusApiService.getSensorByNsEndDeviceId(alertEvent.payload.end_device_ids.device_id).pipe(
            map((sensorDetails: any) => ({
              alert,
              alert_event: alertEvent,
              sensor_node: sensorDetails.data[0],
            }))
          )
        }),
        concatMap((result: any) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { alert, alert_event, sensor_node } = result

          // Handle case where alert_event is null,
          // meaning the SN that sent the uplink is not within a fire alerting SN perimeter
          if (!alert_event)
            return of({ alert, alert_event: null, sensor_node: null, alert_site: null })

          return this.getLegacySite(alert.site).pipe(
            map((site) => ({
              alert,
              alert_event,
              sensor_node,
              alert_site: site,
            }))
          )
        })
      )
    }
  }

  setClickedDeviceId(clickedDeviceId: string): void {
    this.alertMapClickDeviceId.next(clickedDeviceId)
  }

  setCurrentAlertsLoading(loading: boolean): void {
    this.loadingCurrentAlertsSubject.next(loading)
  }
}
