// export class TTNError {
//   constructor(public code: number) { }
// }

import { Type } from "class-transformer"

// export class TTNApplicationIds {
//   constructor(public application_id: string) { }
// }

// export class TTNApplication {
//   @Type(() => TTNApplicationIds) public ids: TTNApplicationIds
//   constructor(public name: string) { }
// }

// export class TTNApplicationsObject {
//   @Type(() => TTNApplication) public applications: TTNApplication[]
// }

// export class TTNUserLocation {
//   constructor(public latitude: number, public longitude: number) {
//   }
// }

// export class TTNLocations {
//   @Type(() => TTNUserLocation) public user: TTNUserLocation

// }

// export class TTNDeviceIds {
//   @Type(() => TTNApplicationIds) public application_ids: TTNApplicationIds

//   constructor(public device_id: string) { }
// }

// export class TTNDevice {
//   @Type(() => TTNDeviceIds) public ids: TTNDeviceIds
//   @Type(() => TTNLocations) public locations: TTNLocations

//   constructor() { }

// }

// export class TTNDevicesObject {
//   @Type(() => TTNDevice) public end_devices: TTNDevice[]

// }

// export class TTNGatewayAntennasLocation {
//   constructor(public latitude: number, public longitude: number) { }
// }

// export class TTNGatewayAntennas {
//   @Type(() => TTNGatewayAntennasLocation) public location: TTNGatewayAntennasLocation
//   constructor() { }
// }

export class TTNGatewayIds {
  constructor(public gateway_id: string, public eui: string) {}
}

export class TTNGateway {
  @Type(() => TTNGatewayIds) public ids: TTNGatewayIds
    // @Type(() => TTNGatewayAntennas) public antennas: TTNGatewayAntennas[]
    | undefined
  // @Type(() => TTNGatewayAntennas) public antennas: TTNGatewayAntennas[]
  constructor(public name: string) {}
}

// {
//   "ids":
//   {
//     "gateway_id":"eui-b827ebfffe6c7001","eui":"B827EBFFFE6C7001"
//   },
//   "created_at":"2020-05-23T14:45:49.458Z",
//   "updated_at":"2020-05-23T15:10:06.182Z",
//   "name":"MVP Demo Jürgen",
//   "version_ids":{},
//   "gateway_server_address":"eywa-networks.eu1.cloud.thethings.industries",
//   "frequency_plan_id":"EU_863_870",
//   "frequency_plan_ids":["EU_863_870"],
//   "antennas":[
//     {
//       "location":{"latitude":52.863238,"longitude":13.7475143,"source":"SOURCE_REGISTRY"}
//     }
//   ],
//   "enforce_duty_cycle":true,
//   "schedule_anytime_delay":"0.530s"}
