import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {MenuItem} from 'primeng/api';
import { capitalizeFirstLetter } from "@dryad-web-app/shared/helpers"

@Component({
  selector: 'dryad-breadcrumb',
  templateUrl: './dryad-breadcrumb.component.html',
  styleUrls: ['./dryad-breadcrumb.component.scss']
})
export class DryadBreadcrumbComponent  {
  items: MenuItem[] = []
  shouldRender = true

  constructor(
    private router:Router,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      // @ts-ignore
      .subscribe(event => { this.setBreadcrumbItems(event.url as string)});
  }



  private setBreadcrumbItems = (url: string): void => {
    this.items = [] as MenuItem[]
    const urlPortions = url.split("#")[0].split("/").filter(urlPortion => urlPortion !== '')
    this.shouldRender = urlPortions.length > 1

    if (this.shouldRender) {
      urlPortions.forEach((urlPortion, index) => {
        const isFirstUrlPortion = index === 0
        const isLastUrlPortion = index === urlPortions.length - 1

        const label = this.getUrlPortionLabel(urlPortion)
        if(isFirstUrlPortion)
          this.items.push({
            label,
            routerLink: isLastUrlPortion ? undefined : urlPortion
          })
        else {
          const routerLink = this.items[index - 1].routerLink + "/" + urlPortion

          this.items.push({
            label,
            routerLink: isLastUrlPortion ? undefined : routerLink
          })
        }
      })
    }
  }

  private getUrlPortionLabel = (urlPortion: string): string => {
    // @ts-ignore
    const isNumber = !isNaN(urlPortion)

    if (!isNumber) {
      return capitalizeFirstLetter(urlPortion)
    }

    return urlPortion
  }
}
