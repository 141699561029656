<div class="filter filter-messages margin-t-m" [ngClass]="{'flex-center-end':!fullScreen}">
  <div class="flex-center">
    <div class="filter-message-item" *ngIf="!timeRange">
      <p-dropdown [options]="times" [(ngModel)]="selectedTime" optionLabel="name" (onChange)="changeTime()"
                  appendTo="body"></p-dropdown>
    </div>
    <div class="filter-message-item flex" *ngIf="fullScreen">
      <p-checkbox label="Time range" [(ngModel)]="timeRange"
                  binary="true"
                  *ngIf="fullScreen"
                 ></p-checkbox>
    </div>
    <div class="filter-message-item" *ngIf="timeRange">
          <span class="p-float-label input-wrapper ">
              <p-calendar
                dataType="string"
                appendTo="body"
                dateFormat="yy-mm-dd"
                [(ngModel)]="fromDate"  [readonlyInput]="true" inputId="range" [showTime]="true"
              ></p-calendar>
                    <label>From  </label>
              </span>
    </div>
    <div class="filter-message-item" *ngIf="timeRange">
          <span class="p-float-label input-wrapper ">
              <p-calendar
                dataType="string"
                appendTo="body"
                dateFormat="yy-mm-dd"
                [(ngModel)]="toDate"  [readonlyInput]="true" inputId="range" [showTime]="true"
                (onSelect)="changeTime(true)"
              ></p-calendar>
                    <label>To  </label>
              </span>
    </div>
  </div>
</div>

<div class="refresh-graph pt-2 pb-3">
  <div class="flex align-items-center justify-content-center" (click)="getUplinkMessages()">
    <i class="ph ph-arrows-clockwise pr-2"></i><span>Refresh graph data</span>
  </div>
</div>

<div class="sensor-node">
  <div *ngIf="chartOptAir.series[0]?.data?.length > 0"class="grid">
    <div class="col-12 md:col-6 lg:col-6 sensor-node-chart">
      <div appDryadCharts [chartOptions]="chartOptAir" class="charts"></div>
    </div>
    <div class="col-12 md:col-6 lg:col-6 sensor-node-chart">
      <div appDryadCharts [chartOptions]="chartOptTemp" class="charts"></div>
    </div>
  </div>
  <div class="grid" *ngIf="chartOptHumidity.series[0]?.data?.length > 0">
    <div class="col-12 md:col-6 lg:col-6 sensor-node-chart">
      <div
        appDryadCharts
        [chartOptions]="chartOptHumidity"
        class="charts"
      ></div>
    </div>
    <div *ngIf="chartOptIAQ.series[0]?.data?.length > 0" class="col-12 md:col-6 lg:col-6 sensor-node-chart">
      <div appDryadCharts [chartOptions]="chartOptIAQ" class="charts"></div>
    </div>
  </div>
  <div class="grid" *ngIf="chartOptBattery.series[0]?.data?.length > 0">
    <div class="col-12 md:col-6 lg:col-6 sensor-node-chart">
      <div
        appDryadCharts
        [chartOptions]="chartOptBattery"
        class="charts"
      ></div>
    </div>
  </div>
</div>
<div
  class="grid sensor-node"
  *ngIf="chartOptAir.series[0]?.data?.length === 0"
>
  <span>{{nodataMsg}} </span>
</div>
