import { Injectable } from "@angular/core"
import {
  SymbolLayerSpecification,
  CircleLayerSpecification,
  LineLayerSpecification,
  FillLayerSpecification,
} from "maplibre-gl"


export const DEFAULT_PIN_COLOR = "#FF0000"
export const DEFAULT_POLYGON_COLOR = "#FFFFFF"
export const DEFAULT_LINE_COLOR = "#0000FF"
export const DEFAULT_OPACITY = 0.3
export const DEFAULT_PIN_OPACITY = 0.7
export const DEFAULT_LINE_OPACITY = 0.7
export const DEFAULT_LINE_WIDTH = 2
export const DEFAULT_POINT_RADIUS = 6

export type LayerSpecification =
  | CircleLayerSpecification
  | LineLayerSpecification
  | FillLayerSpecification
  | SymbolLayerSpecification

@Injectable()
export class LayerFactory {
  static createLayer(feature: GeoJSON.Feature, id: string): LayerSpecification[] {
    switch (feature.geometry.type) {
      case "Point":
      case "MultiPoint":
        return [
          this.createPointLayer(id, feature),
          this.createTextLayer(id, feature),
        ]
      case "LineString":
      case "MultiLineString":
        return [this.createLineStringLayer(id, feature)]
      case "Polygon":
      case "MultiPolygon":
        return [this.createPolygonLayer(id, feature)]
      default:
        throw new Error(`Unsupported geometry type: ${feature.geometry.type}`)
    }
  }

  private static createPointLayer(
    id: string,
    feature: GeoJSON.Feature
  ): CircleLayerSpecification {
    return {
      id: id,
      type: "circle",
      source: id,
      paint: {
        "circle-radius": feature?.properties?.radius || DEFAULT_POINT_RADIUS,
        "circle-color": feature?.properties?.color || DEFAULT_PIN_COLOR,
        "circle-opacity": feature?.properties?.opacity || DEFAULT_OPACITY,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#FFFFFF",
      },
      metadata: {
        zIndex: -1,
      },
    }
  }

  private static createLineStringLayer(
    id: string,
    feature: GeoJSON.Feature
  ): LineLayerSpecification {
    return {
      id: id,
      type: "line",
      source: id,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": feature?.properties?.color || DEFAULT_LINE_COLOR,
        "line-opacity": feature?.properties?.opacity || DEFAULT_OPACITY,
        "line-width": feature?.properties?.width || DEFAULT_LINE_WIDTH,
      },
      metadata: {
        zIndex: -1,
      },
    }
  }

  private static createPolygonLayer(
    id: string,
    feature: GeoJSON.Feature
  ): FillLayerSpecification {
    return {
      id: id,
      type: "fill",
      source: id,
      paint: {
        "fill-color": feature?.properties?.color || DEFAULT_POLYGON_COLOR,
        "fill-opacity": feature?.properties?.opacity || DEFAULT_OPACITY,
        "fill-outline-color": "#FFFFFF",
      },
      metadata: {
        zIndex: -1,
      },
    }
  }

  static createTextLayer(id: string, feature: GeoJSON.Feature): SymbolLayerSpecification {
    return {
      id: `${id}-text`,
      type: "symbol",
      source: id,
      layout: {
        "text-field": feature?.properties?.name,
        "text-anchor": "bottom",
        "text-offset": [0, -1],
        "text-size": 12,
        visibility: "visible",
        "text-allow-overlap": false,
        "text-ignore-placement": false,
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#ffffff",
        "text-halo-width": 1,
      },
      minzoom: 12,
    }
  }
}
