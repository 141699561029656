import { Type } from "class-transformer"
import { SensorValue } from "./model"
export class TTNUplinkMessageGateway {
  constructor(
    public gtwId: string,
    public timestamp: number,
    public time: string,
    public channel: number,
    public rssi: number,
    public snr: number,
    public rfChain: number,
    public latitude: number,
    public longitude: number,
    public altitude: number
  ) {}
}

export class TTNUplinkMessageMetadata {
  @Type(() => TTNUplinkMessageGateway)
  public gateways: TTNUplinkMessageGateway[] | undefined
  constructor(
    public time: string,
    public frequency: number,
    public modulation: string,
    public dataRate: string,
    public bitRate: string,
    public codingRate: string
  ) {}
}

export class TTNUplinkMessage {
  @Type(() => TTNUplinkMessageMetadata)
  public metadata: TTNUplinkMessageMetadata | undefined
  @Type(() => SensorValue) public sensorValues: SensorValue[] | undefined

  constructor(
    public appId: string,
    public devId: string,
    public hardwareSerial: string,
    public isRetry: boolean,
    public port: number,
    public counter: number,
    public payloadRaw: number
  ) {}

  // FIXME : Remove after 28th demo and migration of devices
  ebwFix() {
    if (this.devId.startsWith("final") || this.devId === "adeunis1")
      this.appId = "eywa-eberswalde-pilot"

    switch (this.devId) {
      case "final_candidate2":
        this.devId = "eywa-eberswalde-pilot-2"
        break
      case "final_3":
        this.devId = "eywa-eberswalde-pilot-3"
        break
      case "adeunis1":
        this.devId = "eywa-eberswalde-pilot-4"
        break
      case "final_candidate5":
        this.devId = "eywa-eberswalde-pilot-5"
        break
      case "final_candidate6":
        this.devId = "eywa-eberswalde-pilot-6"
        break
      case "final_candidate7":
        this.devId = "eywa-eberswalde-pilot-7"
        break
      case "final_candidate8":
        this.devId = "eywa-eberswalde-pilot-8"
        break
      case "final_candidate9":
        this.devId = "eywa-eberswalde-pilot-9"
        break
    }
  }
}
