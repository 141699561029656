<span class="p-float-label input-wrapper">
  <p-calendar
    [formControl]="formControl"
    [formlyAttributes]="field"
    [showTime] ='field.templateOptions?.showTime'
    dataType="string"
    appendTo="body"
  ></p-calendar>
  <label>{{ to.label }} <span *ngIf="to.required">*</span></label>
 <span class="p-invalid error-message" *ngIf="showError">{{
   FormUtils.fieldErrorMessage(to.label, formControl)
   }}</span>
</span>
