export class BaseButton {
  constructor(
    public name: string,
    public command: (event?: any) => void,
    public isVisible: boolean = true,
    public isDisabled: boolean = false,
    public icon?: string,
    public styleClass?: string,
    public label?: string,
    public type?: string, // button / switch or link
    public url?: string // for link
  ) {}
}
