import { HttpClient } from "@angular/common/http"
import { ENVIRONMENTS } from "./environments.token"
import { ServiceLocator } from "./service-locator"
import { directusUrl, serviceRootUrl } from "apps/silvanet-web/src/app/utils/api-utils"


export class ApiBase {
  // Base Api url is a proxy redirect set
  // - in proxy.conf.json for development
  // - in the traefik settings on k8s
  static readonly ENGINE_API_BASE_URL = "/api/"
  static readonly DATA_API_BASE_URL = `${directusUrl}/`
  static readonly FUOTA_API_BASE_URL = "/fuota-api/"
  static readonly FORESTFLOOR_API_BASE_URL = `${serviceRootUrl("forestfloor")}/api/`

  protected http: HttpClient
  protected environment: any

  constructor() {
    this.http = ServiceLocator.injector.get(HttpClient)
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
  }
}
