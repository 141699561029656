<span class="password p-float-label">
  <p-password
    [ngClass]="{ 'ng-invalid': !formControl.valid }"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [toggleMask]="true"
    [feedback]="false"
  >
  </p-password>
  <label>{{ to.label }}<span *ngIf="to.required">*</span></label>
  <small class="p-invalid" *ngIf="showError">{{
    FormUtils.fieldErrorMessage(to.label, formControl)
    }}</small>
</span>
