import { Component, OnDestroy, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { MessageService } from "primeng/api"
import { Subscription } from "rxjs"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { DirectusApiService, NsRoleService, RbacService, RoleService, UserService } from "@dryad-web-app/shared/data-access"
import {
  ADMIN_USER_CREATE,
  ADMIN_USER_UPDATE,
  DB_SETTINGS_UPDATE,
  DryadRoutes,
  NSRole,
  ObservableState,
  Role,
  SELF_USER_UPDATE,
  User, UserPermissionDetail, UserRole,
} from "@dryad-web-app/shared/state"
import { noWhitespaceValidator, validateExistingOrgRequired, checkNewOrgNameExists } from "../../utils/validation-utils"
import { Organization, OrganizationsService } from "../../service/http/organizations.service"
import { UserRequest, UsersService } from "../../service/http/users.service"

@Component({
  selector: "app-user-create",
  templateUrl: "./user-create.component.html",
  styleUrls: ["./user-create.component.scss"],
})
export class UserCreateComponent implements OnInit, OnDestroy {
  userId: string
  actionType: string
  isDryadAdmin: boolean
  isStandardUser: boolean
  isLoading = false
  loggedinUserId: string
  userForm: FormGroup
  organizations: Organization[]
  userRoles: UserRole[] = [{ name: "Admin", value: "admin" }, { name: "Standard User", value: "standard_user" }]
  showNameExistsAlready: boolean
  private subscription = new Subscription()
  isSubmitting = false
  isLoggedInUserEndCustomer: boolean
  legacyRoles: Role[]
  nsRoles: NSRole[]
  nsRolesIds: any[] = []

  constructor(
    private usersService: UsersService,
    private directusApiService: DirectusApiService,
    private router: Router,
    private routerActive: ActivatedRoute,
    private rbacService: RbacService,
    private messageService: MessageService,
    private oss: ObservableState,
    private fb: FormBuilder,
    private legacyUserService: UserService,
    private roleService: RoleService,
    private organizationsService: OrganizationsService,
    private nsRoleService: NsRoleService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.initializeProperties()

    this.subscription.add(
      this.routerActive.params.subscribe(data => {
        this.userId = data.userId
        if (this.userId && this.userId !== this.loggedinUserId) {
          this.actionType = ADMIN_USER_UPDATE
          this.getUserDetails()
        } else if (this.userId === this.loggedinUserId) {
          this.actionType = SELF_USER_UPDATE
          this.getUserDetails()
        } else
          this.actionType = ADMIN_USER_CREATE
        this.initializeForm()
      })
    )
    this.initializeForm()
  }

  getUserDetails(): void {
    this.isLoading = true
    this.directusApiService.getUserDetails(this.userId).subscribe((userDetails: UserPermissionDetail) => {
      this.getOrganisationOptions().then(() => {
        this.userForm.patchValue({
          firstName: userDetails.user.first_name,
          lastName: userDetails.user.last_name,
          emailAddress: userDetails.user.email,
          selectedOrganization: this.getUserOrganization(userDetails),
          selectedPermission: this.getUserRole(userDetails),
        })
        this.nsRoles.map(nsrole => this.nsRolesIds.push({ "ns_role_id": nsrole.id }))
        this.isLoading = false
      })

    })
  }

  getUserOrganization(user: UserPermissionDetail): Organization | undefined {
    return this.organizations.find(org => org.client_id === user.client)
  }

  getUserRole(user: UserPermissionDetail): UserRole {
    const legacyRole = this.legacyRoles.find(role => role.id === user.user.role)

    //* since we now have 2 roles only, any other role that is not Standard User will be deemed Admin
    return this.userRoles.find(role => role.name === legacyRole.name) || { name: "Admin", value: "admin" }
  }

  getLegacyRoleID(): string {
    const selectedPermission: UserRole = this.userForm.getRawValue().selectedPermission
    return selectedPermission.value === "standard user" ? "c213e04c-2557-4be8-98c1-9767b0516000" : "4079c655-2c65-40e2-89f9-35510ff8a097"
  }

  private initializeProperties(): void {
    this.isDryadAdmin = this.rbacService.permit(DB_SETTINGS_UPDATE)
    this.isStandardUser = this.rbacService.isStandardUser()
    this.loggedinUserId = this.oss.userLoggedIn().user.id
    this.isLoggedInUserEndCustomer = this.rbacService.isUserDirectCustomer()
    this.getOrganisationOptions()
  }

  private initializeForm(): void {
    this.userForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.maxLength(50), noWhitespaceValidator]],
      lastName: [null, [Validators.required, Validators.maxLength(50), noWhitespaceValidator]],
      emailAddress: [{ value: null, disabled: this.actionType === ADMIN_USER_UPDATE || this.actionType === SELF_USER_UPDATE },
        [Validators.pattern(/^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,10})+$/), noWhitespaceValidator]],
      newOrganizationChecked: [],
      selectedOrganization: [, { disabled: this.actionType !== ADMIN_USER_CREATE }],
      newOrgName: [""],
      selectedPermission: [{
        disabled: this.actionType === ADMIN_USER_UPDATE
          || this.actionType === SELF_USER_UPDATE,
      }, Validators.required],
    }, { validators: validateExistingOrgRequired() })
  }

  handleSubmit(): void {
    // @ts-ignore temp setting type to any since FF and Engine expect different payloads
    const user: any = {
      first_name: this.userForm.value.firstName.trim(),
      last_name: this.userForm.value.lastName.trim(),
      email: this.userForm.getRawValue().emailAddress.trim(),
      role: this.userForm.getRawValue().selectedPermission.value,
    }

    if (this.userForm.value.newOrganizationChecked) {
      const orgCreatePayload: string = this.userForm.value.newOrgName.trim()

      this.userForm.get("newOrgName")?.valueChanges.subscribe((value) => {
        if (value && value.trim().toLowerCase() !== this.userForm.value.newOrgName.trim().toLowerCase())
          this.showNameExistsAlready = false
      })
      this.showNameExistsAlready = checkNewOrgNameExists(this.organizations, this.userForm)
      if (this.showNameExistsAlready) return

      this.organizationsService.createNewOrganization(orgCreatePayload).subscribe((newOrganisation) => {
        if (newOrganisation)
          user.organization = newOrganisation.url

        this.messageService.add({
          severity: "success",
          summary: "The new organisation has been successfully added to the database.",
        })
        this.submitUser(user as any)
      }, () => {
        this.messageService.add({
          severity: "error",
          summary: "The new organisation could not be created. Please try again.",
        })
      })
    } else {
      user.organization = this.userForm.getRawValue().selectedOrganization.url
      this.submitUser(user as any)
    }
  }

  private submitUser(user: UserRequest): void {
    if (this.actionType === ADMIN_USER_CREATE)
      this.createUser(user)
    else if (this.actionType === ADMIN_USER_UPDATE || this.actionType === SELF_USER_UPDATE)
      this.editUser()
  }

  handleCancel(): void {
    this.router.navigate([DryadRoutes.USER_MANAGEMENT])
  }

  private createUser(user: UserRequest): void {
    this.isSubmitting = true
    this.userForm.disable()
    this.usersService.createUser(user).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success!",
        detail: "You have successfully invited a new user.",
      })
      this.isSubmitting = false
      this.router.navigate([DryadRoutes.USER_MANAGEMENT])
    },
    () => {
      this.messageService.add({
        severity: "error",
        summary: "Error!",
        detail: "The user could not be invited, please try again!",
      })
      this.isSubmitting = false
      this.userForm.enable()
    })
  }

  private editUser(): void {
    //* workaround to support user editing until an endpoint is available in FF

    const userClient = !this.userForm.getRawValue().newOrganisationChecked ?
      this.userForm.value.selectedOrganization.client_id : undefined
    const userToSubmit: any = {
      first_name: this.userForm.value.firstName.trim(),
      last_name: this.userForm.value.lastName.trim(),
      email: this.userForm.getRawValue().emailAddress.trim(),
      role: this.getLegacyRoleID(),
      client: userClient,

      userDetails: {
        client: userClient,
        ns_role: this.nsRolesIds,
      },
    }
    userToSubmit.id = this.userId
    this.isSubmitting = true
    this.userForm.disable()
    this.legacyUserService.update(userToSubmit as User).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: "Success!",
        detail: "User details updated successfully.",
      })
      this.isSubmitting = false
      this.router.navigate([DryadRoutes.USER_MANAGEMENT])
    },
    () => {
      this.messageService.add({
        severity: "error",
        summary: "Error!",
        detail: "The user could not be edited, please try again!",
      })
      this.isSubmitting = false
      this.userForm.enable()
    })
  }

  private async getOrganisationOptions(): Promise<void> {
    const [organizations, legacyRoles, nsRoles] = await Promise.all([
      this.organizationsService.getAllOrganizations().toPromise(),
      this.roleService.list().toPromise(),
      this.nsRoleService.list().toPromise(),
    ])

    this.organizations = organizations.results
    this.legacyRoles = legacyRoles
    this.nsRoles = nsRoles
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
