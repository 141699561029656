<div class="grid" *ngIf="sensorTechInfo" xmlns="http://www.w3.org/1999/html">
  <div class="col-12 md:col-4"><span class="sensor-detail-name">Sensor ID</span></div>
  <div class="col-12 md:col-8">{{sensorTechInfo?.data?.serial_id}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">EUI</span></div>
  <div class="col-12 md:col-8">{{devEUI}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">Join EUI</span></div>
  <div class="col-12 md:col-8">{{sensorTechInfo?.details?.joinEUI || "-"}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">Lorawan Phy Version</span></div>
  <div class="col-12 md:col-8">{{sensorTechInfo?.details?.lorawanPhyVersion || "-"}}</div>


  <div class="col-12 md:col-4"><span class="sensor-detail-name">Lorawan Version</span></div>
  <div class="col-12 md:col-8">{{sensorTechInfo?.details?.lorawanVersion || "-"}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">Device Address</span></div>
  <div class="col-12 md:col-8">{{sensorTechInfo?.data?.device_address || "-"}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">Firmware Version</span></div>
  <div class="col-12 md:col-8">{{fmVersion?.label || fmVersion?.version || "-"}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">ML Version</span></div>
  <div class="col-12 md:col-8">{{mlVersion?.label  || mlVersion?.version || "-"}}</div>

  <div class="col-12 md:col-4"><span class="sensor-detail-name">NS End Device ID</span></div>
  <div class="col-12 md:col-8">{{ sensorTechInfo?.data?.ns_end_device_id || "-"}}</div>
</div>
