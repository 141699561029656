import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core"
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import {
   GET_SITES,
  ObservableState,
  USER_UPDATE,
  UserDetails
} from "@dryad-web-app/shared/state"
import {
  RbacService,
  SiteService,
  UplinkMessageService,
  UserService
} from "@dryad-web-app/shared/data-access"
import { ENVIRONMENTS, ServiceLocator } from "@dryad-web-app/shared/helpers"
import { AuthService } from "@dryad-web-app/shared/auth"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators";

import { PageTitleService } from "../../../../../../../apps/silvanet-web/src/app/service/page-title.service"
import { AlertsService } from "apps/silvanet-web/src/app/service/http/alerts.service"

@Component({
  selector: "dryad-web-app-header",
  templateUrl: "./header.component.html",
  styleUrls: [ "./header.component.scss" ],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  alertCount: number
  topBarMenuActive = false
  activeTopBarItem: any
  // @ts-ignore
  userLoggedIn: UserDetails
  userRole = ""
  environment: any
  private subscription = new Subscription()
  isPageLoading = false
  startPageLoadingTimestamp = 0
  minPageLoadingDuration = 2000 // in ms
  userSiteId: string[] = []
  loggingOut = false

  constructor(
    private uplinkMessageService: UplinkMessageService,
    private authService: AuthService,
    private userService: UserService,
    private oss: ObservableState,
    private router: Router,
    public rbacService: RbacService,
    private siteService: SiteService,
    public pageTitleService: PageTitleService,
    private alertsService: AlertsService,

  ) {
    this.environment = ServiceLocator.injector.get(ENVIRONMENTS)
    if (this.environment.dataApiKey)
      this.siteService.sitesWithAggregate().subscribe(sites => {
        this.oss.dispatch({
          type: GET_SITES,
          payload: sites,
        })
      })
    this.initRouterObserver()
  }

  ngOnInit(): void {
    this.subscription.add(
      this.oss.userLoggedIn$().subscribe((userDetails: UserDetails) => {
        this.userLoggedIn = userDetails
        if (this.userLoggedIn) this.userRole = userDetails.user.roleName
      }),
    )
    this.subscription.add(
      this.alertsService.activeAlerts$.subscribe((activeAlerts) => this.alertCount =  activeAlerts.length)
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  private initRouterObserver = (): void => {
    this.router.events
      .pipe(
        filter(
          (event: Event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.startPageLoadingTimestamp = Date.now()
            this.isPageLoading = true;
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
          default: {
            this.onNavigationEnd()
            break;
          }
        }
      })
  }

  onNavigationEnd = (): void => {
    const expectedEndOfLoading = this.startPageLoadingTimestamp + this.minPageLoadingDuration
    const now = Date.now();
    const minLoadingDurationReached = expectedEndOfLoading <= now

    if (minLoadingDurationReached) {
      this.isPageLoading = false
    } else {
      const endLoadingTimeout = expectedEndOfLoading - now
      setTimeout(() => {
        this.isPageLoading = false;
      }, endLoadingTimeout)
    }
  }

  onTopBarMenuButtonClick(event: any) {
    this.topBarMenuActive = !this.topBarMenuActive
    event.preventDefault()
  }

  userName(): string {
    return this.userLoggedIn?.user?.name() ?? ""
  }

  logout() {
    this.loggingOut = true
    this.userService.logout().subscribe((oktaLogoutOk: boolean) => {
      this.authService.logout().subscribe((logoutOk: boolean) => {
        if (logoutOk) {
          this.environment.dataApiKey = undefined
          this.oss.dispatch({
            type: USER_UPDATE,
            payload: undefined,
          })
          this.router.navigate([ "/" ])
          this.loggingOut = false
        }
      })
    })
  }
}
