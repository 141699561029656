/* eslint-disable max-len */
import { SignalStatus } from "@dryad-web-app/shared/state"

export const  meshGatewayIcon = (signalStatus: SignalStatus): string => {
  const fillColor = (signalStatus === SignalStatus.ACTIVE || signalStatus === SignalStatus.PENDING) ? "#31AE7E" : "#B2B2B2"

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 85 85" fill="none">
      <g clip-path="url(#clip0_2458_54537)">
        <path d="M11.693 33.3594C11.693 60.7812 42.5003 82.1094 42.5003 82.1094C42.5003 82.1094 73.3076 60.7812 73.3076 33.3594C73.3076 25.2786 70.0619 17.5287 64.2844 11.8147C58.5069 6.10072 50.6709 2.89063 42.5003 2.89062C34.3297 2.89063 26.4938 6.10072 20.7163 11.8147C14.9388 17.5287 11.693 25.2786 11.693 33.3594Z" 
        fill="${fillColor}" stroke="#1C6247" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.8174 44.2598C35.4254 42.5649 38.8931 41.6191 42.4997 41.6191C46.1062 41.6191 49.574 42.5649 52.182 44.2598" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.0156 35.0176C29.016 29.939 35.7293 27.0957 42.7201 27.0957C49.7108 27.0957 56.4242 29.939 61.4245 35.0176" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M42.7204 57.903C44.7864 57.903 46.4613 56.3267 46.4613 54.3822C46.4613 52.4377 44.7864 50.8613 42.7204 50.8613C40.6543 50.8613 38.9795 52.4377 38.9795 54.3822C38.9795 56.3267 40.6543 57.903 42.7204 57.903Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2458_54537">
          <rect width="84.5" height="84.5" fill="white" transform="translate(0.25 0.25)"/>
        </clipPath>
      </defs>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}

export const borderGatewayIcon = (signalStatus: SignalStatus): string => {
  const fillColor = (signalStatus === SignalStatus.ACTIVE || signalStatus === SignalStatus.PENDING) ? "#31AE7E" : "#B2B2B2"

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 192 192" fill="none">
      <g clip-path="url(#clip0_2187_83330)">
        <path d="M26 75C26 138 96 187 96 187C96 187 166 138 166 75C166 56.4348 158.625 38.6301 145.497 25.5025C132.37 12.375 114.565 5 96 5C77.4348 5 59.6301 12.375 46.5025 25.5025C33.375 38.6301 26 56.4348 26 75Z"      
          fill="${fillColor}"  stroke="#1C6247" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M101.156 98.6754C100.218 98.841 99.2627 98.9261 98.2947 98.9261C89.6449 98.9261 82.0415 92.132 77.6787 81.8711L64.4453 124.077C63.6375 126.653 65.5619 129.273 68.2621 129.273H105.304C108.004 129.273 109.929 126.653 109.121 124.077L101.156 98.6754Z" fill="white"/>
        <path d="M132.498 93.5739C125.715 100.356 116.516 104.167 106.924 104.167C97.332 104.167 88.1329 100.356 81.3503 93.5739C74.5677 86.7913 70.7573 77.5922 70.7573 68.0002C70.7573 58.4082 74.5677 49.2091 81.3503 42.4265L106.924 68.0002L132.498 93.5739Z" fill="white"/>
        <line x1="109.257" y1="69.5909" x2="122.515" y2="56.3335" stroke="white" stroke-width="6" stroke-linecap="round"/>
        <circle cx="125.59" cy="52.8335" r="7" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2187_83330">
          <rect width="192" height="192" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}

export const sensorIcon = (signalStatus: SignalStatus, is_calibrated?: boolean): string => {
  let fillColor: string

  if ((signalStatus === SignalStatus.ACTIVE || signalStatus === SignalStatus.PENDING) && is_calibrated === true)
    fillColor = "#31AE7E"
  if ((signalStatus === SignalStatus.ACTIVE || signalStatus === SignalStatus.PENDING) && is_calibrated === false)
    fillColor = "#FF9F46"
  else if (signalStatus === SignalStatus.OFFLINE)
    fillColor = "#B2B2B2"

  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 84 85" fill="none">
      <g clip-path="url(#clip0_2458_54541)">
        <path d="M72.625 33.2585C72.625 60.985 42 82.5501 42 82.5501C42 82.5501 11.375 60.985 11.375 33.2585C11.375 25.0879 14.6016 17.2519 20.3449 11.4744C26.0882 5.69693 33.8777 2.45117 42 2.45117C50.1223 2.45117 57.9118 5.69693 63.6551 11.4744C69.3984 17.2519 72.625 25.0879 72.625 33.2585Z" 
        fill="${fillColor}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42 3.77148C34.2258 3.77148 26.7701 6.87814 21.2729 12.408C15.7758 17.9379 12.6875 25.438 12.6875 33.2585C12.6875 46.6464 20.0968 58.6652 27.6779 67.4354C31.4524 71.8019 35.2303 75.3177 38.0649 77.7414C39.4812 78.9524 40.6596 79.8888 41.4811 80.5203C41.6744 80.669 41.8479 80.8007 42 80.9151C42.1521 80.8007 42.3256 80.669 42.5189 80.5203C43.3404 79.8888 44.5188 78.9524 45.9351 77.7414C48.7697 75.3177 52.5476 71.8019 56.3221 67.4354C63.9032 58.6652 71.3125 46.6464 71.3125 33.2585C71.3125 25.438 68.2242 17.9379 62.7271 12.408C57.2299 6.87814 49.7742 3.77148 42 3.77148ZM42 82.5501C41.2473 83.6318 41.2469 83.6315 41.2463 83.6311L41.2447 83.6299L41.2392 83.626L41.22 83.6124C41.2036 83.6007 41.1798 83.5838 41.1491 83.5617C41.0876 83.5175 40.998 83.4526 40.8822 83.3676C40.6505 83.1974 40.3138 82.9465 39.8871 82.6184C39.0337 81.9623 37.8195 80.9972 36.3648 79.7534C33.4572 77.2671 29.5788 73.6587 25.6971 69.1681C17.9657 60.2241 10.0625 47.5971 10.0625 33.2585C10.0625 24.7377 13.4273 16.5659 19.4168 10.5408C25.4062 4.51572 33.5296 1.13086 42 1.13086C50.4704 1.13086 58.5938 4.51572 64.5832 10.5408C70.5727 16.5659 73.9375 24.7377 73.9375 33.2585C73.9375 47.5971 66.0343 60.2241 58.3029 69.1681C54.4212 73.6587 50.5428 77.2671 47.6352 79.7534C46.1805 80.9972 44.9663 81.9623 44.1129 82.6184C43.6862 82.9465 43.3495 83.1974 43.1178 83.3676C43.002 83.4526 42.9124 83.5175 42.8509 83.5617C42.8202 83.5838 42.7964 83.6007 42.78 83.6124L42.7608 83.626L42.7553 83.6299L42.7537 83.6311C42.7531 83.6315 42.7527 83.6318 42 82.5501ZM42 82.5501L42.7527 83.6318C42.3007 83.95 41.6992 83.95 41.2473 83.6318L42 82.5501Z" fill="#02353C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4508 25.7762C30.1603 25.7983 30.7862 26.2493 31.0353 26.9179L38.6332 47.3091L42.4826 39.4379C42.777 38.8359 43.386 38.4546 44.0529 38.4546H48.5625C49.529 38.4546 50.3125 39.2428 50.3125 40.215C50.3125 41.1873 49.529 41.9754 48.5625 41.9754H45.1423L39.9861 52.5186C39.678 53.1488 39.0269 53.5344 38.3296 53.4998C37.6324 53.4652 37.0222 53.017 36.7772 52.3594L29.254 32.1687L25.3571 40.9337C25.0754 41.5674 24.4497 41.9754 23.7596 41.9754H19.25C18.2835 41.9754 17.5 41.1873 17.5 40.215C17.5 39.2428 18.2835 38.4546 19.25 38.4546H22.6252L27.7991 26.8171C28.0889 26.1653 28.7414 25.7541 29.4508 25.7762Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M56 39.8594C56 38.4588 55.5117 37.1396 54.6774 36.1711C54.0445 35.4363 54.1236 34.3245 54.854 33.6878C55.5844 33.0511 56.6896 33.1307 57.3226 33.8654C58.7347 35.5049 59.5 37.6535 59.5 39.8594C59.5 42.0653 58.7347 44.2139 57.3226 45.8534C56.6896 46.5882 55.5844 46.6677 54.854 46.031C54.1236 45.3943 54.0445 44.2825 54.6774 43.5477C55.5117 42.5792 56 41.26 56 39.8594Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M63 39.8587C63 36.7021 61.7673 33.6693 59.5633 31.4093C58.8864 30.7152 58.897 29.6006 59.587 28.9198C60.2769 28.2389 61.3849 28.2496 62.0617 28.9436C64.9062 31.8604 66.5 35.7779 66.5 39.8587C66.5 43.9396 64.9062 47.8571 62.0617 50.7738C61.3849 51.4679 60.2769 51.4786 59.587 50.7977C58.897 50.1168 58.8864 49.0022 59.5633 48.3082C61.7673 46.0482 63 43.0154 63 39.8587Z" fill="white"/>
        <path d="M46.375 39.8587C46.375 41.8032 47.942 43.3796 49.875 43.3796C51.808 43.3796 53.375 41.8032 53.375 39.8587C53.375 37.9142 51.808 36.3379 49.875 36.3379C47.942 36.3379 46.375 37.9142 46.375 39.8587Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2458_54541">
          <rect width="84" height="84.5" fill="white" transform="translate(0 0.25)"/>
        </clipPath>
      </defs>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}

export const sensorFireIcon = (): string => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 192 192" fill="none">
      <g clip-path="url(#clip0_2187_83354)">
        <path d="M166 75C166 138 96 187 96 187C96 187 26 138 26 75C26 56.4348 33.375 38.6301 46.5025 25.5025C59.6301 12.375 77.4348 5 96 5C114.565 5 132.37 12.375 145.497 25.5025C158.625 38.6301 166 56.4348 166 75Z" fill="#ED2323"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M96 8C78.2305 8 61.1888 15.0589 48.6238 27.6238C36.0589 40.1888 29 57.2305 29 75C29 105.42 45.9355 132.729 63.2638 152.656C71.8913 162.578 80.5265 170.566 87.0054 176.074C90.2428 178.825 92.9363 180.953 94.8139 182.388C95.2557 182.726 95.6523 183.025 96 183.285C96.3477 183.025 96.7443 182.726 97.1861 182.388C99.0637 180.953 101.757 178.825 104.995 176.074C111.473 170.566 120.109 162.578 128.736 152.656C146.064 132.729 163 105.42 163 75C163 57.2305 155.941 40.1888 143.376 27.6238C130.811 15.0589 113.77 8 96 8ZM96 187C94.2796 189.458 94.2786 189.457 94.2774 189.456L94.2735 189.453L94.261 189.445L94.2172 189.414C94.1796 189.387 94.1254 189.349 94.0551 189.298C93.9146 189.198 93.7098 189.051 93.445 188.857C92.9155 188.471 92.1459 187.901 91.1705 187.155C89.2199 185.664 86.4447 183.472 83.1196 180.645C76.4735 174.996 67.6087 166.797 58.7362 156.594C41.0645 136.271 23 107.58 23 75C23 55.6392 30.691 37.0714 44.3812 23.3812C58.0714 9.69105 76.6392 2 96 2C115.361 2 133.929 9.69105 147.619 23.3812C161.309 37.0714 169 55.6392 169 75C169 107.58 150.936 136.271 133.264 156.594C124.391 166.797 115.526 174.996 108.88 180.645C105.555 183.472 102.78 185.664 100.83 187.155C99.8541 187.901 99.0845 188.471 98.555 188.857C98.2902 189.051 98.0854 189.198 97.9449 189.298C97.8746 189.349 97.8204 189.387 97.7828 189.414L97.739 189.445L97.7265 189.453L97.7226 189.456C97.7214 189.457 97.7204 189.458 96 187ZM96 187L97.7204 189.458C96.6874 190.181 95.3126 190.181 94.2796 189.458L96 187Z" fill="#ED5E11"/>
        <path d="M67.9076 62.3541C66.5826 62.0213 54.4967 81.3297 59.0004 104.049C60.1472 109.824 62.4464 121.405 72.8789 129.848C86.8354 141.135 103.932 137.585 106.231 137.058C108.775 136.504 123.661 132.876 131.087 119.514C132.479 117.012 136.204 110.084 134.611 101.969C133.448 96.0343 129.673 90.6372 128.399 91.0311C127.703 91.2474 128.159 93.0223 127.358 96.6056C127.024 98.1032 125.687 104.199 123.216 104.449C121.546 104.621 119.586 101.98 118.868 99.4955C117.793 95.7736 119.53 92.5731 120.538 89.9993C124.797 78.9501 119.981 67.3241 119.291 65.6489C115.873 57.3787 108.808 51.6211 107.483 52.4365C106.815 52.8525 108.001 54.7107 107.901 58.2162C107.762 62.992 105.284 68.7108 102.512 68.9493C99.7397 69.1878 96.444 64.0237 95.2749 59.2479C93.2077 45.1182 103.786 32.3622 102.735 30.7652C100.575 28.441 91.6341 31.6305 86.3677 37.3714C79.8432 44.4602 79.7653 54.6164 79.7152 57.4008C79.6873 60.7289 80.155 63.4524 79.9267 70.4025C79.6929 77.4691 79.0527 79.3495 77.6443 80.1039C75.5789 81.2132 72.1719 79.7211 70.19 77.63C65.1408 72.3051 68.8261 62.5871 67.9076 62.3541Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_2187_83354">
          <rect width="192" height="192" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}

export const alertBadge = (): string => {
  const svg = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10.7" fill="#FD6666" stroke="white" stroke-width="1.4"/>
      <path d="M12.0195 14.6067C11.7662 14.6067 11.5662 14.5267 11.4195 14.3667C11.2862 14.2067 11.2062 13.98 11.1795 13.6867L10.5795 6.04668C10.5395 5.55334 10.6462 5.16001 10.8995 4.86668C11.1529 4.56001 11.5262 4.40668 12.0195 4.40668C12.4995 4.40668 12.8595 4.56001 13.0995 4.86668C13.3529 5.16001 13.4595 5.55334 13.4195 6.04668L12.8195 13.6867C12.8062 13.98 12.7262 14.2067 12.5795 14.3667C12.4462 14.5267 12.2595 14.6067 12.0195 14.6067ZM12.0195 18.7667C11.5662 18.7667 11.1995 18.6267 10.9195 18.3467C10.6529 18.0667 10.5195 17.7067 10.5195 17.2667C10.5195 16.84 10.6529 16.4933 10.9195 16.2267C11.1995 15.9467 11.5662 15.8067 12.0195 15.8067C12.4862 15.8067 12.8462 15.9467 13.0995 16.2267C13.3662 16.4933 13.4995 16.84 13.4995 17.2667C13.4995 17.7067 13.3662 18.0667 13.0995 18.3467C12.8462 18.6267 12.4862 18.7667 12.0195 18.7667Z" fill="white"/>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}

export const warningBadge = (): string => {
  const svg = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10.7" fill="#FF9F46" stroke="white" stroke-width="1.4"/>
      <path d="M12.0195 14.6067C11.7662 14.6067 11.5662 14.5267 11.4195 14.3667C11.2862 14.2067 11.2062 13.98 11.1795 13.6867L10.5795 6.04668C10.5395 5.55334 10.6462 5.16001 10.8995 4.86668C11.1529 4.56001 11.5262 4.40668 12.0195 4.40668C12.4995 4.40668 12.8595 4.56001 13.0995 4.86668C13.3529 5.16001 13.4595 5.55334 13.4195 6.04668L12.8195 13.6867C12.8062 13.98 12.7262 14.2067 12.5795 14.3667C12.4462 14.5267 12.2595 14.6067 12.0195 14.6067ZM12.0195 18.7667C11.5662 18.7667 11.1995 18.6267 10.9195 18.3467C10.6529 18.0667 10.5195 17.7067 10.5195 17.2667C10.5195 16.84 10.6529 16.4933 10.9195 16.2267C11.1995 15.9467 11.5662 15.8067 12.0195 15.8067C12.4862 15.8067 12.8462 15.9467 13.0995 16.2267C13.3662 16.4933 13.4995 16.84 13.4995 17.2667C13.4995 17.7067 13.3662 18.0667 13.0995 18.3467C12.8462 18.6267 12.4862 18.7667 12.0195 18.7667Z" fill="white"/>
    </svg>
  `
  return `data:image/svg+xml;base64,${window.btoa(svg)}`
}
