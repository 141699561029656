<div  class="search p-input-icon-left" *ngIf="!isAutoComplete">
    <input
      pInputText
      type="text"
      placeholder="Search"
      (input)="searchInputText($event.target.value)"
    />
  <i class="ph ph-magnifying-glass search-icon"></i>
</div>
<div  class="search-auto search p-input-icon-left" *ngIf="isAutoComplete">
  <p-autoComplete placeholder="Search" [(ngModel)]="selectedInput"
                  [suggestions]="suggestions" (completeMethod)="onCompleteMethod($event)"
                  [field]="field" [minLength]="minLength"
                  (onSelect)="onSelectMethods($event)"
                  (onClear)="onClearMethods($event)"
                  [inputStyle]="{'width':'100%','font-size': '16px'}"
  ></p-autoComplete>
  <i class="ph ph-magnifying-glass"></i>
  <i *ngIf="selectedInput" class="ph ph-x-circle close-icon ph-2x" (click)="clearInput()"></i>
</div>
