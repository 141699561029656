<div
  class="grid sensor-list"
  *ngFor="let gateway of gateways"
  (mouseover)="gateway.hover = true"
  (mouseleave)="gateway.hover = false"
>
  <div class="col-12">
    <ng-template #headerActions>
      <img src="{{gateway.icon}}"  width="30px" height="30px">
    </ng-template>
    <dryad-web-app-row-expansion
      [label]="gateway.name"
      [headerButtons]="headerButtonSensorList"
      [headerActions]="headerActions"
      [showIndicators]="true"
      [showAddButton]="true"
    >
      <ng-template let-item>
        <app-gateway-data [gateway]="gateway"></app-gateway-data>
      </ng-template>
    </dryad-web-app-row-expansion>
  </div>
  <ng-template #headerButtonSensorList>
    <app-button-panel
      [buttonList]="buttonList"
      *ngIf="gateway.hover"
      [selectedEntity]="gateway"
    ></app-button-panel>
  </ng-template>
</div>

