<div class="flex">
  <div class="filter-table">
    <div class="dry-table margin-0">
      <p-table
        #deviceTable
        [value]="filterDevices"
        [globalFilterFields]="['name', 'deviceType', 'site']"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="false"
        [rowsPerPageOptions]="[10, 25, 50]"
        [totalRecords]="totalDevice"
        [(selection)]="selectedDevices"
        dataKey="name"
        [scrollable]="true"
        (selectionChange)="changedSelections()"
        scrollDirection="both"
        (onLazyLoad)="filterDevicesList($event)"
        [lazy]="true"
        [loading]="loading"
      >
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of tableColumns" class="filter-table-header " style="width:300px">
              {{col.label}}
            </th>
            <th></th>
            <th>
              <i class="ph ph-2x ph-clock-clockwise clickable icon-active" pTooltip="Reset to default" *ngIf="showResetButton" (click)="resetDevices()"></i>
            </th>
          </tr>
          <tr>
            <th  style="width:300px"><p-columnFilter type="text" field="name" [showMenu]="false"></p-columnFilter></th>
            <th  style="width:300px">
              <p-columnFilter field="site" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="selectedSites" placeholder="Any"
                                 appendTo="body"
                                 (onChange)="filter($event.value)" optionLabel="name"  style="width:300px">
                    <ng-template let-option pTemplate="item">
                      <div class="p-multiselect-representative-option">
                        <span class="p-ml-1">{{option.name}}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
            <th  style="width:300px">
              <p-columnFilter field="deviceType" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="selectedDeviceType" placeholder="Any"
                                 appendTo="body"
                                 (onChange)="filter($event.value)" optionLabel="label"  style="width:300px">
                    <ng-template let-option pTemplate="item">
                      <div class="p-multiselect-representative-option">
                        <span class="p-ml-1">{{option.label}}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-device>
          <tr>
            <td class="filter-table-td clickable" *ngFor="let col of tableColumns" style="width:300px"
                data-cy="{{device[col.field]}}"
                title="{{device[col.field]}}"
                (click)="clickOnDevice(device)"
            >
              {{ device[col.field] }}
            </td>
            <td class="filter-table-td">
              <span *ngIf="device.deviceData.latitude && device.deviceData.latitude" pTooltip="Deployed">  <i
                class="ph ph-2x ph-check-circle icon-active flex"></i></span>
              <span *ngIf="!(device.deviceData.latitude && device.deviceData.latitude)"
                    pTooltip="Pending deployment"> <i
                class="ph ph-2x ph-check-circle icon-inactive flex"></i></span>
            </td>
            <td class="table-buttons">
              <app-button-panel
                [buttonList]="buttonList"
                [selectedEntity]="device"
              ></app-button-panel>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
