import { Component, OnInit } from "@angular/core"
import { DryadRoutes, FLOATING_PANEL_ACTIVATE, FloatingPanel,
  ObservableState,
  User } from "@dryad-web-app/shared/state"
import { Router } from "@angular/router"
import { LazyLoadEvent } from "primeng/api"
import { UserFF, UsersService } from "../../service/http/users.service"

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {

  allUsers: UserFF[] = []
  isLoading: boolean
  totalRecords: number

  constructor(
    private oss: ObservableState,
    private router: Router,
    private usersService: UsersService
  ) {
  }

  ngOnInit(): void {
    this.createAddUserFloatingPanel()

    this.loadUsers({
      first: 0,
      rows: 10,
    })
  }

  async loadUsers(event: LazyLoadEvent): Promise<void> {
    this.isLoading = true
    const pageNumber = (event.first / event.rows) + 1 || 1
    const pageSize = event.rows || 10
    const usersResponse = await this.usersService.getAllUsers(pageNumber, pageSize).toPromise()
    this.allUsers = usersResponse.results
    this.totalRecords = usersResponse.count
    this.isLoading = false
  }

  addUser(): void {
    this.router.navigate([DryadRoutes.USER_CREATE])
  }

  editUser(user: User): void {
    this.router.navigate([DryadRoutes.USER_MANAGEMENT + "/" + user.id])
  }

  createAddUserFloatingPanel(): void {
    const floatingPanel = new FloatingPanel("Add User", () => this.addUser(), "button")
    this.oss.dispatch({
      type: FLOATING_PANEL_ACTIVATE,
      payload: floatingPanel,
    })
  }
}
